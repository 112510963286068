<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <form @submit.prevent class="row align-items-center">
            <div class="col-auto pr-0">
              <span class="fe fe-search text-muted"></span>
            </div>
            <div class="col">
              <input
                type="search"
                class="form-control form-control-flush search"
                placeholder="Search"
                v-model="search"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-nowrap card-table table-hover">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.title">
              <a
                :class="{ sort: column.sortable, 'text-muted': sort != column.title }"
                href="#"
                @click="
                  sort = column.title
                  descending = !descending
                "
                >{{ column.friendly ? column.friendly : column.title }}</a
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in companies" :key="row._id" @click="viewCompany(row)">
            <td>
              {{ row.created | moment('MMM Do YYYY') }}
              <span v-if="row.partner" class="badge badge-soft-primary">{{
                row.partner.name
              }}</span>
            </td>
            <td>{{ row.name }}</td>
            <td>{{ row.friendlyName }}</td>
            <!-- <td>      
              <div class="dropdown d-inline-block">
                <a class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" href="#!" role="button"><i class="fe fe-more-vertical"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" @click="deleteLocation(row._id)" href="javascript:;">
                    <span class="text-danger">Delete</span>
                  </a>
                </div>
              </div>
            </td>-->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'

const { mapMutations, mapState, mapActions } = createNamespacedHelpers('company')

export default {
  name: 'CompanyTable',
  data: () => {
    return {
      searchResults: [],
      searchable: ['name'],
      search: '',
      sort: 'created',
      descending: true,
      columns: [
        { title: 'created', sortable: true },
        { title: 'name', sortable: true },
        { title: 'friendly name', sortable: true },
      ],
    }
  },
  computed: {
    ...mapState(['companies']),
    orderedData: function () {
      return _.orderBy(this.companies, this.sort, this.descending ? 'desc' : 'asc')
    },
  },
  methods: {
    ...mapActions(['getCompanyList']),
    viewCompany(company) {
      this.$router.push({
        name: 'company-detail',
        params: { id: company._id },
      })
    },
  },
  mounted() {
    this.getCompanyList()
  },
  created() {
    this.$on('dataChanged', (results) => {
      this.searchResults = results
    })
  },
  watch: {
    search: debounce(function () {
      this.getCompanyList({ search: this.search })
    }, 500),
  },
}
</script>

<style scoped lang="scss">
.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
