<template>
  <div class="location-selector">
    <multiselect
      :disabled="false"
      v-model="selectedLocations"
      :multiple="multiple"
      selectLabel=""
      :options="locations"
      track-by="_id"
      :closeOnSelect="false"
      placeholder="Select locations"
      :custom-label="customLocationName"
    />
  </div>
</template>

<script>
import { uniqBy } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'
import Multiselect from 'vue-multiselect'

const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'LocationSelector',
  props: {
    companyId: {
      type: [String, Array],
    },
    value: {
      type: Array,
    },
    multiple: {
      type: Boolean,
      default: true,
    },
  },
  components: {
    multiselect: Multiselect,
  },
  data() {
    return {
      locations: [],
      selectedLocations: [],
    }
  },
  watch: {
    selectedLocations(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.selectedLocations = val
    },
    companyId(val) {
      if (val) {
        this.setLocations()
      }
    },
  },
  methods: {
    ...LocationModule.mapActions(['getCompanyLocations']),
    async setLocations() {
      if (this.companyId) {
        let allLocations = []
        if (Array.isArray(this.companyId)) {
          for (const company of this.companyId) {
            const locations = await this.getCompanyLocations(company)
            if (locations && locations.length) {
              allLocations = [...allLocations, ...locations]
            }
          }
        } else {
          allLocations = await this.getCompanyLocations(this.companyId)
        }
        this.locations = uniqBy(allLocations, (location) => location._id)
      } else {
        this.locations = []
      }

      this.selectedLocations = []
    },
    customLocationName(location) {
      return `${location.friendlyName || location.city} ${
        this.displayIds ? ' - ' + location._id : ''
      }`
    },
  },
  mounted() {
    this.setLocations()
  },
}
</script>
