var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"card"},[_c('div',{staticClass:"card-header"},[_c('div',{staticClass:"row align-items-center"},[_c('div',{staticClass:"col"},[_c('form',{staticClass:"row align-items-center",on:{"submit":function($event){$event.preventDefault();}}},[_vm._m(0),_c('div',{staticClass:"col"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"form-control form-control-flush search",attrs:{"type":"search","placeholder":"Search"},domProps:{"value":(_vm.search)},on:{"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})])])]),_vm._m(1)])]),_c('div',{staticClass:"table-responsive"},[_c('table',{staticClass:"table table-sm table-hover table-nowrap card-table"},[_c('thead',[_c('tr',_vm._l((_vm.columns),function(column){return _c('th',{key:column.title},[_c('a',{class:{ sort: column.sortable, 'text-muted': _vm.sort != column.title },attrs:{"href":"#"},on:{"click":function($event){_vm.sort = column.title
                _vm.descending = !_vm.descending}}},[_vm._v(_vm._s(column.friendly ? column.friendly : column.title))])])}),0)]),_c('tbody',_vm._l((_vm.locations),function(row){return _c('tr',{key:row._id,on:{"click":function($event){return _vm.viewLocation(row)}}},[_c('td',[_vm._v(" "+_vm._s(_vm._f("moment")(row.created,'MMM Do YYYY'))+" "),(row.partner)?_c('span',{staticClass:"badge badge-soft-primary"},[_vm._v(_vm._s(row.partner.name))]):_vm._e()]),_c('td',[_c('div',{staticClass:"truncate"},[_vm._v(_vm._s(row.name))])]),_c('td',[_vm._v(" "+_vm._s(row.city)+" "+_vm._s(row.friendlyName ? `(${row.friendlyName})` : '')+" "),(row.disabled)?_c('span',{staticClass:"small text-muted"},[_vm._v("(disabled)")]):_vm._e()]),_c('td',[_vm._v(_vm._s(row.phone))]),_c('td',[_vm._v(_vm._s(row.activationCode))]),_c('td',[_c('div',{staticClass:"avatar-group"},_vm._l((_vm.getPlatforms(row)),function(platform){return _c('div',{key:platform._id,staticClass:"avatar avatar-xs"},[_c('img',{staticClass:"avatar-img rounded-circle",attrs:{"src":`/img/platforms/${platform.network}.png`,"alt":"..."}})])}),0)]),_c('td',_vm._l((row.devices),function(device){return _c('div',{key:device._id},[_c('div',{staticClass:"badge",class:[
                  {
                    'badge-success': _vm.minutesAgo(device.lastPing) < 1,
                    'badge-danger': _vm.minutesAgo(device.lastPing) > 1 || !device.lastPing,
                  },
                ]},[_vm._v(" "+_vm._s(device.device || 'iPad')+" ")])])}),0)])}),0)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto pr-0"},[_c('span',{staticClass:"fe fe-search text-muted"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"col-auto"},[_c('div',{staticClass:"dropdown"})])
}]

export { render, staticRenderFns }