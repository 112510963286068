<template>
  <div id="subscription-section">
    <div class="card">
      <div class="card-body">
        <!-- if no subscriptions show create button -->
        <div class="d-flex flex-wrap justify-content-between align-items-center">
          <h2 class="mb-0 platform-source">Subscriptions</h2>
          <div class="input-group input-group-sm input-group-merge w-50 mx-2">
            <input
              type="search"
              class="form-control form-control-prepended search"
              placeholder="Search"
              v-model="search"
              @input="filterSubscriptions"
            />
            <div class="input-group-prepend">
              <div class="input-group-text">
                <span class="fe fe-search"></span>
              </div>
            </div>
          </div>
          <button class="btn btn-primary btn-sm" @click="openSubscriptionModal()">
            Add a subscription
          </button>
        </div>
      </div>
    </div>

    <div class="text-center" v-if="fetchingData">Loading Subscriptions...</div>
    <div v-else>
      <div v-if="selectSubscriptions.length">
        <div v-for="(subscription, index) of subscriptions" :key="index">
          <SubscriptionCard :subscription="subscription" @edit="openSubscriptionModal" />
        </div>
      </div>
      <div class="text-center" v-else>-- No Subscriptions --</div>
    </div>

    <SubscriptionModal
      :selectedSubscription="selectedSubscription"
      :company="this.company"
      @clearSelectedSubscription="clearSelectedSubscription"
      @refresh="fetchAllSubscriptions"
    />
  </div>
</template>

<script>
import SubscriptionCard from './SubscriptionCard'
import SubscriptionModal from '@/components/Modals/SubscriptionModal'
import { createNamespacedHelpers } from 'vuex'

const SubscriptionModule = createNamespacedHelpers('subscription')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'Subscription',
  components: { SubscriptionCard, SubscriptionModal },
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    fetchingData: true,
    showEditor: false,
    selectedSubscription: null,
    search: '',
    filteredSubscriptions: [],
  }),
  async mounted() {
    this.fetchProductData()
    await this.fetchAllSubscriptions()
    this.fetchingData = false
  },
  computed: {
    ...SubscriptionModule.mapGetters(['selectSubscriptions']),
    ...LocationModule.mapGetters(['selectCompanyLocations']),
    subscriptions() {
      return this.search ? this.filteredSubscriptions : this.selectSubscriptions
    },
  },
  methods: {
    ...SubscriptionModule.mapActions(['fetchProductData', 'fetchSubscriptions']),
    openSubscriptionModal(subscription) {
      this.selectedSubscription = subscription || null
      const originalProducts = subscription?.stripe?.products
      localStorage.setItem('originalProducts', JSON.stringify(originalProducts))
      this.$root.$emit('bv::show::modal', 'SubscriptionModal')
    },
    clearSelectedSubscription() {
      this.selectedSubscription = null
    },
    filterSubscriptions() {
      const searchRegex = new RegExp(this.search, 'gi')
      this.filteredSubscriptions = this.selectSubscriptions.filter((s) =>
        searchRegex.test(s.nickName)
      )
    },
    async fetchAllSubscriptions() {
      await this.fetchSubscriptions({
        types: ['company', 'location'],
        companyIds: [this.company._id],
        locationIds: this.selectCompanyLocations.map((l) => l._id),
      })
    },
  },
}
</script>
