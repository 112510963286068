<template>
    <div class="card" :style="cardStyle" v-if="reviewSubscription">
        <div class="card-body">
					<div class="row align-items-center" v-if="!loading">
						<div class="col-auto">
							<b-img
								alt="Source Image"
								style="width:25px; border-radius: 5px" 
								left :src="getNetworkLogo()">
							</b-img>
						</div>
						<div class="col ml--2">
								<!-- Title -->
								<h4 class="card-title mb-1 review-subscription-network">
								<a href="#!">{{ network }}</a>
								</h4>

								<!-- Text -->
								<p class="card-text small text-muted mb-1">
									<span v-if="reviewSubscriptionState === states.DISABLED">Enable to start tracking {{ network }} reviews.</span>
									<span v-else-if="reviewSubscriptionState === states.UNABLE_TO_LOCATE">Unable to find the platform.</span>
									<span v-else-if="locked">Review subscription is locked for sync with Zembra.</span>
									<span v-else-if="reviewSubscription.reviewCount">
										<i class="fe fe-star"></i> {{ reviewSubscription.globalRating }} <span class="badge badge-soft-secondary">{{ reviewSubscription.reviewCount.available.total }}</span> 
									</span>
									<span v-else>No reviews yet.</span>
								</p>
						</div>
						<div class="col-auto">
							<!-- Dropdown -->
							<div class="dropdown" v-if="!locked">
									<a href="#!" class="dropdown-ellipses dropdown-toggle" role="button" data-toggle="dropdown" aria-haspopup="true" data-expanded="false">
											<i class="fe fe-more-vertical"></i>
									</a>
									<div class="dropdown-menu dropdown-menu-right">
											<a href="#!" class="dropdown-item" @click="$emit('show-enable-review-subscription')" v-if="reviewSubscriptionState === states.DISABLED || reviewSubscriptionState === states.UNABLE_TO_LOCATE">
												Enable
											</a>
											<a href="#!" class="dropdown-item" @click="wrapper(disable)()" v-if="reviewSubscriptionState === states.ENABLED">
												Disable
											</a>
											<a href="#!" class="dropdown-item" @click="refresh()" v-if="reviewSubscriptionState === states.ENABLED">
												Refresh
											</a>
									</div>
							</div>
							<div v-else>
								<p class="h1 pt-2"><i class="fe fe-lock icon-x-large"></i></p>
							</div>
						</div>
					</div>
					<FadeTransition  v-else>
					<div class="row">
						<div class="col d-flex justify-content-center">
							<div class="mt-2 mb-3 loader loader-primary"></div>
						</div>
					</div>
					</FadeTransition>
        </div>
    </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import FadeTransition from '@/components/Transitions/FadeTransition'

const LocationModule = createNamespacedHelpers('location')
const ReviewSubscriptionModule = createNamespacedHelpers('reviewSubscription')

export default {
  name: 'ReviewSubscriptionCard',
  props: ['network'],
  components: {
		FadeTransition
	},
	data: () => ({
		loading: false,
		states: {
			UNABLE_TO_LOCATE: 'UNABLE_TO_LOCATE',
			DISABLED: 'DISABLED',
			ENABLED: 'ENABLED'
		}
	}),
  computed: {
	...LocationModule.mapGetters(['selectActiveLocation']),
    ...ReviewSubscriptionModule.mapGetters(['selectReviewSubscriptionByNetwork']),
		reviewSubscription() {
			return this.selectReviewSubscriptionByNetwork(this.network)
		},
		disabled() {
			return !this.reviewSubscription?._id || this.reviewSubscription?.disabled === true ? true : false
		},
		reviewSubscriptionState() {
			if (!this.reviewSubscription?.slug) {
				return this.states.UNABLE_TO_LOCATE
			} else if (!this.reviewSubscription?._id || this.reviewSubscription?.disabled === true) {
				return this.states.DISABLED
			} else {
				return this.states.ENABLED
			}
		},
		cardStyle() {
			return {
				"border-color": {
					[this.states.UNABLE_TO_LOCATE]: "#EF5350",
					[this.states.DISABLED]: "#42A5F5",
					[this.states.ENABLED]: "#66BB6A"
				}[this.reviewSubscriptionState],
				"border-width": "3px"
			}
		},
		locked() {
			return this.reviewSubscription?.lockedAwaitingReviews === true
		},
		dropdownOptions() {
			return [
				...(this.reviewSubscriptionState === this.states.DISABLED ? [{ name: 'Enable', emit: 'enable-review-subscription' }] : []),
				...(this.reviewSubscriptionState === this.states.ENABLED ? [{ name: 'Disable', emit: 'disable-review-subscription' }] : []),
				...(this.reviewSubscriptionState === this.states.UNABLE_TO_LOCATE ? [{ name: 'Enable', emit: 'enable-review-subscription' }] : []),
			]
		}
  },
	methods: {
		...ReviewSubscriptionModule.mapActions(['disableReviewSubscription', 'refreshReviewSubscription']),
		wrapper(f) {
			const self = this
			return async function() {
				const reviewSubscription = arguments[0]
				try {
					self.loading = true
					await f.apply(this, arguments)
				} catch (error) {
					self.$notify({
						title: 'Something went wrong.',
						text: `${error.body?.message || error.message}`,
						type: 'error',
						duration: 10000,
					})
				} finally {
					self.loading = false
				}
			}
		},
		async refresh() {
			try {
				this.loading = true
				await this.refreshReviewSubscription({ 
					reviewSubscriptionId: this.reviewSubscription._id 
				})
				this.$notify({
					title: 'Success',
					text: 'Review subscription was successfully refreshed',
					type: 'success',
				})
			} catch (error) {
				this.$notify({
					title: 'Something went wrong.',
					text: `${error.body?.message || error.message}`,
					type: 'error',
					duration: 10000,
				})
			} finally {
				this.loading = false
			}
		},
		getNetworkLogo() {
			var images = require.context('../../../../public/img/platforms', false, /\.svg$/)
    		return images('./' + this.network + ".svg")
		},
		async disable() {
			await this.disableReviewSubscription({ 
				locationId: this.selectActiveLocation._id,
				reviewSubscriptionId: this.reviewSubscription._id 
			})
		}
	}
}
</script>
