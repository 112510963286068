import Vue from 'vue'

export default {
  list(payload) {
    return Vue.http.post('v2/companies/list', payload)
  },
  get(companyId) {
    return Vue.http.get(`v2/companies/${companyId}`)
  },
  transactions(payload) {
    return Vue.http.post(`v2/transactions/list`, payload)
  },
  update(companyId, payload) {
    return Vue.http.put(`v2/companies/${companyId}`, payload)
  },
  updatePartner(partnerId, payload) {
    return Vue.http.put(`v2/partners/${partnerId}`, payload)
  },
  connectStripeAccount(companyId, stripeCustomerId) {
    return Vue.http.post(`company/${companyId}/connect-stripe-account`, { stripeCustomerId })
  },
  payments(companyId, lastItemId) {
    return Vue.http.get(`company/${companyId}/payments`, { params: { lastItemId } })
  },
  assignOrganization(payload) {
    return Vue.http.post('company/assign-organization', payload)
  },
  create(payload) {
    return Vue.http.post('v2/companies', payload)
  },
  createBusinessCredit(payload) {
    return Vue.http.post('v2/business-credit', payload)
  },
  updateBusinessCredit({ businessCreditId, reason, notes, amount }) {
    return Vue.http.put(`v2/business-credit/${businessCreditId}`, {
      reason,
      notes,
      amount,
    })
  },
  voidBusinessCredit(businessCreditId) {
    return Vue.http.delete(`v2/business-credit/${businessCreditId}`)
  },
  getBusinessCredits(payload) {
    return Vue.http.post('v2/business-credit/list', payload)
  },
  getCompanyTestDataPreview({ companyId, createdDate }) {
    return Vue.http.post(`v2/companies/${companyId}/preview-test-data`, { createdDate })
  },
  fetchIndustries() {
    return Vue.http.get(`v2/companies/industries`,)
  },
  uploadUsers({ companyId, users, cognitoAuthentication }) {
    return Vue.http.post(`v2/companies/${companyId}/users`, {
      users,
      cognitoAuthentication,
    })
  },
  uploadLocations({ companyId, locations }) {
    return Vue.http.post(`v2/companies/${companyId}/locations`, {
      locations,
    })
  },
  getSurveySourceOverrides({ companyId }) {
    return Vue.http.get(`v2/companies/${companyId}/survey-source-overrides`)
  },
  createSurveySourceOverride({ companyId, payload }) {
    return Vue.http.post(`v2/companies/${companyId}/survey-source-overrides`, payload)
  },
  deleteSurveySourceOverride({ companyId, overrideId }) {
    return Vue.http.delete(`v2/companies/${companyId}/survey-source-overrides`, {
      body: { overrideId },
    })
  },
  applySurveySourceOverride({ companyId, overrideIds }) {
    return Vue.http.post(`v2/companies/${companyId}/survey-source-overrides/apply`, {
      overrideIds,
    })
  },
}
