<template>
  <div class="card">
    <div class="card-header">
      <!-- header -->
      <div class="d-flex justify-content-between align-items-center">
        <span class="pt-3">
          <h2 class="mb-2 text-capitalize">{{ subscription.type }} Subscription</h2>
          <span class="text-muted">
            {{ subscription.nickName }}
          </span>
        </span>
        <span>
          <button class="btn btn-primary btn-sm" @click="edit">Edit Subscription</button>
        </span>
      </div>
    </div>

    <div class="card-body">
      <!-- if not completely set up show url -->
      <div class="mb-4" v-if="['pending', 'incomplete'].includes(subscription.status)">
        <h3 class="mb-3">Url</h3>
        <div class="d-flex">
          {{ url }}
          <span
            class="fe fe-copy px-2 cursor-pointer ml-2 text-primary"
            v-clipboard:copy="url"
            v-clipboard:success="onCopyToClipboard"
          ></span>
        </div>
      </div>

      <!-- if location subscription list location names -->
      <div class="mb-4" v-if="subscription.type === 'location' && subscription.locations">
        <h3 class="mb-3">Locations</h3>
        {{ subscription.locations.length }} Location(s): {{ locationList }}
      </div>

      <!-- point of contact render -->
      <div>
        <h3>Point of Contact</h3>
        <div class="d-flex mt-3">
          <!-- names -->
          <div>
            <h4>Full Name</h4>
            <div
              class="mb-2"
              v-for="(contact, index) of subscription.contacts"
              :key="'name-' + index"
            >
              {{ contact.name }}
            </div>
          </div>
          <!-- emails -->
          <div class="ml-5">
            <h4>Email</h4>
            <div
              class="mb-2"
              v-for="(contact, index) of subscription.contacts"
              :key="'email-' + index"
            >
              {{ contact.email }}
            </div>
          </div>
          <!-- phones -->
          <div class="ml-5">
            <h4>Phone Number</h4>
            <div
              class="mb-2"
              v-for="(contact, index) of subscription.contacts"
              :key="'phone-' + index"
            >
              {{ contact.phone }}
            </div>
          </div>
        </div>
      </div>

      <!-- products render -->
      <div class="mt-4">
        <h3>Products</h3>
        <div class="d-flex mt-3">
          <!-- product names -->
          <div>
            <h4>Product</h4>
            <div class="mb-2" v-for="(product, index) of products" :key="'product-' + index">
              {{ product.name }}
            </div>
          </div>
          <!-- product quantity -->
          <div class="ml-5">
            <h4>Quantity</h4>
            <div class="mb-2" v-for="(product, index) of products" :key="'quantity-' + index">
              {{ product.quantity }}
            </div>
          </div>
          <!-- prices -->
          <div class="ml-5">
            <h4>Price</h4>
            <div class="mb-2" v-for="(product, index) of products" :key="'price-' + index">
              ${{ (product.price.unit_amount / 100).toFixed(2) }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Config from '@/config'

const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'SubscriptionCard',
  props: {
    subscription: {
      type: Object,
    },
  },
  computed: {
    ...LocationModule.mapGetters(['selectCompanyLocations']),
    locationList() {
      const locationNames = this.subscription.locations?.map((locationId) => {
        const l = this.selectCompanyLocations.find((cl) => cl._id === locationId)
        return l && (l.friendlyName || l.city || l.name)
      })
      return locationNames.join(', ')
    },
    products() {
      return this.subscription.stripe?.products?.filter((p) => p.active === true)
    },
    url() {
      return Config.isProduction 
        ? `https://subscription.ovationup.com/subscriptions/${this.subscription._id}`
        : `https://subscription-test.ovationup.com/subscriptions/${this.subscription._id}`
    },
  },
  methods: {
    edit() {
      this.$emit('edit', this.subscription)
    },
    onCopyToClipboard() {
      this.$notify({
        title: 'Copied to clipboard!',
      })
    },
  },
}
</script>
