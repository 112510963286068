<template>
  <div class="ivr-play-audio-content">
    <div class="row">
      <div class="col col-2 align-self-center ml-4">
        <label style="color: #95aac9">Audio File</label>
      </div>
      <div class="col col-8 mr-3 my-2">
        <S3FileUploader
          accept="audio/mp3"
          :filePath="`${uploadPath}/${name}.mp3`"
          :bucketName="bucketName"
          title="Upload Audio"
          @uploaded="handleUpload"
          v-if="uploaded !== true"
        />
        <div class="row mb-3 ml-1" v-else>
          <audio id="audio-player" controls>
            <source :src="cloudUrl" type="audio/mp3" />
            Your browser does not support the audio tag.
          </audio>
          <i
            class="fe fe-x text-danger icon-large mr-4 align-self-center ml-2"
            @click="clearUpload"
          ></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import S3FileUploader from '@/components/Modules/s3/S3FileUploader'

const S3Module = createNamespacedHelpers('s3')

export default {
  name: 'IvrPlayAudio',
  props: ['uploadPath', 'name', 'url'],
  components: { S3FileUploader },
  data: () => ({
    uploaded: false,
    cloudUrl: null,
  }),
  computed: {
    bucketName() {
      if (process.env.NODE_ENV === 'production') return 'ovation-secure-production'
      else return 'ovation-secure-sandbox'
    },
  },
  methods: {
    ...S3Module.mapActions(['getSignedUrl']),
    trimUrlToS3Key(url) {
      const urlObj = new URL(url)
      let path = urlObj.pathname
      return path.replace(/^\//, '')
    },
    async handleUpload(url) {
      const key = this.trimUrlToS3Key(url)
      this.cloudUrl = await this.getSignedUrl({
        bucket: this.bucketName,
        key,
        contentType: 'audio/mp3',
      })
      this.uploaded = true
      this.emitUpdate()
    },
    clearUpload() {
      this.uploaded = false
      this.cloudUrl = null
      this.emitUpdate()
    },
    emitUpdate() {
      const modifications = {}

      if (this.cloudUrl) {
        modifications.url = `companies${this.cloudUrl.split('companies')[1]}`.split('?')[0]
      }
      this.$emit('playAudioUpdate', modifications)
    },
  },
  async mounted() {
    if (this.url) {
      this.cloudUrl = await this.getSignedUrl({
        bucket: this.bucketName,
        key: this.url,
        contentType: 'audio/mp3',
      })
      this.uploaded = true
    }
    this.emitUpdate()
  },
}
</script>
