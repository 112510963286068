<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <!-- Header -->
          <div class="header mt-md-5">
            <div class="header-body">
              <!-- Pretitle -->
              <h6 class="header-pretitle">Integration providers</h6>

              <!-- Title -->
              <h1 class="header-title display-4">
                All providers
                <span style="float: right">
                  <button
                    v-b-modal.creatIntegrationModal
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-outline-primary"
                    type="button"
                  >
                    + Add
                  </button></span
                >
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="list-group" role="tablist">
            <router-link
              :to="`/integration-providers/${provider._id}`"
              class="list-group-item"
              v-for="provider in sortedProviders"
              :key="provider._id"
            >
              {{ provider.displayName || provider.name }}
            </router-link>
          </div>
        </div>
      </div>
      <!-- create integration-partner model -->
      <!-- <b-modal
        ref="modal"
        id="creatIntegrationModal"
        hide-footer
        hide-header
        hide-backdrop
        body-class="p-0"
      >
        <div class="modal-card card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title" id="exampleModalCenterTitle">
                  Create Integration Provider
                </h4>
              </div>
              <div class="col-auto">
                <button @click="modalClose" type="button" class="close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" style="max-height: 650px">
            <div class="row">
              <div class="col-12">
                <form>
                  <div class="form-group">
                    <label for="partner-name">Provider Name </label>
                    <input type="text" class="form-control" v-model="name" ref="name" />
                    <div v-if="isNameInUse" class="text-left">
                      <p id="errorMessage">
                        that name is already in the use, please try using another name
                      </p>
                    </div>
                    <br />

                    <label for="email">Email</label>
                    <input
                      id="email"
                      type="text"
                      class="form-control"
                      placeholder="Email ( optional )"
                      v-model="email"
                    /><br />

                    <label for="lacation-name">Phone</label>
                    <input
                      id="location-name"
                      type="text"
                      class="form-control"
                      placeholder="Phone number ( optional ) "
                      v-model="phone"
                    /><br />

                    <label for="url">URL</label>
                    <input
                      id="url"
                      type="text"
                      class="form-control"
                      placeholder="Url ( optional ) "
                      v-model="url"
                    /><br />

                    <label for="logo-url">Logo URL</label>
                    <input
                      id="logo-url"
                      type="text"
                      class="form-control"
                      placeholder="Logo url ( optional ) "
                      v-model="logoUrl"
                    /><br />

                    <button
                      :disabled="isValid"
                      class="btn btn-primary form-control"
                      @click="create"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </b-modal> -->
      <!-- end of create integration-partner model -->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { BModal, VBModal } from 'bootstrap-vue'
const integrationProvidersModule = createNamespacedHelpers('integrationProvider')

export default {
  name: 'IntegrationProvidersView',
  components: {
    BModal,
  },
  data() {
    return {
      description: '',
      disabled: false,
      displayName: '',
      inputs: [],
      name: '',
      type: '',
      nameInUse: false,
      errorStyle: 'color: red',
    }
  },

  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...integrationProvidersModule.mapState(['integrationProviders']),
    // isValid() {
    //   return this.name.length < 1
    // },
    // isNameInUse() {
    //   return this.nameInUse
    // },
    sortedProviders() {
    return this.integrationProviders.sort((a, b) =>
      (a.displayName || a.name).toLowerCase().localeCompare((b.displayName || b.name).toLowerCase())
    )
  },
  },
  methods: {
    ...integrationProvidersModule.mapActions([
      'fetchIntegrationProviders',
    ]),

    modalClose() {
      this.$refs.modal.hide()
    },

    async create(e) {
      e.preventDefault()

      let provider = {}
      // partner.name = this.name
      // this.phone ? (partner.phone = this.phone) : (partner = { ...partner })
      // this.email ? (partner.email = this.email) : (partner = { ...partner })
      // this.url ? (partner.url = this.url) : (partner = { ...partner })
      // this.logoUrl ? (partner.logoUrl = this.logoUrl) : (partner = { ...partner })

      // creating the integation
      await this.createIntegrationProvider(provider)
      const response = await this.activeProvider()

      // handle the response errors
      if (response && response.status === 409) {
        this.nameInUse = true
        this.$refs.name.style.border = '1px solid red'
      } else {
        this.nameInUse = false

        if (response && response.status === 200) {
          const { _id: id } = response.body
          this.$router.push({ name: 'integration-partner', params: { id: id } })
        }
      }
    },
  },
  mounted() {
    this.fetchIntegrationProviders()
  },
}
</script>

<style scoped lang="scss">
#errorMessage {
  color: red;
}
</style>
