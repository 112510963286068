import DeletionBackupService from '@/services/DeletionBackupService'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    async createDeletionBackups(_, payload) {
      const response = await DeletionBackupService.createDeletionBackups(payload)
      return response
    },
  },
}
