import TagService from '@/services/TagService'
import { cloneDeep } from 'lodash'

export default {
  namespaced: true,
  state: {
    tags: [],
    originalTags: [],
    campaignId: null,
  },
  mutations: {
    setTags(state, tags) {
      state.tags = [...tags]
    },
    setOrginalTags(state, tags) {
      state.originalTags = [...tags]
    },
    addTag(state, tag) {
      state.originalTags = [tag, ...state.originalTags]
      state.tags = cloneDeep(state.originalTags)
    },
    updateTag(state, tag) {
      state.tags = state.tags.map((s) => (s._id === tag._id ? tag : s))
      state.originalTags = cloneDeep(state.tags)
    },
    removeTag(state, tagId) {
      state.tags = state.tags.filter((s) => s._id !== tagId)
      state.originalTags = cloneDeep(state.tags)
    },
  },
  actions: {
    async getTags({ commit }) {
      const {
        body: { tags },
      } = await TagService.list()

      commit('setTags', tags)
      commit('setOrginalTags', tags)
    },

    async addTag({ commit }, tagName) {
      const { body } = await TagService.create({
        name: tagName,
      })

      commit('addTag', body.tag)

      return body.tag
    },

    async archiveTag({ commit }, tagId) {
      await TagService.archive(tagId)
      commit('removeTag', tagId)
    },

    async updateTag({ commit }, { tagId, ...fields }) {
      const { body } = await TagService.update({ tagId, fields })
      commit('updateTag', body.tag)
    },

    filterTags({ state, commit }, search = '') {
      const cleanSearch = search.trim().toLowerCase()

      if (cleanSearch) {
        const filteredTags = state.tags.filter((s) => s.name.toLowerCase().includes(cleanSearch))
        commit('setTags', filteredTags)
      } else {
        commit('setTags', state.originalTags)
      }
    },

    async activateTagAction({ commit }, { tagId, action }) {
      const { body } = await TagService.activateAction({
        tagId,
        action,
      })

      commit('updateTag', body.tag)
    },
    
    async bulkImportCustomers({ commit }, payload){
      const response = await TagService.bulkImport(payload)
      return response
    }
  },
}
