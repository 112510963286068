import Vue from 'vue'

export default {
  create(payload) {
    return Vue.http.post('pentaApi/integration', payload)
  },
  list(locationIds) {
    return Vue.http.post('pentaApi/integration/list', {
      locationIds: locationIds,
    })
  },
  getProviders() {
    return Vue.http.get('pentaApi/integration/providers')
  },
}
