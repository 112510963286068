<template>
  <table class="table table-hover table-responsive">
    <thead>
      <tr>
        <th v-for="header in headers" :key="header">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(user, index) in userErrors" :key="index">
        <td>
          {{ user.firstName }}
        </td>
        <td>
          {{ user.lastName }}
        </td>
        <td :class="['col-3']">
          {{ formatPhoneNumber(user.phone) }}
        </td>
        <td>
          {{ user.success }}
        </td>
        <td>
          {{ user.reason }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'UsersUploadErrors',
  components: {},
  props: {
    userErrors: {
      type: Array,
    },
  },
  computed: {
    headers() {
      return (
        Object.entries(this.userErrors?.[0]).map((e) => {
          return e[0]
        }) || []
      )
    },
  },
  methods: {
    formatPhoneNumber(phone) {
      const cleaned = ('' + phone).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return phone
    },
  },
}
</script>

<style></style>
