<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <div class="mt-6">
            <LocationMap />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LocationMap from '../components/Modules/LocationMap/LocationMap.vue'

export default {
  name: 'LocationMapPage',
  components: {
    LocationMap,
  },
}
</script>
<style scoped></style>
