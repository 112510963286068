<template>
  <div class="card">
    <div class="card my-0">
      <div class="card-body">
        <div class="row align-items-center">
          <div class="col">
            <h4 class="card-title mb-1 platform-source">
              Overages
            </h4>
            <p class="card-text small text-muted mb-1">
              CSV of overages for the last month
            </p>
          </div>
          <div class="col-auto">
            <a class="btn btn-white btn-sm" download :href="overagesUrl">
              Download
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Config from '@/config'

const { api } = Config

export default {
  name: 'ReportsTable',
  computed: {
    overagesUrl() {
      return (api.substr(-1) === '/' ? api : api + '/') + 'report/overages'
    }
  }
}
</script>

<style scoped lang="scss">
</style>
