<template>
  <div class="IntegrationId">{{ displayValue }}</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const integrationModule = createNamespacedHelpers('integration')

export default {
  name: 'IntegrationId',
  props: ['id', 'integrationLocationId'],
  computed: {
    ...integrationModule.mapGetters([
      'selectIntegrationId',
      'selectIntegrationByIntegrationLocation',
    ]),
    displayValue() {
      if (this.id) {
        return this.selectIntegrationId(this.id)
      } else if (this.integrationLocationId) {
        return this.selectIntegrationByIntegrationLocation(this.integrationLocationId)?._id
      }
      return null
    },
  },
}
</script>