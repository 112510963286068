<template>
  <div class="main-content">
    <div class="container-fluid" v-if="activeLocation">
      <!-- <div class="container-fluid"> -->
      <div class="row align-items-center">
        <div class="col">
          <!-- Header -->
          <div class="header mt-md-5">
            <div class="header-body">
              <!-- Pretitle -->
              <router-link :to="'/company/' + activeLocation.company._id">
                <h6 class="header-pretitle">{{ activeLocation.company.name }}</h6>
              </router-link>

              <!-- Title -->
              <h1 class="header-title display-4">
                {{ activeLocation.friendlyName || activeLocation.city }}
              </h1>
            </div>
          </div>
        </div>
        <div class="col-auto">
          <div class="dropdown">
            <a
              href="#"
              class="dropdown-ellipses dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <i class="fe fe-more-vertical"></i>
            </a>
            <div
              class="dropdown-menu dropdown-menu-right"
              x-placement="top-end"
              style="
                position: absolute;
                will-change: transform;
                top: 0px;
                left: 0px;
                transform: translate3d(-171px, -118px, 0px);
              "
            >
              <a
                href="#"
                v-if="!activeLocation.disabled"
                @click="confirmModal(activeLocation._id, 'Disable')"
                class="dropdown-item"
                >Disable</a
              >
              <a
                href="#!"
                v-if="activeLocation.disabled"
                @click="enable(activeLocation._id)"
                class="dropdown-item"
                >Enable</a
              >
            </div>
          </div>
        </div>
      </div>
      <!-- platform section -->
      <div v-if="!isLoading">
        <ReviewSubscriptionDisplay :locationId="activeLocation._id" />
      </div>
      <hr />
      <!-- custom cta's section -->
      <div class="row">
        <div class="col-11 col-lg-11 col-xl-11">
          <h2 class="mb-4">Custom CTAs</h2>
        </div>
        <div class="col-1 col-lg-1 col-xl-1">
          <div @click.prevent="handleCreateCTA" class="show text-right">
            <a class="btn btn-sm btn-primary btn-rounded-circle" href="#" role="button">+</a>
          </div>
        </div>
        <div class="col-md-6 col-sm-12 col-xm-12 cta-list">
          <div class="cta-item" v-for="cta in callToActions" :key="cta._id">
            <LocationCustomCTA :cta="cta" @editCTA="handleEditCTA" @deleteCTA="handleDeleteCTA" />
          </div>
        </div>
      </div>
      <hr />
      <!-- Order Platforms section -->
      <div class="row">
        <div class="col-12 col-lg-12 col-xl-12">
          <h2 class="mb-4">Order Platforms</h2>
          <location-order-platforms :location="activeLocation" />
        </div>
      </div>
      <hr />
      <div class="row" v-if="activeLocation.devices.length">
        <div class="col-12 col-lg-12 col-xl-12">
          <h2 class="mb-4">Devices</h2>
          <location-devices
            :location="activeLocation"
            v-on:unlink-device="unlinkDevice"
          ></location-devices>
        </div>
      </div>
      <hr />
      <div class="row">
        <div class="col-12 col-lg-12 col-xl-12">
          <h2 class="mb-4">Utilities</h2>
          <location-sources :location="activeLocation"></location-sources>
        </div>
      </div>
      <div class="row" v-if="activeLocation">
        <div class="col-12 col-lg-12 col-xl-12">
          <h2 class="mb-4">Data</h2>
          <location-info :location="activeLocation"></location-info>
        </div>
      </div>
      <!-- hard-phone section -->
      <div class="row" v-if="activeLocation">
        <div class="col-12 col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-2">Hard Phone</h2>
              <small class="text-muted form-text mb-3">
                This is the location's real-world phone number
              </small>
              <div class="row">
                <div class="col-12 col-md-12 col-lg-6 col-xl-6">
                  <div class="form-group">
                    <input
                      type="text"
                      class="form-control mb-4"
                      v-model="form.hardPhone"
                      @input="updateAndFormatPhoneNumber"
                    />
                    <button class="btn btn-primary" @click.prevent="updateHardPhone">Update</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- hard-phone section end -->
      <LocationBilling class="row mx-1" />
      <!-- ivr section -->
      <div class="row" v-if="activeLocation && activeLocation.company.settings.ivrEnabled">
        <div class="col-12 col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-3 platform-source">Call to Text</h2>
              <div class="row">
                <div class="col-auto">
                  <div class="custom-control custom-checkbox-toggle">
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      :id="`location-ivr-toggle`"
                      @change="initIVR"
                      v-model="overideCompanyIvr"
                    />
                    <label class="custom-control-label" :for="`location-ivr-toggle`"></label>
                  </div>
                </div>
                <div class="col">
                  <small class="text-muted">{{
                    overideCompanyIvr === true ? 'Using Location Override' : 'Using Default Company'
                  }}</small>
                </div>
              </div>
              <div class="row" v-if="showIvrBuilder">
                <div class="col-12 mt-4">
                  <IvrBuilder
                    :ivr="selectIvrs.length ? selectIvrs[0] : null"
                    :companyId="
                      activeLocation && activeLocation.company ? activeLocation.company._id : null
                    "
                    :locationId="activeLocation ? activeLocation._id : null"
                    @deleted="overideCompanyIvr = false"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- gift card section -->
      <div class="row">
        <div class="col-12 col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Gift Cards</h2>
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="form-group">
                    <label>Gift Cards Enabled</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="gift-cards-enabled"
                            v-model="giftCardSettings.giftCardEnabled"
                          />
                          <label class="custom-control-label" for="gift-cards-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          giftCardSettings.giftCardEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                    <div class="row mt-3" v-if="giftCardSettings.giftCardEnabled">
                      <div class="col-lg-6 col-md-12 mt-2">
                        <label for="program-id">Program Id</label>
                        <input
                          v-model="giftCardSettings.programId"
                          class="form-control"
                          id="program-id"
                          type="text"
                          placeholder="Enter program id"
                        />
                      </div>
                      <div class="col-lg-6 col-md-12 mt-2">
                        <label for="terminal-id">Terminal Id</label>
                        <input
                          v-model="giftCardSettings.terminalId"
                          class="form-control"
                          id="terminal-id"
                          type="text"
                          placeholder="Enter terminal id"
                        />
                      </div>
                    </div>
                    <div class="row" v-if="giftCardSettings.giftCardEnabled">
                      <div class="col-lg-6 col-md-12 mt-2">
                        <label for="apikey">ApiKey</label>
                        <input
                          v-model="giftCardSettings.credentials.apiKey"
                          class="form-control"
                          id="apikey"
                          type="text"
                          placeholder="Enter api key"
                        />
                      </div>
                      <div class="col-lg-6 col-md-12 mt-2">
                        <label for="secret">Secret</label>
                        <input
                          v-model="giftCardSettings.credentials.secret"
                          class="form-control"
                          id="secret"
                          type="text"
                          placeholder="Enter secret key"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <button @click.prevent="saveGiftCardSettings" class="btn btn-primary">
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <LocationAddressForm :addressDetails="activeLocation.addressDetails" @updateAddressDetails="updateAddressDetails"/>
      </div>
      <!-- <LocationTransfer class="row" /> -->
    </div>
    <!-- modal section -->
    <LocationDisableModal @process="disable" :companyLocations="otherActiveCompanyLocations" />
    <CreateAndEditCTAModal />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import IvrBuilder from '@/components/Modules/Ivrs/IvrBuilder.vue'
import LocationService from '@/services/LocationService'
import AddressService from '@/services/AddressService'
import LocationCustomCTA from '@/components/Modules/Locations/LocationDetail/LocationCustomCTA'
import LocationOrderPlatforms from '@/components/Modules/Locations/LocationDetail/LocationOrderPlatforms'
import LocationSources from '@/components/Modules/Locations/LocationDetail/LocationSources'
import LocationDevices from '@/components/Modules/Locations/LocationDetail/LocationDevices'
import LocationAddressForm from '@/components/Modules/Locations/LocationDetail/LocationAddressForm'
import LocationInfo from '@/components/Modules/Locations/LocationDetail/LocationInfo'
import LocationBilling from '@/components/Modules/Locations/LocationDetail/LocationBilling.vue'
// import LocationTransfer from '@/components/Modules/Locations/LocationDetail/LocationTransfer.vue'
import LocationDisableModal from '@/components/Modals/LocationDisableModal.vue'
import CreateAndEditCTAModal from '@/components/Modals/CreateAndEditCTAModal.vue'
import ReviewSubscriptionDisplay from '@/components/Modules/ReviewSubscriptions/ReviewSubscriptionDisplay.vue'

const LocationModule = createNamespacedHelpers('location')
const AuthModule = createNamespacedHelpers('auth')
const IvrModule = createNamespacedHelpers('ivr')
const ReviewSubscriptionModule = createNamespacedHelpers('reviewSubscription')

export default {
  name: 'LocationDetail',
  props: ['id'],
  components: {
    IvrBuilder,
    LocationOrderPlatforms,
    LocationSources,
    LocationDevices,
    LocationInfo,
    LocationBilling,
    // LocationTransfer, // commenting out as backend is not currently supported
    LocationDisableModal,
    LocationCustomCTA,
    CreateAndEditCTAModal,
    ReviewSubscriptionDisplay,
    LocationAddressForm,
  },
  data: () => {
    return {
      isLoading: false,
      processLocationId: undefined,
      overideCompanyIvr: false,
      modalAction: undefined,
      form: {
        hardPhone: null,
      },
      companyLocations: [],
      ivrInitialized: false,
      giftCardSettings: {
        programId: null,
        terminalId: null,
        credentials: {
          apiKey: null,
          secret: null,
        },
        giftCardEnabled: false,
      },
    }
  },
  computed: {
    ...LocationModule.mapState(['locations', 'activeLocation']),
    ...LocationModule.mapGetters(['selectCompanyLocations', 'selectCallToActions']),
    ...AuthModule.mapGetters(['selectEmail']),
    ...IvrModule.mapGetters(['selectIvrs']),
    ...ReviewSubscriptionModule.mapGetters(['selectReviewSubscriptions']),
    orderedData: function () {
      return _.orderBy(this.locations, this.sort)
    },
    callToActions: function () {
      return this.selectCallToActions
    },
    otherActiveCompanyLocations() {
      return this.companyLocations?.filter(
        (l) => this.activeLocation && l._id !== this.activeLocation._id && !l.disabled
      )
    },
    showIvrBuilder() {
      return (
        this.overideCompanyIvr === true &&
        (this.ivrInitialized || (this.selectIvrs.length && this.selectIvrs[0].location))
      )
    },
  },
  watch: {
    overideCompanyIvr(val, oldVal) {
      if (oldVal === true && val === false) {
        if (this.selectIvrs && this.selectIvrs.length && this.selectIvrs[0]._id) {
          this.deleteIvr({
            ivrId: this.selectIvrs[0]._id,
          })
        }
      }
    },
    activeLocation(value) {
      if (value) {
        this.populateGiftCardSettings()
      }
    },
  },
  methods: {
    ...LocationModule.mapActions([
      'deleteCTA',
      'getLocation',
      'setCurrentCTA',
      'updateLocation',
      'setIsEditingCTA',
      'fetchCallToActions',
      'getCompanyLocations',
      'moveLocationCustomers',
    ]),
    ...IvrModule.mapActions(['getIvrs', 'getIvrEnums', 'deleteIvr', 'removeIvrId']),
    ...ReviewSubscriptionModule.mapActions(['getReviewSubscriptions', 'createInitial']),
    async initIVR(event) {
      console.log('clicked', event, this.overideCompanyIvr)
      if (this.overideCompanyIvr) {
        console.log('getting ivrs')
        await this.getIvrs({
          filters: {
            companyIds: [this.activeLocation.company._id],
          },
        })
        await this.removeIvrId()
        this.ivrInitialized = true
      }
    },
    deleteLocation(locationId) {
      console.log('delete location', locationId)
    },
    unlinkDevice: async function (deviceId) {
      await LocationService.unlinkDevice(deviceId, this.activeLocation._id)
      this.getLocation(this.id)
    },
    confirmModal(locationId, action) {
      this.processLocationId = locationId
      this.modalAction = action
      this.$root.$emit('bv::show::modal', 'LocationDisableModal')
    },
    async disable({ destination }) {
      try {
        if (destination) {
          await this.moveLocationCustomers({ origin: this.processLocationId, destination })
        }
        await LocationService.disable(this.processLocationId)
        this.getLocation(this.id)
        this.$notify({
          text: `Successfully disabled location: ${
            this.activeLocation.friendlyName || this.activeLocation.city
          }`,
        })
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Failed to disable location, check console and submit a bug',
        })
      }
    },
    enable: async function (locationId) {
      await LocationService.enable(locationId)
      this.getLocation(this.id)
    },
    async updateAddressDetails(addressDetails) {
      try {
        await AddressService.update(this.activeLocation.addressDetails._id, addressDetails)
        this.$notify({
          text: `Successfully updated address details`,
        })
      } catch (error) {
        console.log(error)
        this.$notify({
          type: 'error',
          text: 'Failed to update address details, check console and submit a bug',
        })
      }
    },
    async updateHardPhone() {
      let hardPhone = this.form.hardPhone
      const locationId = this.activeLocation._id
      hardPhone = hardPhone && hardPhone.length ? hardPhone.replace(/[^a-zA-Z0-9]/g, '') : ''

      if (!hardPhone.length || hardPhone.length < 10) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Invalid phone number. Please check the number and then try again.',
        })
        return
      }

      try {
        await this.updateLocation({
          locationId,
          fields: {
            hardPhone,
          },
        })

        this.$notify({
          type: 'info',
          title: 'Success',
          text: `Location's hardPhone was updated successfully.`,
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again later.',
        })
      }
    },
    formatPhoneNumber(phone) {
      if (!phone) {
        return
      }
      phone = phone.replace(/[^\d]/g, '')

      if (phone.length < 4) {
        return phone
      }
      if (phone.length < 7) {
        return `(${phone.slice(0, 3)}) ${phone.slice(3)}`
      }
      return `(${phone.slice(0, 3)}) ${phone.slice(3, 6)}-${phone.slice(6, 10)}`
    },
    updateAndFormatPhoneNumber(event) {
      const phone = this.form.hardPhone
      this.form.hardPhone = this.formatPhoneNumber(phone)
    },
    handleEditCTA(cta) {
      this.setCurrentCTA(cta)
      this.setIsEditingCTA(true)
      this.$root.$emit('bv::show::modal', 'CreateAndEditCTAModal')
    },
    async handleDeleteCTA(ctaId) {
      try {
        await this.deleteCTA({
          ctaId,
          locationId: this.activeLocation._id,
        })
        this.$notify({
          title: 'Success',
          text: 'CTA was deleted Successfully.',
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: `
          Sorry, something went wrong, we're unable 
          to remove that CTA. Please try again later.
          `,
          duration: 5000,
        })
      }
    },
    handleCreateCTA() {
      this.setCurrentCTA(null)
      this.setIsEditingCTA(false)
      this.$root.$emit('bv::show::modal', 'CreateAndEditCTAModal')
    },
    async saveGiftCardSettings() {
      const fields = {
        'settings.giftCardEnabled': this.giftCardSettings.giftCardEnabled,
        'settings.moneris.terminalId': this.giftCardSettings.terminalId,
        'settings.moneris.programId': this.giftCardSettings.programId,
      }
      if (this.giftCardSettings.credentials.apiKey && this.giftCardSettings.credentials.secret) {
        fields['settings.moneris.credentials'] = this.giftCardSettings.credentials
      }

      try {
        await LocationService.update(this.activeLocation._id, fields)
        this.$notify({
          title: 'Success',
          text: 'Gift card settings updated successfully',
        })
        this.getLocation(this.id)
      } catch (e) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again.',
        })
      }
    },
    populateGiftCardSettings() {
      if (!this.activeLocation) return
      this.giftCardSettings.giftCardEnabled = this.activeLocation?.settings?.giftCardEnabled
      this.giftCardSettings.programId = this.activeLocation?.settings?.moneris?.programId
      this.giftCardSettings.terminalId = this.activeLocation?.settings?.moneris?.terminalId
      this.giftCardSettings.credentials = {
        apiKey: this.activeLocation?.settings?.moneris?.credentials?.apiKey,
        secret: this.activeLocation?.settings?.moneris?.credentials?.secret,
      }
    },
  },
  async mounted() {
    this.isLoading = true
    await this.getLocation(this.id)
    this.companyLocations = await this.getCompanyLocations(this.activeLocation.company._id)
    if (this.activeLocation) {
      const hardPhone = this.activeLocation.hardPhone
      this.form.hardPhone = this.formatPhoneNumber(hardPhone)
      this.populateGiftCardSettings()
    }
    await Promise.all([
      this.getReviewSubscriptions({ locationId: this.activeLocation._id }),
      this.fetchCallToActions(this.activeLocation._id),
      this.getIvrs({
        filters: {
          companyIds: [this.activeLocation.company._id],
          locationIds: [this.activeLocation._id],
        },
      }),
      this.getIvrEnums(),
    ])

    if (this.selectIvrs && this.selectIvrs.length) {
      this.overideCompanyIvr = true
    }

    this.isLoading = false
  },
}
</script>

<style lang="scss">
.platform-source {
  text-transform: capitalize;
}
</style>
