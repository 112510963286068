import Vue from 'vue'
import { BootstrapVue } from 'bootstrap-vue'
import VueResource from 'vue-resource'
import VueMask from 'v-mask'
import App from './App.vue'
import Clipboard from 'v-clipboard'
import router from './router'
import store from './store'
import { initCharts } from './lib/chart'
import { initFirebase, refreshIdToken } from './lib/firebase'
import { interceptor } from './lib/http'
import Vue2Filters from 'vue2-filters'
import VueNotifications from 'vue-notification'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import VueFuse from 'vue-fuse'
import VModal from 'vue-js-modal'
import TextareaAutosize from 'vue-textarea-autosize'
import VueHotkey from 'v-hotkey'
import IdleVue from 'idle-vue'

// import './registerServiceWorker'

// Initialize global Chart.js options
initCharts()

// Initialzie firebase connection
initFirebase()

const eventsHub = new Vue()

Vue.use(TextareaAutosize)
Vue.use(VueResource)
Vue.use(Vue2Filters)
Vue.use(VueMask)
Vue.use(VueNotifications)
Vue.use(VueFuse)
Vue.use(VueLodash, { name: 'custom', lodash: lodash })
Vue.use(VueHotkey)
Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  idleTime: 1800000 // 30 minutes
})
Vue.use(BootstrapVue)
Vue.use(require('vue-moment'))
Vue.use(VModal, { dialog: true })
Vue.use(Clipboard)
Vue.config.productionTip = false
Vue.http.interceptors.push(interceptor)

new Vue({
  router,
  store,

  onIdle() {
    // handle going into idle state
  },
  async onActive() {
    await refreshIdToken(this)
  },
  render: h => h(App),
}).$mount('#app')
