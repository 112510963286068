import * as lamejs from '@breezystack/lamejs'

export const compressAudio = (file, bitrate = 64, type = 'audio/mp3') => {
  if (!file) return Promise.reject(new Error('No file provided'))

  return new Promise((resolve, reject) => {
    const reader = new FileReader()

    reader.onload = function (e) {
      const arrayBuffer = e.target.result

      const audioContext = new (window.AudioContext || window.webkitAudioContext)()
      audioContext.decodeAudioData(
        arrayBuffer,
        function (buffer) {
          const samples = buffer.getChannelData(0)
          const sampleRate = buffer.sampleRate
          const mp3encoder = new lamejs.Mp3Encoder(1, sampleRate, bitrate)
          const mp3Data = []

          const sampleBlockSize = 1152
          for (let i = 0; i < samples.length; i += sampleBlockSize) {
            const sampleBlock = samples.slice(i, i + sampleBlockSize)
            const int16Samples = new Int16Array(sampleBlock.length)
            for (let j = 0; j < sampleBlock.length; j++) {
              int16Samples[j] = Math.min(1, sampleBlock[j]) * 0x7fff
            }
            const mp3Tmp = mp3encoder.encodeBuffer(int16Samples)
            mp3Data.push(mp3Tmp)
          }

          const mp3End = mp3encoder.flush()
          mp3Data.push(mp3End)

          const blob = new Blob(mp3Data, { type })

          resolve(blob)
        },
        reject
      )
    }

    reader.onerror = reject
    reader.readAsArrayBuffer(file)
  })
}
