import Vue from 'vue'

export default {
  listPhoneNumbers(payload) {
    return Vue.http.post('v2/bandwidth/phone-numbers/list', payload)
  },
  submitPhoneVerifications(payload) {
    return Vue.http.put('v2/bandwidth/phone-numbers/submit-verifications', payload)
  },
}
