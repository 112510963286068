<script src="https://cdn.jsdelivr.net/npm/redoc@next/bundles/redoc.standalone.js"></script>
<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="header mt-md-5">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">Overview</h6>
              <h1 class="header-title display-4">Phone Verifications</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="px-3 pt-3">
                    <div class="row align-items-center">
                      <div class="col-3 form-group">
                        <label for="partner-select">Status</label>
                        <select
                          class="form-control custom-select"
                          id="partner-select"
                          v-model="selectedStatus"
                          :disabled="submitting"
                        >
                          <option value>Any</option>
                          <option class="toCapitalFirst" v-for="status of statuses" :key="status">
                            {{ status }}
                          </option>
                        </select>
                      </div>
                      <div class="col-8">
                        <form @submit.prevent class="row align-items-center">
                          <div class="col-auto pr-0">
                            <span class="fe fe-search text-muted"></span>
                          </div>
                          <div class="col">
                            <input
                              type="search"
                              class="form-control form-control-flush search"
                              placeholder="Search"
                              v-model="search"
                              :disabled="submitting"
                            />
                          </div>
                        </form>
                      </div>
                      <div class="col-1">
                        <i
                          class="fas fa-sync-alt"
                          style="cursor: pointer"
                          @click="fetchPhoneNumbers()"
                        ></i>
                      </div>
                    </div>
                    <div class="text-center" v-if="selectedRows.length">
                      <button
                        class="btn btn-primary"
                        @click="submitTollFreeVerifications"
                        :disabled="submitting"
                      >
                        <div class="row ml-2">
                          <div class="col">
                            <span>Submit Selected Verifications </span>
                          </div>
                          <div class="col-auto">
                            <div class="loader text-center" v-if="submitting"></div>
                          </div>
                        </div>
                      </button>
                    </div>
                  </div>
                  <div class="row justify-content-center align-items-center" v-if="loading">
                    <FadeTransition>
                      <div class="loader loader-primary mt-4"></div>
                    </FadeTransition>
                  </div>
                  <div class="table-responsive" v-else>
                    <table class="table mb-0 mt-4">
                      <thead>
                        <tr>
                          <th>
                            <input
                              type="checkbox"
                              @change="selectAllRows"
                              v-model="allRowsSelected"
                              :disabled="submitting"
                            />
                          </th>
                          <th>Status</th>
                          <th>Phone</th>
                          <th>Type</th>
                          <th>Name</th>
                          <th>Creation Date</th>
                          <th>Submission Date</th>
                          <th>Verified Date</th>
                          <th>Denial Reason</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="!selectPhoneNumbers.length">
                          <td>No phones found...</td>
                        </tr>
                        <tr v-for="phone of selectPhoneNumbers" :key="phone.number">
                          <td>
                            <input
                              type="checkbox"
                              :value="phone.phone"
                              v-model="selectedRows"
                              :disabled="phone.status === 'VERIFIED' || submitting"
                            />
                          </td>
                          <td>
                            <b-badge :variant="getStatusVariant(phone)" class="toCapitalFirst">{{
                              getStatusText(phone)
                            }}</b-badge>
                          </td>
                          <td>{{ phone.phone }}</td>
                          <td>
                            <span class="toCapitalFirst">{{ phone.type }}</span>
                          </td>
                          <td>{{ phone.name }}</td>
                          <td>{{ phone.createdDate | moment('MM/DD/YYYY h:mm A') }}</td>
                          <td>{{ phone.submissionDate | moment('MM/DD/YYYY h:mm A') }}</td>
                          <td>{{ phone.verifiedDate | moment('MM/DD/YYYY h:mm A') }}</td>

                          <td style="color: red">{{ phone.denialReasonDescription }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'
import FadeTransition from '@/components/Transitions/FadeTransition'

const BandwidthModule = createNamespacedHelpers('bandwidth')

export default {
  name: 'PhoneVerificationsPage',
  components: { FadeTransition },
  data: () => ({
    loading: false,
    submitting: false,
    selectedRows: [],
    statuses: ['Verified', 'Unverified', 'Pending', 'Denied'],
    selectedStatus: 'Unverified',
    search: null,
    allRowsSelected: false,
  }),
  computed: {
    ...BandwidthModule.mapGetters(['selectPhoneNumbers']),
  },
  methods: {
    ...BandwidthModule.mapActions(['listPhoneNumbers', 'submitVerifications']),
    getStatusVariant(phone) {
      const { status, denialReasonDescription } = phone
      if (status === 'VERIFIED') {
        return 'success'
      }

      if (status === 'UNVERIFIED') {
        if (denialReasonDescription) {
          return 'danger'
        }
        return 'warning'
      }

      if (status === 'INVALID_STATUS') {
        return 'danger'
      }

      return 'info'
    },
    getStatusText(phone) {
      const { status, denialReasonDescription } = phone

      if (status === 'UNVERIFIED' && denialReasonDescription) {
        return 'Denied'
      }
      return status
    },
    selectAllRows(e) {
      if (this.allRowsSelected) {
        this.selectedRows = this.selectPhoneNumbers
          .filter((phone) => phone.status !== 'VERIFIED')
          .map((phone) => phone.phone)
      } else {
        this.selectedRows = []
      }
    },
    async submitTollFreeVerifications() {
      this.submitting = true
      try {
        await this.submitVerifications({
          phoneNumbers: this.selectedRows,
        })
        this.$notify({
          title: 'Success',
          type: 'success',
          text: 'Successfully submitted phone numbers to bandwidth for verification',
        })
      } catch (e) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: 'Oops, something went wrong',
        })
      }
      this.submitting = false
    },
    fetchPhoneNumbers: debounce(async function (event) {
      this.loading = true
      this.selectedRows = []
      let status = this.selectedStatus === 'Denied' ? 'Unverified' : this.selectedStatus
      await this.listPhoneNumbers({
        filters: {
          ...(this.selectedStatus ? { statuses: [status.toUpperCase()] } : {}),
          ...(this.selectedStatus === 'Denied' ? { denialReasonDescriptionExists: true } : {}),
          ...(this.selectedStatus === 'Unverified' ? { denialReasonDescriptionExists: false } : {}),
        },
        ...(this.search ? { search: this.search } : {}),
      })
      this.loading = false
    }, 500),
  },
  watch: {
    selectedStatus() {
      this.fetchPhoneNumbers()
    },
    search() {
      this.fetchPhoneNumbers()
    },
  },
  mounted() {
    this.fetchPhoneNumbers()
  },
}
</script>

<style scoped lang="scss">
.toCapitalFirst {
  text-transform: lowercase;
  display: inline-block;
}
.toCapitalFirst::first-line {
  text-transform: capitalize;
}
</style>
