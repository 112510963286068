<template>
  <b-modal
    id="FeatureFlagEditModal"
    hide-footer
    hide-header
    centered
    @hide="closeModal"
    body-class="p-0"
    size="lg"
  >
    <div class="px-4 pt-4">
      <!-- body -->
      <div class="card-body px-2" v-if="flag">
        <div class="header-title mb-5" style="font-size: 20px">
          <strong>{{ flag.name }} </strong> <br />
        </div>
        <div v-if="flag.users.length && editType === 'users'" class="users-table mb-5">
          <div class="list-group">
            <div class="list-group-item">
              <div class="row">
                <div class="col">
                  <strong>User Name</strong>
                </div>
                <div class="col text-center">
                  <strong>User ID</strong>
                </div>
                <div class="col text-right">
                  <strong>Actions</strong>
                </div>
              </div>
            </div>
            <div class="list-group-item" v-for="userId in flag.users" :key="userId">
              <div class="row">
                <div class="col">
                  {{ customUserName(userId) }}
                </div>
                <div class="col text-right">
                  {{ userId }}
                </div>
                <div class="col text-right">
                  <button class="btn btn-sm" @click.prevent="removeItem(userId)">
                    <span class="fa fa-trash"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div v-if="flag.companies.length && editType === 'companies'" class="companies-table mb-5">
          <div class="list-group">
            <div class="list-group-item">
              <div class="row">
                <div class="col">
                  <strong>Company Name</strong>
                </div>
                <div class="col text-center">
                  <strong>Company ID</strong>
                </div>
                <div class="col text-right">
                  <strong>Actions</strong>
                </div>
              </div>
            </div>
            <div class="list-group-item" v-for="companyId in flag.companies" :key="companyId">
              <div class="row">
                <div class="col">
                  {{ customCompanyName(companyId) }}
                </div>
                <div class="col text-right">
                  {{ companyId }}
                </div>
                <div class="col text-right">
                  <button class="btn btn-sm" @click.prevent="removeItem(companyId)">
                    <span class="fa fa-trash"></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <UsersSelector
          class="mb-3"
          :displayIds="true"
          v-if="editType === 'users' && showInput"
          @updateUsers="handleUsersUpdate"
          @dropdownClosed="handleDropdownClosed"
        />

        <CompanySelector
          class="mb-3"
          :displayIds="true"
          v-if="editType === 'companies' && showInput"
          @updateCompanies="handleCompaniesUpdate"
          @dropdownClosed="handleDropdownClosed"
        />

        <div class="form-group text-left mt-4">
          <button @click="showInputSelector" class="btn btn-primary text-capitalize">
            Add {{ editType }}
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import UsersSelector from '../Modules/Users/UsersSelector/UsersSelector.vue'
import CompanySelector from '../Modules/Companies/CompanySelector/CompanySelector.vue'
import { createNamespacedHelpers } from 'vuex'
const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')

export default {
  name: 'FeatureFlagEditModal',
  data() {
    return {
      form: {
        users: [],
        companies: [],
      },
      showInput: false,
    }
  },
  components: {
    UsersSelector,
    CompanySelector,
  },
  computed: {
    ...UserModule.mapGetters(['selectUsers', 'selectRawUser']),
    ...CompanyModule.mapGetters(['selectRawCompany']),
    ...FeatureFlagModule.mapGetters(['selectFlagToBeEdited']),

    flag() {
      return this.selectFlagToBeEdited?.flag
    },
    users() {
      return this.selectUsers
    },
    editType() {
      return this.selectFlagToBeEdited?.editType
    },
  },
  methods: {
    ...UserModule.mapActions(['getUsers']),
    ...CompanyModule.mapActions(['getCompanyList']),
    ...FeatureFlagModule.mapActions(['updateFeatureFlag', 'setFlagToBeEdited']),

    closeModal() {
      this.setFlagToBeEdited(null)
      this.$root.$emit('bv::hide::modal', 'FeatureFlagEditModal')
    },
    resetState() {
      this.showInput = false
      this.form.users = []
      this.form.companies = []
    },
    removeItem(itemId) {
      const payload = {
        ...this.flag,
      }
      if (this.editType === 'users') {
        payload.users = [...new Set(this.flag.users.filter((u) => u !== itemId))]
      } else if (this.editType === 'companies') {
        payload.companies = [...new Set(this.flag.companies.filter((c) => c !== itemId))]
      }

      this.updateFeatureFlag(payload)
      this.resetState()
    },
    customUserName(userId) {
      const user = this.selectRawUser(userId)
      return `${user?.firstName} ${user?.lastName}`
    },
    showInputSelector() {
      this.showInput = true
    },
    customCompanyName(companyId) {
      const company = this.selectRawCompany(companyId)
      return `${company?.friendlyName || company?.name}`
    },
    handleUsersUpdate(users) {
      this.form.users = users
    },
    handleCompaniesUpdate(companies) {
      this.form.companies = companies
    },
    handleDropdownClosed() {
      const payload = {
        ...this.flag,
        users: [...new Set([...this.flag.users, ...this.form.users.map((u) => u._id)])],
        companies: [...new Set([...this.flag.companies, ...this.form.companies.map((c) => c._id)])],
      }
      this.editType === 'companies'
        ? this.getCompanyList({ filters: { companyIds: this.form.companies.map((c) => c._id) } })
        : this.getUsers({ userIds: this.form.users.map((u) => u._id) })
      this.updateFeatureFlag(payload)
      this.resetState()
    },
  },
}
</script>
