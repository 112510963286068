import Vue from 'vue'

export default {
  createFeatureFlag({ enabled = true, name, users, companies }) {
    return Vue.http.post('ff', {
      name,
      users, 
      enabled,
      companies
    })
  },
  removeFeatureFlag(name) {
    return Vue.http.delete(`ff/${name}`)
  },
  updateFeatureFlag({ enabled = true, name, users, companies }) {
    return Vue.http.put(`ff/${name}`, {
      users, 
      enabled,
      companies
    })
  },
  list() {
    return Vue.http.get('pentaApi/ff')
  }
}
