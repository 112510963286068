import SubscriptionService from '@/services/SubscriptionService'

export default {
  namespaced: true,
  state: {
    subscriptions: [],
    products: [],
    subscriptionsMap: {},
    prices: {},
  },
  getters: {
    selectSubscriptions: (state) => state.subscriptions,
    selectProducts: (state) => state.products,
    selectProductPrices: (state) => (productId) => state.prices[productId],
    selectSubscriptionById: (state) => (id) => {
      return {
        ...state.subscriptionsMap[id],
      }
    },
  },
  mutations: {
    ADD_SUBSCRIPTION(state, subscription) {
      state.subscriptions = [...state.subscriptions, subscription]
    },
    EDIT_SUBSCRIPTION(state, subscription) {
      state.subscriptions = [
        ...state.subscriptions.filter((s) => s._id !== subscription._id),
        subscription,
      ]
    },
    SET_SUBSCRIPTIONS(state, subscriptions) {
      state.subscriptions = subscriptions
      state.subscriptionsMap = subscriptions.reduce((acc, cur) => ({ ...acc, [cur._id]: cur }), {})
    },
    SET_PRODUCTS(state, products) {
      state.products = products
    },
    SET_PRICES(state, pricesByProductId) {
      state.prices = { ...state.prices, ...pricesByProductId}
    },
  },
  actions: {
    async formatPayload(_, { type, company, locations, nickName, contacts, stripe, prorated }) {
      let payload = { type: type.toLowerCase(), nickName, contacts, prorated }

      if (stripe.subscriptionId) {
        payload.companyId = company
        payload.stripeSubscriptionId = stripe.subscriptionId
      }
      if (company && type.toLowerCase() === 'company') {
        payload.companyIds = [company]
      }
      if (locations && locations.length) {
        payload.locationIds = locations.map((l) => l._id || l)
      }
      if (stripe.products) {
        payload.products = stripe.products.map((p) => ({
          productId: p.id,
          priceId: p.price?.id,
          quantity: parseInt(p.quantity),
        }))
      }
      return payload
    },

    async fetchSubscriptions({ commit }, { companyIds, locationIds }) {
      const [companyReponse, locationResponse] = await Promise.all([
        SubscriptionService.list({ types: ['company'], companyIds }),
        SubscriptionService.list({ types: ['location'], locationIds }),
      ])
      const companySubscriptions = companyReponse.body.data.subscriptions
      const locationSubscriptions = locationResponse.body.data.subscriptions
      commit('SET_SUBSCRIPTIONS', [...companySubscriptions, ...locationSubscriptions])
    },

    async createSubscription({ commit, dispatch }, subscriptionData) {
      const payload = await dispatch('formatPayload', subscriptionData)
      const response = await (payload.stripeSubscriptionId
        ? SubscriptionService.link(payload)
        : SubscriptionService.create(payload))

      commit('ADD_SUBSCRIPTION', response.body.data.subscription)
    },

    async updateSubscription({ commit, dispatch }, { subscriptionId, subscriptionData }) {
      const payload = await dispatch('formatPayload', subscriptionData)
      const response = await SubscriptionService.update({ subscriptionId, payload })

      commit('EDIT_SUBSCRIPTION', response.body.data.subscription)
    },

    async fetchProductData({ commit }) {
      const response = await SubscriptionService.fetchProducts()
      commit('SET_PRODUCTS', response.body.data.products)
    },

    async fetchProductPrices({ commit }, { productId }) {
      const response = await SubscriptionService.fetchPrices({ productId })
      commit('SET_PRICES', { [productId]: response.body.data.prices })
    },
  },
}
