<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <div class="header mt-md-5">
            <div class="header-body">
              <div class="row align-items-center">
                <div class="col">
                  <h6 class="header-pretitle">Overview</h6>
                  <h1 class="header-title display-4">Users</h1>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-outline-primary"
                    @click="$bvModal.show('InviteUserModal')"
                    v-if="!isPartner"
                  >
                    <span class="fe fe-plus"></span>
                    Invite User
                  </button>
                </div>
                <div class="col-auto">
                  <button
                    class="btn btn-outline-primary"
                    @click="showCreationModal"
                    v-if="!isPartner"
                  >
                    <span class="fe fe-plus"></span>
                    Add User
                  </button>
                </div>
              </div>
            </div>
          </div>
          <UsersTable :key="refreshKey" />
        </div>
      </div>
    </div>
    <AddUserModal @addedUser="refreshKey++" />
    <InviteUserModal />
  </div>
</template>

<script>
import UsersTable from '@/components/Modules/Users/UsersTable'
import AddUserModal from '@/components/Modals/AddUserModal'
import InviteUserModal from '@/components/Modals/InviteUserModal'
import { createNamespacedHelpers } from 'vuex'

const AuthModule = createNamespacedHelpers('auth')

export default {
  name: 'UsersPage',
  components: {
    UsersTable,
    AddUserModal,
    InviteUserModal
  },
  data: () => ({
    refreshKey: 0,
  }),
  computed: {
    ...AuthModule.mapGetters(['selectIsPartner']),
    isPartner() {
      return this.selectIsPartner
    },
  },
  methods: {
    showCreationModal() {
      this.$root.$emit('bv::show::modal', 'AddUserModal')
    },
  },
}
</script>

<style scoped lang="scss"></style>
