<template>
  <div class="ivr-gather-option">
    <div class="row no-gutters">
      <div class="col col-auto align-self-center mr-4 ml-3">
        <label style="color: #95aac9">{{ `Option ${optionDigit}` }}</label>
        <input
          type="text"
          v-model="optionDigit"
          class="form-control"
          style="width: 60px"
          v-if="useDefaultOptions === false"
          maxlength="2"
        />
      </div>
      <div class="col col-10">
        <div
          class="card"
          :style="
            errors && errors.includes(option.uuid) ? 'border-color: #e63757; border-width:2px' : ''
          "
        >
          <div class="container mx-0 px-0">
            <div class="row mt-3">
              <div class="col col-2 align-self-center ml-4 mt-2">
                <label style="color: #95aac9">Name</label>
              </div>
              <div class="col col-8 mr-3 my-2">
                <input
                  type="text"
                  v-model="optionName"
                  class="form-control"
                  placeholder="Option Name..."
                />
              </div>
            </div>
            <div class="row">
              <div class="col col-2 align-self-center ml-4 mt-2">
                <label style="color: #95aac9">Action</label>
              </div>
              <div class="col col-8 mr-3 my-2">
                <select class="form-control custom-select my-2" v-model="selectedAction">
                  <option v-for="actionType of selectIvrActions" :key="actionType">
                    {{ actionType }}
                  </option>
                </select>
              </div>
            </div>
            <div class="row mt-3" v-if="selectedAction === 'TRANSFER_CALL'">
              <div class="col col-2 align-self-center ml-4 mt-2">
                <label style="color: #95aac9">Transfer To</label>
              </div>
              <div class="col-8 mr-3 my-2">
                <IvrDynamicFieldPickerVue @update="transferTo = $event" :value="transferTo">
                  <template v-slot:constant>
                    <input
                      :id="`transferTo-${option.uuid}`"
                      v-model="transferTo"
                      type="text"
                      ref="phone"
                      class="form-control"
                      placeholder="+1 (000) 000-0000"
                      v-mask="'+# (###) ###-####'"
                    />
                  </template>
                </IvrDynamicFieldPickerVue>
              </div>
            </div>
            <div class="row mt-3" v-if="selectedAction === 'CUSTOM_SMS'">
              <div class="col col-2 align-self-center ml-4 mt-2">
                <label style="color: #95aac9">Custom Message</label>
              </div>
              <div class="col col-8 mr-3 my-2">
                <textarea-autosize
                  :id="`customMessage-${option.uuid}`"
                  v-model="customMessage"
                  class="form-control"
                  :min-height="42"
                  placeholder="Custom message..."
                  rows="1"
                ></textarea-autosize>
              </div>
            </div>
            <div class="row">
              <div class="col col-2 align-self-center ml-4 mt-2">
                <label style="color: #95aac9">Audio Producer</label>
              </div>
              <div class="col col-8 mr-3 my-2">
                <select class="form-control custom-select my-2" v-model="selectedAudioProducer">
                  <option value>None</option>
                  <option v-for="verb of audioProducerVerbs" :key="verb">
                    {{ verb }}
                  </option>
                </select>
              </div>
            </div>
            <IvrPlayAudio
              v-if="selectedAudioProducer === 'PlayAudio'"
              class="mt-3 mb-2"
              :uploadPath="uploadPath"
              :name="`GatherOption-${selectedAction}-${option.uuid}`"
              :url="url"
              @playAudioUpdate="playAudioUpdate"
            />
            <IvrSpeakSentence
              v-if="selectedAudioProducer === 'SpeakSentence'"
              class="mb-2"
              :voice="voice"
              :gender="gender"
              :sentence="sentence"
              :locale="locale"
              @speakSentenceUpdate="speakSentenceUpdate"
            />
          </div>
        </div>
      </div>
      <div class="col-auto align-self-center align-end d-flex ml-auto mb-4">
        <div class="ml-auto">
          <i class="fe fe-x text-danger icon-large mr-4" @click="$emit('deleteOption')"></i>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import IvrDynamicFieldPickerVue from './IvrDynamicFieldPicker.vue'
import IvrPlayAudio from './IvrPlayAudio.vue'
import IvrSpeakSentence from './IvrSpeakSentence.vue'

const IvrModule = createNamespacedHelpers('ivr')

export default {
  name: 'IvrGatherOption',
  props: ['option', 'index', 'uploadPath', 'useDefaultOptions', 'errors'],
  components: { IvrPlayAudio, IvrSpeakSentence, IvrDynamicFieldPickerVue },
  data: () => ({
    optionName: null,
    selectedAction: null,
    selectedAudioProducer: null,
    url: null,
    sentence: null,
    voice: null,
    gender: null,
    locale: null,
    transferTo: null,
    optionDigit: null,
    customMessage: null,
  }),
  computed: {
    ...IvrModule.mapGetters(['selectIvrActions', 'selectIvrDynamicFields']),
    audioProducerVerbs() {
      return ['PlayAudio', 'SpeakSentence']
    },
  },
  methods: {
    playAudioUpdate(modifications) {
      this.url = modifications.url
    },
    speakSentenceUpdate(modifications) {
      this.sentence = modifications.sentence
      this.voice = modifications.voice
      this.gender = modifications.gender
      this.locale = modifications.locale
      this.emitUpdate()
    },
    emitUpdate() {
      const modifications = {
        name: this.optionName,
        digit: this.optionDigit,
        action: {
          type: this.selectedAction,
        },
      }

      if (this.selectedAction === 'TRANSFER_CALL') {
        modifications.action.transferTo = this.transferTo
        if (
          modifications.action.transferTo &&
          !this.selectIvrDynamicFields.includes(modifications.action.transferTo)
        ) {
          modifications.action.transferTo = `+${modifications.action.transferTo.replace(
            /\D+/g,
            ''
          )}`
        }
      }

      if (this.selectedAction === 'CUSTOM_SMS') {
        modifications.action.message = this.customMessage
      }

      if (this.selectedAudioProducer) {
        modifications.action.audioProducer = {
          verb: this.selectedAudioProducer,
        }

        if (this.selectedAudioProducer === 'PlayAudio') {
          modifications.action.audioProducer.url = this.url
        }

        if (this.selectedAudioProducer === 'SpeakSentence') {
          modifications.action.audioProducer.sentence = this.sentence
          if (this.voice) {
            modifications.action.audioProducer.voice = this.voice
          } else {
            modifications.action.audioProducer.gender = this.gender
            modifications.action.audioProducer.locale = this.locale
          }
        }
      }
      this.$emit('gatherOptionUpdate', modifications)
    },
  },
  watch: {
    index(val) {
      if (this.useDefaultOptions) {
        this.optionDigit = val + 1
      }
    },
    optionDigit(val) {
      if (val) {
        this.optionDigit = String(val).replace(/[^\d#*]/g, '')
      }
      this.emitUpdate()
    },
    optionName(val) {
      this.emitUpdate()
    },
    selectedAction(val) {
      if (val !== this.option.action.type) {
        this.selectedAudioProducer = null
        this.url = null
        this.sentence = null
        this.gender = null
        this.locale = null
        this.voice = null
      }
      this.emitUpdate()
    },
    selectedAudioProducer(val) {
      if (val === 'PlayAudio') {
        this.gender = null
        this.locale = null
        this.voice = null
      } else if (val === 'SpeakSentence') {
        this.url = null
      }
      this.emitUpdate()
    },
    transferTo(val) {
      this.emitUpdate()
    },
    customMessage(val) {
      this.emitUpdate()
    },
    url(val) {
      this.emitUpdate()
    },
    voice(val) {
      this.emitUpdate()
    },
    gender(val) {
      this.emitUpdate()
    },
    locale(val) {
      this.emitUpdate()
    },
    useDefaultOptions(val) {
      if (val === true) {
        this.optionDigit = this.index + 1
      }
    },
  },
  mounted() {
    this.selectedAction = this.option.action.type
    if (this.option.action.audioProducer) {
      this.url = this.option.action.audioProducer.url
      this.sentence = this.option.action.audioProducer.sentence
      this.gender = this.option.action.audioProducer.gender
      this.locale = this.option.action.audioProducer.locale
      this.voice = this.option.action.audioProducer.voice
      this.selectedAudioProducer = this.option.action.audioProducer.verb
    }
    if (this.option.name) {
      this.optionName = this.option.name
    }

    if (this.useDefaultOptions) {
      this.optionDigit = this.index + 1
    } else {
      this.optionDigit = this.option.digit
    }

    if (!this.optionName) {
      this.optionName = this.selectedAction
    }

    if (this.option.action.audioProducer) {
      this.selectedAudioProducer = this.option.action.audioProducer.verb
      this.url = this.option.action.audioProducer.url
    }

    if (this.option.action.transferTo) {
      this.transferTo = this.option.action.transferTo
    }

    if (this.option.action.message) {
      this.customMessage = this.option.action.message
    }
    this.emitUpdate()
  },
}
</script>
