<template>
  <div class="main-content" v-if="partner">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <!-- Header -->
          <div class="header mt-md-5">
            <div class="header-body">
              <!-- Pretitle -->
              <h6 class="header-pretitle">Integration partners</h6>

              <!-- Title -->
              <h1 class="header-title display-4">
                {{ partner.name
                }}<span style="float: right">
                  <button
                    v-b-modal.updateIntegrationPartnerModal
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-outline-primary"
                    type="button"
                  >
                    Edit
                  </button></span
                >
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="row mb-5">
        <div class="col-12">
          <h4>Environment:</h4>
          <b-badge v-if="isTest" variant="secondary">Test</b-badge>
          <b-badge v-if="!isTest" variant="primary">Live</b-badge>
        </div>
      </div>
      <div class="row" v-if="activeApiKey">
        <div class="col-6">
          <h4>API Key:</h4>
          <p>created: {{ activeApiKey.created | moment('MM/DD/YYYY') }}</p>
        </div>
        <div class="col-6">
          <div class="row">
            <div class="col-12 text-right">
              <button @click="showRevokeModal()" type="button" class="btn btn-danger">
                Revoke
              </button>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="activeApiKey === undefined">
        <div class="loader loader-primary"></div>
      </div>
      <div class="row" v-else-if="!activeApiKey">
        <div class="col-12">
          <form @submit.prevent="submitApiKey">
            <h3>API Key</h3>
            <fieldset class="border rounded form-group px-4">
              <legend class="header-pretitle h6 col-1">Scopes</legend>
              <div class="mb-3 form-check" v-for="scope in Object.keys(scopes)" :key="scope">
                <input
                  v-model="scopes[scope]"
                  type="checkbox"
                  class="form-check-input"
                  :id="scope"
                />
                <label class="form-check-label" :for="scope">{{ scope }}</label>
              </div>
            </fieldset>
            <div class="row">
              <div class="col-12 text-right">
                <button :disabled="!selectedScopes.length" type="submit" class="btn btn-primary">
                  Create API Key
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <IntegrationLocationPage :partner="partner"></IntegrationLocationPage>
    </div>
    <DownloadApiTokenModal :token="token" />
    <RevokeApiTokenModal @confirm="revoke()" />
    <b-modal
      ref="updateIntegrationPartnerModal"
      id="updateIntegrationPartnerModal"
      hide-footer
      hide-header
      body-class="p-0"
    >
      <div class="modal-card card">
        <div class="card-header">
          <div class="row align-items-center">
            <div class="col">
              <h4 class="card-header-title" id="exampleModalCenterTitle">
                Update Integration Partner
              </h4>
            </div>
            <div class="col-auto">
              <button @click="modalClose" type="button" class="close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
          </div>
        </div>
        <div class="card-body" style="max-height: 650px">
          <div class="row">
            <div class="col-12">
              <form>
                <div class="form-group">
                  <label for="partner-name">Partner Name </label>
                  <input
                    type="text"
                    class="form-control"
                    v-model="currentIntegrationPartner.name"
                    ref="name"
                  />
                  <div v-if="isNameInUse" class="text-left">
                    <p id="errorMessage">
                      that name is already in the use, please try using another name
                    </p>
                  </div>
                  <br />

                  <label for="companies">Companies</label>
                  <CompanySelector
                    id="company-select"
                    :display-ids="true"
                    :multiple="true"
                    :close-on-select="true"
                    :initial-companies="currentIntegrationPartner.companies"
                    v-model="selectedCompanies"
                  /><br />

                  <label for="email">Email</label>
                  <input
                    id="email"
                    type="text"
                    class="form-control"
                    placeholder="Email ( optional )"
                    v-model="currentIntegrationPartner.email"
                  /><br />

                  <label for="location-name">Phone</label>
                  <input
                    id="location-name"
                    type="text"
                    class="form-control"
                    placeholder="Phone number ( optional ) "
                    v-model="currentIntegrationPartner.phone"
                  /><br />

                  <label for="url">URL</label>
                  <input
                    id="url"
                    type="text"
                    class="form-control"
                    placeholder="Url ( optional ) "
                    v-model="currentIntegrationPartner.url"
                  /><br />

                  <label for="logo-url">Logo URL</label>
                  <input
                    id="logo-url"
                    type="text"
                    class="form-control"
                    placeholder="Logo url ( optional ) "
                    v-model="currentIntegrationPartner.logoUrl"
                  /><br />
                  <label for="domain">Domain</label>
                  <input
                    id="domain"
                    type="text"
                    class="form-control"
                    placeholder="Domain ( optional ) "
                    v-model="currentIntegrationPartner.domain"
                  /><br />

                  <button :disabled="isValid" class="btn btn-primary form-control" @click="update">
                    Update
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { BModal, VBModal } from 'bootstrap-vue'
import CompanySelector from '@/components/Selects/CompanySelector.vue'
import DownloadApiTokenModal from '@/components/Modals/DownloadApiTokenModal.vue'
import RevokeApiTokenModal from '@/components/Modals/RevokeApiTokenModal.vue'
import Config from '@/config'
import IntegrationLocationPage from './IntegrationLocationPage.vue'
const CompanyModule = createNamespacedHelpers('company')
const TranslationTemplateModule = createNamespacedHelpers('translationTemplate')
const IntegrationPartnerModule = createNamespacedHelpers('integrationPartner')
const integrationProvidersModule = createNamespacedHelpers('integrationProvider')
const { mapActions, mapState, mapGetters } = createNamespacedHelpers('integrationPartner')

export default {
  data: function () {
    return {
      currentIntegrationPartner: {
        name: '',
        email: '',
        phone: '',
        url: '',
        logoUrl: '',
        companies: [],
      },
      selectedCompanies: [],
      nameInUse: false,
      errorStyle: 'color: red',
      scopes: {
        'create:order': false,
        'read:customers': false,
        'read:companies': false,
        'read:locations': false,
        'read:surveys': false,
      },
    }
  },
  directives: {
    'b-modal': VBModal,
  },
  name: 'IntegrationPartnersView',

  components: {
    BModal,
    CompanySelector,
    DownloadApiTokenModal,
    RevokeApiTokenModal,
    IntegrationLocationPage,
  },

  computed: {
    ...mapState(['integrationPartners']),
    ...mapGetters(['integrationPartner', 'selectApiKey', 'selectToken', 'getActivePartner']),
    selectedScopes: function () {
      return Object.keys(this.scopes).filter((s) => this.scopes[s] === true)
    },
    activeApiKey: function () {
      return this.selectApiKey(this.$route.params.id)
    },
    partner: function () {
      return this.integrationPartner(this.$route.params.id)
    },
    token: function () {
      return this.selectToken(this.$route.params.id)
    },
    isTest: function () {
      const { isDevelopment, isTest } = Config
      return isDevelopment || isTest
    },
    isValid() {
      return this.currentIntegrationPartner.name?.length < 1
    },
    isNameInUse() {
      return this.nameInUse
    },
  },

  methods: {
    ...mapActions(['createApiKey', 'getApiKey', 'getIntegrationPartners', 'revokeApiKey']),
    ...CompanyModule.mapActions(['getCompanyList']),
    ...IntegrationPartnerModule.mapActions(['setActivePartnerId', 'updateIntegrationPartner']),
    ...TranslationTemplateModule.mapActions(['fetchTranslationTemplates']),
    ...integrationProvidersModule.mapActions([
      'fetchIntegrationProviders',
    ]),

    update: async function (e) {
      e.preventDefault()

      // updating the integation
      try {
        await this.updateIntegrationPartner({
          partnerId: this.getActivePartner._id,
          update: {
            ...this.currentIntegrationPartner,
            companies: this.selectedCompanies.map((c) => c._id),
          },
        })
        this.modalClose()
      } catch (e) {
        console.log(e)
        this.$notify({
          type: 'error',
          title: 'Error',
          text: `Failed to update the integration partner`,
        })
      }
    },

    revoke: async function () {
      try {
        const integrationPartnerId = this.$route.params.id
        const apiKeyId = this.activeApiKey?._id
        await this.revokeApiKey({ integrationPartnerId, apiKeyId })
      } catch (error) {
        // TODO
        console.log(error)
      }
    },
    showRevokeModal() {
      this.$root.$emit('v::show::revoke-api-token-modal')
    },
    submitApiKey: async function () {
      const payload = {
        integrationPartnerId: this.partner._id,
        scopes: this.selectedScopes,
      }
      await this.createApiKey(payload)
      this.$root.$emit('v::show::download-api-token-modal')
    },
    modalClose() {
      this.$refs.updateIntegrationPartnerModal.hide()
    },
  },
  async mounted() {
    await this.setActivePartnerId(this.$route.params.id)
    await Promise.all([
      this.getIntegrationPartners(),
      this.getApiKey(this.$route.params.id),
      this.fetchTranslationTemplates(),
      this.fetchIntegrationProviders()
    ])

    if (this.getActivePartner) {
      this.currentIntegrationPartner.name = this.getActivePartner.name
      this.currentIntegrationPartner.email = this.getActivePartner.email
      this.currentIntegrationPartner.phone = this.getActivePartner.phone
      this.currentIntegrationPartner.url = this.getActivePartner.url
      this.currentIntegrationPartner.logoUrl = this.getActivePartner.logoUrl
      this.currentIntegrationPartner.domain = this.getActivePartner.domain

      if (this.getActivePartner.companies?.length) {
        this.currentIntegrationPartner.companies = this.getActivePartner.companies
      }
    }
  },
}
</script>

<style scoped lang="scss"></style>
