<template>
  <div class="s3-file-uploader">
    <b-button 
      type="file" 
      class="mb-3" 
      variant="primary" 
      @click.prevent="uploadImage"
    >
      {{ isUploading ? 'Uploading...' : 'Upload Image' }}
    </b-button>
    <div style="display: none">
      <input
        accept="image/png, image/jpg, image/gif, image/jpeg"
        ref="fileOpener"
        type="file"
        @change="handleFileUpload"
      />
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
const S3Module = createNamespacedHelpers('s3')

export default {
  name: 'S3ImageFileUploader',
  data() {
    return {
      isUploading: false,
    }
  },
  computed: {
    ...mapGetters(['selectConfigByName']),
    ...S3Module.mapGetters(['selectUploadProgress']),

    bucketName() {
      return this.selectConfigByName('BUCKET_NAME')
    },
  },
  methods: {
    ...S3Module.mapActions(['uploadFile']),

    async uploadImage() {
      await this.$refs.fileOpener.click()
    },
    async handleFileUpload() {
      this.isUploading = true

      try {
        const file = this.$refs.fileOpener.files[0]
        const bucketParams = {
          Bucket: this.bucketName,
          Key: `assets/images/platforms/${file.name}`,
          Body: file,
          ContentType: file.type,
        }

        const response = await this.uploadFile(bucketParams)
        this.$emit('uploaded', response.Location)

        this.$notify({
          title: 'Success!',
          text: 'Image was uploaded successfully.',
        })
      }
      catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Failed to upload image. Please try again.',
        })
      }
      
      this.isUploading = false
    },
  },
}
</script>
