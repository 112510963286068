<template>
  <div class="row d-flex justify-contents-center">
    <div class="col-lg-3 d-none d-xl-block">
      <MapSidebar
        :isLoadingLocations="isLoading"
        @onAddMarker="handleAddMarker"
        @onHover="handleHover"
        @onRemove="removeAllMarkers"
      />
    </div>
    <div class="col-lg-9 mapContainer">
      <div id="map"></div>
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { mapGetters, createNamespacedHelpers } from 'vuex'
import MapSidebar from './MapSidebar.vue'
import moment from 'moment-timezone'
import { debounce, findIndex } from 'lodash-es'

const LocationModule = createNamespacedHelpers('location')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'LocationMap',
  components: {
    MapSidebar,
  },
  data() {
    return {
      map: {},
      markers: [],
      isLoading: false,
      markerColor: 'red',
      lastMoveType: null,
      currentMarker: null,
      hoverMarkerColor: '#4184f4',
      mousePosition: [],
      popupContainer: null,
      isGeneratingLink: false,
    }
  },
  computed: {
    ...mapGetters(['selectConfigByName']),

    accessToken() {
      return this.selectConfigByName('MAPBOX_ACCESS_TOKEN')
    },
  },
  methods: {
    ...LocationModule.mapActions(['fetchGeoLocations']),
    ...UserModule.mapActions(['getCompanyLoginLink']),

    initializeMap() {
      const map = new mapboxgl.Map({
        container: 'map',
        center: [-97.380753, 42.896089],
        zoom: 3.8,
        style: 'mapbox://styles/mapbox/streets-v12',
        projection: 'mercator',
        attributionControl: false,
      })
      map.on('load', () => {
        map.on('moveend', () => {
          const bounds = map.getBounds()
          this.handleMoveEnded(bounds)
        })
        map.on('mousemove', (event) => {
          const { lngLat } = event
          const coordinates = [lngLat?.lng, lngLat?.lat]
          this.mousePosition = coordinates
        })
      })
      this.map = map
    },
    handleAddMarker(payload) {
      this.lastMoveType = 'manual'
      this.addMarkers(payload)
    },
    removeAllMarkers() {
      if (this.markers.length) {
        for (const marker of this.markers) {
          marker?.remove()
        }
        this.makers = []
      }
    },
    handleHover(location) {
      const { _id: locationId } = location
      const marker = this.markers.find((marker) => marker._element.id === locationId)
      if (marker) {
        if (!this.isTheSameMarker(this.currentMarker, marker)) {
          this.resetMarkerColor(this.currentMarker, this.markerColor)
          this.setMarkerColor(marker, this.hoverMarkerColor)
          this.currentMarker = marker
        }
      }
    },
    getPopupHtml(location) {
      if (!location) return '<div></div>'
      const { name, city, friendlyName, phone, created, address, company, health } = location
      const locationName = friendlyName || city || name
      const createdDate = moment(created).format('L')
      const { responseRate, recentSurveys } = health || {}

      const htmlString = `
      <div id="popup-${location?._id}">
        <p><strong>Name: </strong>${company?.name} - ${locationName}</p>
        <p class="mt--3"><strong>Phone: </strong>${phone ?? '-'}</p>
        <p class="mt--3"><strong>Address: </strong>${address ?? '-'}</p>
        <p class="mt--3"><strong>Created: </strong>${createdDate ?? '-'}</p>
        <div class="row d-flex justify-content-center align-items-center">
          <div class="col-9">
            <p class=""><strong>Recent Surveys: </strong>${recentSurveys ?? '-'}</p>
            <p class=""><strong>Response Rate: </strong>${
              responseRate ? responseRate + '%' : '0'
            }</p>
          </div>
          <div class="col-3 text-right">
            <button 
              style="outline: none !important; border: none;"
              data-companyId="${company?._id}" 
              class="btn btn-default btn-sm mt--3 rounded-circle" id="btn-${location?._id}">
              <span class="fe fe-arrow-right fe-lg"></span>
            </button>
          </div>
        </div>
      </div>`
      return htmlString
    },
    setMarkerColor(marker, color) {
      const element = marker.getElement()
      element.querySelectorAll(`path[fill="${this.markerColor}"`)?.[0]?.setAttribute('fill', color)
      marker._color = color
    },
    resetMarkerColor(marker, color) {
      if (!marker) return
      const element = marker.getElement()
      element
        ?.querySelectorAll(`path[fill="${this.hoverMarkerColor}"`)?.[0]
        ?.setAttribute('fill', color)
      marker._color = color
    },
    isTheSameMarker(markerA, markerB) {
      return markerA?._element?.id === markerB?._element?.id
    },
    addMarkers(payload) {
      const { locations, flyToEnabled = true } = payload
      if (this.map && locations?.length) {
        locations?.forEach((location) => {
          const { coordinates } = location

          const popup = new mapboxgl.Popup({
            offset: 25,
            closeButton: false,
          }).setHTML(this.getPopupHtml(location))

          const marker = new mapboxgl.Marker({ color: this.markerColor })
            .setLngLat(coordinates)
            .setPopup(popup)
            .addTo(this.map)
          marker._element.id = location._id

          const markerDiv = marker.getElement()
          const self = this

          markerDiv.addEventListener('mouseenter', () => {
            self.currentMarker?.togglePopup()
            marker.togglePopup()

            const loginBtn = document.getElementById(`btn-${location._id}`)
            const companyId = loginBtn?.dataset?.companyid

            loginBtn?.addEventListener('click', () => self.companyLogin(companyId))
            self.popupContainer = document.getElementById(`popup-${location._id}`)
            self.currentMarker = marker
          })
          this.markers.push(marker)
        })
        const { center } = locations?.[0]
        if (flyToEnabled) {
          this.map.flyTo({ center, zoom: 11, pitch: 0, bearing: 0 })
        }
      }
    },
    handleMoveEnded: debounce(async function (bounds) {
      if (this.lastMoveType !== 'manual') {
        this.isLoading = true
        try {
          const coordinates = this.formatBoundingBox(bounds)
          const locations = await this.fetchGeoLocations({
            limit: 100,
            coordinates,
            maxDistance: 5000,
          })
          const [remove, update] = this.findMarkersToRemoveOrAdd(locations)
          this.removeMarkers(remove)
          this.addMarkers({ locations: update, flyToEnabled: false })
        } catch (error) {
          this.$notify({
            type: 'error',
            text: 'Something went wrong. Check the console for details',
          })
        }
        this.isLoading = false
      } else {
        this.lastMoveType = null
      }
    }, 500),
    formatBoundingBox(bbox) {
      const { _ne: northEast, _sw: southWest } = bbox
      if (!northEast || !southWest) return
      return [
        [northEast.lng, northEast.lat],
        [southWest.lng, southWest.lat],
      ]
    },
    removeMarkers(markers) {
      if (!markers || !markers?.length) return
      markers.forEach((marker) => {
        const index = findIndex(this.markers, (m) => m._element.id === marker._element.id)
        if (index > -1) this.markers.splice(index, 1)
        marker.remove()
      })
    },
    findMarkersToRemoveOrAdd(locations) {
      const markers = [...this.markers]
      const remove = markers?.filter((marker) => {
        const markerId = marker._element.id
        const location = locations.find((l) => l._id === markerId)
        if (!location) return true
        return false
      })
      const update = locations?.filter((location) => {
        const { _id: locationId } = location
        const marker = markers.find((m) => m._element.id === locationId)
        if (!marker) return true
        return false
      })
      return [remove, update]
    },
    async companyLogin(companyId) {
      if (!companyId || this.isGeneratingLink) return
      this.isGeneratingLink = true
      try {
        const response = await this.getCompanyLoginLink(companyId)
        const { link } = response.body
        window.open(link, '_blank')
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Oops, something went wrong while trying to get the login link.',
        })
      }
      this.isGeneratingLink = false
    },
  },
  watch: {
    popupContainer: function (container) {
      if (container) {
        const self = this
        container?.addEventListener('mouseleave', () => {
          self.currentMarker?.togglePopup()
          self.currentMarker = null
        })
      } else {
        self.currentMarker = null
      }
    },
  },
  mounted() {
    if (this.accessToken) {
      mapboxgl.accessToken = this.accessToken
      this.initializeMap()
    }
  },
}
</script>

<style lang="scss" scoped>
#map {
  top: 0;
  bottom: 0;
  width: 98%;
  position: absolute;
  overflow: hidden;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}
.mapContainer {
  min-height: 720px;
}
</style>
