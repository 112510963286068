<template>
  <BillingDetails
    @onCustomerUpdate="setActiveLocationCustomerId($event)"
    :customer-id="activeLocationCustomerId"
    :location-id="activeLocation._id"
  />
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const LocationModule = createNamespacedHelpers('location')
import BillingDetails from '@/components/Modules/Billing/BillingDetails.vue'
export default {
  name: 'LocationBilling',
  components: {
    BillingDetails
  },
  computed: {
     ...LocationModule.mapState([
      'activeLocation',
      'activeLocationCustomerId'
    ])
  },
  methods: {
    ...LocationModule.mapMutations(['setActiveLocationCustomerId'])
  }
}
</script>
