<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <div class="header-body mb-3">
            <h1 class="header-title display-4">Banners</h1>
          </div>

          <!-- banner creation -->
          <div class="card p-4">
            <div class="mb-2">Create Banner</div>
            <input
              type="text"
              class="form-control"
              placeholder="Enter banner message..."
              v-model="newMessage"
            />
            <div class="text-right mt-2">
              <button class="btn btn-primary" @click="createNewBanner()">Save</button>
            </div>
          </div>
          <hr />
          <!-- banner list -->
          <div
            v-for="(banner, index) in banners"
            :key="index"
            class="card p-4 mb-3 d-flex flex-row justify-content-between align-items-center"
          >
            <div class="row">
              <div class="col mr-4">
                <div class="dropdown" role="button" data-toggle="dropdown" :style="applyColorStyle(banner.color)">
                  <div class="dropdown-menu dropdown-menu-bottom">
                    <div class="row">
                      <div class="col">
                        <div
                          class="dropdown-item d-flex align-items-center text-capitalize color-item" 
                          v-for="(color, itemIndex) in bannerColors" :key="itemIndex"
                          @click.prevent="handleColorUpdate(banner, color)">
                          <div class="mr-3" :style="applyColorStyle(color.value)"></div> 
                          {{ color.name }} <span class="ml-1" v-if="color.deprecated">(Legacy Only)</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {{ banner.message }}
            </div>
            <div class="d-flex align-items-center">
               <button class="btn btn-sm mr-3" @click.prevent="openModal(banner)">
                <span class="fa fa-link" />
               </button>
              <div class="custom-control custom-checkbox-toggle">
                <input
                  class="custom-control-input"
                  type="checkbox"
                  :id="`bannerToggle-${index}`"
                  v-model="banners[index].active"
                  @change="toggleActive(index)"
                />
                <label class="custom-control-label" :for="`bannerToggle-${index}`"></label>
              </div>
              <div class="pl-2 border-left ml-2 border-dark">
                <i class="far fa-trash-alt text-danger fa-lg" @click="deleteBanner(index)"></i>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- modal section -->
    <AddRedirectURLModal
      @modalClosed="handleModalClosed"
      @updateRedirectURL="handleUpdateRedirectURL" />
    <!-- modal section end-->
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import AddRedirectURLModal from '../components/Modals/AddRedirectURLModal.vue'
const BannerModule = createNamespacedHelpers('banner')
export default {
  name: 'BannersPage',
  components: {
    AddRedirectURLModal
  },
  data: () => ({
    newMessage: '',
    banners: [],
    bannerColors: [
      { name: 'danger', value: '#e63757'},
      { name: 'info', value: '#39afd1'},
      { name: 'success', value: '#00d97e'},
      { name: 'warning', value: '#f6c343'},
      { name: 'secondary', value: '#6e84a3'},
      { name: 'contrast', value: '#1f2937'},
      { name: 'primary', value: '#2c7be5', deprecated: true },

    ],
    isModalActive: false,
  }),
  computed: {
    ...BannerModule.mapGetters(['selectBanners']),
  },
  methods: {
    ...BannerModule.mapActions([
      'fetchAllBanners', 
      'createBanner', 
      'updateBanner',
      'setBannerToBeModified'
    ]),

    async toggleActive(index) {
      const payload = {
        bannerId: this.banners[index]._id,
        updateFields: {
          active: this.banners[index].active,
        }
      }
      await this.updateBanner(payload)
      this.getAllBanners()
    },
    async deleteBanner(index) {
      console.log('deleting', index)
      const payload = {
        bannerId: this.banners[index]._id,
        updateFields: {
          active: false,
          deleted: true,
        }
      }
      this.banners.splice(index, 1)
      await this.updateBanner(payload)
      this.getAllBanners()
    },
    async createNewBanner() {
      try {
        await this.createBanner({ message: this.newMessage })
        await this.getAllBanners()
        this.newMessage = ''
      } catch (error) {
        console.log('Failed to create New banner', error)
      }
    },
    async getAllBanners() {
      try {
        await this.fetchAllBanners()
        this.banners = JSON.parse(JSON.stringify(this.selectBanners))
      } catch (error) {
        console.log('Failed to fetch banners', error)
      }
    },
    openModal(banner){
      this.setBannerToBeModified(banner)
      this.$bvModal.show('AddRedirectURLModal')
    },
    applyColorStyle(color){
      return {
        height: '20px',
        backgroundColor: color || '#e63757',
        borderRadius: '3px',
        width: '20px',
      }
    },
    handleModalClosed(){
      this.setBannerToBeModified(null)
    },
    async handleColorUpdate(banner, color){
      if(!banner || !color) return
      const payload = {
        bannerId: banner._id,
        updateFields: {
          color: color.value,
          type: this.mapBannerTypeByColor(color.name)
        }
      }
      await this.updateBanner(payload)
      this.getAllBanners()
    },
    async handleUpdateRedirectURL(payload){
      await this.updateBanner(payload)
      this.getAllBanners()
    },
    mapBannerTypeByColor(color){
      switch(color){
        case 'primary':
          return 'INFO'
        case 'success':
          return 'SALE'
        case 'danger':
          return 'BUG'
        default:
          return 'OTHER'
      }
    }
  },
  async mounted() {
    await this.getAllBanners()
  },
}
</script>
<style scoped lang="scss">
  .color-item:hover {
    cursor: pointer;
  }
</style>
