import Vue from 'vue'

export default {
  fetchFeedback({status}) {
    return Vue.http.get(`v2/insight-classification/${status}`)
  },
  classify({_id, type, classifications, timeToClassify}) {
    return Vue.http.post('v2/insight-classification/', {id: _id, type, classifications, timeToClassify})
  },
  markStatus({_id, type, status}) {
    return Vue.http.put('v2/insight-classification/mark-status', {id: _id, type, status})
  },
  fetchCategories() {
    return Vue.http.get('v2/insight-category/list')
  },
}