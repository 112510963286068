<template>
  <div class="card">
    <div class="card-body">
      <h2 class="mb-4">Location Address</h2>
      <div class="form-group">
        <div class="row">
          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="streetNumber">Street Number</label>
            <input
              id="streetNumber"
              v-model="formData.streetNumber"
              type="text"
              placeholder="123 E"
              class="form-control"
            />
          </div>

          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="streetName">Street Name</label>
            <input
              id="streetName"
              v-model="formData.streetName"
              type="text"
              placeholder="Main St"
              class="form-control"
            />
          </div>

          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="suite">Suite</label>
            <input
              id="suite"
              v-model="formData.suite"
              type="text"
              placeholder="3"
              class="form-control"
            />
          </div>

          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="locationCity">City</label>
            <input
              id="locationCity"
              v-model="formData.city"
              type="text"
              placeholder="Spokane"
              class="form-control"
            />
          </div>

          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="locationState">State/Province</label>
            <input
              id="locationState"
              v-model="formData.state"
              type="text"
              placeholder="WA"
              class="form-control"
            />
          </div>

          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="locationZipcode">Zipcode</label>
            <input
              id="locationZipcode"
              v-model="formData.zipcode"
              type="text"
              placeholder="84058"
              class="form-control"
            />
          </div>

          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="locationCountry">Country</label>
            <input
              v-model="formData.country"
              type="text"
              placeholder="US"
              id="locationCountry"
              class="form-control"
            />
          </div>

          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="latitude">Latitude</label>
            <input
              id="latitude"
              v-model="formData.latitude"
              type="text"
              placeholder="US"
              class="form-control"
            />
          </div>

          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="longitude">Longitude</label>
            <input
              id="longitude"
              v-model="formData.longitude"
              type="text"
              placeholder="US"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="form-group mb-0">
        <button @click.prevent="updateAddressDetails" class="btn btn-primary">
          Save settings
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocationAddressForm',
  props: {
    addressDetails: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    formData: {
      streetName: '',
      streetNumber: '',
      suite: '',
      city: '',
      state: '',
      zipcode: '',
      country: '',
      longitude: '',
      latitude: '',
    },
  }),
  mounted() {
    this.formData.streetName = this.addressDetails.streetName || ''
    this.formData.streetNumber = this.addressDetails.streetNumber || ''
    this.formData.suite = this.addressDetails.suite || ''
    this.formData.city = this.addressDetails.city || ''
    this.formData.state = this.addressDetails.state || ''
    this.formData.zipcode = this.addressDetails.zipcode || ''
    this.formData.country = this.addressDetails.country || ''
    this.formData.longitude = this.addressDetails.longitude || ''
    this.formData.latitude = this.addressDetails.latitude || ''
  },
  methods: {
    updateAddressDetails() {
      this.$emit('updateAddressDetails', this.formData)
    },
  },
}
</script>
