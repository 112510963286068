import firebase from 'firebase/app'
import Config from '@/config'

export function initFirebase() {
  firebase.initializeApp(Config.firebase)
}

let idToken
export async function fetchUserToken() {
  if (!idToken) {
    idToken = await firebase.auth().currentUser?.getIdToken(true)
  }
}

export function getUserToken() {
  return idToken
}

export async function refreshIdToken(context) {
  const auth = context.$store.state.auth
  if (!_.isEmpty(auth)) {
    idToken = null
    await fetchUserToken()
  }
}