<template>
  <b-modal id="LocationDisableModal" hide-footer hide-header centered body-class="p-0" size="lg">
    <div class="px-4 pt-4">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h2>What would you like to do with the customers associated with this location?</h2>
      </div>

      <!-- body -->
      <div class="card-body px-5">
        <button
          class="btn btn-link d-block pl-0"
          :class="{ 'text-dark': moveCustomers !== false }"
          @click="moveCustomers = false"
        >
          <span style="font-size: 17px"> Keep them associated with this location </span>
        </button>

        <button
          class="btn btn-link d-block pl-0"
          :class="{ 'text-dark': moveCustomers !== true }"
          @click="moveCustomers = true"
        >
          <span style="font-size: 17px"> Associate them with a new location </span>
        </button>

        <div v-show="moveCustomers">
          <MultiSelect
            :options="companyLocations"
            v-model="destination"
            :show-labels="false"
            placeholder="Select a destination location"
            closeOnSelect
            track-by="_id"
          >
            <template slot="option" slot-scope="{ option }">
              <span class="custom__tag">
                <span>{{ option.friendlyName || option.city || option.name }}</span>
              </span>
            </template>
            <template slot="singleLabel" slot-scope="{ option }">
              <span v-if="option.name">
                {{ option.friendlyName || option.city || option.name }}
              </span>
            </template>
          </MultiSelect>
        </div>
      </div>

      <!-- footer -->
      <div class="border-top py-4 text-right">
        <button class="btn btn-outline-secondary mr-2 px-5" @click="close">Cancel</button>
        <button class="btn btn-danger px-5" @click="process" :disabled="!validInputs">
          Disable
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MultiSelect from '@/components/MainContent/MultiSelect'

export default {
  name: 'LocationDisableModal',
  components: {
    MultiSelect,
  },
  props: {
    companyLocations: {
      type: Array,
      required: true,
    },
  },
  data: () => ({
    moveCustomers: null,
    destination: null,
  }),
  computed: {
    validInputs() {
      return this.moveCustomers === false ||
        (this.moveCustomers === true && Object.keys(this.destination || {}).length)
        ? true
        : false
    },
  },
  methods: {
    close() {
      this.$root.$emit('bv::hide::modal', 'LocationDisableModal')
    },
    reset() {
      this.moveCustomers = null
      this.destination = null
    },
    process() {
      this.$emit('process', { destination: this.destination?._id })
      this.close()
    },
  },
}
</script>
