<template>
  <b-modal
    id="CreateTranslationTemplateModal"
    hide-footer
    hide-header
    @hide="closeModal"
    body-class="p-0"
    size="md"
  >
    <div class="p-2">
      <!-- header -->
      <div class="card-header px-4 pt-4">
        <div class="header-title" style="font-size: 20px">
          <h3>{{ mode }} Translation Template</h3>
        </div>
      </div>
      <!-- body -->
      <div class="card-body px-4 pt-4">
        <div class="form-group">
          <label for="provider">Language</label>
          <input
            id="region"
            type="text"
            class="form-control"
            placeholder="Language ISO 639-1 (e.g. en)"
            v-model="form.language"
          />
        </div>
        <div class="form-group">
          <label for="provider">Region</label>
          <input
            id="region"
            type="text"
            class="form-control"
            placeholder="Region (e.g. Canada)"
            v-model="form.region"
          />
        </div>
        <div class="form-group">
          <label for="provider">Template Name</label>
          <input
            id="email-template"
            type="text"
            class="form-control"
            placeholder="Template name"
            v-model="form.strings.templateName"
          />
        </div>
        <div class="form-group">
          <label for="provider">Subject</label>
          <input
            id="email-template"
            type="text"
            class="form-control"
            placeholder="Subject"
            v-model="form.strings.subject"
          />
        </div>
        <div class="form-group">
          <label for="provider">Message Preview</label>
          <input
            id="email-template"
            type="text"
            class="form-control"
            placeholder="Message Preview"
            v-model="form.strings.messagePreview"
          />
        </div>
        <div class="form-group mt-5 text-right">
          <button
            :disabled="isDisabled"
            class="btn btn-primary px-4"
            @click.prevent="createTemplate"
          >
            <div class="d-flex flex-row justify-content-end align-items-center">
              <span v-if="isLoading" class="loader loader-sm loader-white"></span>
              <span class="ml-2">{{ translationTemplate ? 'Update' : 'Create' }}</span>
            </div>
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const TranslationTemplateModule = createNamespacedHelpers('translationTemplate')

export default {
  name: 'CreateTranslationTemplateModal',
  props: ['integrationPartnerId'],
  data() {
    return {
      form: {
        type: 'OVATION.SURVEY.CUSTOM-SURVEY-INVITATION-EMAIL-TEMPLATE',
        region: '',
        language: '',
        strings: {
          templateName: '',
        },
      },
      mode: 'Create',
      isLoading: false,
    }
  },
  computed: {
    ...TranslationTemplateModule.mapGetters(['selectActiveTranslationTemplate']),
    isDisabled() {
      return !this.form.language || !this.form.region || !this.form.strings.templateName
    },
    translationTemplate() {
      return this.selectActiveTranslationTemplate
    },
  },
  watch: {
    translationTemplate: function (template) {
      if (template) {
        this.mode = 'Update'
        this.form.language = template?.language
        this.form.region = template?.region
        this.form.strings.templateName = template?.strings?.[0]?.v
        this.form.strings.subject = template?.strings?.[1]?.v
        this.form.strings.messagePreview = template?.strings?.[2]?.v
      }
    },
  },
  methods: {
    ...TranslationTemplateModule.mapActions([
      'createTranslationTemplate',
      'updateTranslationTemplate',
      'setActiveTranslationTemplate',
    ]),
    resetModal() {
      this.mode = 'Create'
      this.form.region = ''
      this.form.language = ''
      this.form.strings.templateName = ''
      this.form.strings.subject = ''
      this.form.strings.messagePreview = ''
    },
    closeModal() {
      this.resetModal()
      this.setActiveTranslationTemplate(null)
      this.$root.$emit('bv::hide::modal', 'CreateTranslationTemplateModal')
    },
    validateForm() {
      let isValid = true
      if (!this.form.language) {
        isValid = false
      }
      if (!this.form.region) {
        isValid = false
      }
      if (!this.form.strings.templateName) {
        isValid = false
      }
      return isValid
    },
    async createTemplate() {
      if (!this.validateForm()) return
      if (this.isLoading) return
      this.isLoading = true
      const mode = this.mode?.toLocaleLowerCase()
      try {
        if (this.translationTemplate) {
          const translationTemplateId = this.translationTemplate?._id
          const payload = {
            update: {
              ...this.form,
              strings: [
                {
                  k: 'templateName',
                  v: this.form.strings.templateName,
                },
                ...(this.form.strings.subject
                  ? [
                      {
                        k: 'subject',
                        v: this.form.strings.subject,
                      },
                    ]
                  : []),
                ...(this.form.strings.messagePreview
                  ? [
                      {
                        k: 'messagePreview',
                        v: this.form.strings.messagePreview,
                      },
                    ]
                  : []),
              ],
            },
            translationTemplateId,
          }
          await this.updateTranslationTemplate(payload)
        } else {
          const payload = {
            ...this.form,
            strings: [
              {
                k: 'templateName',
                v: this.form.strings.templateName,
              },
              ...(this.form.strings.subject
                ? [
                    {
                      k: 'subject',
                      v: this.form.strings.subject,
                    },
                  ]
                : []),
              ...(this.form.strings.messagePreview
                ? [
                    {
                      k: 'messagePreview',
                      v: this.form.strings.messagePreview,
                    },
                  ]
                : []),
            ],
            integrationPartner: this.integrationPartnerId,
          }
          await this.createTranslationTemplate(payload)
        }
        this.$notify({
          title: `Success!`,
          text: `Translation template ${mode}d successfully`,
        })
        this.closeModal()
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: `Failed to ${mode} translation template`,
        })
      }
      this.isLoading = false
    },
  },
}
</script>
