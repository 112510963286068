<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <!-- Header -->
          <div class="header mt-md-5">
            <div class="header-body">
              <!-- Pretitle -->
              <h6 class="header-pretitle">Developer Zone</h6>
              <!-- Title -->
              <div class="row">
                <div class="col-10">
                  <h1 class="header-title display-4">Feature Flags</h1>
                </div>
                <div class="col text-right" v-if="!showForm">
                  <button class="btn btn-outline-primary" @click="showForm = true">
                    <span class="fe fe-plus"></span>
                    Add Feature
                  </button>
                  <!-- <button @click="showForm = true" class="btn btn-primary">
                    <span class="fa fa-plus" aria-label="Add flag" /> Add Feature Flag
                  </button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row card p-4 mb-4" v-if="showForm">
        <div class="col-12">
          <form @submit.prevent="createFlag">
            <div class="row">
              <div class="col-lg-3 col-sm-12 col-xm-12">
                <label for="flag-enabled mb-5"><strong>Globally Enabled?</strong></label>
                <div class="form-check mb-3">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id="flagEnabled"
                    v-model="form.enabled"
                  />
                  <label class="form-check-label" for="flagEnabled"> Enabled </label>
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-xm-12">
                <div class="form-name">
                  <label for="flag-name"><strong>Feature Name</strong></label>
                  <input
                    v-model="form.name"
                    type="text"
                    class="form-control mb-3"
                    id="flagName"
                    placeholder="SHINY_NEW_FEATURE"
                    autofocus
                  />
                </div>
              </div>
              <div class="col-lg-3 col-sm-12 col-xm-12">
                <label for="flag-name"><strong>Companies</strong></label>
                <CompanySelector :displayIds="true" @updateCompanies="handleCompaniesUpdate" />
              </div>
              <div class="col-lg-3 col-sm-12 col-xm-12">
                <label for="flag-name"><strong>Users</strong></label>
                <UsersSelector :displayIds="true" @updateUsers="handleUsersUpdate" />
              </div>
            </div>
            <div class="text-right mt-4">
              <button @click="showForm = false" type="button" class="btn btn-secondary mr-3">
                Cancel
              </button>
              <button :disabled="!form.name || saving" type="submit" class="btn btn-primary">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="row">
        <div class="col-12 mb-5">
          <!-- feature flag list -->
          <div class="list-group" role="tablist">
            <div class="list-group-item" v-for="flag in flags" :key="flag._id">
              <div class="row">
                <div class="col-md-6 col-xm-12">
                  <button :class="`btn btn-sm`" @click="toggle(flag)">
                    <span :class="`fa ${flag.enabled ? 'fa-toggle-on' : 'fa-toggle-off'}`" />
                  </button>
                  <span :class="`ml-3${!flag.enabled ? ' text-secondary' : ' text-success'}`">
                    {{ flag.name }}
                  </span>
                </div>
                <!-- action buttons section -->
                <div class="col-md-6 col-xm-12 text-right">
                  <div class="option-buttons row">
                    <div class="col">
                      <button
                        :style="!flag.enabled ? isEnabledStyle(flag, 'users') : ''"
                        class="btn btn-sm mr-3"
                        @click="editUsers(flag)"
                      >
                        <span class="fa fa-users" />
                      </button>
                      <button
                        :style="!flag.enabled ? isEnabledStyle(flag, 'companies') : ''"
                        class="btn btn-sm mr-3"
                        @click="editCompanies(flag)"
                      >
                        <span class="fa fa-building" />
                      </button>
                      <button class="btn btn-sm" @click="remove(flag.name)">
                        <span class="fa fa-trash" />
                      </button>
                    </div>
                  </div>
                  <!-- action buttons section ends -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- modals section -->
      <FeatureFlagEditModal />
      <!-- modals section end -->
    </div>
  </div>
</template>

<script>
import UsersSelector from '../components/Modules/Users/UsersSelector/UsersSelector.vue'
import CompanySelector from '../components/Modules/Companies/CompanySelector/CompanySelector.vue'
import FeatureFlagEditModal from '../components/Modals/FeatureFlagEditModal.vue'
import { createNamespacedHelpers } from 'vuex'

const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')

export default {
  name: 'FeatureFlagsView',
  components: {
    UsersSelector,
    CompanySelector,
    FeatureFlagEditModal,
  },
  data: () => ({
    form: {
      name: '',
      users: [],
      enabled: true,
      companies: [],
    },
    saving: false,
    showForm: false,
  }),
  computed: {
    ...FeatureFlagModule.mapGetters(['selectFlags']),

    flags() {
      return this.selectFlags
    },
  },
  methods: {
    ...FeatureFlagModule.mapActions([
      'createFeatureFlag',
      'getFeatureFlags',
      'removeFeatureFlag',
      'updateFeatureFlag',
      'setFlagToBeEdited',
    ]),
    ...UserModule.mapActions(['getUsersList', 'getUsers']),
    ...CompanyModule.mapActions(['getCompanyList', 'getCompanyList']),

    createFlag: async function () {
      this.saving = true

      const payload = {
        enabled: this.form.enabled,
        name: this.form.name,
        users: this.form.users,
        companies: this.form.companies,
      }

      await this.createFeatureFlag(payload)
      this.saving = false
      this.showForm = false
    },
    remove: async function (name) {
      if (window.confirm('Are you sure?')) {
        await this.removeFeatureFlag(name)
      }
    },
    toggle: async function ({ name, enabled, users, companies }) {
      await this.updateFeatureFlag({ name, enabled: !enabled, users, companies })
    },
    editUsers(flag) {
      this.setFlagAndShowModal(flag, 'users')
    },
    editCompanies(flag) {
      this.setFlagAndShowModal(flag, 'companies')
    },
    isEnabledStyle(flag, property) {
      if (flag[property].length) {
        return {
          'border-bottom': '4px solid #5cb85c',
        }
      }
      return ''
    },
    customUserName(user) {
      return `${user.firstName} ${user.lastName} - ${user._id}`
    },
    customCompanyName(company) {
      return `${company.friendlyName || company.name} - ${company._id}`
    },
    handleUsersUpdate(users) {
      this.form.users = users.map((user) => user._id)
    },
    setFlagAndShowModal(flag, editType) {
      this.setFlagToBeEdited({
        editType: editType,
        flag: flag,
      })
      const { users, companies } = flag
      if (users && users.length && editType === 'users') {
        this.getUsers({ userIds: users })
      } else if (companies && companies.length && editType === 'companies') {
        this.getCompanyList({ filters: { companyIds: companies } })
      }
      this.$bvModal.show('FeatureFlagEditModal')
    },
    handleCompaniesUpdate(companies) {
      this.form.companies = companies.map((company) => company._id)
    },
  },
  mounted() {
    this.getFeatureFlags()
    this.getUsersList()
    this.getCompanyList()
  },
}
</script>
