<template>
  <div class="content-wrapper">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h2 class="mb-4 platform-source">Groups</h2>
          </div>
          <div class="col-auto">
            <button @click.prevent="createNewGroup" class="btn btn btn-primary px-4">
              <span class="fe fe-plus"></span> Group
            </button>
          </div>
        </div>
        <div class="row">
          <div class="col-lg-12 col-sm-12 mt-5" v-if="groups.length">
            <div v-for="group in groups" :key="group._id">
              <div class="row">
                <div class="col d-flex flex-wrap align-content-center">
                  <div class="d-flex flex-wrap align-content-center">
                    <span
                    style="transform: rotate(90deg);"
                    v-if="group.type !== 'singular'" 
                    class="fe fe-share-2 mr-4"></span>
                  </div>
                  <div :class="group.type === 'singular' ? 'ml-5' : ''">
                    {{ group.name }}
                  </div>
                </div>
                <div class="d-flex flex-wrap align-content-center">
                  <button
                    class="btn btn-outline-dark px-5 py-2 mr-4"
                    @click.prevent="handleEditGroup(group)">
                    Edit
                  </button>
                  <a href="#!" class="trash-icon">
                    <span
                      style="font-size: 20px; color: black"
                      class="fe fe-trash"
                      @click.prevent="handleDeleteGroup(group)"
                    ></span>
                  </a>
                </div>
              </div>
              <hr />
            </div>
          </div>
          <div class="col-lg-12 col-sm-12 mt-5 text-center" v-else>
            No groups to display
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import GroupBase from './GroupBase.vue'
import { createNamespacedHelpers } from 'vuex'
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'GroupMainContent',
  extends: GroupBase,
  computed: {
    ...GroupModule.mapGetters([
      'selectGroups', 
      'selectLevelNames', 
      'selectHttpResponse'
    ]),

    groups() {
      return this.selectGroups
    },
    levelNames(){
      return this.selectLevelNames
    },
    httpResponse() {
      return this.selectHttpResponse
    },
  },
  methods: {
    ...GroupModule.mapActions([
      'setIsEditing',
      'deleteGroupById',
      'setActiveSelectedGroup', 
    ]),

    async handleEditGroup(group) {
      this.setIsEditing(true)
      await this.setActiveSelectedGroup(group)
      // insert add-location placeholder
      this.insertAddLocationPlaceholders(this.tree)
      // navigate to the group creation page
      this.$router.push({ name: 'group-creator' })
    },
    createNewGroup() {
      this.updateTree({})
      this.setIsEditing(false)
      this.$router.push({ name: 'group-creator' })
    },
    async handleDeleteGroup(group) {
      if (!group) return

      const userResponse = await this.$bvModal.msgBoxConfirm(`Deleting: ${group.name} ?`, {
        size: 'sm',
        okTitle: 'Delete',
        okVariant: 'danger',
        centered: true,
      })

      if(userResponse) {
        try {
          await this.deleteGroupById(group._id)
          if (this.httpResponse && this.httpResponse.body.success) {
            this.$notify({
              type: 'success',
              title: 'Success',
              text: `Group "${group.name}" was deleted successfully.`,
            })
          }
        } catch (err) {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Unable to delete group, please try again.',
          })
        }
      }
    },

    recursiveIterator(obj){
      Object.entries(obj).forEach(([key, value]) => {
        if(key === 'children' && Array.isArray(value)){
          const id = obj['id']
          const path = this.findGroupPathById(this.tree, id)
          obj[key] = [ ...obj[key], this.generateNewGroupNode(path, this.levelNames)]

          obj[key].forEach(child => {
            if(child.children){
              this.recursiveIterator(child)
            }
          })
        }
      })
      return obj
    },

    addLocationPlaceholder(object){
      if(object.members && !object.children){
        object.children = [
          {
            id: this.generatePlaceholderId(),
            name: 'Locations',
            type: 'placeholder',
            kind: 'locations'
          }
        ]
      }

      if(!this.hasChildren(object)){
        return object
      }

      object.children.forEach(child => {
        this.addLocationPlaceholder(child)
      })
      return object
    },

    insertAddLocationPlaceholders(tree){
      const obj = this.recursiveIterator(tree)
      this.updateTree(this.addLocationPlaceholder(obj))
    },

    findCurrentGroupLevelDepth(path){
      if(!path) return
      let depthLevel = 0
      
      path.split('').forEach(char => {
        if(char.includes('.')){
          depthLevel += 1
        }
      })

      return depthLevel
    },

    generateNewGroupNode(path, levelNames){
      const maxLevelDepth = levelNames.length + 1
      const depthLevel = this.findCurrentGroupLevelDepth(path)
      const nextLevel = levelNames[depthLevel + 1]
      
      let group = {}

      if(path === ''){
        group = {
          id: this.generatePlaceholderId(),
          name: levelNames[0],
          type: 'placeholder',
          groupLevel: levelNames[0],
        }
      }
      else {
        group = {
          id: this.generatePlaceholderId(),
          name: levelNames[depthLevel + 1],
          type: 'placeholder',
          groupLevel: levelNames[depthLevel + 1],
        }
      }
      return group
    }
  },
}
</script>
<style scoped lang="scss">
  .trash-icon:hover {
    cursor: pointer;
    transform: translateY(-5px);
    transform: translateX(-2px);
    opacity: 5;
  }
</style>
