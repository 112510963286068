<template>
  <b-modal id="AssignUserOrganizationModal" 
    hide-footer 
    hide-header
    centered
    @hide="closeModal"
    body-class="p-0" 
    size="md">
    <div class="px-4 pt-4">
      <!-- body -->
      <div class="card-body px-4">
        <div class="header-title mb-4" style="font-size: 20px">
          Assign <strong> {{ userName }} </strong> to 
        </div>
        <multiselect
          class="mb-2"
          placeholder="Select Organization"
          :close-on-select="true"
          :multiple="false"
          v-model="form.organization"
          :searchable="true"
          :showLabels="false"
          :options="organizations"
          :custom-label="organizationName"
          track-by="_id"/>
        
        <div class="form-group text-right mt-4">
          <button
          style="font-size: 20px"
          :disabled="!this.form.organization"
          class="btn btn-primary px-5" 
          @click.prevent="assign">Assign</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import Multiselect from 'vue-multiselect'
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'AssignUserOrganizationModal',
  components: {
    Multiselect
  },
  data(){
    return {
      form: {
        organization: ''
      }
    }
  },
  computed: {
    ...OrganizationModule.mapGetters(['selectOrganizations', 'selectUserToAssignOrg']),

    user() {
      return this.selectUserToAssignOrg
    },
    userName(){
      let name = ''
      if(this.user){
        name = `${this.user.firstName} ${this.user.lastName}`
      }
      return name
    },
    organizations() {
      return this.selectOrganizations
    }
  },
  methods: {
    assign(){
      if(this.form.organization){
        this.$emit('assignOrganization', {
          userId: this.user._id,
          organizationId: this.form.organization._id,
        })
        // close the modal
        this.closeModal()
      }
    },
    closeModal() {
      // reset the form
      this.form.organization = ''
      this.$root.$emit('bv::hide::modal', 'AssignUserOrganizationModal')
    },
    organizationName(org) {
      return org.friendlyName || org.name
    }
  }
}
</script>