<template>
  <multiselect
    placeholder="Select companies"
    :close-on-select="closeOnSelect"
    :multiple="multiple"
    v-model="selectedCompanies"
    :searchable="true"
    :showLabels="false"
    :options="options"
    :custom-label="customCompanyName"
    track-by="_id"
    @search-change="fetchCompanies"
  />
</template>
<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CompanySelector',
  props: {
    multiple: {
      type: Boolean,
      default: false,
    },
    displayIds: {
      type: Boolean,
      default: false,
    },
    closeOnSelect: {
      type: Boolean,
      default: true,
    },
    value: {},
    initialCompanies: {},
  },
  data() {
    return {
      companies: [],
      selectedCompanies: [],
    }
  },
  components: {
    Multiselect,
  },
  computed: {
    options() {
      return this.companies
    },
  },
  watch: {
    selectedCompanies(val) {
      this.$emit('input', val)
    },
    value(val) {
      this.selectedCompanies = val
    },
  },
  methods: {
    ...CompanyModule.mapActions(['getCompanyList']),
    customCompanyName(company) {
      return `${company.name || company.friendlyName} ${this.displayIds ? ' - ' + company._id : ''}`
    },
    fetchCompanies: debounce(async function (query) {
      this.companies = await this.getCompanyList({ search: query })
    }, 500),
  },
  async mounted() {
    if (this.initialCompanies?.length) {
      this.companies = await this.getCompanyList({
        filters: {
          companyIds: this.initialCompanies,
        },
      })

      this.selectedCompanies = this.companies.filter((c) => this.initialCompanies.includes(c._id))

      const companyList = await this.getCompanyList()

      this.companies = [
        ...this.companies,
        ...companyList.filter((c) => !this.initialCompanies.includes(c._id)),
      ]
    } else {
      this.companies = await this.getCompanyList()
    }
  },
}
</script>
