<template>
  <div class="row">
    <div class="col-xl-12 card">
      <div class="card-body">
        <dl class="row">
          <dt class="mr-3">Phone</dt>
          <dd
            class="mr-5 cusor-pointer"
            @click="copyTargetText"
            title="Click to copy"
          >
            {{ location.phone }}
          </dd>

          <dt class="mr-3">Company Link</dt>
          <dd
            class="mr-5 cusor-pointer"
            @click="copyTargetText"
            title="Click to copy"
          >
            {{ location.company.orderLink }}
          </dd>

          <dt class="mr-3">Location Link</dt>
          <dd
            class="mr-5 cusor-pointer"
            @click="copyTargetText"
            title="Click to copy"
          >
            {{ location.orderLink }}
          </dd>
        </dl>

        <form>
          <LocationOrderPlatformRow
            class="mb-4"
            v-for="(orderPlatform, index) in location.orderPlatforms"
            :key="index"
            v-model="location.orderPlatforms[index]"
            @remove="removeRow(index)"
          />
          <div class="form-row">
            <div class="col">
              <div class="btn-group mr-3">
                <button
                  @click="addRow()"
                  type="button"
                  class="btn btn-success"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import LocationOrderPlatformRow from '@/components/Modules/Locations/LocationDetail/LocationOrderPlatformRow'

export default {
  name: 'LocationOrderPlatforms',
  props: {
    location: {
      required: true,
      type: Object
    }
  },
  components: {
    LocationOrderPlatformRow
  },
  methods: {
    removeRow(index) {
      this.$delete(this.location.orderPlatforms, index)
    },
    addRow() {
      this.location.orderPlatforms.push({
        location: this.location._id,
        company: this.location.company._id,
        orderUsesPopup: true
      })
    },
    copyTargetText({ target }) {
      this.copyTextToClipboard(target.innerText)
    },
    fallbackCopyTextToClipboard(text) {
      let textArea = document.createElement('textarea')
      textArea.value = text

      // Avoid scrolling to bottom
      textArea.style.top = '0'
      textArea.style.left = '0'
      textArea.style.position = 'fixed'

      document.body.appendChild(textArea)
      textArea.focus()
      textArea.select()

      try {
        const successful = document.execCommand('copy')
        const msg = successful ? 'successful' : 'unsuccessful'
        console.log('Fallback: Copying text command was ' + msg)
      } catch (err) {
        console.error('Fallback: Oops, unable to copy', err)
      }

      document.body.removeChild(textArea)
    },
    copyTextToClipboard(text) {
      if (!navigator.clipboard) {
        this.fallbackCopyTextToClipboard(text)
        return
      }
      navigator.clipboard.writeText(text).then(
        function() {
          console.log('Async: Copying to clipboard was successful!')
        },
        function(err) {
          console.error('Async: Could not copy text: ', err)
        }
      )
    }
  }
}
</script>

<style scoped lang="scss">
.cursor-pointer {
  cursor: pointer;
}
</style>
