<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="header mt-md-5">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">Overview</h6>
              <h1 class="header-title display-4">Api Documentation</h1>
            </div>
            <div class="col-4">
              <h6 class="header-pretitle">Definition</h6>
              <select
                class="form-control custom-select"
                id="status-select"
                v-model="selectedDefinition"
              >
                <option v-for="definition of definitions" :key="definition" :value="definition">
                  {{ definition }}
                </option>
              </select>
            </div>
          </div>
        </div>
      </div>
      <div class="spinner-border text-primary text-center" role="status" v-show="loading">
        <span class="visually-hidden">Loading...</span>
      </div>
      <div v-show="!loading" id="redoc-container"></div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const ApiDocsModule = createNamespacedHelpers('apiDocs')

export default {
  name: 'ApiDocsPage',
  components: {},
  data: () => ({
    definitions: ['Admin', 'Messaging', 'Ovation', 'Surveys', 'Voice', 'Webhooks'],
    loading: true,
    selectedDefinition: 'Admin',
  }),
  computed: {
    ...ApiDocsModule.mapGetters(['selectDefinition']),
  },
  watch: {
    selectedDefinition(val) {
      this.loadRedoc()
    },
  },
  methods: {
    ...ApiDocsModule.mapActions(['getApiDefinition']),
    async loadRedoc() {
      this.loading = true
      await this.getApiDefinition(this.selectedDefinition)
      this.loading = false
      const definition = this.selectDefinition(this.selectedDefinition)
      if (definition) {
        Redoc.init(
          definition,
          {
            expandResponses: '200,201',
            sortEnumValuesAlphabetically: true,
            sortOperationsAlphabetically: true,
            sortPropsAlphabetically: true,
            sortTagsAlphabetically: true,
            theme: {
              colors: {
                primary: {
                  main: '#6EC5AB',
                },
              },
              typography: {
                fontFamily: `"museo-sans", 'Helvetica Neue', Helvetica, Arial, sans-serif`,
                fontSize: '15px',
                lineHeight: '1.5',
                code: {
                  code: '#87E8C7',
                  backgroundColor: '#4D4D4E',
                },
              },
              menu: {
                backgroundColor: '#ffffff',
              },
            },
          },
          document.getElementById('redoc-container')
        )
      }
    },
  },
  async mounted() {
    const redocScript = document.createElement('script')
    redocScript.setAttribute(
      'src',
      'https://cdn.jsdelivr.net/npm/redoc@next/bundles/redoc.standalone.js'
    )
    document.head.appendChild(redocScript)
    await this.loadRedoc()
  },
}
</script>

<style scoped lang="scss"></style>
