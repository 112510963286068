import ClassifierService from '../services/ClassifierService'
import _ from 'lodash-es'
import moment from 'moment'

export default {
  namespaced: true,
  state: {
    feedbackData: {},
    categories: {},
    remainingCount: 0,
    classificationStatus: 'unclassified',
    startTime: null,
  },
  getters: {
    selectFeedbackText(state) {
      return state.feedbackData.feedbackText
    },
    selectFeedbackData(state) {
      return state.feedbackData
    },
    selectCategories(state) {
      return _.flow([
        Object.entries,
        (arr) => arr.filter(([k, v]) => v.name !== 'Other'),
        Object.fromEntries,
      ])(state.categories)
    },
    selectDefaultCategories(state) {
      return _.flow([
        Object.entries,
        (arr) => arr.filter(([k, v]) => v.name === 'Other'),
        Object.fromEntries,
      ])(state.categories)
    },
    selectRemainingCount(state) {
      return state.remainingCount
    },
    selectClassificationStatus(state) {
      return state.classificationStatus
    },
    selectStartTime(state) {
      return state.startTime
    },
  },
  mutations: {
    SET_FEEDBACK_DATA(state, feedbackData) {
      state.feedbackData = feedbackData
    },
    SET_CATEGORIES(state, categories) {
      state.categories = categories
    },
    SET_REMAINING_COUNT(state, remainingCount) {
      state.remainingCount = remainingCount
    },
    SET_CLASSIFICATION_STATUS(state, classificationStatus) {
      state.classificationStatus = classificationStatus
    },
    SET_START_TIME(state, startTime) {
      state.startTime = startTime
    },
  },
  actions: {
    async fetchFeedbackToClassify({ commit, getters }) {
      const response = await ClassifierService.fetchFeedback({
        status: getters.selectClassificationStatus,
      })
      commit('SET_FEEDBACK_DATA', response.body.data.feedbackData || {})
      commit('SET_REMAINING_COUNT', response.body.data.count || 0)
      commit('SET_START_TIME', moment())
    },

    async markStatus({ getters }, status) {
      const { _id, type } = getters.selectFeedbackData
      await ClassifierService.markStatus({ _id, type, status })
    },

    async classify({ getters }, classifications) {
      const { _id, type } = getters.selectFeedbackData
      const timeToClassify = moment().diff(getters.selectStartTime, 'seconds')
      await ClassifierService.classify({ _id, type, classifications, timeToClassify })
    },

    async fetchClassifierCategories({ commit }) {
      const response = await ClassifierService.fetchCategories()
      const rawCategories = response.body.data.categories

      // build category tree off category paths
      let categories = {}
      rawCategories.forEach((cat) => {
        if (cat.path === '') {
          if (categories[cat._id]) {
            categories[cat._id]['name'] = cat.name
            categories[cat._id]['_id'] = cat._id
          } else {
            categories[cat._id] = { ...cat, subcategories: {} }
          }
        } else {
          const splitPath = cat.path.split('/')
          for (let i = 0; i < splitPath.length; i += 2) {
            splitPath.splice(i + 1, 0, 'subcategories')
          }
          splitPath.push(`${cat._id}`)
          _.set(categories, splitPath, { ...cat, subcategories: {} })
        }
      })

      commit('SET_CATEGORIES', categories)
    },

    setClassificationStatus({ commit }, status) {
      commit('SET_CLASSIFICATION_STATUS', status)
    },
  },
}
