import Vue from 'vue'

export default {
  create({ type, companyIds, locationIds, nickName, contacts, products }) {
    return Vue.http.post(`v2/subscriptions`, {
      type,
      companyIds,
      locationIds,
      nickName,
      contacts,
      products,
    })
  },
  link({ type, companyId, nickName, contacts, stripeSubscriptionId, locationIds }) {
    return Vue.http.post(`v2/subscriptions/link`, {
      type,
      companyId,
      nickName,
      contacts,
      stripeSubscriptionId,
      locationIds,
    })
  },
  list({ types, companyIds, locationIds }) {
    return Vue.http.post(`v2/subscriptions/list`, { filters: { types, companyIds, locationIds } })
  },
  fetchSubscription({ subscriptionId }) {
    return Vue.http.get(`v2/subscriptions/${subscriptionId}`)
  },
  update({ subscriptionId, payload }) {
    return Vue.http.put(`v2/subscriptions/${subscriptionId}`, payload)
  },
  fetchProducts() {
    return Vue.http.get(`v2/subscriptions/products`)
  },
  fetchPrices({ productId }) {
    return Vue.http.get(`v2/subscriptions/prices/${productId}`)
  },
}
