import Vue from 'vue'

export default {
  createApiKey({ integrationPartnerId, scopes }) {
    return Vue.http.post('v2/api-keys', {
      integrationPartnerId,
      scopes,
    })
  },
  revokeApiKey(id) {
    return Vue.http.delete(`v2/api-keys/${id}`)
  },
  createPartner(partner) {
    return Vue.http.post('v2/integration-partners', {
      ...partner,
    })
  },
  getIntegrationLocations() {
    return Vue.http.get('integration-location')
  },
  getApiKey({ integrationPartnerId }) {
    return Vue.http.post(`v2/api-keys/list`, {
      filters: {
        integrationPartnerIds: [integrationPartnerId]
      }
    })
  },
  list(options) {
    return Vue.http.post('v2/integration-partners/list', options)
  },
  saveIntegrationLocation(payload) {
    const { integrationPartnerId: id, ...data } = payload
    return Vue.http.post(`v2/integration-partners/${id}/integration-location`, {
      ...data,
    })
  },
  updateIntegrationPartner({ partnerId, update }) {
    return Vue.http.put(`v2/integration-partners/${partnerId}`, {
      ...update,
    })
  },
}
