import Vue from 'vue'

export default {
  listBanners(payload) {
    return Vue.http.post('v2/banners/list', payload)
  },
  createBanner(payload) {
    return Vue.http.post('v2/banners', payload)
  },
  updateBanner(bannerId, modifications) {
    return Vue.http.put(`v2/banners/${bannerId}`, modifications)
  },
}
