<template>
  <table style="min-height: 500px" class="table table-hover table-responsive">
    <thead>
      <tr>
        <th v-for="header in headers" :key="header">
          {{ header }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="(user, index) in users" :key="index">
        <td>
          {{ user['First Name'] }}
        </td>
        <td>
          {{ user['Last Name'] }}
        </td>
        <td :class="[user.isValidEmail === false ? 'text-danger border border-danger' : '']">
          {{ user.Email }}
        </td>
        <td
          :class="['col-2', user.isValidPhone === false ? 'text-danger border border-danger' : '']">
          {{ formatPhoneNumber(user.Phone) }}
        </td>
        <td :class="[user.isValidRole === false ? 'text-danger border border-danger' : '']">
          {{ user.Role }}
        </td>
        <td>
          <LocationSelector
            @input="(input) => handleInput(index, input)"
            :value="user.locations"
            :companyId="companyId"
            v-if="user.Role !== 'admin'"
          />
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import LocationSelector from '../../Selects/LocationSelector.vue'
export default {
  name: 'UsersUploadPreview',
  components: {
    LocationSelector,
  },
  props: {
    headers: {
      type: Array,
    },
    users: {
      type: Array,
    },
    companyId: {
      type: String,
    },
  },
  methods: {
    handleInput(index, values) {
      this.$emit('locationChange', { index, values })
    },
    formatPhoneNumber(phone) {
      const cleaned = ('' + phone).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return phone
    },
  },
}
</script>

<style></style>
