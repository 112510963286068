import Vue from 'vue'

export default {
    list: async payload => {
        return Vue.http.post(`v2/review-subscription/list`, { ...payload })
    },
    create: async payload => {
        return Vue.http.post(`v2/review-subscription`, { ...payload })
    },
    enable: async ({ reviewSubscriptionId, slug }) => {
        return Vue.http.put(`v2/review-subscription/${reviewSubscriptionId}`, { slug })
    },
    disable: async ({ reviewSubscriptionId, locationId }) => {
        return Vue.http.delete(`v2/review-subscription/${reviewSubscriptionId}/location/${locationId}`)
    },
    search: async payload => {
        return Vue.http.post(`v2/review-subscription/search`, { ...payload })
    },
    refresh: async ({ reviewSubscriptionId }) => {
      return Vue.http.put(`v2/review-subscription/${reviewSubscriptionId}/refresh`)
    }
}
