import Vue from 'vue'

export default {
  list() {
    return Vue.http.get('order')
  },
  shipped(orderId) {
    console.log('orderId', orderId)
    return Vue.http.post('order/shipped', { orderId })
  }
}
