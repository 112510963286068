<template>
  <div class="main-content">
    <div class="container-fluid" v-if="activeCompany">
      <div class="header">
        <div class="header-body pb-3">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">{{ activeCompany._id }}</h6>
              <h1 class="header-title display-4">{{ activeCompany.name }}</h1>
            </div>
            <div class="col-auto">
              <div class="form-group d-inline-block mr-3">
                <select
                  class="form-control custom-select"
                  id="status-select"
                  :value="activeCompany.status || ''"
                  @input="saveCompanyStatus($event.target.value)"
                >
                  <option v-for="status of statusOptions" :key="status" :value="status">
                    {{ status }}
                  </option>
                </select>
              </div>
              <button
                v-if="activeCompany.partner !== 'vendasta' && !isPartner"
                class="btn btn btn-outline-primary"
                @click="showUploadUsersModal"
              >
                <span class="fe fe-plus"></span>
                Upload Users
              </button>
              <b-dropdown
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                class="companyDropdown"
              >
                <template #button-content>
                  <button
                    v-if="activeCompany.partner !== 'vendasta' && !isPartner"
                    class="btn btn btn-outline-primary"
                  >
                    <span class="fe fe-plus"></span>
                    Add Location
                  </button>
                </template>
                <b-dropdown-item @click.prevent="showCreationModal">
                  Add single location</b-dropdown-item
                >
                <b-dropdown-item @click.prevent="showUploadLocationsModal">
                  Upload Locations
                </b-dropdown-item>
              </b-dropdown>
              <b-dropdown
                size="lg"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                class="companyDropdown"
              >
                <template #button-content><i class="fas fa-ellipsis-v"></i></template>
                <b-dropdown-item @click="$bvModal.show('CompanyTestDataModal')"
                  >Delete Test Data</b-dropdown-item
                >
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
      <div class="btn-group btn-group-sm mt--3 mb-4 d-flex w-100" role="group">
        <button
          class="btn btn-light w-100"
          :class="{ active: filter === 'settings' }"
          @click="filter = 'settings'"
        >
          Settings
        </button>
        <button
          class="btn btn-light w-100"
          :class="{ active: filter === 'toggles' }"
          @click="filter = 'toggles'"
        >
          Toggles
        </button>
        <button
          class="btn btn-light w-100"
          :class="{ active: filter === 'details' }"
          @click="filter = 'details'"
        >
          Details
        </button>
        <button
          class="btn btn-light w-100"
          :class="{ active: filter === 'subscriptions' }"
          @click="filter = 'subscriptions'"
        >
          Subscriptions
        </button>
      </div>
      <div class="row">
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'settings'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Friendly Name</h2>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label for="friendly-name">Company Friendly Name</label>
                    <input
                      type="text"
                      placeholder="Enter Friendly Name"
                      id="friendly-name"
                      :value="activeCompany.friendlyName || null"
                      @input="setActiveCompanyFriendlyName($event.target.value)"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group mb-0">
                    <button
                      @click.prevent="updateActiveCompanyFriendlyName"
                      class="btn btn-primary"
                    >
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- company friendlyName end -->
        <!-- company location form -->
        <div class="col-md-12" v-if="filter === 'settings'">
          <CompanyAddressForm :company="activeCompany" @updateCompanyAddressData="updateCompanyAddressData"/>
        </div>
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'settings'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Website Url</h2>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label for="corporate-url">Corporate Website Url</label>
                    <input
                      type="text"
                      placeholder="Enter Corporate Website Url"
                      id="corporate-url"
                      :value="activeCompany.corporateWebsite || null"
                      @input="setActiveCompanyCorporateWebsite($event.target.value)"
                      class="form-control"
                    />
                  </div>
                  <div class="form-group mb-0">
                    <button
                      @click.prevent="updateActiveCompanyCorporateWebsite"
                      class="btn btn-primary"
                    >
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- company corporate website end -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'subscriptions'">
          <Subscription :company="activeCompany" />
        </div>
        <!-- subscription end -->
        <div class="col-md-12" v-if="filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Campaigns</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Campaign status</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="campaigns-enabled"
                            v-model="activeCompany.campaignsEnabled"
                          />
                          <label class="custom-control-label" for="campaigns-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.campaignsEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="!auth.isPartner">
                    <label>Allow MMS</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="mms-enabled"
                            v-model="activeCompany.mmsEnabled"
                            :disabled="activeCompany.partner"
                          />
                          <label class="custom-control-label" for="mms-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.mmsEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group">
                    <div class="row">
                      <div class="col">
                        <label for="text-usage-limit">Text usage limit (per month)</label>
                        <input
                          v-model="activeCompany.textUsageLimit"
                          class="form-control"
                          id="text-usage-limit"
                          placeholder="1000"
                          type="text"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="form-group" v-if="!auth.isPartner">
                    <div class="row">
                      <div class="col">
                        <label for="text-overage-cost">Text overage cost</label>
                        <div class="input-group">
                          <div class="input-group-prepend">
                            <div class="input-group-text">$</div>
                          </div>
                          <input
                            v-model="activeCompany.textOverageCost"
                            class="form-control"
                            id="text-overage-cost"
                            placeholder="0.03"
                            step="0.01"
                            type="number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <button
                      @click="saveCampaignSettings"
                      :disabled="isSaveDisabled"
                      class="btn btn-primary"
                    >
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- campaigns end -->
        <div class="col-md-12" v-if="filter === 'settings'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Single-use Credits</h2>
              <div class="row">
                <div class="col-12 mt-2">
                  <div class="row ml-1">
                    <label class="pt-2"
                      >Currently Available:<span class="font-weight-bold">{{
                        ' ' + (selectActiveCompany.textCreditBalance || 0)
                      }}</span></label
                    >
                    <button
                      @click="showAddBusinessCredit(undefined)"
                      class="btn btn-primary ml-4 mb-2 px-4"
                    >
                      Add Credit
                    </button>
                  </div>
                  <div class="table-responsive">
                    <table class="table mb-0 mt-4">
                      <thead>
                        <tr>
                          <th>Date Issued</th>
                          <th>Amount</th>
                          <th>Reason</th>
                          <th width="300">Notes</th>
                          <th>Created By</th>
                          <th>Last Modified By</th>
                          <th>Voided</th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-if="!selectActiveCompanyBusinessCredits.length">
                          <td>No credits found...</td>
                        </tr>
                        <tr
                          v-for="businessCredit of selectActiveCompanyBusinessCredits"
                          :key="businessCredit._id"
                        >
                          <td>{{ businessCredit.createdAt | moment('MM/DD/YYYY h:mm A') }}</td>
                          <td>{{ businessCredit.amount }}</td>
                          <td>{{ businessCredit.reason }}</td>
                          <td>{{ businessCredit.notes }}</td>
                          <td>
                            {{
                              businessCredit.createdBy
                                ? `${businessCredit.createdBy.firstName} ${businessCredit.createdBy.lastName}`
                                : ''
                            }}
                          </td>
                          <td>
                            {{
                              businessCredit.lastUpdatedBy
                                ? `${businessCredit.lastUpdatedBy.firstName} ${businessCredit.lastUpdatedBy.lastName}`
                                : ''
                            }}
                          </td>
                          <td>
                            <b-badge v-if="businessCredit.voided" variant="warning">Voided</b-badge>
                          </td>
                          <td v-if="!businessCredit.voided">
                            <i
                              class="fe fe-edit text-info icon-large mr-2"
                              @click="showAddBusinessCredit(businessCredit._id)"
                            ></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- escalation section -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Escalations</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Allow company to create escalation rules</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="escalations-enabled"
                            v-model="activeCompany.settings.escalationsEnabled"
                            @change="updateEscalationsEnabled"
                          />
                          <label class="custom-control-label" for="escalations-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.settings.escalationsEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- CTT Only section -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">CTT Only</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Only show the navbar option for CTT in the webapp</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="ctt-only-enabled"
                            v-model="activeCompany.settings.cttOnly"
                            @change="updateCTTOnly"
                          />
                          <label class="custom-control-label" for="ctt-only-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.settings.cttOnly ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- bulk import section -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Bulk Survey Send</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Allow company to send bulk surveys invites</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="bulk-survey-enabled"
                            v-model="activeCompany.settings.bulkSurveyInvitesEnabled"
                            @change="updateBulkSurveyInvites"
                          />
                          <label class="custom-control-label" for="bulk-survey-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.settings.bulkSurveyInvitesEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- routing rules section -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Routing Rules</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Allow company to create routing rules</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="routing-enabled"
                            v-model="activeCompany.settings.routingRulesEnabled"
                            @change="updateRoutingRulesEnabled"
                          />
                          <label class="custom-control-label" for="routing-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.settings.routingRulesEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- internal notes section -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Internal Notes</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Allow users to create internal notes in conversations</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="internal-notes-enabled"
                            v-model="activeCompany.settings.internalNotesEnabled"
                            @change="updateinternalNotesEnabled"
                          />
                          <label class="custom-control-label" for="internal-notes-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.settings.internalNotesEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- marqii clickout section -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Marqii click out</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Allow for redirecting to Marqii</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="maqii-click-out-enabled"
                            v-model="activeCompany.settings.marqiiClickOutEnabled"
                            @change="updateMarqiiClickOutEnabled"
                          />
                          <label class="custom-control-label" for="maqii-click-out-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.settings.marqiiClickOutEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- advanced reporting section -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Advanced Reports</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Allow access to BoldBI advanced reports</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="advanced-reports-enabled"
                            v-model="activeCompany.settings.advancedReportsEnabled"
                            @change="updateAdvancedReportsEnabled"
                          />
                          <label
                            class="custom-control-label"
                            for="advanced-reports-enabled"
                          ></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.settings.advancedReportsEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- auto reponses section -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Auto Responses</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Allow access to Auto Responses</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="responses-enabled"
                            v-model="activeCompany.settings.responsesEnabled"
                            @change="updateResponsesEnabled"
                          />
                          <label
                            class="custom-control-label"
                            for="responses-enabled"
                          ></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.settings.responsesEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Insights section -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Insights</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Insight status</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="insights-enabled"
                            v-model="activeCompany.insightsEnabled"
                          />
                          <label class="custom-control-label" for="insights-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.insightsEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <button @click="saveInsightSettings" class="btn btn-primary">
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- insights section end -->
        <div class="col-md-12" v-if="filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Isolate Locations</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Isolation status: shows only locations belonging to user.</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="isolate-locations"
                            v-model="activeCompany.isolateLocations"
                          />
                          <label class="custom-control-label" for="isolate-locations"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.isolateLocations ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <button @click="saveIsolationSettings" class="btn btn-primary">
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- end location isolation toggle -->
        <div class="col-md-12" v-if="filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Report Builder Access</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Report Builder Enabled</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="rb-access"
                            v-model="activeCompany.reportBuilderAccess"
                          />
                          <label class="custom-control-label" for="rb-access"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.reportBuilderAccess ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <button @click="saveReportBuilderStatus" class="btn btn-primary">
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- report builder access end -->
        <div class="col-md-12" v-if="filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Winback AI</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Winback AI Enabled</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="winback-ai-access"
                            v-model="activeCompany.settings.winbackAiEnabled"
                          />
                          <label class="custom-control-label" for="winback-ai-access"></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.settings.winbackAiEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <button @click="saveWinbackAiEnabledStatus" class="btn btn-primary">
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- report winback enabled access end -->
        <div class="col-md-12" v-if="filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Custom Questions</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Custom Questions Status</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="custom-questions-enabled"
                            v-model="activeCompany.customQuestionsEnabled"
                          />
                          <label
                            class="custom-control-label"
                            for="custom-questions-enabled"
                          ></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.customQuestionsEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <button @click="saveCustomQuestionSettings" class="btn btn-primary">
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- custom questions end -->
        <div class="col-md-12" v-if="filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Daily Review Updates</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Daily Review Updates Status</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="daily-review-updates-enabled"
                            v-model="activeCompany.dailyReviewUpdatesEnabled"
                          />
                          <label
                            class="custom-control-label"
                            for="daily-review-updates-enabled"
                          ></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.dailyReviewUpdatesEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <button @click="saveDailyReviewUpdateSettings" class="btn btn-primary">
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Daily Review Updates section end -->
        <div class="col-md-12" v-if="filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Loyalty</h2>
              <div class="row">
                <div class="col-12 col-md-12">
                  <div class="form-group">
                    <label>Integrated Loyalty</label>
                    <small class="form-text text-muted"
                      >Make sure their POS integration is setup properly before toggling this
                      on.</small
                    >
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="integrated-loyalty-enabled"
                            v-model="activeCompany.loyalty.integrated"
                          />
                          <label
                            class="custom-control-label"
                            for="integrated-loyalty-enabled"
                          ></label>
                        </div>
                      </div>
                      <div class="col ml--2">
                        <small class="text-muted">{{
                          activeCompany.loyalty.integrated ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-0">
                    <button @click="saveLoyaltySettings" class="btn btn-primary">
                      Save settings
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- loyalty section end -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'settings'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Partner</h2>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label for="partner-select">Select a partner</label>
                    <select
                      class="form-control custom-select"
                      id="partner-select"
                      :value="activeCompany.partner || ''"
                      @input="setActiveCompanyPartner($event.target.value)"
                    >
                      <option value>None</option>
                      <option v-for="partner of partners" :key="partner._id" :value="partner._id">
                        {{ partner.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mb-0">
                    <button @click="saveCampaignPartner" class="btn btn-primary">Assign</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- partner section end -->
        <div class="col-md-12" v-if="!auth.isPartner && filter === 'settings'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Organization</h2>
              <div class="row">
                <div class="col-lg-6 col-sm-12">
                  <div class="form-group">
                    <label for="organization-select">Select an organization</label>
                    <select
                      class="form-control custom-select"
                      id="organization-select"
                      :value="activeCompany.organization || null"
                      @input="setActiveCompanyOrganization($event.target.value)"
                    >
                      <option value>None</option>
                      <option v-for="org of organizations" :key="org._id" :value="org._id">
                        {{ org.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group mb-0">
                    <button @click.prevent="saveOrganizationSettings" class="btn btn-primary">
                      Assign
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- organization section end -->
        <div class="col-md-12" v-if="filter === 'settings'">
          <GroupMainContent />
        </div>
        <!-- group section end -->
        <div
          class="col-md-12"
          v-if="
            activeCompanyTransactions &&
            activeCompanyTransactions.length > 0 &&
            filter === 'settings'
          "
        >
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Overages</h2>
              <div class="table-responsive">
                <table class="table mb-0">
                  <caption>
                    Overages grouped by month
                  </caption>
                  <thead>
                    <tr>
                      <th>Month</th>
                      <th>Overage #</th>
                      <th>Rate</th>
                      <th>Total</th>
                      <th>Charged?</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="transaction in activeCompanyTransactions" :key="transaction.id">
                      <th>{{ transaction.createdAt | moment('MMMM YYYY') }}</th>
                      <td>{{ transaction.overageAmount }}</td>
                      <td>{{ transaction.overageCost | currency }}</td>
                      <td>{{ transaction.totalCost | currency }}</td>
                      <td>{{ transaction.isCharged ? 'Yes' : 'No' }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- overage section end -->
        <div class="col-md-12">
          <!-- <CompanyBilling class="row" /> -->
        </div>
        <!-- locations section -->
        <div class="col-12 col-sm-12" v-if="filter === 'details'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Locations</h2>
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div v-if="locations.length" class="table-responsive">
                    <b-table responsive :items="locations" :fields="locationTableFields">
                      <template #cell(name)="data">
                        <router-link :to="'/locations/' + data.item.locationId">
                          {{ data.item.name }}
                        </router-link>
                      </template>
                    </b-table>
                  </div>
                  <div v-else>No locations</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- health section -->
        <div class="col-12 col-sm-12" v-if="filter === 'details'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Health</h2>
              <div class="row">
                <div class="col-lg-12 col-sm-12">
                  <div v-if="healthScores.length" class="table-responsive">
                    <b-table responsive :items="healthScores"></b-table>
                  </div>
                  <div v-else>No health data</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Survey Source Overrides section -->
        <div class="col-md-12" v-if="filter === 'settings'">
          <SurveySourceOverrides :companyId="activeCompany._id" />
        </div>
        <!-- ivr section -->
        <div class="col-md-12" v-if="filter === 'toggles'">
          <div class="card">
            <div class="card-body">
              <h2 class="mb-4 platform-source">Call to Text</h2>
              <div class="row">
                <div class="col-12 col-md-6">
                  <div class="form-group">
                    <label>Allow company to use call to text</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="custom-control custom-checkbox-toggle">
                          <input
                            class="custom-control-input"
                            type="checkbox"
                            id="ivr-enabled"
                            v-model="activeCompany.settings.ivrEnabled"
                            @change="updateIvrEnabled"
                          />
                          <label class="custom-control-label" for="ivr-enabled"></label>
                        </div>
                      </div>
                      <div class="col ml-2">
                        <small class="text-muted">{{
                          activeCompany.settings.ivrEnabled ? 'Enabled' : 'Disabled'
                        }}</small>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row" v-if="activeCompany.settings.ivrEnabled === true">
                <div class="col-12">
                  <IvrBuilder
                    :ivr="selectIvrs.length ? selectIvrs[0] : null"
                    :companyId="activeCompany ? activeCompany._id : null"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <AddLocationModal
        id="AddLocationModal"
        :companyId="this.$route.params.id"
        @refreshLocations="getCompanyLocations($route.params.id)"
      />
      <AddBusinessCreditModal
        id="AddBusinessCreditModal"
        :companyId="this.$route.params.id"
        @refreshBusinessCredits="getCompanyBusinessCredits($route.params.id)"
      />
      <CompanyTestDataModal id="CompanyTestDataModal" :companyId="this.$route.params.id" />
      <UploadLocationsModal :companyId="this.$route.params.id" />
      <UploadUsersModal :companyId="this.$route.params.id" />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import AddLocationModal from '@/components/Modals/AddLocationModal'
import AddBusinessCreditModal from '@/components/Modals/AddBusinessCreditModal'
import CompanyTestDataModal from '@/components/Modals/CompanyTestDataModal'
import CompanyService from '@/services/CompanyService'
import CompanyBilling from '@/components/Modules/Companies/CompanyDetails/CompanyBilling.vue'
import CompanyAddressForm from '@/components/Modules/Companies/CompanyDetails/CompanyAddressForm'
import GroupMainContent from '../components/Modules/groups/GroupMainContent.vue'
import Subscription from '@/components/Modules/Billing/Subscription/Subscription'
import IvrBuilder from '@/components/Modules/Ivrs/IvrBuilder.vue'
import UploadUsersModal from '@/components/Modals/UploadUsersModal'
import UploadLocationsModal from '@/components/Modals/UploadLocationsModal'
import SurveySourceOverrides from '../components/Modules/Companies/CompanyDetails/SurveySourceOverrides.vue'

const AuthModule = createNamespacedHelpers('auth')
const CompanyModule = createNamespacedHelpers('company')
const GroupModule = createNamespacedHelpers('group')
const IvrModule = createNamespacedHelpers('ivr')
const LocationModule = createNamespacedHelpers('location')
const HealthModule = createNamespacedHelpers('health')
const OrganizationModule = createNamespacedHelpers('organization')
const PartnerModule = createNamespacedHelpers('partner')

export default {
  name: 'CompanyDetail',
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  components: {
    AddBusinessCreditModal,
    AddLocationModal,
    // CompanyBilling,
    CompanyTestDataModal,
    GroupMainContent,
    IvrBuilder,
    UploadUsersModal,
    UploadLocationsModal,
    SurveySourceOverrides,
    Subscription,
    CompanyAddressForm,
  },
  data: () => ({
    statusOptions: ['active', 'cancelled', 'test'],
    filter: 'settings',
    locationTableFields: [
      { key: 'name', label: 'city/nickname' },
      'bandwidth phone',
      'disabled',
      'address',
      'locationId',
      'created',
    ],
  }),
  computed: {
    ...AuthModule.mapState(['auth']),
    ...AuthModule.mapGetters(['selectIsPartner']),
    ...CompanyModule.mapState(['activeCompany', 'activeCompanyTransactions']),
    ...CompanyModule.mapGetters(['selectActiveCompany', 'selectActiveCompanyBusinessCredits']),
    ...GroupModule.mapGetters(['selectGroups']),
    ...LocationModule.mapGetters(['selectCompanyLocations']),
    ...HealthModule.mapGetters(['selectHealthScores']),
    ...IvrModule.mapGetters(['selectIvrs']),
    ...OrganizationModule.mapGetters(['selectOrganizations']),
    ...PartnerModule.mapState(['partners']),
    groups() {
      return this.selectGroups
    },
    organizations() {
      return this.selectOrganizations
    },
    isSaveDisabled() {
      return (
        !this.activeCompany.textUsageLimit ||
        (!this.auth.isPartner && !this.activeCompany.textOverageCost)
      )
    },
    locations() {
      return this.selectCompanyLocations.map((loc) => {
        return {
          name: loc.friendlyName || loc.city,
          'bandwidth phone': loc.bandwidth && loc.bandwidth.number,
          disabled: loc.disabled ? 'X' : '',
          address: loc.address,
          locationId: loc._id,
          created: this.$moment(loc.created).format('l'),
        }
      })
    },
    healthScores() {
      return this.selectHealthScores
    },
    isPartner() {
      return this.selectIsPartner
    },
  },
  methods: {
    ...CompanyModule.mapMutations(['setActiveCompanyPartner', 'setActiveCompanyCustomerId']),
    ...CompanyModule.mapActions([
      'getCompany',
      'getCompanyTransactions',
      'getCompanyBusinessCredits',
      'setBusinessCreditEditId',
      'assignOrganization',
      'updateCompanySettings',
      'setActiveCompanyOrganization',
      'setActiveCompanyFriendlyName',
      'setActiveCompanyCorporateWebsite',
    ]),
    ...GroupModule.mapActions(['fetchGroups']),
    ...HealthModule.mapActions(['getSummaryEmails']),
    ...IvrModule.mapActions(['getIvrs', 'getIvrEnums']),
    ...LocationModule.mapActions(['getCompanyLocations']),
    ...OrganizationModule.mapActions(['fetchOrganizations']),
    ...PartnerModule.mapActions(['getPartnerList']),

    refreshLocations() {},
    showCreationModal() {
      this.$root.$emit('bv::show::modal', 'AddLocationModal')
    },
    showUploadLocationsModal() {
      this.$root.$emit('bv::show::modal', 'UploadLocationsModal')
    },
    showUploadUsersModal() {
      this.$root.$emit('bv::show::modal', 'UploadUsersModal')
    },
    showAddBusinessCredit(businessCreditId) {
      this.setBusinessCreditEditId(businessCreditId)
      this.$root.$emit('bv::show::modal', 'AddBusinessCreditModal')
    },
    async saveCompanyStatus(status) {
      const payload = {
        fields: {
          status,
        },
        companyId: this.activeCompany._id,
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage('success', "Company's status updated successfully.")
      } else {
        this.displayNotifyMessage('error', 'Failed to update company status.')
      }
    },
    async saveCampaignPartner() {
      if (this.auth.isPartner) {
        return
      }

      const payload = {
        companyId: this.activeCompany._id,
      }

      if (Object.prototype.hasOwnProperty.call(this.activeCompany, 'partner')) {
        this.activeCompany.mmsEnabled = false

        payload.partner = this.activeCompany.partner
      }

      await CompanyService.updatePartner(payload)

      return this.$forceUpdate()
    },
    saveCampaignSettings() {
      const fields = {
        campaignsEnabled: this.activeCompany.campaignsEnabled,
        textOverageCost: this.activeCompany.textOverageCost,
        textUsageLimit: this.activeCompany.textUsageLimit,
      }

      if (!this.auth.isPartner) {
        fields.mmsEnabled = this.activeCompany.mmsEnabled
      }

      return CompanyService.update(this.activeCompany._id, fields)
    },
    saveInsightSettings() {
      const fields = {
        insightsEnabled: this.activeCompany.insightsEnabled,
      }

      return CompanyService.update(this.activeCompany._id, fields)
    },
    saveIsolationSettings() {
      const fields = {
        isolateLocations: this.activeCompany.isolateLocations,
      }

      return CompanyService.update(this.activeCompany._id, fields)
    },
    saveReportBuilderStatus() {
      const fields = {
        reportBuilderAccess: this.activeCompany.reportBuilderAccess,
      }

      return CompanyService.update(this.activeCompany._id, fields)
    },
    saveWinbackAiEnabledStatus() {
      const fields = {
        'settings.winbackAiEnabled': this.activeCompany.settings.winbackAiEnabled,
      }

      return CompanyService.update(this.activeCompany._id, fields)
    },
    saveCustomQuestionSettings() {
      const fields = {
        customQuestionsEnabled: this.activeCompany.customQuestionsEnabled,
      }

      return CompanyService.update(this.activeCompany._id, fields)
    },

    saveDailyReviewUpdateSettings() {
      const fields = {
        dailyReviewUpdatesEnabled: this.activeCompany.dailyReviewUpdatesEnabled,
      }

      return CompanyService.update(this.activeCompany._id, fields)
    },
    saveLoyaltySettings() {
      const fields = {
        'loyalty.integrated': this.activeCompany.loyalty.integrated,
      }
      return CompanyService.update(this.activeCompany._id, fields)
    },
    async saveOrganizationSettings() {
      // if there is no organization id set, then
      // that means that the 'None' option is selected
      if (!this.activeCompany.organization) {
        this.activeCompany.organization = null
      }

      const payload = {
        companyId: this.activeCompany._id,
        organizationId: this.activeCompany.organization,
      }

      const response = await this.assignOrganization(payload)
      const { body } = response

      if (body.success) {
        this.displayNotifyMessage('success', 'Organization assigned successfully')
      } else {
        this.displayNotifyMessage('error', 'failed to assign organization')
      }
    },
    async updateActiveCompanyFriendlyName() {
      let friendlyName = ''

      if (this.activeCompany.friendlyName) {
        friendlyName = this.activeCompany.friendlyName.trim()
      }

      const payload = {
        fields: {
          friendlyName,
        },
        companyId: this.activeCompany._id,
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage('success', "Company's friendly name updated successfully.")
      } else {
        this.displayNotifyMessage('error', 'Failed to update company friendly name.')
      }
    },
    async updateCompanyAddressData(addressData) {
     const payload = {
        fields: {
          ...addressData
        },
        companyId: this.activeCompany._id,
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage('success', "Company's address data updated successfully.")
      } else {
        this.displayNotifyMessage('error', 'Failed to update company address data.')
      }
    },
    async updateActiveCompanyCorporateWebsite() {
      let corporateWebsite = ''

      if (this.activeCompany.corporateWebsite) {
        corporateWebsite = this.activeCompany.corporateWebsite.trim()
      }

      const payload = {
        fields: {
          corporateWebsite,
        },
        companyId: this.activeCompany._id,
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage('success', "Company's corporate website updated successfully.")
      } else {
        this.displayNotifyMessage('error', "Failed to update company's corporate website.")
      }
    },
    async updateEscalationsEnabled() {
      const { escalationsEnabled } = this.activeCompany.settings

      const payload = {
        companyId: this.activeCompany._id,
        fields: {
          settings: this.activeCompany.settings,
        },
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage(
          'success',
          `Escalations ${escalationsEnabled ? 'enabled' : 'disabled'}`
        )
      } else {
        this.displayNotifyMessage('error', 'Oops an error occurred.')
      }
    },
    async updateCTTOnly() {
      const { cttOnly } = this.activeCompany.settings

      const payload = {
        companyId: this.activeCompany._id,
        fields: {
          settings: this.activeCompany.settings,
        },
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage(
          'success',
          `CTT Only ${cttOnly ? 'enabled' : 'disabled'}`
        )
      } else {
        this.displayNotifyMessage('error', 'Oops an error occurred.')
      }
    },
    async updateBulkSurveyInvites() {
      const { bulkSurveyInvitesEnabled } = this.activeCompany.settings

      const payload = {
        companyId: this.activeCompany._id,
        fields: {
          settings: this.activeCompany.settings,
        },
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage(
          'success',
          `Bulk survey invites ${bulkSurveyInvitesEnabled ? 'enabled' : 'disabled'}`
        )
      } else {
        this.displayNotifyMessage('error', 'Oops an error occurred.')
      }
    },
    async updateRoutingRulesEnabled() {
      const { routingRulesEnabled } = this.activeCompany.settings

      const payload = {
        companyId: this.activeCompany._id,
        fields: {
          settings: this.activeCompany.settings,
        },
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage(
          'success',
          `Routing rules ${routingRulesEnabled ? 'enabled' : 'disabled'}`
        )
      } else {
        this.displayNotifyMessage('error', 'Oops an error occurred.')
      }
    },
    async updateIvrEnabled() {
      const { ivrEnabled } = this.activeCompany.settings

      const payload = {
        companyId: this.activeCompany._id,
        fields: {
          settings: this.activeCompany.settings,
        },
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage(
          'success',
          `Text to Speech ${ivrEnabled ? 'enabled' : 'disabled'}`
        )
      } else {
        this.displayNotifyMessage('error', 'Oops an error occurred.')
      }
    },
    displayNotifyMessage(type, message) {
      if (type === 'success') {
        this.$notify({
          type: 'info',
          text: message || 'Company settings updated successfully.',
        })
      } else if (type === 'error') {
        this.$notify({
          type: 'error',
          text: message || 'An error has occurred, unable to update company settings.',
        })
      }
    },
    async updateinternalNotesEnabled() {
      const { internalNotesEnabled } = this.activeCompany.settings

      const payload = {
        companyId: this.activeCompany._id,
        fields: {
          settings: this.activeCompany.settings,
        },
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage(
          'success',
          `Internal notes ${internalNotesEnabled ? 'enabled' : 'disabled'}`
        )
      } else {
        this.displayNotifyMessage('error', 'Oops an error occurred.')
      }
    },
    async updateMarqiiClickOutEnabled() {
      const { marqiiClickOutEnabled } = this.activeCompany.settings

      const payload = {
        companyId: this.activeCompany._id,
        fields: {
          settings: this.activeCompany.settings,
        },
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage(
          'success',
          `Marqii click out ${marqiiClickOutEnabled ? 'enabled' : 'disabled'}`
        )
      } else {
        this.displayNotifyMessage('error', 'Oops an error occurred.')
      }
    },
    async updateAdvancedReportsEnabled() {
      const { advancedReportsEnabled } = this.activeCompany.settings

      const payload = {
        companyId: this.activeCompany._id,
        fields: {
          settings: this.activeCompany.settings,
        },
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage(
          'success',
          `Advanced Reports ${advancedReportsEnabled ? 'enabled' : 'disabled'}`
        )
      } else {
        this.displayNotifyMessage('error', 'Oops an error occurred.')
      }
    },
    async updateResponsesEnabled() {
      const { responsesEnabled } = this.activeCompany.settings

      const payload = {
        companyId: this.activeCompany._id,
        fields: {
          settings: this.activeCompany.settings,
        },
      }

      const response = await this.updateCompanySettings(payload)

      if (response && response.success) {
        this.displayNotifyMessage(
          'success',
          `Auto Responses ${responsesEnabled ? 'enabled' : 'disabled'}`
        )
      } else {
        this.displayNotifyMessage('error', 'Oops an error occurred.')
      }
    },
  },
  async mounted() {
    await Promise.all([
      this.getCompany(this.$props.id),
      this.getSummaryEmails({ filters: { companyIds: [this.$props.id], summaryTypes: ['daily'] } }),
      this.getIvrs({ filters: { companyIds: [this.$props.id] } }),
      this.getIvrEnums(),
      this.getCompanyLocations(this.$route.params.id),
      this.getCompanyBusinessCredits(this.$route.params.id),
    ])
    this.getCompanyTransactions(this.$props.id)
    this.getPartnerList()
    await this.fetchOrganizations()
    await this.fetchGroups({ companyId: this.$route.params.id })
    this.giftCardSettings = this.activeCompany?.settings?.moneris || {
      programId: null,
      terminalId: null,
    }
  },
}
</script>

<style lang="scss">
.companyDropdown {
  .dropdown-toggle:after {
    content: none;
  }

  .dropdown-menu {
    margin-right: 100px;
  }
}
</style>
