<template>
  <b-modal
    id="UploadLocationsModal"
    hide-footer
    hide-header
    centered
    scrollable
    body-class="p-0"
    size="larger"
    @hidden="clearState"
  >
    <div class="px-4 pt-4 modal-dialog-scrollable">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <div>
          <h2>
            {{ modalTitle }}
            <br />
            <small class="text-muted" v-if="step === 'preview' && locations.length > 1"
              >Count: {{ locations.length }} locations</small
            >
          </h2>
        </div>
        <pre class="text-danger" v-if="!isValidLocation">Invalid fields are marked in red</pre>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>
      <!-- body-->
      <div class="d-flex justify-content-between flex-column">
        <div class="row scrollable-view justify-content-center">
          <div class="py-6" v-if="step === 'init'">
            <CSVFileUploader
              @uploaded="handleUploaded"
              buttonClass="px-4"
              button-text="Select File"
            />
          </div>
          <div class="pt-3" v-if="step === 'preview'">
            <LocationUploadPreview :headers="headers" :locations="locations" />
          </div>
          <div class="pt-3" v-if="step === 'errors'">
            <LocationUploadErrors :locationErrors="errors" />
          </div>
        </div>
        <div class="row py-4 justify-content-between">
          <div class="d-flex">
            <button v-if="step === 'preview'" class="btn btn-default" @click.prevent="clearState">
              Cancel
            </button>
          </div>
          <div>
            <button
              v-if="step === 'preview'"
              :disabled="isDisabled"
              class="btn btn-primary"
              @click.prevent="createLocations"
            >
              {{ isLoading ? 'Creating...' : 'Create' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { sortBy } from 'lodash-es'
import CSVFileUploader from '../Modules/files/CSVFileUploader.vue'
import LocationUploadPreview from '../Modules/Locations/LocationUploadPreview.vue'
import LocationUploadErrors from '../Modules/Locations/LocationUploadErrors.vue'
import { createNamespacedHelpers } from 'vuex'
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'UploadLocationsModal',
  components: {
    CSVFileUploader,
    LocationUploadErrors,
    LocationUploadPreview,
  },
  props: {
    companyId: {
      type: String,
    },
  },

  data() {
    return {
      step: 'init',
      headers: [],
      errors: [],
      locations: [],
      isLoading: false,
      modalTitle: 'Upload Locations',
      isValidLocation: true,
    }
  },
  computed: {
    isDisabled() {
      return !this.locations.length || !this.isValidLocation
    },
  },
  methods: {
    ...CompanyModule.mapActions(['uploadLocations']),

    close() {
      this.clearState()
      this.$root.$emit('bv::hide::modal', 'UploadLocationsModal')
    },
    clearState() {
      this.step = 'init'
      this.headers = []
      this.errors = []
      this.locations = []
      this.isLoading = false
      this.isValidLocation = true
      this.modalTitle = 'Upload Locations'
    },
    handleUploaded({ headers, data }) {
      if (data && data.length) {
        this.headers = headers
        this.locations = this.validateLocations(data)
        this.step = 'preview'
        this.modalTitle = 'Preview'
      }
    },
    formatPhone(phone) {
      if (!phone) return
      phone = phone.replace(/[^\w]/g, '')
      if (phone.startsWith('1') && phone.length > 10) {
        phone = phone.substring(1)
      }
      return phone
    },
    isValidPhone(phone) {
      if (!phone) return false
      const regex = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)
      return regex.test(phone)
    },
    validateLocations(locations) {
      if (!locations || !locations.length) return
      locations = locations.map((item) => {
        const location = { ...item, Phone: this.formatPhone(item['Phone Number']) }
        const isValidPhone = this.isValidPhone(location.Phone)
        if (!isValidPhone) {
          location.isValidPhone = false
          this.isValidLocation = false
        } else {
          location.isValidPhone = true
        }
        return location
      })
      return sortBy(locations, (location) => location.isValidPhone)
    },
    async createLocations() {
      this.isLoading = true
      if (!this.locations.length) {
        this.isLoading = false
        return
      }
      const payload = {
        companyId: this.companyId,
        locations: this.locations.map((location) => {
          return {
            name: location['Location Name'],
            phone: location.Phone,
            address: location.Address,
            placeId: location['Google Place ID'],
            company: this.companyId,
            friendlyName: location['Friendly Name'],
          }
        }),
      }

      try {
        const {
          data: { errors, locations: createdLocationIds },
        } = (await this.uploadLocations(payload))?.data || {}

        const total = this.locations?.length

        if (errors?.length) {
          this.errors = [...errors]
          const totalCreated = total - errors.length
          this.$notify({
            type: 'warn',
            text: `${totalCreated} out of ${total} locations created. Please check the table for the reason`,
            duration: 5000,
          })
          this.step = 'errors'
          this.modalTitle = 'Location Creation Errors'
        } else {
          this.$notify({
            type: 'success',
            text: `${total} locations created successfully`,
            duration: 4000,
          })
          this.close()
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Error uploading locations. Please try again.',
        })
      }
      this.isLoading = false
    },
  },
}
</script>

<style lang="scss">
.scrollable-view {
  overflow-y: auto;
  max-height: 750px;
}
.modal .modal-larger {
  width: 950px !important;
  max-width: 950px !important;
}
</style>
