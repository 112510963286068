<script>
import { v4 as uuidv4 } from 'uuid'
import { createNamespacedHelpers } from 'vuex'
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'GroupBase',
  computed: {
    ...GroupModule.mapGetters(['selectTree', 'selectGroupType']),
    
    tree(){
      return this.selectTree
    },
    groupType(){
      return this.selectGroupType
    },
  },
  methods: {
    ...GroupModule.mapActions([
      'updateTree', 
      'setPlaceholderIds',
      'removeAssignedLocation',
      'removePlaceholdersById',
    ]),

    hasChildren(obj){
      return (
        typeof obj === 'object' && 
        typeof obj.children !== 'undefined' && 
        obj.children.length > 0
      )
    },
    findParentPath(path){
      if(!path){
        return null
      }

      const index = path.lastIndexOf('[')
      const parentPath = path.substring(0, index)
      return parentPath
    },

    findGroupPathById(tree, id){
      if(!tree || !id){
        return null
      }

      let result

      function iterator(obj, path){
        return Object.keys(obj).some(function (key) {
          result = path.concat(Array.isArray(obj) ? +key : key)
          return obj[key] === id || (obj[key] && 
          typeof obj[key] === 'object' && iterator(obj[key], result))
        })
      }

      return this.formatGroupPath((iterator(tree, []) && result) || undefined)
    },

    insertGroupNode({ tree, path, group }){
      if (!tree || !group || !path){
        return null
      }

      // get the current group value in the tree
      const value = this.getGroupValueByPath({ tree, path })
      const childrenLength = Array.isArray(value) ? value.length : null
      const newItemPath = childrenLength > 0 ? `${path}[${childrenLength}]` : `${path}[${0}]`
      // using lodash set, set the new node
      return _.set({ ...tree }, newItemPath, group)
    },

    handleDragAndDrop({ to, item, tree }){
      // find the item path from the tree
      const path = this.findGroupPathById(tree, item)
      const toPath = this.findGroupPathById(tree, to)
      // find the item value from from the tree
      let itemValue = this.getGroupValueByPath({ tree, path })
      let group = this.getGroupValueByPath({ tree, path: toPath })
      // delete the item from the current path in the tree
      let result = this.findOneAndDelete({ tree, groupId: item }), newTree = ''
      // move the item to its new location
      if(result){
        group = { ...group, children: group.children && 
        group.children.length ? [...group.children, itemValue] : {...group, children: [itemValue]}}
        newTree =  _.set({ ...tree }, toPath, group)
      }
      return newTree
    },

    findOneAndUpdate({ groupId, tree, fields }){
      // find the path of the item in the tree
      const path = this.findGroupPathById(tree,  groupId)
      const currentValue = this.getGroupValueByPath({ tree, path })
      const group = { ...currentValue, ...fields }

      // update the tree with the new value
      const newTree = _.set({ ...tree }, path, group)
      return newTree
    },

    findOneAndDelete({ tree, groupId }){
      if (!groupId || !tree){
        return null
      }

      // find the item path and its parent path
      const path = this.findGroupPathById(tree, groupId)
      const parentPath = this.findParentPath(path)
      // get the current group value
      let childrensList = this.getGroupValueByPath({ tree, path: parentPath })
      const itemBeingDeleted = childrensList.find(group => group.id === groupId)
      // filter out the object thats being removed
      childrensList = Array.isArray(childrensList) ? 
      childrensList.filter((child) => child.id !== groupId) : childrensList
      // set the value back on that path in the tree
      const result = _.set({ ...tree }, parentPath, childrensList)
      
      if(result){
        // release members and placeholder from group, if any
        this.releaseLocationMembersAndPlaceholders(itemBeingDeleted)
      }
      return result
    },

    formatGroupPath(path) {
      if (!path) {
        return null
      }

      let pathString = ''
      if (Array.isArray(path)) {
        pathString = path.join('')
      } else {
        pathString = path
      }

      // format number inside brackets
      const pathParts = [...pathString]
      const toBeRemoved = []
      let result = pathParts
        .map((char, index) => {
          const nextChar = pathParts[index + 1]
          if (!isNaN(char) && !isNaN(nextChar)) {
            toBeRemoved.push(`[${nextChar}].`)
            return `[${char}${nextChar}].`
          } else if (!isNaN(char)) {
            return `[${char}].`
          }
          return char
        })
        .join('')
        .replace('id', '')

      if (toBeRemoved.length) {
        toBeRemoved.forEach((item) => {
          result = result.replace(item, '')
        })
      }
      // remove the last dot from the path
      if (result[result.length - 1] === '.') {
        result = result.slice(0, -1)
      }
      return result
    },

    getGroupValueByPath({ tree, path, def = null }){
      if (!tree || !path) return null
      // using lodash _.get methods
      return _.get(tree, path, def)
    },

    generatePlaceholderId(){
      const id = uuidv4()
      this.setPlaceholderIds(id)
      return id 
    },

    assignLocation({ tree, path, locationId }){
      if(!tree || !path || !locationId){
        return null
      }

      // find the current item value
      let itemValue = this.getGroupValueByPath({ tree, path })
      // add the location to members array
      if(itemValue.members && itemValue.members.length){
        itemValue = {
          ...itemValue,
          members: [...itemValue.members, locationId ]
        }
      }
      else {
        itemValue = {
          ...itemValue,
          members: [locationId]
        }
      }
      // set the value back into the tree
      return _.set({ ...tree }, path, itemValue)
    },

    assignLocationToSingularGroup({ tree, locationId }){
      if(!tree || !locationId){
        return null
      }

      if(tree.members && tree.members.length){
        tree.members.push(locationId)
      }
      else {
        tree = {
          ...tree,
          members: [locationId]
        }
      }

      return tree
    },

    removeLocationFromSingularGroup({ tree, locationId }){
      if(!tree || !locationId){
        return null
      }

      if(tree.members && tree.members.length){
        tree = {
          ...tree,
          members: tree.members.filter(id => id !== locationId)
        }
      }

      return tree
    },

    getParentPathForMembers(id){
      const itemPath = this.findGroupPathById(this.tree, id)
      const index = itemPath.lastIndexOf('.children')
      const parentPath = itemPath.substring(0, index)
      return parentPath
    },

    removeLocation({ tree, path, locationId }){
      if(!tree || !path || !locationId){
        return null
      }

      // find the current item value
      let itemValue = this.getGroupValueByPath({ tree, path })
      // add the location to members array
      const { members } = itemValue
      if(members && members.length){
        itemValue = {
          ...itemValue,
          members: members.filter(member => member !== locationId)
        }
      }
      // set the value back into the tree
      return _.set({ ...tree }, path, itemValue)
    },

    findGroupPath({ tree, key = 'id', value }){
      if (!tree || !key || !value){
        return null
      }
      const path = []
      // find group path given any key
      const keyExists = (obj) => {
        if (!obj || (typeof obj !== 'object' && !Array.isArray(obj))) {
          return false
        } else if (obj.hasOwnProperty(key) && obj[key] === value) {
          return true
        } else if (Array.isArray(obj)) {
          let parentKey = path.length ? path.pop() : ''

          for (let i = 0; i < obj.length; i++) {
            path.push(`${parentKey}[${i}]`)
            const result = keyExists(obj[i], key)
            if (result) {
              return result
            }
            path.pop()
          }
        } else {
          for (const k in obj) {
            path.push(k)
            const result = keyExists(obj[k], key)
            if (result) {
              return result
            }
            path.pop()
          }
        }
        return false
      }
      keyExists(tree)
      return path.join('.')
    },

    removePlaceholderId(tree, placeHolderIds){
      if(placeHolderIds && placeHolderIds.length){
        placeHolderIds.forEach(groupId => {
          tree = this.findOneAndDelete({ tree, groupId })
        })
      }
      return tree
    },

    removeEmptyProperties(object) {
      Object.entries(object).forEach(([k, v]) => {
        if (v && typeof v === 'object') {
          this.removeEmptyProperties(v);
        }
        
        if (v && typeof v === 'object' && 
        !Object.keys(v).length || v === null || v === undefined) {
            if (Array.isArray(object)) {
              object.splice(k, 1);
            } 
            else {
                delete object[k];
            }
          }
        }
      )
      return object;
    },

    releaseLocationMembersAndPlaceholders(group){
      const locationIds = []
      const placeHolderIds = []

      // find members recursively
      const findMembersAndPlaceholders = (obj) => {
        if (obj.members && obj.members.length){
          obj.members.forEach(member => locationIds.push(member))
        }

        // also get the placeholder ids, if any
        if(obj.type && obj.type === 'placeholder'){
          placeHolderIds.push(obj.id)
        }

        if(!this.hasChildren(obj)){
          return
        }

        obj.children.forEach(
          child => findMembersAndPlaceholders(child)
        )
      }
      // find the members and placeholders
      findMembersAndPlaceholders(group)

      // release the location members
      if(locationIds.length){
        locationIds.forEach(
          locationId => this.removeAssignedLocation(locationId)
        )
      }
      // also, release the placeholder
      if(placeHolderIds.length){
        placeHolderIds.forEach(
          placeHolderId => this.removePlaceholdersById(placeHolderId)
        )
      }
    }
  }
}
</script>