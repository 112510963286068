<template>
  <b-modal 
    id="EnableReviewSubscriptionModal" 
    hide-footer 
    hide-header 
    centered 
    body-class="p-0" 
    size="lg"
    @shown="setState()"
    @hidden="clearState()"
  >
    <div class="px-4 pt-4" v-if="visible">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <div class="col-auto nosidepadding">
        <b-img
            alt="Source Image"
            style="width:25px; border-radius: 5px" 
            left :src="getNetworkLogo()">
        </b-img>
        </div>
        <div class="col nosidepadding">
            <h1>{{ `Enable ${reviewSubscription.network}` }}</h1>
        </div>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- body -->
      <div class="card" v-if="!showSearch">
          <div class="card-header">
            <div class="row">
                <div class="col-auto nosidepadding">
                    <h3 class="card-title text-uppercase text-muted mb-4 mt-3">Matched business</h3>
                </div>
            </div>
            <div class="row">
                <div class="col-auto">
                    <b-img
                        style="width: 40px; border-radius: 5px"
                        left
                        :src="reviewSubscription.profileImage"
                        >
                    </b-img>
                </div>
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center">
            <div class="col-12 ml-3 mb-4 nosidepadding">
                <!-- Title -->
                <h6 class="card-title text-uppercase text-muted mb-2">Name</h6>

                <!-- Heading -->
                <span class="h4 mb-0">{{ reviewSubscription.name }}</span>
              </div>
              <div class="col-12 ml-3 mb-4 nosidepadding">
                <!-- Title -->
                <h6 class="card-title text-uppercase text-muted mb-2">Address</h6>

                <!-- Heading -->
                <span class="h4 mb-0">{{ reviewSubscription.formattedAddress }}</span>
              </div>
              <div class="col-12 ml-3 mb-4 nosidepadding">
                <!-- Title -->
                <h6 class="card-title text-uppercase text-muted mb-2">Business page</h6>

                <!-- Heading -->
                <span class="h4 mb-0"><a :href="reviewSubscription.url" target="_blank" rel="noopener">{{ reviewSubscription.url }}</a></span>
              </div>
            </div>
          </div>
      </div>
      <div class="card" v-else>
          <div class="card-header">
            <div class="col-10">
                <div class="row">
                    <div class="col-auto nosidepadding">
                        <h3 class="card-title text-uppercase text-muted mb-4 mt-3">{{idSearch ? 'Search By Provider Id' : 'Search By Address'}}</h3>
                    </div>
                    <div class="col-auto nosidepadding">
                        <b-button size="sm" class="mt-2"><i class="fe fe-arrow-up-circle" @click="idSearch = !idSearch"/></b-button>
                    </div>
                </div>
                <div class="row" v-if="idSearch === false">
                    <div class="col-12 nosidepadding">
                        <h6 class="card-title text-uppercase text-muted">Name</h6>
                        <input type="text" id="name" v-model="searchName" class="form-control mb-5" />
                    </div>
                    <div class="col-12 nosidepadding">
                        <h6 class="card-title text-uppercase text-muted">Address</h6>
                        <input type="text" id="address" v-model="searchAddress" class="form-control mb-5" />
                    </div>
                </div>
                <div class="row" v-else>
                    <div class="col-12 nosidepadding">
                        <h6 class="card-title text-uppercase text-muted">Provider Id</h6>
                        <input type="text" id="slug" v-model="searchSlug" class="form-control mb-5" />
                    </div>
                </div>
            </div>
          </div>
          <div class="card-body" v-if="selectSearchResults.length">
                <div class="row">
                    <div class="col-12 mb-5">
                        <!-- feature flag list -->
                        <div class="list-group" role="tablist">
                            <div
                                class="list-group-item"
                                v-for="searchResult of selectSearchResults"
                                :key="searchResult.slug"
                            >
                                <div class="row">
                                    <div class="col-1 align-self-center">
                                        <b-img
                                            style="width:25px; border-radius: 5px" 
                                            left 
                                            :src="searchResult.profileImage">
                                        </b-img>
                                    </div>
                                    <div class="col-10">
                                        <div class="row">
                                            <span :class="`ml-3 text-secondary`">
                                                <span class="text-primary">Name:</span>{{ ' ' + searchResult.name}}
                                            </span>
                                        </div>
                                        <div class="row">
                                            <span :class="`ml-3 text-secondary`">
                                                <span class="text-primary">Id:</span>{{ ' ' + searchResult.slug}}
                                            </span>
                                        </div>
                                        <div class="row">
                                            <span :class="`ml-3 text-secondary`">
                                                <span class="text-primary">Address:</span>{{ searchResult.formattedAddress}}
                                            </span>
                                        </div>
                                        <div class="row">
                                            <a :class="`ml-3 text-primary`" :href="searchResult.url">
                                                {{ searchResult.url }}
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-1 text-right align-self-center">
                                        <button :class="`btn btn-sm`" @click="selectBusiness(searchResult)">
                                            <span :class="`fa fa-check-circle`" />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
          </div>
      </div>

      <!-- footer -->
      <div class="border-top py-4 text-muted text-small">
        <div class="row">
              <div class="col nosidepadding" v-if="showSearch && validInputs">
                <button
                    class="btn btn-primary mr-3"
                    style="width: 120px"
                    @click="showSearch = false"
                >
                    <span> Back </span>
                </button>
              </div>
              <div class="col nosidepadding" v-if="!showSearch">
                <button
                    class="btn btn-warning mr-3"
                    style="width: 120px"
                    @click="notAMatch()"
                >
                    <div class="d-flex justify-content-center" v-if="loading">
                        <div class="loader loader-light"></div>
                    </div>
                    <span v-else> Not a Match </span>
                </button>
              </div>
               <div class="col nosidepadding align-self-center" v-if="errorMessage">
                <span class="text-danger">{{ errorMessage }}</span>
              </div>
              <div class="col nosidepadding text-right" v-if="!showSearch">
                <button
                    class="btn btn-primary"
                    style="width: 120px"
                    @click="wrapper(enable)()"
                    :disabled="!validInputs"
                >
                    <div class="d-flex justify-content-center" v-if="loading">
                        <div class="loader loader-light"></div>
                    </div>
                    <span v-else> Enable </span>
                </button>
              </div>
              <div class="col nosidepadding text-right" v-else>
                <button
                    class="btn btn-primary"
                    style="width: 120px"
                    @click="wrapper(executeSearch)()"
                    :disabled="!validSearchInputs || loading"
                >
                    <div class="d-flex justify-content-center" v-if="loading">
                        <div class="loader loader-light"></div>
                    </div>
                    <span v-else> Search </span>
                </button>
              </div>
              <!-- <div class="col text-right nosidepadding">
                <button class="btn btn-white btn">
                  <span class="fe fe-arrow-right"></span>
                </button>
              </div> -->
            </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const ReviewSubscriptionModule = createNamespacedHelpers('reviewSubscription')
const initialState = {
    loading: false,
    visible: false,
    showSearch: false,
    idSearch: false,
    errorMessage: null,
    searchName: null,
    searchAddress: null,
    searchSlug: null
}

export default {
  name: 'EnableReviewSubscriptionModal',
  props: ['network', 'locationId'],
  data: () => ({...initialState }),
  components: {
  },
  computed: {
    ...ReviewSubscriptionModule.mapGetters(['selectReviewSubscriptionByNetwork', 'selectSearchResults']),
    reviewSubscription() {
        return this.selectReviewSubscriptionByNetwork(this.network) || {}
    },
    validInputs() {
        return this.reviewSubscription.slug
    },
    validSearchInputs() {
        return (this.idSearch && this.searchSlug) || (!this.idSearch && this.searchName && this.searchAddress)
    }
  },
  mounted() {
    
  },
  methods: {
    setState() {
        this.visible = true

        if (!this.reviewSubscription.slug) {
            this.showSearch = true
        }
    },
    clearState() {
        this.clearSearchResults()
        for (var key in initialState) {
            this[key] = initialState[key]
        }
    },
    ...ReviewSubscriptionModule.mapActions(['searchReviewSubscriptions', 'setReviewSubscription', 'clearSearchResults', 'createReviewSubscription', 'enableReviewSubscription']),
    getNetworkLogo() {
        var images = require.context('../../../../public/img/platforms', false, /\.svg$/)
    	return images('./' + this.network + ".svg")
    },
    notAMatch() {
        this.showSearch = true
    },
    wrapper(f) {
        const self = this
        return async function() {
            try {
                self.loading = true
                self.errorMessage = null
                await f.apply(this, arguments)
            } catch (error) {
                console.log(error)
                self.errorMessage = error?.body?.message || error.message || 'Oops something went wrong.'
            } finally {
                self.loading = false
            }
        }
    },
    async executeSearch() {
        const result = await this.searchReviewSubscriptions({
            network: this.network,
            ...(this.idSearch ? { 
                slug: this.searchSlug 
            } : { 
                name: this.searchName,
                address: this.searchAddress
            })
        })

    },
    async enable() {
        let result = null
        if (this.reviewSubscription._id) {
            result = await this.enableReviewSubscription({ reviewSubscriptionId: this.reviewSubscription._id, slug: this.reviewSubscription.slug })
        } else {
            result = await this.createReviewSubscription({ locationId: this.locationId, network: this.network, slug: this.reviewSubscription.slug })
        }

        if (result.subscriptionError) {
            this.errorMessage = result.subscriptionError
        } else {
            this.close()
        }
    },
    close() {
        this.$bvModal.hide('EnableReviewSubscriptionModal')
    },
    selectBusiness(searchResult) {
        this.setReviewSubscription(searchResult)
        this.showSearch = false
    }
  },
}
</script>
