<template>
  <div class="ivr-landline-steps">
    <div class="row no-gutters mt-1 mr-4">
      <div class="col col-auto align-self-center mr-4 ml-4">
        <div class="row">
          <label style="color: #95aac9" class="ml-3 mr-4">Landline</label>
        </div>
        <div class="row">
          <label style="color: #95aac9" class="ml-3 mr-4">Steps</label>
        </div>
      </div>
      <div class="col col-10">
        <div class="row no-gutters" v-if="hasFinalStep && finalStepIndex === 0 && !hasLimitingStep">
          <div class="col d-flex justify-content-center">
            <button class="btn btn-sm btn-outline-primary mb-3" @click="addLandlineStep(0)">
              <span>Add Landline Step</span>
            </button>
          </div>
        </div>
        <draggable v-model="steps" id="step-draggable" :move="validateMove">
          <transition-group class="row" name="steps">
            <div v-for="(step, index) of steps" :key="step.uuid" class="col col-12">
              <IvrStep
                :step="step"
                :errors="errors"
                :steps="landlineSteps"
                :index="index"
                class="mb-3 draggable"
                :verbs="landlineVerbs"
                :uploadPath="uploadPath"
                @deleteStep="$emit('deleteLandlineStep', step.uuid)"
                @stepUpdate="
                  $emit('landlineStepUpdate', { stepUuid: step.uuid, modifications: $event })
                "
              ></IvrStep>
              <div
                class="row no-gutters"
                v-if="hasFinalStep && finalStepIndex - 1 === index && !hasLimitingStep"
              >
                <div class="col d-flex justify-content-center">
                  <button
                    class="btn btn-sm btn-outline-primary mb-3"
                    @click="addLandlineStep(index + 1)"
                  >
                    <span>Add Landline Step</span>
                  </button>
                </div>
              </div>
            </div>
          </transition-group>
        </draggable>
      </div>
    </div>
    <div class="row" v-if="!hasFinalStep">
      <div class="col col-12 d-flex justify-content-center">
        <button class="btn btn-sm btn-outline-primary mb-3" @click="addLandlineStep">
          <span>Add Landline Step</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import draggable from 'vuedraggable'

export default {
  name: 'IvrLandlineSteps',
  props: ['uploadPath', 'landlineSteps', 'errors'],
  components: { IvrStep: () => import('./IvrStep.vue'), draggable },
  data: () => ({
    steps: [],
  }),
  computed: {
    landlineVerbs() {
      if (this.steps.length === 1) return ['Forward', 'PlayAudio', 'SpeakSentence']
      return ['PlayAudio', 'SpeakSentence', 'Transfer']
    },
    hasFinalStep() {
      return this.steps.find((s) => ['Forward', 'Gather', 'Transfer'].includes(s.verb))
        ? true
        : false
    },
    finalStepIndex() {
      return Math.max(
        this.steps.map((s) => s.verb).indexOf('Forward'),
        this.steps.map((s) => s.verb).indexOf('Gather'),
        this.steps.map((s) => s.verb).indexOf('Transfer')
      )
    },
    hasLimitingStep() {
      return this.steps.find((s) => ['Forward'].includes(s.verb)) ? true : false
    },
  },
  methods: {
    addLandlineStep(i) {
      this.$emit('addLandlineStep', i)
    },
    validateMove(evt) {
      const { draggedContext } = evt
      const { futureIndex, element } = draggedContext
      const { uuid, verb } = element

      const newSteps = this.steps.filter((s) => s.uuid !== uuid)
      newSteps.splice(futureIndex, 0, element)
      const gatherIndex = newSteps.map((s) => s.verb)?.indexOf('Gather')
      const forwardIndex = newSteps.map((s) => s.verb)?.indexOf('Forward')
      const transferIndex = newSteps.map((s) => s.verb)?.indexOf('Transfer')
      if (
        gatherIndex === 0 ||
        (gatherIndex !== -1 && gatherIndex !== newSteps.length - 1) ||
        (forwardIndex !== -1 && forwardIndex !== newSteps.length - 1) ||
        (transferIndex !== -1 && transferIndex !== newSteps.length - 1)
      ) {
        return false
      }

      return true
    },
  },
  watch: {
    landlineSteps: {
      handler: function (val) {
        this.steps = val || []
      },
      deep: true,
    },
  },
  mounted() {
    this.steps = this.landlineSteps || []

    if (!this.steps.length) {
      this.addLandlineStep()
    }
  },
}
</script>
