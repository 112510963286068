<template>
  <div class="ivr-builder">
    <p>Steps</p>
    <div class="row no-gutters" v-if="hasFinalStep && finalStepIndex === 0 && !hasLimitingStep">
      <div class="col d-flex justify-content-center">
        <button class="btn btn-sm btn-outline-primary mb-3" @click="addStep(0)">
          <span>Add Step</span>
        </button>
      </div>
    </div>
    <draggable v-model="steps" id="step-draggable" :move="validateMove">
      <transition-group class="row" name="steps">
        <div v-for="(step, index) of steps" :key="step.uuid" class="col col-12">
          <IvrStep
            :step="step"
            :errors="errors"
            :steps="steps"
            :index="index"
            class="mb-3 draggable"
            :verbs="verbs"
            :usedVerbs="usedVerbs"
            :uploadPath="uploadPath"
            :invalidOption="invalidOption"
            :landlineSteps="landlineSteps"
            @deleteStep="deleteStep(step.uuid)"
            @deleteLandlineStep="deleteLandlineStep"
            @landlineStepUpdate="landlineStepUpdate"
            @addLandlineStep="addLandlineStep"
            @stepUpdate="stepUpdate(step.uuid, $event)"
            @invalidOptionUpdate="invalidOptionUpdate"
          ></IvrStep>
          <div
            class="row no-gutters"
            v-if="hasFinalStep && finalStepIndex - 1 === index && !hasLimitingStep"
          >
            <div class="col d-flex justify-content-center">
              <button class="btn btn-sm btn-outline-primary mb-3" @click="addStep(index + 1)">
                <span>Add Step</span>
              </button>
            </div>
          </div>
        </div>
      </transition-group>
    </draggable>

    <div class="row no-gutters" v-if="!hasFinalStep">
      <div class="col d-flex justify-content-center">
        <button class="btn btn-sm btn-outline-primary mb-3" @click="addStep">
          <span>Add Step</span>
        </button>
      </div>
    </div>
    <hr />
    <div class="row no-gutters d-flex justify-content-end">
      <div class="col" v-if="ivr && ivr._id">
        <button
          class="btn btn-danger mt-1 mx-2"
          @click="deleteBuild"
          style="width: 150px"
          :disabled="loading"
        >
          <div class="row justify-content-center">
            <span v-if="loading !== true">Delete</span>
            <FadeTransition>
              <div v-if="loading === true" class="loader loader-light"></div>
            </FadeTransition>
          </div>
        </button>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-warning mt-1 mx-2"
          @click="init"
          style="width: 150px"
          :disabled="loading"
        >
          Cancel
        </button>
      </div>
      <div class="col-auto">
        <button
          class="btn btn-primary mt-1 mx-2"
          @click.prevent="validateBuild"
          style="width: 150px"
          :disabled="loading"
        >
          <div class="row justify-content-center">
            <span v-if="loading !== true">Save Call to Text</span>
            <FadeTransition>
              <div v-if="loading === true" class="loader loader-light"></div>
            </FadeTransition>
          </div>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import FadeTransition from '@/components/Transitions/FadeTransition'
import draggable from 'vuedraggable'
import IvrStep from './IvrStep.vue'
import { v4 as uuidv4 } from 'uuid'

const IvrModule = createNamespacedHelpers('ivr')

export default {
  name: 'IvrBuilder',
  props: ['companyId', 'ivr', 'locationId'],
  components: {
    draggable,
    IvrStep,
    FadeTransition,
  },
  data: () => ({
    loading: false,
    steps: [],
    invalidOption: {},
    landlineSteps: [],
    errors: [],
  }),
  computed: {
    ...IvrModule.mapGetters(['selectIvrDynamicFields']),
    ivrId() {
      return this.ivr ? this.ivr._id : null
    },
    usedVerbs() {
      return this.steps.map((s) => s.verb)
    },
    uploadPath() {
      const locationPath = this.locationId ? `/locations/${this.locationId}/` : '/'
      return `companies/${this.companyId}${locationPath}ivr`
    },
    verbs() {
      if (this.steps.length === 1)
        return ['Forward', 'Gather', 'PlayAudio', 'SpeakSentence', 'Transfer']
      return ['Gather', 'PlayAudio', 'SpeakSentence', 'Transfer']
    },
    hasFinalStep() {
      return this.steps.find((s) => ['Forward', 'Gather', 'Transfer'].includes(s.verb))
        ? true
        : false
    },
    hasLimitingStep() {
      return this.steps.find((s) => ['Forward'].includes(s.verb)) ? true : false
    },
    finalStepIndex() {
      return Math.max(
        this.steps.map((s) => s.verb).indexOf('Forward'),
        this.steps.map((s) => s.verb).indexOf('Gather'),
        this.steps.map((s) => s.verb).indexOf('Transfer')
      )
    },
  },
  methods: {
    ...IvrModule.mapActions(['createIvr', 'deleteIvr', 'updateIvr']),
    init() {
      if (this.ivr && this.ivr.tree) {
        this.invalidOption = { ...this.ivr.tree.invalidOption }
        this.landlineSteps = this.ivr.tree.landline?.response ?? []
        this.steps = this.ivr.tree.steps.map((s) => ({ uuid: uuidv4(), ...s }))
      } else {
        this.steps = []
        this.invalidOption = {}
        this.landlineSteps = []
        this.addStep()
      }
    },
    addStep(i) {
      if (!isNaN(parseInt(i))) {
        const newSteps = [...this.steps]
        newSteps.splice(i, 0, { uuid: uuidv4() })
        this.steps = newSteps
      } else {
        this.steps = [...this.steps, { uuid: uuidv4() }]
      }
    },
    deleteStep(stepUuid) {
      this.steps = this.steps.filter((s) => s.uuid !== stepUuid)
    },
    stepUpdate(stepUuid, modifications) {
      const newSteps = [...this.steps]
      const index = newSteps.findIndex((x) => x.uuid === stepUuid)
      newSteps.splice(index, 1, {
        ...modifications,
        uuid: stepUuid,
      })
      this.steps = newSteps
      if (!this.steps.find((s) => s.verb === 'Gather')) {
        this.invalidOption = {}
        this.landlineSteps = []
      }
    },
    invalidOptionUpdate(modifications) {
      this.invalidOption = modifications
    },
    addLandlineStep(i) {
      if (!isNaN(parseInt(i))) {
        const newSteps = [...this.landlineSteps]
        newSteps.splice(i, 0, { uuid: uuidv4() })
        this.landlineSteps = newSteps
      } else {
        this.landlineSteps = [...this.landlineSteps, { uuid: uuidv4() }]
      }
    },
    deleteLandlineStep(stepUuid) {
      this.landlineSteps = this.landlineSteps.filter((s) => s.uuid !== stepUuid)
    },
    landlineStepUpdate({ stepUuid, modifications }) {
      const newSteps = [...this.landlineSteps]
      const index = newSteps.findIndex((x) => x.uuid === stepUuid)
      newSteps.splice(index, 1, {
        ...modifications,
        uuid: stepUuid,
      })
      this.landlineSteps = newSteps
    },
    validateSteps(steps) {
      steps.forEach((s) => {
        switch (s.verb) {
          case 'Gather':
            if (!s.options?.length) {
              this.errors.push(s.uuid)
            } else {
              if (!this.invalidOption?.verb) {
                this.errors.push(s.uuid)
                this.errors.push('invalid-option')
              } else {
                switch (this.invalidOption.verb) {
                  case 'PlayAudio':
                    if (!this.invalidOption.url) {
                      this.errors.push('invalid-option')
                      this.errors.push(s.uuid)
                    }
                    break

                  case 'SpeakSentence':
                    if (
                      !this.invalidOption.sentence ||
                      !(
                        (this.invalidOption.voice &&
                          !this.invalidOption.gender &&
                          !this.invalidOption.locale) ||
                        (!this.invalidOption.voice &&
                          this.invalidOption.gender &&
                          this.invalidOption.locale)
                      )
                    ) {
                      this.errors.push('invalid-option')
                      this.errors.push(s.uuid)
                    }
                    break
                    break
                }
              }

              if (!this.landlineSteps?.length) {
                this.errors.push(s.uuid)
              } else {
                this.validateSteps(this.landlineSteps)
              }
              s.options.forEach((o) => {
                if (!o.action || !o.action.type || !o.digit || !o.name || !o.action.audioProducer) {
                  this.errors.push(o.uuid)
                } else {
                  switch (o.action.audioProducer.verb) {
                    case 'PlayAudio':
                      if (!o.action.audioProducer.url) {
                        this.errors.push(o.uuid)
                      }
                      break

                    case 'SpeakSentence':
                      if (
                        !o.action.audioProducer.sentence ||
                        !(
                          (o.action.audioProducer.voice &&
                            !o.action.audioProducer.gender &&
                            !o.action.audioProducer.locale) ||
                          (!o.action.audioProducer.voice &&
                            o.action.audioProducer.gender &&
                            o.action.audioProducer.locale)
                        )
                      ) {
                        this.errors.push(o.uuid)
                      }
                      break
                  }
                  switch (o.action.type) {
                    case 'CUSTOM_SMS':
                      if (!o.action.message) {
                        this.errors.push(o.uuid)
                      }
                      break

                    case 'TRANSFER_CALL':
                      if (!o.action.transferTo) {
                        this.errors.push(o.uuid)
                      }
                      break
                  }
                }
              })
            }

            break

          case 'Forward':
            if (
              !s.forwardTo ||
              (!this.selectIvrDynamicFields.includes(s.forwardTo) && s.forwardTo.length !== 12)
            ) {
              this.errors.push(s.uuid)
            }
            break

          case 'Transfer':
            if (
              !s.transferTo ||
              (!this.selectIvrDynamicFields.includes(s.transferTo) && s.transferTo.length !== 12)
            ) {
              this.errors.push(s.uuid)
            }
            break

          case 'PlayAudio':
            if (!s.url) {
              this.errors.push(s.uuid)
            }
            break

          case 'SpeakSentence':
            if (
              !s.sentence ||
              !((s.voice && !s.gender && !s.locale) || (!s.voice && s.gender && s.locale))
            ) {
              this.errors.push(s.uuid)
            }
            break
        }
      })
    },
    validateBuild() {
      this.errors = []
      this.validateSteps(this.steps)
      if (!this.errors?.length) {
        return this.saveBuild()
      }
    },
    async saveBuild() {
      this.loading = true
      try {
        if (this.ivr && this.ivr._id) {
          await this.updateIvr({
            ivrId: !this.locationId || (this.locationId && this.ivr.location) ? this.ivr._id : undefined,
            modifications: {
              steps: this.steps,
              invalidOption: this.invalidOption,
              landline: {
                response: this.landlineSteps,
              },
            },
          })
          this.$notify({
            type: 'success',
            title: 'Success',
            text: `Successfully updated the call to text!`,
          })
        } else {
          const payload = {
            companyId: this.companyId,
            steps: this.steps,
            invalidOption: this.invalidOption,
            landline: {
              response: this.landlineSteps,
            },
          }

          if (this.locationId) {
            payload.locationId = this.locationId
          }

          await this.createIvr(payload)
          this.$notify({
            type: 'success',
            title: 'Success',
            text: `Successfully created the call to text!`,
          })
        }
      } catch (e) {
        console.log(e)
        this.$notify({
          title: 'Error saving Call to Text',
          text: `${e.message}`,
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.loading = false
      }
    },
    async deleteBuild() {
      this.loading = true
      try {
        if (this.ivr && this.ivr._id) {
          await this.deleteIvr({
            ivrId: this.ivr._id,
          })
          this.$notify({
            type: 'success',
            title: 'Success',
            text: `Successfully deleted the call to text!`,
          })
        } else {
          this.$notify({
            type: 'error',
            title: 'Error deleting Call to Text',
            text: 'There is nothing to delete.',
            duration: 5000,
          })
        }
      } catch (e) {
        console.log(e)
        this.$notify({
          title: 'Error saving Call to Text',
          text: `${e.message}`,
          type: 'error',
          duration: 5000,
        })
      } finally {
        this.loading = false
      }
    },
    validateMove(evt) {
      const { draggedContext } = evt
      const { futureIndex, element } = draggedContext
      const { uuid, verb } = element

      const newSteps = this.steps.filter((s) => s.uuid !== uuid)
      newSteps.splice(futureIndex, 0, element)
      const gatherIndex = newSteps.map((s) => s.verb)?.indexOf('Gather')
      const forwardIndex = newSteps.map((s) => s.verb)?.indexOf('Forward')
      const transferIndex = newSteps.map((s) => s.verb)?.indexOf('Transfer')
      if (
        gatherIndex === 0 ||
        (gatherIndex !== -1 && gatherIndex !== newSteps.length - 1) ||
        (forwardIndex !== -1 && forwardIndex !== newSteps.length - 1) ||
        (transferIndex !== -1 && transferIndex !== newSteps.length - 1)
      ) {
        return false
      }

      return true
    },
  },
  watch: {
    ivr: {
      handler: function (val) {
        if (val && val.tree) {
          this.init()
        } else {
          this.init()
          this.$emit('deleted')
        }
      },
      deep: true,
    },
  },
  mounted() {
    this.init()
  },
}
</script>
