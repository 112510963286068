<template>
  <div ref="uploader"></div>
</template>

<script>
import Uppy from '@uppy/core'
import FileInput from '@uppy/file-input'
import PapaParse from 'papaparse'

export default {
  name: 'CSVFileUploader',
  props: {
    buttonText: {
      type: String,
      required: false,
    },
    buttonClass: {
      type: String,
      required: true,
    },
    hasHeaders: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    buttonText() {
      this.$refs.uploader.querySelector('button').innerText = this.buttonText
    },
  },
  data: () => ({
    btnText: 'Select File',
  }),
  methods: {
    uploadFile(file) {
      if(file && file.name) {
        this.$refs.uploader.querySelector('button').innerText = file.name
      }
      const button = this.$refs.uploader.querySelector('button')
      button.classList.add('is-loading')
      button.disabled = true
      try {
        this.convertFileToJson(file.data)
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Failed to upload the csv file. Please try again.',
        })
      }
      button.classList.remove('is-loading')
      button.disabled = false
    },
    convertFileToJson(blob) {
      const reader = new FileReader()
      reader.readAsBinaryString(blob)
      reader.onload = (event) => {
        const results = PapaParse.parse(reader.result.toString(), {
          header: this.hasHeaders,
          skipEmptyLines: true,
        })
        const { data, meta } = results || {}
        this.$emit('uploaded', { data, headers: meta.fields })
      }
    },
  },
  mounted() {
    const uppy = new Uppy({
      restrictions: {
        allowedFileTypes: ['text/csv'],
      },
      onBeforeFileAdded: (file) => {
        return this.uploadFile(file)
      },
    })

    uppy.use(FileInput, {
      target: this.$refs.uploader,
      locale: {
        strings: {
          chooseFiles: this.buttonText,
        },
      },
    })

    this.$refs.uploader
      .querySelector('button')
      .classList.add('btn', this.buttonClass || 'btn-white')
    this.$refs.uploader
      .querySelector('button')
      .innerText = (this.buttonText || this.btnText)
  },
}
</script>

<style scoped lang="scss">
::v-deep .btn-white {
  border-color: #d2ddec;
}
</style>
