<template>
  <v-dialog />
</template>

<script>
export default {
  name: 'DownloadApiTokenModal',
  props: ['token'],
  mounted() {
    this.$root.$on('v::show::download-api-token-modal', () => {
      this.$modal.show('dialog', {
        title: 'Download Token',
        text: 'This will not be visible again and a new token will need to be issued once this modal is closed',
        buttons: [
          {
            title: 'Download CSV',
            handler: () => {
              const button = window.document.createElement('a')
              button.download = 'credentials.json'
              button.href =
                'data:text/plain;charset=utf-8,' +
                encodeURIComponent(JSON.stringify({ token: this.token }, null, ' '))
              button.click()
              this.$emit('confirm')
              this.$modal.hide('dialog')
            },
          },
          {
            title: 'Cancel',
          },
        ],
      })
    })
  },
}
</script>

<style scoped>
</style>
