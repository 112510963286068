import Vue from 'vue'

export default {
  createAssumeUser(payload) {
    return Vue.http.post('v2/assume-users', payload)
  },

  deleteAssumeUser(userId) {
    return Vue.http.post(`v2/assume-users/${userId}`)
  },
}
