import Vue from 'vue'

const SettingsService = {
  getSettings,
}

export function getSettings() {
  return Vue.http.get('v2/settings')
}

export default SettingsService
