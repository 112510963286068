<template>
  <div class="px-4">
    <div class="row">
      <div class="input-group">
        <span class="input-group-prepend">
          <div class="input-group-text border">
            <i class="fa fa-search fa-lg" aria-hidden="true"></i>
          </div>
        </span>
        <input
          class="form-control border-left-0 border"
          type="search"
          v-model="search"
          id="location-search"
          placeholder="90210 / Brigham City, UT"
        />
      </div>
    </div>
    <div
      :class="[
        'row mt-5 border scrollEnabled bg-white',
        isLoading || !locations.length ? 'd-flex align-items-center justify-content-center' : '',
      ]"
      style="min-height: 720px; border-radius: 5px"
    >
      <div class="p-4">
        <div v-if="!isLoading">
          <div class="px-4" v-for="location in locations" :key="location._id">
            <LocationItem :location="location" @onHover="handleHover" />
          </div>
        </div>
        <div v-if="isLoading" class="d-flex flex-row align-items-center justify-content-center">
          <div class="loader loader-sm loader-primary" role="status"></div>
          <span class="text-center ml-3">Searching...</span>
        </div>
        <div v-if="!isLoading && !locations.length">
          <div>
            <span class="visually-hidden">No results</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { debounce } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'
import LocationItem from './LocationItem.vue'

const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'MapSidebar',
  props: {
    isLoadingLocations: {
      type: Boolean,
    },
  },
  components: {
    LocationItem,
  },
  data() {
    return {
      search: '',
      isLoading: false,
    }
  },
  watch: {
    search: debounce(function () {
      this.searchLocation(this.search)
    }, 500),
    isLoadingLocations: function (value) {
      this.isLoading = value
    },
  },
  computed: {
    ...LocationModule.mapGetters(['selectGeoLocations']),

    locations() {
      return this.selectGeoLocations
    },
  },
  methods: {
    ...LocationModule.mapActions(['fetchGeoLocations']),

    addMarker(locations) {
      this.$emit('onAddMarker', locations)
    },
    removeMarkers() {
      this.$emit('onRemove')
    },
    async searchLocation(search) {
      this.isLoading = true
      try {
        const locations = await this.fetchGeoLocations({
          query: search,
          maxDistance: 5000, // max distance of about 3 miles
        })
        this.removeMarkers()
        if (locations?.length) {
          this.addMarker({ locations })
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Oops, something went wrong. Please try again.',
        })
      }
      this.isLoading = false
    },
    handleHover(location) {
      this.$emit('onHover', location)
    },
  },
}
</script>

<style lang="scss" scoped>
.scrollEnabled {
  max-height: 720px !important;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 5px;
}
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  border-radius: 5px;
}
</style>
