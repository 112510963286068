// temp fix for vue-multiselect positioning until v3.0
<template>
  <vue-multiselect-original
    :ref="$attrs.id"
    v-bind="$attrs"
    v-on="listeners"
    class="position-relative"
  >
    <template #caret
      ><span v-if="!hideCaret" :class="['caret', opened && 'caret-opened']"></span
    ></template>
    <template v-for="(_, name) in $scopedSlots" :slot="name" slot-scope="slotData"
      ><slot v-if="name !== 'caret'" :name="name" v-bind="slotData"
    /></template>
  </vue-multiselect-original>
</template>

<script>
import Multiselect from 'vue-multiselect'

export default {
  name: 'MultiSelect',

  inheritAttrs: false,

  props: {
    reposition: {
      type: Boolean,
      default: false,
    },
    hideCaret: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    opened: false,
    listeners: {},
  }),

  components: {
    'vue-multiselect-original': Multiselect,
  },

  watch: {
    $listeners(newListeners) {
      this.listeners = {
        ...newListeners,
        open: (...args) => {
          if (newListeners.open) {
            newListeners.open(...args)
          }
          this.opened = true
          this.onOpen(...args)
        },
        close: (...args) => {
          if (newListeners.close) {
            newListeners.close(...args)
          }
          this.opened = false
        },
      }
    },
  },

  mounted() {
    window.addEventListener('scroll', this.onOpen)
  },

  destroyed() {
    window.removeEventListener('scroll', this.onOpen)
  },

  methods: {
    onOpen() {
      if (this.reposition) {
        if (!this.$attrs.id) {
          console.error('No multiselect id')
          return
        }

        const { top, height, width, left } = this.$el.getBoundingClientRect()
        const ref = this.$refs[this.$attrs.id]

        if (ref) {
          ref.$refs.list.style.width = `${width}px`
          ref.$refs.list.style.position = 'fixed'
          ref.$refs.list.style.bottom = 'auto'
          ref.$refs.list.style.top = `${top + height}px`
          ref.$refs.list.style.left = `${left}px`
        }
      }
    },
  },
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss">
.multiselect {
  small {
    font-size: 11px;
  }

  min-height: 40px;
  font-size: 0.8125rem;
  width: auto;
  .multiselect__tags {
    min-height: 40px;
    padding: 8px 8px 0px 9px;
  }
  .multiselect__tag {
    margin-bottom: 3px;
    background-color: var(--light);
    color: var(--dark);
  }
  .multiselect__option--highlight {
    background: var(--light);
    color: var(--dark);
    &:after {
      background: var(--primary);
    }
  }
  .multiselect__tag-icon:after {
    color: var(--muted);
  }
  .multiselect__tag-icon:focus,
  .multiselect__tag-icon:hover {
    background: none;
    &:after {
      color: var(--dark);
    }
  }
  .multiselect__spinner:after,
  .multiselect__spinner:before {
    border-color: var(--muted) transparent transparent;
  }
  .multiselect__option--selected {
    background: #fff;
    color: var(--primary);
  }
  .multiselect__option--selected.multiselect__option--highlight {
    background: #fff;
    &:after {
      background: var(--light);
    }
  }
  .multiselect__select {
    display: none;
  }
  .multiselect__input,
  .multiselect__single {
    background: transparent;
    font-size: 14px;
    padding-top: 1px;
    padding-left: 0;
    &::placeholder {
      font-size: 14px;
      color: var(--secondary);
    }
  }
  .multiselect__placeholder {
    margin-bottom: 8px;
    color: #95aac9;
  }
}

.multiselect--active {
  .multiselect__placeholder {
    display: block !important;
  }
}
</style>

<style lang="scss" scoped>
.caret {
  pointer-events: none;
  // cursor: pointer;

  &:after {
    display: block;
    content: '\e92e';
    color: #95aac9;
    font-family: Feather;
    font-size: 16px;
    padding: 8px;
    position: absolute;
    right: 0;
    transition: transform 0.2s;
  }

  &.caret-opened:after {
    transform: rotate(-180deg);
  }
}
</style>
