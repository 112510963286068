<template>
  <b-modal id="AddCompanyModal" hide-footer hide-header centered body-class="p-0" size="lg">
    <div class="px-4 pt-4">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h1>Create Company</h1>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- body -->
      <div class="card-body px-5">
        <label for="name" class="font-weight-bold"> Company Name </label>
        <input type="text" id="name" v-model="name" class="form-control mb-5" />

        <label for="friendlyName" class="font-weight-bold"> Friendly Name (optional)</label>
        <input type="text" id="friendlyName" v-model="friendlyName" class="form-control mb-5" />

        <label for="industry" class="font-weight-bold"> Industry </label>
        <MultiSelect
          :options="industries"
          v-model="industry"
          :show-labels="false"
          closeOnSelect
          track-by="id"
          class="mb-5"
        >
          <template slot="option" slot-scope="{ option }">
            <span class="custom__tag">
              <span class="text-capitalize">{{ option.name }}</span>
            </span>
          </template>
          <template slot="singleLabel" slot-scope="{ option }">
            <span v-if="option.name" class="text-capitalize">{{ option.name }}</span>
            <span v-else class="text-muted">Select an industry</span>
          </template>
        </MultiSelect>

        <label for="user" class="font-weight-bold"> User </label>
        <MultiSelect
          :options="selectUsers"
          v-model="user"
          :show-labels="false"
          closeOnSelect
          :loading="isLoading"
          :internal-search="false"
          @search-change="updateList"
          track-by="_id"
        >
          <template slot="option" slot-scope="{ option }">
            <span class="custom__tag">
              <span>{{ option.firstName }} {{ option.lastName }} - {{ option.company }}</span>
            </span>
          </template>
          <template slot="singleLabel" slot-scope="{ option }">
            <span v-if="option.firstName">
              {{ option.firstName }} {{ option.lastName }} - {{ option.company }}
            </span>
            <span v-else class="text-muted">Select a user</span>
          </template>
        </MultiSelect>
      </div>

      <!-- footer -->
      <div class="border-top py-4 text-right">
        <button
          class="btn btn-primary"
          style="width: 120px"
          @click="create"
          :disabled="!validInputs || createLoading"
        >
          <div class="d-flex justify-content-center" v-if="createLoading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else> Create </span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'
import MultiSelect from '@/components/MainContent/MultiSelect'

const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'AddCompanyModal',
  data: () => ({
    name: '',
    friendlyName: '',
    user: {},
    isLoading: false,
    createLoading: false,
    industry: '',
  }),
  components: {
    MultiSelect,
  },
  mounted() {
    this.getUsersList()
    this.fetchIndustries()
  },
  computed: {
    ...UserModule.mapGetters(['selectUsers']),
    ...CompanyModule.mapGetters(['selectIndustries']),
    validInputs() {
      return this.name && this.industry
    },
    industries() {
      return Object.keys(this.selectIndustries)?.map((key) => ({
        id: key,
        name: this.selectIndustries[key],
      }))
    },
  },
  methods: {
    ...UserModule.mapActions(['getUsersList']),
    ...CompanyModule.mapActions(['createCompany', 'fetchIndustries']),
    close() {
      this.$root.$emit('bv::hide::modal', 'AddCompanyModal')
    },
    updateList: debounce(async function (event) {
      this.isLoading = true
      await this.getUsersList(event)
      this.isLoading = false
    }, 500),
    async create() {
      this.createLoading = true
      try {
        const response = await this.createCompany({
          userId: this.user?._id,
          name: this.name,
          friendlyName: this.friendlyName,
          industry: this.industry.name
        })
        this.createLoading = false
        this.$router.push({
          name: 'company-detail',
          params: { id: response.body.data.company._id },
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Oops, something went wrong. Please try again',
        })
        this.createLoading = false
      }
    },
  },
}
</script>
