import Vue from 'vue'
import Vuex from 'vuex'
import ApiDocsModule from './api-docs'
import AuthModule from './auth'
import BandwidthModule from './bandwidth'
import BannerModule from './banner'
import Classifier from './classifier'
import CompanyModule from './company'
import DeletionBackupModule from './deletionBackup'
import FeatureFlagModule from './featureFlag'
import Group from './group'
import health from './health'
import integration from './integration'
import IntegrationLocationModule from './integrationLocation'
import IntegrationPartnerModule from './integrationPartner'
import IntegrationProviderModule from './integrationProvider'
import IvrModule from './ivr'
import LocationModule from './location'
import OrderModule from './order'
import Organization from './organization'
import PartnerModule from './partner'
import ReviewSubscriptionModule from './review-subscription'
import S3Module from './s3'
import SettingsService from '../services/SettingsService'
import SubscriptionModel from './subscription'
import TagModule from './tag'
import UserModule from './user'
import TranslationTemplateModule from './translationTemplate'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    apiDocs: ApiDocsModule,
    auth: AuthModule,
    bandwidth: BandwidthModule,
    banner: BannerModule,
    classifier: Classifier,
    company: CompanyModule,
    deletionBackup: DeletionBackupModule,
    featureFlag: FeatureFlagModule,
    group: Group,
    health,
    integration: integration,
    integrationLocation: IntegrationLocationModule,
    integrationPartner: IntegrationPartnerModule,
    integrationProvider: IntegrationProviderModule,
    ivr: IvrModule,
    location: LocationModule,
    order: OrderModule,
    organization: Organization,
    partner: PartnerModule,
    reviewSubscription: ReviewSubscriptionModule,
    s3: S3Module,
    subscription: SubscriptionModel,
    tag: TagModule,
    user: UserModule,
    translationTemplate: TranslationTemplateModule,
  },
  state: {
    config: {}
  },
  getters: {
    selectConfigByName: (state) => (name) => {
      return state.config[name]
    },
  },
  mutations: {
    RESET_CONFIG(state) {
      state.config = {}
    },
    SET_CONFIG(state, config) {
      state.config = config
    },
  },
  actions: {
    resetSettings({ commit }) {
      commit('RESET_CONFIG')
    },
    async setConfig({ commit, dispatch }) {
      try {
        const result = await SettingsService.getSettings()
        commit('SET_CONFIG', result.body.data.config)
        dispatch('s3/init')
      } catch(e) {
        console.log('Error loading settings: ', e)
      }
    },
  },
})
