<template>
  <div class="card" v-if="isReady">
    <div class="card-body">
      <h2 class="mb-4 platform-source">Billing</h2>
      <div class="row" v-if="!payments.items.length">
        <BillingCustomer v-model="mutableCustomerId"
          @change="setStripeCustomerId($event)"
          :error="errorMessage"/>
      </div>
      <div>
        <BillingSubscription :subscription="subscription"/>
      </div>

      <BillingPayments :payments="payments" />
    </div>
  </div>
</template>
<script>
import { get, head } from 'lodash'
import BillingService from '@/services/BillingService'

import BillingCustomer from '@/components/Modules/Billing/BillingCustomer.vue'
import BillingSubscription from '@/components/Modules/Billing/BillingSubscription.vue'
import BillingPayments from '@/components/Modules/Billing/BillingPayments.vue'
export default {
  name: 'BillingDetails',
  components: {
    BillingCustomer,
    BillingSubscription,
    BillingPayments
  },
  props: {
    customerId: {
      type: String,
      default: ''
    },
    companyId: {
      type: String,
      default: ''
    },
    locationId: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    mutableCustomerId: '',
    errorMessage: '',

    payments: {
      hasNextPage: false,
      items: []
    },
    billingInfo: null,
    isReady: false
  }),
  computed: {
    subscription() {
      if (
        get(this.billingInfo, 'subscriptions.data')
        // this.activeCompany.payment &&
        // this.activeCompany.payment.customer &&
        // this.activeCompany.payment.customer.subscriptions &&
        // this.activeCompany.payment.customer.subscriptions.data[0]
      )
        return head(this.billingInfo.subscriptions.data)
      return undefined
    }
  },
  methods: {
    async setStripeCustomerId() {
      try {
        await BillingService.connectStripeAccount({
          customerId: this.mutableCustomerId,
          companyId: this.companyId,
          locationId: this.locationId
        })
        await this.init()
        this.$emit('onCustomerUpdate', this.mutableCustomerId)
      } catch (err) {
        this.errorMessage = get(err, 'body.message', '')
        setTimeout(() => {
          this.errorMessage = ''
        }, 5000)
      }
    },
    async getPayments() {
      const { data } = await BillingService.getPayments(this.mutableCustomerId)
      this.payments = data;
    },
    async getBillingInfo() {
      const { data } = await BillingService.get(this.mutableCustomerId)
      this.billingInfo = data
    },
    init() {
      if (!this.mutableCustomerId) {
        this.resetBilling();
        return false;
      }
      return Promise.all([
        this.getPayments(),
        this.getBillingInfo()
      ])
    },
    resetBilling() {
      this.billingInfo = null;
      this.payments = { items: [] };
    },
    setMutableCustomerId() {
      this.mutableCustomerId = this.customerId;
    }
  },
  watch: {
    customerId() {
      this.setMutableCustomerId();
      this.init();
    }
  },
  async mounted() {
    this.setMutableCustomerId();
    await this.init();
    this.isReady = true;
  }
}
</script>
