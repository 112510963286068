import LocationService from '@/services/LocationService'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    locations: [],
    companyLocations: [],
    activeLocation: undefined,
    activeLocationCustomerId: '',
    searchQuery: '',
    isSearching: false,
    callToActions: [],
    isEditingCTA: false,
    currentCTA: undefined,
    geoLocations: [],
  },
  getters: {
    selectLocations(state) {
      return state.locations
    },
    selectSearchQuery(state) {
      return state.searchQuery
    },
    selectIsSearching(state) {
      return state.isSearching
    },
    selectActiveLocation(state) {
      return state.activeLocation
    },
    selectCompanyLocations: (state) => state.companyLocations,
    selectIsEditingCTA(state) {
      return state.isEditingCTA
    },
    selectCurrentCTA(state) {
      return state.currentCTA
    },
    selectCallToActions(state) {
      return state.callToActions
    },
    selectCallToActionById: (state) => (id) => {
      return state.callToActions.find((cta) => cta._id === id)
    },
    selectGeoLocations(state) {
      return state.geoLocations
    },
    selectLocationName: (state) => (id) => {
      const { city, friendlyName, name } = state.locations.find((l) => l._id === id) || {}
      return friendlyName || city || name
    },
  },
  mutations: {
    setLocations(state, locations) {
      state.locations = [...locations]
    },
    setActiveLocation(state, location) {
      state.activeLocation = location
    },
    clearActiveLocation(state) {
      state.activeLocation = undefined
    },
    setActiveLocationCustomerId(state, customerId) {
      state.activeLocationCustomerId = customerId
    },
    SET_CURRENT_CTA(state, cta) {
      state.currentCTA = cta
    },
    SET_SEARCH_QUERY(state, query) {
      state.searchQuery = query
    },
    SET_IS_SEARCHING(state, searching) {
      state.isSearching = searching
    },
    SET_COMPANY_LOCATIONS(state, locations) {
      state.companyLocations = locations
    },
    SET_CALL_TO_ACTIONS(state, callToActions) {
      state.callToActions = callToActions
    },
    SET_IS_EDITING_CTA(state, isEditing) {
      state.isEditingCTA = isEditing
    },
    SET_GEO_LOCATIONS(state, geoLocations) {
      state.geoLocations = geoLocations
    },
  },

  actions: {
    setSearchQuery({ commit }, query) {
      commit('SET_SEARCH_QUERY', query)
    },

    setIsSearching({ commit }, searching) {
      commit('SET_IS_SEARCHING', searching)
    },

    setIsEditingCTA({ commit }, isEditing) {
      commit('SET_IS_EDITING_CTA', isEditing)
    },

    setCurrentCTA({ commit }, cta) {
      commit('SET_CURRENT_CTA', cta)
    },

    async getLocationList({ commit }, { filters, search } = {}) {
      const response = await LocationService.list({
        filters,
        search,
      })
      commit('setLocations', response.body.data.locations)

      // const { body: { count } } = await LocationService.count()
      // let steps = []
      // for (let i = 0; i < count; i += 500) {
      //   steps.push({ skip: i, limit: 500 })
      // }
      // const locations = await Promise.all(
      //   steps.flatMap(async ({ skip, limit }) => {
      //     const response = await LocationService.list({
      //       excludePartnerLocations,
      //       hideDisabled,
      //       limit,
      //       skip,
      //     })
      //     return response.body.locations
      //   })
      // )
      // commit('setLocations', locations.flat())
    },

    async getCompanyLocations({ commit }, companyId) {
      const result = await LocationService.getByCompany(companyId)
      const locations = result.body.data.locations
      commit('SET_COMPANY_LOCATIONS', locations)

      return locations
    },

    async getLocation({ commit }, locationId) {
      const response = await LocationService.get(locationId)
      commit('setActiveLocation', response.body.data.location)
      commit(
        'setActiveLocationCustomerId',
        get(response, 'data.location.body.payment.customer.id', '')
      )
      return response
    },

    async transferLocation({ commit }, { location, company }) {
      return await LocationService.transfer(location, company)
    },

    async createLocation(_, payload) {
      const response = await LocationService.create(payload)
      return response
    },

    async moveLocationCustomers(_, { origin, destination }) {
      return await LocationService.moveLocationCustomers({ origin, destination })
    },

    async updateLocation({ commit }, { locationId, fields }) {
      const response = await LocationService.update(locationId, fields)

      if (response && response.body.location) {
        commit('setActiveLocation', response.body.location)
      }
    },

    async createCTA({ dispatch }, payload) {
      const response = await LocationService.createCTA(payload)
      if (response && response.body.success) {
        await dispatch('fetchCallToActions')
      }
    },

    async updateCTA({ dispatch }, payload) {
      const response = await LocationService.updateCTA(payload)
      if (response && response.body.success) {
        await dispatch('fetchCallToActions')
      }
    },

    async deleteCTA({ dispatch }, { locationId, ctaId }) {
      const response = await LocationService.deleteCTA({ locationId, ctaId })
      if (response && response.body.success) {
        await dispatch('fetchCallToActions')
      }
    },

    async fetchCallToActions({ commit, getters }, locationId) {
      const location = getters.selectActiveLocation
      const response = await LocationService.getCallToActions({
        locationId: locationId || location._id,
      })
      if (response && response.body) {
        commit('SET_CALL_TO_ACTIONS', response.body.data.ctas)
      }
    },
    async fetchGeoLocations({ commit }, payload) {
      const response = await LocationService.getGeocoding(payload)
      const locations = response?.data?.data?.locations
      commit('SET_GEO_LOCATIONS', locations)
      return locations
    },
  },
}
