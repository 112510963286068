<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <!-- Header -->
          <div class="header mt-md-5">
            <div class="header-body">
              <!-- Pretitle -->
              <h6 class="header-pretitle">Integration partners</h6>

              <!-- Title -->
              <h1 class="header-title display-4">
                All partners
                <span style="float: right">
                  <button
                    v-b-modal.creatIntegrationModal
                    aria-expanded="false"
                    aria-haspopup="true"
                    class="btn btn-outline-primary"
                    type="button"
                  >
                    + Add
                  </button></span
                >
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="list-group" role="tablist">
            <router-link
              :to="`/integration-partners/${partner._id}`"
              class="list-group-item"
              v-for="partner in integrationPartners"
              :key="partner._id"
            >
              {{ partner.name }}
            </router-link>
          </div>
        </div>
      </div>
      <!-- create integration-partner model -->
      <b-modal ref="modal" id="creatIntegrationModal" hide-footer hide-header body-class="p-0">
        <div class="modal-card card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title" id="exampleModalCenterTitle">
                  Create Integration Partner
                </h4>
              </div>
              <div class="col-auto">
                <button @click="modalClose" type="button" class="close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" style="max-height: 650px">
            <div class="row">
              <div class="col-12">
                <form>
                  <div class="form-group">
                    <label for="partner-name">Partner Name </label>
                    <input type="text" class="form-control" v-model="name" ref="name" />
                    <div v-if="isNameInUse" class="text-left">
                      <p id="errorMessage">
                        that name is already in the use, please try using another name
                      </p>
                    </div>
                    <br />

                    <label for="email">
                      Email (this will be used as the sender's email address)
                    </label>
                    <input
                      id="email"
                      type="text"
                      class="form-control"
                      placeholder="Email ( optional )"
                      v-model="email"
                    />
                    <span v-if="!isValidEmail" class="text-danger mb-6">
                      Please provide a properly formatted email address
                    </span>
                    <br />

                    <label for="companies">Companies</label>
                    <CompanySelector
                      id="company-select"
                      :display-ids="true"
                      :multiple="true"
                      :close-on-select="true"
                      v-model="companies"
                    /><br />

                    <label for="location-name">Phone</label>
                    <input
                      id="location-name"
                      type="text"
                      class="form-control"
                      placeholder="Phone number ( optional ) "
                      v-model="phone"
                    /><br />

                    <label for="url">URL</label>
                    <input
                      id="url"
                      type="text"
                      class="form-control"
                      placeholder="Url ( optional ) "
                      v-model="url"
                    /><br />

                    <label for="logo-url">Logo URL</label>
                    <input
                      id="logo-url"
                      type="text"
                      class="form-control"
                      placeholder="Logo url ( optional ) "
                      v-model="logoUrl"
                    /><br />

                    <label for="domain">Domain</label>
                    <input
                      id="domain"
                      type="text"
                      class="form-control"
                      placeholder="Domain ( optional ) "
                      v-model="domain"
                    /><br />

                    <button
                      :disabled="isValid || !isValidEmail"
                      class="btn btn-primary form-control"
                      @click="create"
                    >
                      Create
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
      <!-- end of create integration-partner model -->
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { BModal, VBModal } from 'bootstrap-vue'
import CompanySelector from '@/components/Selects/CompanySelector.vue'
const { mapActions, mapState, mapGetters } = createNamespacedHelpers('integrationPartner')

export default {
  name: 'IntegrationPartnersView',
  components: {
    BModal,
    CompanySelector,
  },
  data() {
    return {
      name: '',
      email: '',
      phone: '',
      url: '',
      domain: '',
      logoUrl: '',
      nameInUse: false,
      errorStyle: 'color: red',
      companies: [],
    }
  },

  directives: {
    'b-modal': VBModal,
  },
  computed: {
    ...mapState(['integrationPartners']),
    ...mapGetters(['getActivePartner']),

    isValid() {
      return this.name.length < 1
    },
    isNameInUse() {
      return this.nameInUse
    },
    isValidEmail() {
      return !this.email || this.validateEmail(this.email)
    },
  },
  methods: {
    ...mapActions(['getIntegrationPartners', 'createIntegrationPartner']),
    ...mapState(['activePartner']),

    modalClose() {
      this.$refs.modal.hide()
    },

    async create(e) {
      e.preventDefault()

      let partner = {}
      partner.name = this.name
      this.phone ? (partner.phone = this.phone) : (partner = { ...partner })
      this.email ? (partner.email = this.email) : (partner = { ...partner })
      this.url ? (partner.url = this.url) : (partner = { ...partner })
      this.logoUrl ? (partner.logoUrl = this.logoUrl) : (partner = { ...partner })
      this.domain ? (partner.domain = this.domain) : (partner = { ...partner })
      this.companies ? (partner.companyIds = this.companies.map(c => c._id)) : []

      try {
        // creating the integation
        const response = await this.createIntegrationPartner(partner)
        this.nameInUse = false
        const { _id: id } = response.body.data.integrationPartner
        this.$router.push({ name: 'integration-partner', params: { id: id } })
      } catch (error) {
        if (error?.data?.data?.code === 11000) {
          this.nameInUse = true
          this.$refs.name.style.border = '1px solid red'
        }
        this.$notify({
          type: 'error',
          text: 'Sorry, something went wrong. We were unable to create the partner',
        })
      }
    },
    validateEmail(email) {
      const regex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
      return regex.test(email)
    },
  },
  mounted() {
    this.getIntegrationPartners()
  },
}
</script>

<style scoped lang="scss">
#errorMessage {
  color: red;
}
</style>
