const isProduction = process.env.NODE_ENV === 'production'
const isTest = process.env.NODE_ENV === 'test'
const isDevelopment = !isProduction && !isTest

export default {
  isDevelopment,
  isTest,
  isProduction,
  firebase: true
    ? {
        apiKey: 'AIzaSyARx8z2QLkrMZWyNCRbCMmeNm3pH1iMTIQ',
        authDomain: 'ovation-v2.firebaseapp.com',
        databaseURL: 'https://ovation-v2.firebaseio.com',
        projectId: 'ovation-v2',
        storageBucket: 'ovation-v2.appspot.com',
        messagingSenderId: '690925511448',
      }
    : {
        apiKey: 'AIzaSyDm0d4cKy1NrwEjbaplqzBpO6NAmI91JmM',
        authDomain: 'ovation-v2-dev.firebaseapp.com',
        databaseURL: 'https://ovation-v2-dev.firebaseio.com',
        projectId: 'ovation-v2-dev',
        storageBucket: 'ovation-v2-dev.appspot.com',
        messagingSenderId: '57123254012',
      },
  intercom: isProduction ? 'tbu5bls0' : 'ix7dwy5j',
  pentaApi: isProduction
    ? 'https://apis.ovationup.com/app/v1'
    : isTest
    ? 'https://sandbox-apis.ovationup.com/app/v1'
    : 'http://localhost:3000/app/v1',
  api: isProduction
    ? 'https://apis.ovationup.com/admin/v1'
    : isTest
    ? 'https://sandbox-apis.ovationup.com/admin/v1'
    : 'http://localhost:3001/admin/v1',
  apiV2: isProduction
    ? 'https://api.ovationup.com/admin-services/v2'
    : isTest
    ? 'https://api-test.ovationup.com/admin-services/v2'
    : 'https://api-test.ovationup.com/admin-services/v2',
  appUrl: isProduction
    ? 'https://app.ovationup.com/'
    : isTest
    ? 'https://app-test.ovationup.com/'
    : 'https://app-test.ovationup.com/',
}
