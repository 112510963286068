import ApiDocService from '@/services/ApiDocService'

export default {
  namespaced: true,
  state: {
    definitions: {},
  },
  getters: {
    selectDefinition: (state) => (definition) => state.definitions[definition],
  },
  mutations: {
    SET_API_DEFINITION(state, { definition, data }) {
      state.definitions[definition] = data
    },
  },
  actions: {
    async getApiDefinition({ commit, getters }, definition) {
      if (!getters.selectDefinition(definition)) {
        const response = await ApiDocService.getApiDefinition(definition)
        commit('SET_API_DEFINITION', {
          definition,
          data: response.body.data.definition,
        })
      }
    },
  },
}
