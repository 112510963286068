import Vue from 'vue'
import Router from 'vue-router'
import FeatureFlagsPage from './pages/FeatureFlagsPage'
import IntegrationPartnersPage from './pages/IntegrationPartnersPage'
import IntegrationPartnerPage from './pages/IntegrationPartnerPage'
import IntegrationProvidersPage from './pages/IntegrationProvidersPage'
import LandingPage from './pages/LandingPage'
import LoginPage from './pages/LoginPage'
import BannersPage from './pages/BannersPage'
import LocationsPage from './pages/LocationsPage'
import ScorecardsPage from './pages/ScorecardsPage'
import CompaniesPage from './pages/CompaniesPage'
import CompanyDetail from './pages/CompanyDetail'
import DangerZonePage from './pages/DangerZonePage'
import LocationDetail from './pages/LocationDetail'
import OrdersPage from './pages/OrdersPage'
import ReportsPage from './pages/ReportsPage'
import UsersPage from './pages/UsersPage'
import TagsPage from './pages/TagsPage'
import Config from './config'
import ClassifierPage from './pages/ClassifierPage'
import GroupCreatorPage from './pages/GroupCreator'
import LocationMapPage from './pages/LocationMapPage'
import PhoneVerificationsPage from './pages/PhoneVerificationsPage'
import CustomerTaggingPage from './pages/CustomerTaggingPage'
import ApiDocsPage from './pages/ApiDocsPage'

import firebase from 'firebase/app'
import 'firebase/auth'
import { fetchUserToken } from './lib/firebase'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/',
      name: 'landing',
      component: LandingPage,
      meta: {
        hideNav: true,
      },
    },
    {
      path: '/login',
      name: 'login',
      component: LoginPage,
      meta: {
        hideNav: true,
      },
    },
    {
      path: '/scorecards',
      name: 'scorecards',
      component: ScorecardsPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/companies',
      name: 'companies',
      component: CompaniesPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/company/:id',
      name: 'company-detail',
      component: CompanyDetail,
      props: true,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/danger-zone',
      name: 'danger-zone',
      component: DangerZonePage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/ff',
      name: 'feature-flags',
      component: FeatureFlagsPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/integration-partners',
      name: 'integration-partners',
      component: IntegrationPartnersPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/integration-partners/:id',
      name: 'integration-partner',
      component: IntegrationPartnerPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/providers',
      name: 'providers',
      component: IntegrationProvidersPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/locations',
      name: 'locations',
      component: LocationsPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/users',
      name: 'users',
      component: UsersPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/locations/:id',
      name: 'location-detail',
      component: LocationDetail,
      props: true,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/orders',
      name: 'orders',
      component: OrdersPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/reports',
      name: 'reports',
      component: ReportsPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/tags',
      name: 'tags',
      component: TagsPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/banners',
      name: 'banners',
      component: BannersPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/classifier',
      name: 'classifier',
      component: ClassifierPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/companies/:id/groups/creator',
      name: 'group-creator',
      component: GroupCreatorPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/location-map',
      name: 'location-map',
      component: LocationMapPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/phone-verifications',
      name: 'phone-verifications',
      component: PhoneVerificationsPage,
      meta: {
        hideNav: false,
      },
    },
    {
      path: '/customer-tagging',
      name: 'customer-tagging',
      component: CustomerTaggingPage,
      meta: {
        hideNav: false
      }
    },
    {
      path: '/api-docs',
      name: 'api-docs',
      component: ApiDocsPage,
      meta: {
        hideNav: false,
      },
    },
  ],
})

export async function initRouterView(context) {
  const path = context.$router.currentRoute.path
  await new Promise((resolve) => {
    const done = firebase.auth().onAuthStateChanged(() => {
      done()
      resolve()
    })
  })

  if (!firebase.auth().currentUser) {
    if (path !== '/login') {
      context.$router.push('/login')
    }
  } else {
    await fetchUserToken()
    await context.$store.dispatch('setConfig')
    await context.getAuth()

    if (path === '/' || path === '/login') {
      if (context.selectIsClassifier) {
        context.$router.push('/classifier')
      } else {
        context.$router.push('/scorecards')
      }
    }
  }
}
