import AWS from 'aws-sdk'

export default {
  namespaced: true,
  state: {
    connector: null,
    uploadProgress: {},
  },
  getters: {
    selectConnector: (state) => state.connector,
    selectUploadProgress: (state) => (id) => state.uploadProgress[id],
  },
  mutations: {
    SET_CONNECTOR(state, connector) {
      state.connector = connector
    },
    SET_UPLOAD_PROGRESS(state, { id, progress }) {
      state.uploadProgress[id] = progress
    },
  },
  actions: {
    init({ commit, rootGetters }) {
      const credentials = new AWS.Credentials(
        rootGetters.selectConfigByName('AWS_S3_ACCESS_KEY'),
        rootGetters.selectConfigByName('AWS_S3_SECRET')
      )

      commit(
        'SET_CONNECTOR',
        new AWS.S3({
          region: rootGetters.selectConfigByName('AWS_S3_REGION'),
          credentials,
        })
      )
    },
    async listObjects({ getters }, { id, bucket, prefix }) {
      const response = await getters.selectConnector
        .listObjectsV2({
          Bucket: bucket,
          Prefix: prefix,
        })
        .promise()

      return response
    },
    async getSignedUrl({ getters, rootGetters }, { bucket, key, expiration, contentType }) {
      let signedUrl = getters.selectConnector.getSignedUrl('getObject', {
        Bucket: bucket,
        Key: key,
        Expires: expiration || 300 * 60,
        ResponseContentType: contentType,
      })
      return signedUrl
    },
    async getObject({ getters }, { bucket, key }) {
      let getObjectResult = await getters.selectConnector
        .getObject({
          Bucket: bucket,
          Key: key,
        })
        .promise()

      return getObjectResult
    },
    async uploadFile({ getters }, { Bucket, Key, Body, ContentType, Metadata }) {
      const response = await getters.selectConnector
        .upload({
          Bucket: Bucket,
          Key: Key,
          ContentType: ContentType,
          Body: Body,
          ACL: 'private',
          Metadata: Metadata,
        })
        .promise()
      return response
    },
  },
}
