import GroupService from '../services/GroupService'
import { v4 as uuidv4 } from 'uuid'

export default {
  namespaced: true,
  state: {
    tree: {
      id: 'root',
      children: []
    },
    groups: [],
    isEditing: false,
    groupType: null,
    levelNames: [],
    httpResponse: null,
    placeHolderIds: [],
    assignedLocations: [],
    selectedGroupItem: null,
    groupCreationSteps: [],
    activeSelectedGroup: null,
    currentGroupMembers: null,
  },
  getters: {
    selectTree(state){
      return state.tree
    },
    selectGroups(state){
      return state.groups
    },
    selectIsEditing(state){
      return state.isEditing
    },
    selectGroupType(state){
      return state.groupType
    },
    selectLevelNames(state){
      return state.levelNames
    },
    selectHttpResponse(state){
      return state.httpResponse
    },
    selectPlaceHolderIds(state){
      return state.placeHolderIds
    },
    selectCurrentGroupItem(state){
      return state.selectedGroupItem
    },
    selectAssignedLocations(state){
      return state.assignedLocations
    },
    selectcurrentGroupMembers(state){
      return state.currentGroupMembers
    },
    selectActiveSelectedGroup(state){
      return state.activeSelectedGroup
    },
    selectGroupCreationSteps(state){
      return state.groupCreationSteps
    }
  },
  mutations: {
    SET_TREE(state, tree){
      state.tree = tree
    },
    SET_GROUPS(state, groups){
      state.groups = groups
    },
    UPDATE_TREE(state, tree){
      state.tree = tree
    },
    SET_IS_EDITING(state, status){
      state.isEditing = status
    },
    SET_GROUP_TYPE(state, type){
      state.groupType = type
    },
    INITIALIZE_TREE(state, tree){
      state.tree = { ...state.tree, ...tree }
    },
    SET_LEVEL_NAMES(state, levelNames){
      state.levelNames = levelNames
    },
    SET_HTTP_RESPONSE(state, response){
      state.httpResponse = response
    },
    RESET_GROUP_STATE(state){
      state.groupType = null,
      state.levelNames = []
      state.placeHolderIds = []
      state.assignedLocations = []
      state.selectedGroupItem = null
      state.groupCreationSteps = []
      state.activeSelectedGroup = null
      state.currentGroupMembers = []
    },
    SET_PLACEHOLDER_IDS(state, id){
      const foundOne = state.placeHolderIds.find(item => item === id)
      if(!foundOne){
        state.placeHolderIds.push(id)
      }
    },
    SET_ROOT_PROPERTIES(state, props){
      state.tree = { ...state.tree, ...props }
    },
    SET_ASSIGNED_LOCATIONS(state, locationId){
      const foundOne = state.assignedLocations.find(loc => loc === locationId)
      if(!foundOne){
        state.assignedLocations.push(locationId)
      }
    },
    REMOVE_ASSIGNED_LOCATION(state, locationId){
      state.assignedLocations = state.assignedLocations
      .filter(location => location !== locationId)
    },
    SET_SELECTED_GROUP_ITEM(state, item){
      state.selectedGroupItem = item
    },
    SET_ACTIVE_SELECTED_GROUP(state, group){
      state.activeSelectedGroup = group
    },
    SET_GROUP_CREATION_STEPS(state, steps){
      state.groupCreationSteps = steps
    },
    SET_CURRENT_GROUP_MEMBERS(state, members){
      state.currentGroupMembers = members
    },
    REMOVE_PLACEHOLDERS_BY_ID(state, id){
      state.placeHolderIds = state.placeHolderIds
      .filter(placeholderId => placeholderId !== id)
    }
  },
  actions: {
    updateTree({ commit }, tree){
      commit('SET_TREE', tree)
    },

    setIsEditing({ commit }, status){
      commit('SET_IS_EDITING', status)
    },

    setGroupType({ commit }, type){
      commit('SET_GROUP_TYPE', type)
    },

    setLevelNames({ commit }, levelNames){
      commit('SET_LEVEL_NAMES', levelNames)
    },

    initializeTree({ commit }, tree){
      commit('INITIALIZE_TREE', tree)
    },

    resetGroupState({ commit }){
      commit('RESET_GROUP_STATE')
    },

    setRootProperties({ commit }, props){
      commit('SET_ROOT_PROPERTIES', props)
    },

    setPlaceholderIds({ commit }, id){
      commit('SET_PLACEHOLDER_IDS', id)
    },

    removePlaceholdersById({ commit }, id){
      commit('REMOVE_PLACEHOLDERS_BY_ID', id)
    },

    setAssignedLocations({ commit }, locationId){
      commit('SET_ASSIGNED_LOCATIONS', locationId)
    },

    setSelectedGroupItem({ commit }, item){
      commit('SET_SELECTED_GROUP_ITEM', item)
    },

    setCurrentGroupMembers({ commit }, members){
      commit('SET_CURRENT_GROUP_MEMBERS', members)
    },

    removeAssignedLocation({ commit }, locationId){
      commit('REMOVE_ASSIGNED_LOCATION', locationId)
    },

    setGroupCreationSteps({ commit }, steps){
      commit('SET_GROUP_CREATION_STEPS', steps)
    },

    generatePlaceholderId({ dispatch }){
      const id = uuidv4()
      dispatch('setPlaceholderIds', id)
      return id 
    },

    async deleteGroupById({ commit, rootGetters, dispatch }, groupId){
      const { _id: companyId } = rootGetters['company/selectActiveCompany']

      const response = await GroupService.deleteGroup(groupId)
      commit('SET_HTTP_RESPONSE', response),
      await dispatch('fetchGroups', { companyId })
    },

    async setActiveSelectedGroup({ commit, dispatch }, group){
      const { _id: groupId } = group
      const response = await GroupService.getGroup({ groupId })

      if(response && response.data){
        const { groupTree } = response.body.data

        commit('RESET_GROUP_STATE')      
        commit('SET_GROUP_TYPE', groupTree.type)
        commit('SET_ACTIVE_SELECTED_GROUP', groupTree)

        if(groupTree.type === 'hierarchy'){
          commit('SET_LEVEL_NAMES', groupTree.levelNames)
          commit('SET_GROUP_CREATION_STEPS', groupTree.levelNames)
          dispatch('mapGroupIntoTree', groupTree)
        }
        else if(groupTree.type === 'singular'){
          // handle singular group types
          dispatch('mapSingularGroupIntoTree', groupTree)
        }
      }
    },

    async createAndSaveGroup({ commit, dispatch }, group ){
      const response = await GroupService.createGroup(group)
      commit('SET_HTTP_RESPONSE', response)
      dispatch('resetGroupState')
    },

    async updateAndSaveGroup({ commit, dispatch }, payload){
      const { _id: groupId, company, ...group } = payload
      group.companyId = company
      // first, create a new group
      const response = await GroupService.createGroup(group)
      // then, delete the old group
      if(response && response.body.success){
        await dispatch('deleteGroupById', groupId)
      }
      // set the http response
      commit('SET_HTTP_RESPONSE', response)
      dispatch('resetGroupState')
    },

    mapGroupIntoTree({ commit, dispatch }, group){
      const hasChildren = (node) => {
        return (
          typeof node === 'object' && 
          typeof node.children !== 'undefined' 
          && node.children.length > 0
        )
      }
      
      // recursively add id to each node
      const recursiveMapper = (parent) => {
        parent = { 
          ...parent, 
          id: parent.type === 'hierarchy' ? 'root' : uuidv4() 
        }

        // get the group members/locations
        if(parent.members && parent.members.length){
          parent.members.forEach(memberId => dispatch('setAssignedLocations', memberId))
        }

        if(!hasChildren(parent)){
          return parent
        }

        parent.children.forEach(child => {
          child.id = uuidv4()
          recursiveMapper(child)
        })
        return parent
      }

      // map the group and update tree
      const tree = recursiveMapper(group)
      commit('SET_TREE', tree)
    },

    async fetchGroups({ commit }, payload){
      const response = await GroupService.list(payload)

      if(response && response.data){
        commit('SET_GROUPS', response.data.data.groups)
      }
      commit('SET_HTTP_RESPONSE', response)
    },

    mapSingularGroupIntoTree({ commit, dispatch }, group){
      group.id = 'root'
      
      // set the current group members, if any
      if(group.members && group.members.length){
        dispatch('setCurrentGroupMembers', group.members)
        group.members.forEach(
          member => dispatch('setAssignedLocations', member)
        )
      }
      commit('SET_TREE', group)
    },
  }
}