<template>
  <div class="integration-location">
    <div class="row mt-5">
      <div class="col-12">
        <h3 class="mb-4">Integration Locations</h3>
        <button
          v-b-modal.creatIntegrationLocationModal
          aria-expanded="false"
          aria-haspopup="true"
          class="btn btn-outline-primary mb-3"
          type="button"
        >
          + Add Location
        </button>
      </div>
      <!-- vue modal for creating integration location-->
      <b-modal ref="modal" id="creatIntegrationLocationModal" hide-header body-class="p-0">
        <div class="modal-card card">
          <div class="card-header">
            <div class="row align-items-center">
              <div class="col">
                <h4 class="card-header-title" id="exampleModalCenterTitle">
                  Create Integration Location
                </h4>
              </div>
              <div class="col-auto">
                <button @click="modalClose" type="button" class="close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
            </div>
          </div>
          <div class="card-body" style="max-height: 650px">
            <div class="row">
              <div class="col-12">
                <form>
                  <h4>Integration Location fields</h4>
                  <div class="form-group">
                    <label for="id">Id (3rd-party)</label>
                    <input
                      type="text"
                      class="form-control"
                      v-model="id"
                      ref="id"
                      placeholder="Enter id of location of partner system"
                    />
                  </div>

                  <div class="form-group">
                    <label for="name">Name (3rd-party)</label>
                    <input
                      id="name"
                      type="text"
                      class="form-control"
                      placeholder="Enter integration partner name"
                      v-model="name"
                    />
                  </div>

                  <div class="form-group">
                    <label for="companyId">Company Id</label>
                    <multiselect
                      id="companyId"
                      aria-describedby="companyIdHelp"
                      placeholder="Search company..."
                      selectLabel
                      v-model="form.company"
                      :multiple="false"
                      @input="updateCompanies"
                      @search-change="fetchCompanies"
                      :searchable="true"
                      :showLabels="false"
                      :limitText="(count) => `+${count} more`"
                      :limit="1"
                      :loading="isCompanyLoading"
                      :options="companies"
                      :custom-label="companyName"
                      track-by="_id"
                    >
                    </multiselect>
                  </div>

                  <div class="form-group">
                    <label for="locationId">Location Id</label>
                    <multiselect
                      id="locationId"
                      placeholder="Search location..."
                      selectLabel
                      v-model="form.location"
                      :multiple="false"
                      @input="updateLocations"
                      @search-change="fetchLocations"
                      :searchable="true"
                      :showLabels="false"
                      :limitText="(count) => `+${count} more`"
                      :limit="1"
                      :loading="isLocationLoading"
                      :options="companyLocations"
                      :custom-label="locationName"
                      track-by="_id"
                      :disabled="!form.company"
                    >
                    </multiselect>
                  </div>
                  <h4>Integration fields</h4>
                  <div class="form-group">
                    <label for="provider">Provider</label>
                    <multiselect
                      id="provider"
                      placeholder="Select provider..."
                      selectLabel
                      v-model="provider"
                      :multiple="false"
                      :options="providers"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
        <template #modal-footer>
          <button
            :disabled="!isValid || isCreatingLocation"
            class="btn btn-primary form-control mt-4 float-right"
            @click="create"
          >
            Create
          </button>
        </template>
      </b-modal>
      <!-- end of vue modal -->
    </div>
    <hr />
    <div v-if="integrationLocationIds && integrationLocationIds.length">
      <IntegrationLocationTable :locations="integrationLocations" />
    </div>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-4">Email Domain</h3>
        <hr />
      </div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <div class="row">
          <div class="col-lg-3 col-9">
            <div class="form-group">
              <input
                id="domain"
                type="text"
                class="form-control"
                placeholder="Domain name"
                v-model="domain"
              />
            </div>
          </div>
          <div class="col-lg-2 col-3">
            <div class="form-group">
              <button
                aria-expanded="false"
                aria-haspopup="true"
                class="btn ml-3 btn-primary mb-3"
                type="button"
                @click.prevent="updateEmailDomain"
              >
                <span v-if="!isUpdating">Update</span>
                <span v-else class="loader loader-sm loader-white"></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <h3 class="mb-4">Translation Templates</h3>
      </div>
      <button
        aria-expanded="false"
        aria-haspopup="true"
        class="btn ml-3 btn-outline-primary mb-3"
        type="button"
        @click="openModal"
      >
        + Add Template
      </button>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <TranslationTemplateTable
          v-if="!isFetchingTemplates"
          @updateTranslation="handleUpdate"
          @deleteTranslation="handleDelete"
          :translationTemplates="translationTemplates"
        />
        <div v-else class="d-flex justify-content-center align-items-center">
          <span class="loader text-center loader-primary loader-sm"></span>
          <span class="ml-3">Loading templates...</span>
        </div>
      </div>
    </div>
    <CreateTranslationTemplateModal
      :integrationPartnerId="integrationPartnerId"
      :translationTemplate="translationTemplate"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { BModal, VBModal } from 'bootstrap-vue'
import Multiselect from 'vue-multiselect'
import IntegrationLocationTable from '../components/Modules/IntegrationPartner/IntegrationLocationTable.vue'
import { debounce } from 'lodash-es'
import TranslationTemplateTable from '../components/Modules/TranslationTemplate/TranslationTemplateTable.vue'
import CreateTranslationTemplateModal from '../components/Modals/CreateTranslationTemplateModal.vue'

const integrationLocationModule = createNamespacedHelpers('integrationLocation')
const locationModule = createNamespacedHelpers('location')
const companyModule = createNamespacedHelpers('company')
const integrationModule = createNamespacedHelpers('integration')
const integrationProvidersModule = createNamespacedHelpers('integrationProvider')
const integrationPartnerModule = createNamespacedHelpers('integrationPartner')
const translationTemplateModule = createNamespacedHelpers('translationTemplate')

export default {
  data: function () {
    return {
      form: {
        location: undefined,
        company: undefined,
      },
      id: '',
      name: '',
      domain: '',
      provider: '',
      isUpdating: false,
      isCompanyLoading: false,
      isLocationLoading: false,
      translationTemplate: null,
      isCreatingLocation: false,
    }
  },
  name: 'IntegrationLocationPage',
  props: ['partner'],
  components: {
    BModal,
    multiselect: Multiselect,
    IntegrationLocationTable,
    TranslationTemplateTable,
    CreateTranslationTemplateModal,
  },
  directives: {
    'b-modal': VBModal,
  },

  computed: {
    ...integrationLocationModule.mapState(['saveResponse', 'integrationLocations']),
    ...locationModule.mapState(['locations']),
    ...companyModule.mapState(['companies']),
    ...companyModule.mapState(['activeCompany']),
    ...integrationLocationModule.mapGetters(['selectIntegrationLocationIds']),
    ...integrationModule.mapGetters(['selectProviders']),
    ...integrationProvidersModule.mapGetters([
      'selectIntegrationProviders',
      'selectIntegrationProviderByName',
    ]),
    ...translationTemplateModule.mapGetters([
      'selectTranslationTemplates',
      'selectIsFetchingTemplates',
    ]),
    isValid() {
      if (
        (this.id.length < 1 && this.name.length < 1) ||
        this.provider.length < 1 ||
        !this.form.location ||
        !this.form.company
      ) {
        return false
      }
      return true
    },
    companyLocations() {
      if (!this.form.company) {
        return []
      }
      const locationIdsWithExistingIntegrations = (this.integrationLocations || []).map(
        ({ location }) => location
      )
      return this.locations.filter((location) => {
        return (
          location?.company?._id === this.form.company._id &&
          !locationIdsWithExistingIntegrations.includes(location._id)
        )
      })
    },
    integrationLocationIds() {
      return this.selectIntegrationLocationIds()
    },
    providers() {
      const selected = this.selectIntegrationProviders();
      return selected
        .map((i) => i.displayName || i.name)
        .sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()))
    },
    integrationPartnerId() {
      return this.partner?._id
    },
    translationTemplates() {
      return this.selectTranslationTemplates
    },
    isFetchingTemplates() {
      return this.selectIsFetchingTemplates
    },
  },
  methods: {
    ...integrationLocationModule.mapActions(['saveIntegrationLocation', 'getIntegrationLocations']),
    ...locationModule.mapActions(['getLocationList']),
    ...companyModule.mapActions(['getCompanyList']),
    ...integrationModule.mapActions(['createIntegrationDocument', 'getIntegrationsList']),
    ...integrationPartnerModule.mapActions(['updateIntegrationPartner']),
    ...translationTemplateModule.mapActions([
      'createTranslationTemplate',
      'updateTranslationTemplate',
      'deleteTranslationTemplate',
      'setActiveTranslationTemplate',
    ]),

    locationName({ _id, city, friendlyName, name }) {
      return `${name} — ${friendlyName || city} — (${_id})`
    },
    companyName({ _id, name }) {
      return `${name} — (${_id})`
    },
    updateLocations(location) {
      this.$emit('updateLocations', location)
      this.currentCompany = location.company
    },
    updateCompanies(company) {
      this.$emit('updateCompanies', company)
      this.form.location = undefined
    },

    modalClose() {
      this.$refs.modal.hide()
    },
    modalClear() {
      this.form = {
        location: undefined,
        company: undefined,
      }
      this.id = ''
      this.name = ''
      this.provider = ''
    },

    async create(e) {
      e.preventDefault()
      if (this.isCreatingLocation) return
      this.isCreatingLocation = true
      let intLocation = {}
      // create a new integration location object
      intLocation.integrationPartnerId = this.partner._id
      this.id ? (intLocation.id = this.id) : (intLocation = { ...intLocation })
      this.name ? (intLocation.name = this.name) : (intLocation = { ...intLocation })
      this.form.location
        ? (intLocation.locationId = this.form.location._id)
        : (intLocation = { ...intLocation })

      const { _id: companyId } = this.form.location.company
      const provider = this.selectIntegrationProviderByName('ovation-partner-create-order-webhook')

      const payload = {
        ...intLocation,
        companyId: companyId,
        provider: this.provider,
        credentials: {},
        type: 'webhook',
        integrationProviderId: provider?._id,
      }

      try {
        await this.saveIntegrationLocation(payload)
        this.$notify({
          type: 'success',
          title: 'Created!',
          text: 'Integration location added successfully',
        })
        await this.getIntegrationLocations(this.partner._id)
        this.modalClose()
        this.modalClear()
      } catch (error) {
        const { data: { name } = {} } = error?.data || {}
        if (name === 'ValidationError') {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          })
        } else {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'Unable to create integration location document.',
          })
        }
        console.error(error)
      }
      this.isCreatingLocation = false
    },
    fetchCompanies: debounce(async function (query) {
      this.isCompanyLoading = true
      await this.getCompanyList({ search: query })
      this.isCompanyLoading = false
    }, 500),
    fetchLocations: debounce(async function (query) {
      this.isLocationLoading = true
      const payload = { filters: { disabled: false }, search: query }
      await this.getLocationList(payload)
      this.isLocationLoading = false
    }, 500),
    async openModal() {
      this.$root.$emit('bv::show::modal', 'CreateTranslationTemplateModal')
    },
    async handleDelete(template) {
      if (!template) return
      try {
        const translationTemplateId = template?._id
        await this.deleteTranslationTemplate({ translationTemplateId })
        this.$notify({
          text: 'Translation template deleted successfully',
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Failed to delete translation template',
        })
      }
    },
    handleUpdate(template) {
      this.setActiveTranslationTemplate(template)
      this.openModal()
    },
    async updateEmailDomain() {
      if (this.isUpdating) return
      this.isUpdating = true
      try {
        const payload = {
          update: {
            domain: this.domain,
          },
          partnerId: this.integrationPartnerId,
        }
        await this.updateIntegrationPartner(payload)
        this.$notify({
          title: 'Updated!',
          text: 'Email domain updated successfully',
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          text: `Failed to update email domain: ${error?.message}`,
        })
      }
      this.isUpdating = false
    },
  },
  mounted() {
    this.getLocationList({ filters: { disabled: false } })
    this.getCompanyList()
    // get integration location for the current partner
    this.getIntegrationLocations(this.partner._id)
    if (this.partner?.domain) {
      this.domain = this.partner.domain
    }
  },
}
</script>

<style scoped lang="scss"></style>
