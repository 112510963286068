<template>
  <div class="location-selector">
    <multiselect
    :disabled="false"
    v-model="selectedRole"
    :multiple="false"
    selectLabel=""
    :options="roles"
    track-by="value"
    :closeOnSelect="true"
    placeholder="Select locations"
	:custom-label="customRoleName"
	/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  name: 'RoleSelector',
  props: ['value'],
  components: {
    multiselect: Multiselect,
  },
  data(){
    return {
        roles: [{ name: 'Admin', value: 'admin'}, { name: 'Location Manager', value: 'location-manager'}, { name: 'Employee', value: 'employee'}],
        selectedRole: null
    }
  },
  watch: {
    selectedRole(val) {
        this.$emit('input', val)
    },
    value(val) {
        this.selectedRole = val
    }
  },
  methods: {
	customRoleName(role){
      return `${role.name}`
    }
  }
}
</script>