<template>
  <div class="ivr-invalid-gather-option">
    <div class="row no-gutters mt-1 mr-4">
      <div class="col col-auto align-self-center mr-4 ml-4">
        <div class="row">
          <label style="color: #95aac9" class="ml-3 mr-4">Invalid</label>
        </div>
        <div class="row">
          <label style="color: #95aac9" class="ml-3 mr-4">Option</label>
        </div>
      </div>
      <div class="col col-10">
        <div
          class="card"
          :style="
            errors && errors.includes('invalid-option')
              ? 'border-color: #e63757; border-width:2px'
              : ''
          "
        >
          <div class="container mx-0 px-0">
            <div class="row">
              <div class="col col-2 align-self-center ml-4 mt-2">
                <label style="color: #95aac9">Audio Producer</label>
              </div>
              <div class="col col-8 mr-3 my-2">
                <select class="form-control custom-select my-2" v-model="selectedAudioProducer">
                  <option value>None</option>
                  <option v-for="verb of audioProducerVerbs" :key="verb">
                    {{ verb }}
                  </option>
                </select>
              </div>
            </div>
            <IvrPlayAudio
              v-if="selectedAudioProducer === 'PlayAudio'"
              class="mt-3 mb-2"
              :uploadPath="uploadPath"
              :name="`InvalidGatherOption`"
              :url="url"
              @playAudioUpdate="playAudioUpdate"
            />
            <IvrSpeakSentence
              v-if="selectedAudioProducer === 'SpeakSentence'"
              class="mb-2"
              :voice="voice"
              :gender="gender"
              :sentence="sentence"
              :locale="locale"
              @speakSentenceUpdate="speakSentenceUpdate"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import IvrPlayAudio from './IvrPlayAudio.vue'
import IvrSpeakSentence from './IvrSpeakSentence.vue'

export default {
  name: 'IvrInvalidGatherOption',
  props: ['invalidOption', 'index', 'uploadPath', 'errors'],
  components: { IvrPlayAudio, IvrSpeakSentence },
  data: () => ({
    selectedAudioProducer: null,
    url: null,
    sentence: null,
    voice: null,
    gender: null,
    locale: null,
  }),
  computed: {
    audioProducerVerbs() {
      return ['PlayAudio', 'SpeakSentence']
    },
  },
  methods: {
    playAudioUpdate(modifications) {
      this.url = modifications.url
    },
    speakSentenceUpdate(modifications) {
      this.sentence = modifications.sentence
      this.voice = modifications.voice
      this.gender = modifications.gender
      this.locale = modifications.locale
      this.emitUpdate()
    },
    emitUpdate() {
      const modifications = {
        verb: this.selectedAudioProducer,
      }

      if (this.selectedAudioProducer) {
        if (this.selectedAudioProducer === 'PlayAudio') {
          modifications.url = this.url
        }

        if (this.selectedAudioProducer === 'SpeakSentence') {
          modifications.sentence = this.sentence
          if (this.voice) {
            modifications.voice = this.voice
          } else {
            modifications.gender = this.gender
            modifications.locale = this.locale
          }
        }
      }
      this.$emit('invalidGatherOptionUpdate', modifications)
    },
  },
  watch: {
    selectedAudioProducer(val) {
      if (val === 'PlayAudio') {
        this.gender = null
        this.locale = null
        this.voice = null
      } else if (val === 'SpeakSentence') {
        this.url = null
      }
      this.emitUpdate()
    },
    url(val) {
      this.emitUpdate()
    },
  },
  mounted() {
    if (this.invalidOption) {
      this.selectedAudioProducer = this.invalidOption.verb
      this.url = this.invalidOption.url
      this.sentence = this.invalidOption.sentence
      this.gender = this.invalidOption.gender
      this.locale = this.invalidOption.locale
      this.voice = this.invalidOption.voice
    }
    this.emitUpdate()
  },
}
</script>
