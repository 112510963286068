<template>
  <div class="row">
    <div class="col-12">
      <div class="card">
        <div class="card-header">
          <form class="d-flex flex-wrap" @submit.prevent="addNew">
            <div class="flex-grow-1">
              <input
                type="text"
                ref="nameInput"
                aria-label="tag name"
                v-model="newTagName"
                v-mask="nameMask"
                placeholder="Enter new tag name"
                class="form-control form-control-sm form-control-flush"
              />
            </div>
            <button
              type="submit"
              :disabled="!newTagName"
              class="btn btn-sm btn-primary flex-grow-0"
            >
              <i class="fe fe-plus"></i> Add
            </button>
          </form>
        </div>

        <div class="table-responsive mb-0">
          <table class="table table-sm table-nowrap card-table">
            <thead>
              <tr>
                <th scope="col" style="width: 15%">name</th>
                <th scope="col" style="width: 15%">Keyword</th>
                <th scope="col" style="width: 30%">Description</th>
                <th scope="col" style="width: 20%">Action</th>
                <th scope="col" style="width: 10%">External</th>
                <th scope="col" style="width: 10%">Archive</th>
              </tr>
            </thead>
            <tbody class="list">
              <tr v-for="tag of tags" :key="tag._id">
                <td class="align-middle name">
                  {{ tag.name }}
                </td>
                <td class="align-middle">
                  <input
                    type="text"
                    v-mask="keywordMask"
                    aria-label="tag keyword"
                    placeholder="add keyword"
                    v-model="keywordModels[tag._id]"
                    @blur="updateKeyword(tag._id, tag.keyword)"
                    class="form-control form-control-sm form-control-flush keyword-input"
                  />
                </td>
                <td class="align-middle">
                  <input
                    type="text"
                    aria-label="tag description"
                    placeholder="add description"
                    v-model="descriptionModels[tag._id]"
                    @blur="updateDescription(tag._id, tag.description)"
                    class="form-control form-control-sm form-control-flush"
                  />
                </td>
                <td class="align-middle">
                  <MultiSelect
                    reposition
                    :id="`${tag._id}-action`"
                    :allow-empty="false"
                    :searchable="false"
                    :show-labels="false"
                    openDirection="below"
                    :options="actionOptions"
                    v-model="actionModels[tag._id]"
                    @select="activateAction($event, tag)"
                  />
                </td>
                <td class="align-middle">
                  <div class="custom-control custom-checkbox-toggle">
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      :id="tag.name + '-external'"
                      :aria-label="`${tag.name} external status`"
                      v-model="externalModels[tag._id]"
                      @change="updateExternal(tag._id)"
                    />
                    <label
                      class="custom-control-label pointer"
                      :for="tag.name + '-external'"
                    ></label>
                  </div>
                </td>
                <td class="align-middle">
                  <div class="custom-control custom-checkbox-toggle">
                    <input
                      class="custom-control-input"
                      type="checkbox"
                      :id="tag.name + '-archived'"
                      :aria-label="`${tag.name} archived status`"
                      v-model="archiveModels[tag._id]"
                      @change="archive(tag)"
                    />
                    <label
                      class="custom-control-label pointer"
                      :for="tag.name + '-archived'"
                    ></label>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MultiSelect from '@/components/MainContent/MultiSelect'

const tagModule = createNamespacedHelpers('tag')

const generateKeywordMask = (value = '') => [value.replace(/[^0-9A-Za-z]/g, '')]

const generateNameMask = (value = '') => {
  const captalized = (value[0] || '').toUpperCase() + value.slice(1)
  return [captalized.split(/\s/).slice(0, 3).join(' ')]
}

export default {
  name: 'TagsTable',

  components: {
    MultiSelect,
  },

  data: () => ({
    wsInstance: null,
    addNewStatus: 'IDLE',
    typeFilter: 'active',
    types: ['active', 'archived'],
    newTagName: '',
    actionModels: {},
    keywordModels: {},
    archiveModels: {},
    externalModels: {},
    descriptionModels: {},
    nameMask: generateNameMask,
    keywordMask: generateKeywordMask,
    actionOptions: [
      'no action',
      'send survey invite',
      'send order link',
      'send menu link',
      'marketing opt-in',
      // 'create auto campaign',
    ],
  }),

  computed: {
    ...tagModule.mapState(['tags']),
  },

  watch: {
    newTagName(name) {
      this.filterTags(name)
    },
    tags: {
      immediate: true,
      handler(newTags) {
        newTags.forEach((t) => {
          this.$set(this.actionModels, t._id, t.action)
          this.$set(this.keywordModels, t._id, t.keyword)
          this.$set(this.descriptionModels, t._id, t.description)
          this.$set(this.externalModels, t._id, !Boolean(t.internal))
          this.$set(this.archiveModels, t._id, Boolean(t.archivedAt))
        })
      },
    },
  },

  async mounted() {
    this.$refs.nameInput.focus()
    try {
      await this.getTags()
    } catch (error) {
      console.error(error)
      this.$notify({
        type: 'error',
        text: 'getting tags failed!',
      })
    }
  },

  methods: {
    ...tagModule.mapActions([
      'getTags',
      'addTag',
      'archiveTag',
      'updateTag',
      'filterTags',
      'activateTagAction',
    ]),

    onWSMessage(message) {
      this.handleWSMessage({ message, notify: this.$notify })
    },

    filter(newFilter) {
      this.typeFilter = newFilter
    },

    async addNew() {
      if (!this.newTagName) return

      try {
        this.addNewStatus = 'PENDING'

        await this.addTag(this.newTagName)

        this.addNewStatus = 'SUCCESS'
        this.cancelAddingNew()
      } catch (error) {
        this.addNewStatus = 'FAILED'
        console.error(error)

        this.$notify({
          type: 'error',
          text: (error.data && error.data.message) || 'Adding new tag failed!',
        })
      }
    },

    cancelAddingNew() {
      this.newTagName = ''
      this.$refs.nameInput.focus()
    },

    async updateKeyword(tagId, prevKeyword) {
      try {
        const keyword = this.keywordModels[tagId]

        if (prevKeyword === keyword) return

        await this.updateTag({ tagId, keyword })

        this.$notify({
          title: 'keyword saved!',
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          type: 'error',
          title: (error.data && error.data.message) || 'updating keyword failed!',
        })
      }
    },

    async updateDescription(tagId, prevDescription) {
      try {
        const description = this.descriptionModels[tagId]

        if (prevDescription === description) return

        await this.updateTag({ tagId, description })

        this.$notify({
          title: 'description saved!',
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          type: 'error',
          title: (error.data && error.data.message) || 'updating description failed!',
        })
      }
    },

    async updateExternal(tagId) {
      try {
        const internal = !this.externalModels[tagId]

        await this.updateTag({ tagId, internal })

        this.$notify({
          title: 'external status saved!',
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          type: 'error',
          title: (error.data && error.data.message) || 'updating external failed!',
        })
      }
    },

    async archive(tag) {
      try {
        const { _id: tagId } = tag
        const archived = archiveModels[tagId]
        archived ? await this.archiveTag(tagId) : await this.updateTag({ tagId, archivedAt: null })
      } catch (error) {
        console.error(error)

        this.$notify({
          type: 'error',
          title: `Archiving ${tag.name} failed!`,
        })
      }
    },

    async activateAction(action, tag) {
      const { _id: tagId } = tag

      try {
        await this.activateTagAction({ tagId, action })

        this.$notify({
          title: `action activated`,
        })
      } catch (error) {
        console.error(error)
        this.$notify({
          type: 'error',
          title: `activating action failed!`,
        })
      }
    },
  },
}
</script>

<style scoped lang="scss">
.badge-size {
  font-size: 100%;
}
.qr-codes-btn {
  height: 29px;
}
.btn-disabled {
  pointer-events: none;
}
.name {
  &::first-letter {
    text-transform: uppercase;
  }
}
.keyword-input {
  text-transform: uppercase;
  &::-webkit-input-placeholder {
    /* WebKit browsers */
    text-transform: none;
  }
  &:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    text-transform: none;
  }
  &::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    text-transform: none;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10+ */
    text-transform: none;
  }
  &::placeholder {
    /* Recent browsers */
    text-transform: none;
  }
}
/deep/ .multiselect {
  .multiselect__tags {
    border: none !important;
    padding-left: 0;
    padding-right: 0;
    width: 150px;

    .multiselect__single {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .multiselect__content-wrapper {
    border-top: 1px solid #e8e8e8;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
</style>
