<template>
  <div v-if="translationTemplates.length > 0">
    <div class="table-responsive">
      <table class="table table-sm table-nowrap card-table shadow-sm p-3 mb-5 bg-white rounded">
        <thead>
          <tr>
            <th>TRANSLATION TEMPLATE ID</th>
            <th>LANGUAGE</th>
            <th>REGION</th>
            <th>EMAIL TEMPLATE NAME</th>
            <th>EMAIL SUBJECT</th>
            <th>EMAIL MESSAGE PREVIEW</th>
            <th class="text-center"></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="translationTemplate in translationTemplates" :key="translationTemplate._id">
            <td>{{ translationTemplate._id }}</td>
            <td>{{ translationTemplate.language }}</td>
            <td>{{ translationTemplate.region || '—' }}</td>
            <td>{{ translationTemplate.strings[0] && translationTemplate.strings[0].v }}</td>
            <td>
              {{ truncateText(translationTemplate.strings[1] && translationTemplate.strings[1].v) }}
            </td>
            <td>
              {{ truncateText(translationTemplate.strings[2] && translationTemplate.strings[2].v) }}
            </td>
            <td class="d-flex flex-row justify-content-end align-items-center">
              <div class="d-flex flex-wrap align-content-center">
                <a href="#!" class="trash-icon">
                  <span
                    style="font-size: 20px; color: black"
                    class="fe fe-edit mr-4"
                    @click.prevent="handleUpdate(translationTemplate)"
                  ></span>
                </a>
                <a href="#!" class="trash-icon">
                  <span
                    style="font-size: 20px; color: black"
                    class="fe fe-trash"
                    @click.prevent="handleDelete(translationTemplate)"
                  ></span>
                </a>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TranslationTemplateTable',
  props: ['translationTemplates'],
  methods: {
    handleDelete(template) {
      this.$emit('deleteTranslation', template)
    },
    handleUpdate(template) {
      this.$emit('updateTranslation', template)
    },
    truncateText(text) {
      return text 
        ? text.length > 20
          ? text.slice(0, 20) + '...' 
          : text
        : ''
    },
  },
}
</script>
