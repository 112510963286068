<template>
  <div class="ivr-step">
    <div
      class="card"
      :style="
        hasError
          ? 'border-radius: 5px; border-color: #e63757; border-width:2px'
          : 'border-radius: 5px'
      "
    >
      <div class="ma-1">
        <div
          class="row no-gutters justify-center"
          style="
            background-color: #edf2f9;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
          "
        >
          <div class="col-auto align-self-center">
            <h4 class="mt-2 ml-4 mr-4" style="color: #95aac9">
              {{ `Step ${parseInt(index) + 1}` }}
            </h4>
          </div>
          <div class="col-4">
            <select class="form-control custom-select my-2" v-model="selectedVerb">
              <option>None</option>
              <option v-for="verb of allowedVerbs" :key="verb">
                {{ verb }}
              </option>
            </select>
          </div>
          <div class="col align-self-center align-end d-flex ml-auto">
            <div class="ml-auto">
              <i class="fe fe-trash-2 text-danger icon-large mr-4" @click="$emit('deleteStep')"></i>
            </div>
          </div>
        </div>

        <IvrForward
          v-if="selectedVerb === 'Forward'"
          :step="step"
          class="mt-3"
          @forwardUpdate="stepUpdate"
        />
        <IvrTransfer
          v-else-if="selectedVerb === 'Transfer'"
          :step="step"
          class="mt-3"
          @transferUpdate="stepUpdate"
        />
        <IvrGather
          v-else-if="selectedVerb === 'Gather'"
          class="mt-3"
          :step="step"
          :errors="errors"
          :uploadPath="uploadPath"
          :invalidOption="invalidOption"
          :landlineSteps="landlineSteps"
          @gatherUpdate="stepUpdate"
          @invalidOptionUpdate="invalidOptionUpdate"
          @landlineStepUpdate="landlineStepUpdate"
          @addLandlineStep="addLandlineStep"
          @deleteLandlineStep="deleteLandlineStep"
        />
        <IvrPlayAudio
          v-else-if="selectedVerb === 'PlayAudio'"
          class="mt-3 ml-1"
          :uploadPath="uploadPath"
          :name="`${step.verb}Step-${step.uuid}`"
          :url="step.url"
          @playAudioUpdate="stepUpdate"
        />
        <IvrSpeakSentence
          v-else-if="selectedVerb === 'SpeakSentence'"
          @speakSentenceUpdate="stepUpdate"
          :sentence="step.sentence"
          :voice="step.voice"
          :gender="step.gender"
          :locale="step.locale"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import IvrForward from './IvrForward.vue'
import IvrGather from './IvrGather.vue'
import IvrPlayAudio from './IvrPlayAudio.vue'
import IvrSpeakSentence from './IvrSpeakSentence.vue'
import IvrTransfer from './IvrTransfer.vue'

const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'IvrStep',
  props: [
    'step',
    'steps',
    'index',
    'uploadPath',
    'verbs',
    'invalidOption',
    'landlineSteps',
    'errors',
  ],
  components: { IvrForward, IvrGather, IvrPlayAudio, IvrSpeakSentence, IvrTransfer },
  data: () => ({
    selectedVerb: null,
  }),
  computed: {
    allowedVerbs() {
      const gatherIndex = this.steps?.map((s) => s.verb)?.indexOf('Gather')
      const forwardIndex = this.steps?.map((s) => s.verb)?.indexOf('Forward')
      const transferIndex = this.steps?.map((s) => s.verb)?.indexOf('Transfer')

      if (this.index < gatherIndex || this.index < forwardIndex || this.index < transferIndex) {
        return this.verbs.filter((v) => !['Forward', 'Gather', 'Transfer'].includes(v))
      }

      if (
        (gatherIndex >= 0 && this.index > gatherIndex) ||
        (forwardIndex >= 0 && this.index > forwardIndex) ||
        (transferIndex >= 0 && this.index > transferIndex)
      ) {
        return []
      }

      if (this.index === 0) {
        return this.verbs.filter((v) => v !== 'Gather')
      }

      return [...this.verbs]
    },
    hasError() {
      if (this.errors?.includes(this.step.uuid)) {
        return true
      }

      if (this.step?.options?.length) {
        for (var option of this.step.options) {
          if (this.errors?.includes(option.uuid)) {
            return true
          }
        }
      }

      return false
    },
  },
  methods: {
    addLandlineStep(i) {
      this.$emit('addLandlineStep', i)
    },
    deleteLandlineStep(uuid) {
      this.$emit('deleteLandlineStep', uuid)
    },
    landlineStepUpdate(event) {
      this.$emit('landlineStepUpdate', event)
    },
    invalidOptionUpdate(modifications) {
      this.$emit('invalidOptionUpdate', {
        ...modifications,
      })
    },
    stepUpdate(modifications) {
      this.$emit('stepUpdate', {
        ...modifications,
        verb: this.selectedVerb,
      })
    },
  },
  mounted() {
    this.selectedVerb = this.step.verb
  },
}
</script>
