<template>
  <nav
    class="navbar navbar-vertical fixed-left navbar-expand-md navbar-dark bg-vibrant"
    id="sidebar"
  >
    <div class="container-fluid">
      <!-- Toggler -->
      <button
        class="navbar-toggler"
        type="button"
        data-toggle="collapse"
        data-target="#sidebarCollapse"
        aria-controls="sidebarCollapse"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>

      <!-- Brand -->
      <a class="navbar-brand" href="index.html">
        <img src="/theme/img/logo-symbol.svg" class="navbar-brand-img mx-auto" alt="..." />
      </a>

      <!-- User (xs) -->
      <div class="navbar-user d-md-none">
        <!-- Dropdown -->
        <div class="dropdown">
          <!-- Toggle -->
          <a
            href="#!"
            id="sidebarIcon"
            class="dropdown-toggle"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            <div class="avatar avatar-sm avatar-online">
              <span class="avatar-title rounded-circle">SW</span>
            </div>
          </a>

          <!-- Menu -->
          <div class="dropdown-menu dropdown-menu-right" aria-labelledby="sidebarIcon">
            <a href="#" class="dropdown-item">Logout</a>
          </div>
        </div>
      </div>

      <!-- Collapse -->
      <div class="collapse navbar-collapse" id="sidebarCollapse">
        <!-- Navigation -->
        <ul class="navbar-nav mb-md-4" v-if="isPartner !== undefined">
          <li
            class="nav-item"
            v-for="(navItem, index) in navItems.filter((i) => i.show)"
            :key="index"
          >
            <router-link class="nav-link" :to="navItem.route" active-class="active">
              <i :class="navItem.icon"></i> {{ navItem.title }}
            </router-link>
          </li>
        </ul>

        <!-- Push content down -->
        <div class="mt-auto"></div>

        <!-- User (md) -->
        <div class="navbar-user d-none d-md-flex" id="sidebarUser">
          <!-- Dropup -->
          <div class="dropup">
            <!-- Toggle -->
            <a
              href="#!"
              id="sidebarIconCopy"
              class="dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              <div class="avatar avatar-sm avatar-online">
                <span class="avatar-title rounded-circle" :title="email">
                  <span class="fe fe-user"></span>
                </span>
              </div>
            </a>

            <!-- Menu -->
            <div class="dropdown-menu" aria-labelledby="sidebarIconCopy">
              <a href="#" class="dropdown-item" @click="logout">Logout</a>
            </div>
          </div>
        </div>
      </div>
      <!-- / .navbar-collapse -->
    </div>
  </nav>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'
import { createNamespacedHelpers } from 'vuex'
const { mapGetters } = createNamespacedHelpers('auth')

export default {
  name: 'SideNav',
  computed: {
    ...mapGetters(['selectEmail', 'selectIsPartner', 'selectIsClassifier']),
    isPartner() {
      return this.selectIsPartner
    },
    isClassifier() {
      return this.selectIsClassifier
    },
    email() {
      return this.selectEmail
    },
    navItems() {
      return [
        {
          title: 'Scorecards',
          route: '/scorecards',
          icon: 'fe fe-activity',
          category: 'main',
          show: this.navShowScorecards,
        },
        {
          title: 'Companies',
          route: '/companies',
          icon: 'fe fe-briefcase',
          category: 'main',
          show: this.navShowCompanies,
        },
        {
          title: 'Locations',
          route: '/locations',
          icon: 'fe fe-map-pin',
          category: 'main',
          show: this.navShowLocations,
        },
        {
          title: 'Users',
          route: '/users',
          icon: 'fe fe-user',
          category: 'main',
          show: this.navShowUsers,
        },
        {
          title: 'Reports',
          route: '/reports',
          icon: 'fe fe-file',
          category: 'main',
          show: this.navShowReports,
        },
        {
          title: 'Integration Partners',
          route: '/integration-partners',
          icon: 'fa fa-handshake mr-3',
          category: 'main',
          show: this.navShowIntegrationPartners,
        },
        {
          title: 'Feature Flags',
          route: '/ff',
          icon: 'fas fa-toggle-off mr-3',
          category: 'main',
          show: this.navShowFeatureFlags,
        },
        {
          title: 'Banners',
          route: '/banners',
          icon: 'far fa-flag mr-3',
          category: 'main',
          show: this.navShowBanners,
        },
        {
          title: 'Classifier',
          route: '/classifier',
          icon: 'fas fa-search-plus mr-3',
          category: 'main',
          show: this.navShowClassifier,
        },
        {
          title: 'Location Map',
          route: '/location-map',
          icon: 'fas fa-globe mr-3',
          category: 'main',
          show: true,
        },
        {
          title: 'API Docs',
          route: '/api-docs',
          icon: 'fas fa-book mr-3',
          category: 'main',
          show: true,
        },
        {
          title: 'Phone Verifications',
          route: '/phone-verifications',
          icon: 'fas fa-phone-volume mr-3',
          category: 'main',
          show: this.navShowClassifier,
        },
        {
          title: 'Customer Tagging ',
          route: '/customer-tagging',
          icon: 'fas fa-users mr-3',
          category: 'main',
          show: true,
        },
      ]
    },
    navShowScorecards() {
      return !this.isClassifier
    },
    navShowCompanies() {
      return !this.isClassifier
    },
    navShowLocations() {
      return !this.isClassifier
    },
    navShowUsers() {
      return !this.isClassifier
    },
    navShowReports() {
      return !this.isPartner && !this.isClassifier
    },
    navShowIntegrationPartners() {
      return !this.isPartner && !this.isClassifier
    },
    navShowFeatureFlags() {
      return !this.isPartner && !this.isClassifier
    },
    navShowBanners() {
      return !this.isPartner && !this.isClassifier
    },
    navShowClassifier() {
      return !this.isPartner
    },
  },
  methods: {
    async logout() {
      await firebase.auth().signOut()
      this.$router.push('/login')
    },
  },
}
</script>

<style scoped lang="scss"></style>
