<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="header mt-md-5">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">Overview</h6>
              <h1 class="header-title display-4">Customer Tagging</h1>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="card">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6 col-xs-12 mt-2">
                  <div class="form-group">
                    <label for="organization">Organization</label>
                    <multiselect
                      class="mb-2"
                      placeholder="Select Organization"
                      :close-on-select="true"
                      :multiple="false"
                      v-model="organization"
                      :searchable="true"
                      :showLabels="false"
                      :options="organizations"
                      :custom-label="organizationName"
                      track-by="_id"
                      @input="generateFilePath"
                      id="organization"
                    />
                  </div>
                </div>
              </div>
              <div v-if="organization" class="row">
                <div class="col-md-6 col-xs-12">
                  <div class="form-group">
                    <label for="organization">Tag Name</label>
                    <input 
                      id="tagName" 
                      type="text" 
                      v-model="tagName" 
                      class="form-control"
                      placeholder="Enter tag name (e.g curbside)"
                    >
                  </div>
                </div>
              </div>
              <div v-if="totalRecords" class="row mt-3 d-flex">
                <div class="col-md-6 col-xs-12">
                  <h3 class="text-primary">
                    {{ totalRecords }} Customers Uploaded
                    <i class="fas fa-check text-success"></i>
                  </h3>
                </div>
              </div>
              <div class="row">
                <div v-if="organization" class="col-md-3 col-xs-12 mt-5 d-flex flex-row">
                  <CSVFileUploader
                    accept="text/csv"
                    @uploaded="handleUploaded"
                    buttonClass="btn-default"
                  />
                  <div class="mx-6" v-if="totalRecords">
                    <button
                      @click.prevent="uploadFileToS3"
                      class="btn btn-primary d-flex flex-row align-items-center"
                      :disabled="!tagName || !organization"
                    >
                      <span v-if="isLoading" class="loader loader-white loader-sm mr-3" />
                      {{isLoading ? operationStatus : 'Tag Customers'}}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import PapaParse from 'papaparse'
import Multiselect from 'vue-multiselect'
import CSVFileUploader from '../components/Modules/files/CSVFileUploader'

const S3Module = createNamespacedHelpers('s3')
const TagModule = createNamespacedHelpers('tag')
const OrganizationModule = createNamespacedHelpers('organization')

export default {
  name: 'CustomerTaggingPage',
  components: {
    Multiselect,
    CSVFileUploader,
  },
  data() {
    return {
      tagName: '',
      filePath: '',
      fileName: '',
      csvData: '',
      totalRecords: 0,
      isLoading: false,
      organization: '',
      operationStatus: 'Uploading...'
    }
  },
  computed: {
    ...OrganizationModule.mapGetters(['selectOrganizations']),

    bucketName() {
      const stage = process.env.NODE_ENV
      return stage === 'test' ? `ovation-secure-sandbox` : `ovation-secure-${stage}`
    },
    organizations() {
      return this.selectOrganizations
    },
  },
  methods: {
    ...S3Module.mapActions(['uploadFile']),
    ...TagModule.mapActions(['bulkImportCustomers']),
    ...OrganizationModule.mapActions(['fetchOrganizations']),

    organizationName(org) {
      return org.friendlyName || org.name
    },
    generateFilePath(org) {
      if (org) {
        const fileName = `${uuidv4()}.csv`
        const organizationId = org._id
        const filePath = `organizations/${organizationId}/bulk-tag-import/${fileName}`
        this.filePath = filePath
        this.fileName = fileName
      }
    },
    validateInput(data){
      let isValid = true
      if(!data || !data.length){
        this.$notify({
          title: 'Oops',
          type: 'error',
          text: 'Something went wrong, invalid file contents.'
        })
        isValid = false
      } else {
        data.forEach(({ phone }, index) => {
          if(!phone || !this.validatePhoneNumber(phone)){
            this.$notify({
              title: 'Validation Error',
              type: 'error',
              text: `Invalid phone number at index ${index +1}`,
              duration: 4000,
            })
            isValid = false
          }
        })
      }
      return isValid
    },
    handleUploaded({ data, headers }) {
      try {
        this.totalRecords = 0
        if(!this.validateInput(data)) return
        const csv = PapaParse.unparse(data)
        this.totalRecords = data.length
        this.csvData = csv
      } catch (error) {
        this.$notify({
          title: 'Unexpected Error',
          type: 'error',
          text: `Something went wrong. Please trying again.`
        })
        console.error(error)
      }
    },
    validatePhoneNumber(phoneNumber) {
      const usPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      const canadaPattern = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
      return usPattern.test(phoneNumber) || canadaPattern.test(phoneNumber)
    },
    async uploadFileToS3(){
      this.isLoading = true
      try {
        if(
          !this.bucketName || 
          !this.filePath || 
          !this.csvData || 
          !this.fileName || 
          !this.tagName ||
          !this.organization._id
        ) throw new Error('Unexpected state: validation failed')

        const bucketParams = {
          Bucket: this.bucketName,
          Key: this.filePath,
          Body: this.csvData,
          ContentType: 'text/csv',
        }

        const payload = {
          tagName: this.tagName,
          fileName: this.fileName,
          organizationId: this.organization._id
        }

        await this.uploadFile(bucketParams)
        this.operationStatus = 'Tagging customers...'
        await this.bulkImportCustomers(payload)
        
        this.$notify({
          title: 'Success!',
          text: 'Customers tagged successfully'
        })
        this.resetState()
      } catch (error) {
        this.$notify({
          title: 'Unexpected Error',
          type: 'error',
          text: 'Something went wrong. Please trying again.'
        })
        console.error(error)
      }
      this.isLoading = false
    },
    resetState(){
      this.fileName = ''
      this.filePath = ''
      this.organization = null
      this.isLoading = false
      this.csvData = ''
      this.totalRecords = 0
      this.operationStatus = 'Uploading...'
    }
  },
  async mounted() {
    try {
      await this.fetchOrganizations()
    } catch (error) {
      console.error(error)
    }
  },
}
</script>

<style lang="scss" scoped></style>
