<template>
  <div class="card">
    <div class="card-body">
      <div class="row">
        <div class="cta-logo">
          <div class="clearfix">
            <b-img
              v-if="cta.logo"
              alt="Left image logo"
              style="width: 40px; border-radius: 5px"
              left
              :src="cta.logo"
            >
            </b-img>
            <div v-else class="no-logo text-muted">
              <span style="border-radius: 5px; font-size: 40px" class="fa fa-image"></span>
            </div>
          </div>
        </div>
        <div class="col-8 cta-title">
          <div class="h3 header-title">
            {{ cta.action }}
          </div>
          <small>
            <a target="_blank" :href="cta.url" class="text-muted" style="text-decoration: none">
              {{ cta.url }}
            </a>
          </small>
        </div>
        <div class="col cta-action d-flex justify-content-end align-items-center">
          <!-- Dropdown -->
          <div class="dropdown">
            <a
              href="#!"
              class="dropdown-ellipses dropdown-toggle"
              role="button"
              data-toggle="dropdown"
              aria-haspopup="true"
              data-expanded="false">
              <i class="fe fe-more-vertical"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-right">
              <a href="#!" class="dropdown-item" v-on:click.prevent="$emit('editCTA', cta)">
                Edit
              </a>
              <a
                href="#!"
                class="dropdown-item text-danger"
                v-on:click.prevent="$emit('deleteCTA', cta._id)">
                Delete
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'LocationCustomCTA',
  props: {
    cta: {
      type: Object,
      required: true,
    },
  },
}
</script>
