<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="header mt-md-5">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h1 class="header-title display-4">Group Creator</h1>
              <div class="spinner-border" role="status">
              </div>
            </div>
            <div class="col-auto">
              <button class="btn btn-primary" @click.prevent="saveGroup">
                {{ isLoading ? 'Working on it...' : isEditing ? 'Update Group' : 'Save Group' }}
              </button>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <h3 class="mt-3 text-capitalize">{{ companyName }}</h3>
          </div>
          <div class="col-auto">
            <h5 class="mt-3">Unassigned Locations: {{ unAssignedCount }}</h5>
          </div>
        </div>
      </div>
      <div>
        <div class="row mt-5">
          <div class="col-md-3 col-sm-12 col-xs-12">
            <div class="form-group">
              <label for="group-name">Group/Hierarchy Name</label>
              <!-- group-name -->
              <input
                class="form-control"
                type="text"
                v-model="group.name"
                placeholder="Enter group name"
                @input="initTree"/>
              <!-- group-name end -->
            </div>
          </div>
          <div class="col-md-3  col-sm-12 col-xs-12" 
            v-if="group.name.length && !isEditing">
            <!-- group type selector -->
            <div class="form-group">
              <label for="group-name">Group Type </label>
              <select
                class="form-select form-control" 
                aria-label="Select group type"
                @input="handleGroupTypeSelection">
                <option selected>Select group type</option>
                <option value="singular">Single Level</option>
                <option value="hierarchy">Multiple Levels</option>
              </select>
            </div>
          </div>
          <div class="col-md-3 col-sm-12 col-xs-12">
            <GroupLevelNamesInput :levelNames="stringFormattedLevelNames"
            v-if="isEditingLevelNames && (groupType && groupType !== 'singular')"
            @handleLevelNamesUpdate="handleLevelNamesUpdate"/>

            <!-- level names display -->
            <div class="row" v-if="!isEditingLevelNames">
              <div class="col">
                <label for="group-name">Level Names </label>
                <multiselect
                  :disabled="true"
                  v-model="group.levelNames"
                  :multiple="true"
                  selectLabel=""
                  :options="levelOptions"
                  track-by="value"
                  @input="initTree"
                  placeholder="Select level"/>
              </div>
              <div class="col-auto" style="margin-top: 30px; margin-left: -20px">
                <button
                :disabled="isEditing"
                class="btn btn-outline-primary px-4" type="button" 
                @click.prevent="editLevelNames">Change</button>
              </div>
            </div>
            <!-- level names display end -->
          </div>
        </div>
        <div class="row mt-5 mb-4">
          <div class="col">
            <Finder
              class="border"
              :tree="tree"
              :theme="theme"
              :dragEnabled="true"
              @move="handleOnMove"
              :sortBy="sortNodeItemFirst"
              :defaultExpanded="defaultExpandedGroup"
              :item-component="GroupLevelItem" 
              v-if="!isEditingLevelNames || groupType === 'singular'"
              id="vueFinder"/>
          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import GroupLevelItem from '../components/Modules/groups/GroupLevelItem.vue'
import { Finder } from '@jledentu/vue-finder'
import '@jledentu/vue-finder/dist/vue-finder.css' // vue-finder styling
import GroupLevelNamesInput from '../components/Modules/groups/GroupLevelNamesInput.vue'
import Multiselect from 'vue-multiselect'
import GroupBase from '../components/Modules/groups/GroupBase.vue'

const CompanyModule = createNamespacedHelpers('company')
const GroupModule = createNamespacedHelpers('group')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'GroupCreator',
  extends: GroupBase,
  components: {
    Finder,
    multiselect: Multiselect,
    GroupLevelNamesInput
  },
  data() {
    return {
      group: {
        name: '',
        region: '',
        levelNames: [],
      },
      theme: {
        primaryColor: '#3d9aeb',
        arrowColor: 'black',
        separatorColor: '#ccc',
        separatorWidth: '1px',
        dropZoneBgColor: 'rgba(33, 150, 243, 0.2)',
        draggedItemBgColor: 'rgba(33, 150, 243, 0.5)',
      },
      isLoading: false,
      levelOptions: [],
      isEditingLevelNames: true,
      GroupLevelItem: GroupLevelItem,
    }
  },
  computed: {
    ...GroupModule.mapGetters([
      'selectTree',
      'selectIsEditing',
      'selectHttpResponse',
      'selectPlaceHolderIds',
      'selectGroupCreationSteps', 
      'selectAssignedLocations']),
    ...CompanyModule.mapGetters(['selectActiveCompany']),
    ...LocationModule.mapGetters(['selectCompanyLocations']),

    locations() {
      return this.selectCompanyLocations
    },
    isEditing(){
      return this.selectIsEditing
    },
    activeCompany() {
      return this.selectActiveCompany
    },
    creationSteps(){
      return this.selectGroupCreationSteps
    },
    companyName() {
      let name = ''
      if(this.activeCompany){
        name = this.activeCompany.friendlyName || this.activeCompany.name
      }
      return name 
    },
    httpResponse() {
      return this.selectHttpResponse
    },
    unAssignedCount(){
      return this.selectCompanyLocations.length - this.selectAssignedLocations.length
    },
    placeHolderIds() {
      return this.selectPlaceHolderIds
    },
    defaultExpandedGroup(){
      return this.tree.children && this.tree.children.length > 0 ? this.tree.children[0].id : null
    },
    stringFormattedLevelNames(){
      return this.group.levelNames.join(', ')
    }
  },
  methods: {
    ...GroupModule.mapActions([
      'setGroupType',
      'initializeTree',
      'deleteGroupById',
      'setRootProperties',
      'updateAndSaveGroup',
      'createAndSaveGroup',
      'setGroupCreationSteps',
    ]),
    ...CompanyModule.mapActions(['getCompany']),
    ...LocationModule.mapActions(['getCompanyLocations']),

    sortNodeItemFirst(a, b) {
      if(a.type === 'placeholder'){
        return 1
      }
      else if(b.type === 'placeholder'){
        return -1
      }
    },
    initTree(){
      if(this.group.levelNames.length && this.group.name.length ){
        // only initialize tree if the group is new
        if(!this.isEditing){
          this.initializeTree({
            id: 'root',
            type: "hierarchy",
            name: this.group.name,
            companyId: this.activeCompany._id,
            memberType: 'location',
            levelNames: this.group.levelNames,
            children: [
              {
                id: this.generatePlaceholderId(),
                type: 'placeholder',
                name: this.group.levelNames[0],
                groupLevel: this.group.levelNames[0],
              },
            ]
          })
        }
        else {
          this.setRootProperties({
            name: this.group.name,
            levelNames: this.group.levelNames,
          })
        }
        // set the next creation level step
        this.setGroupCreationSteps(this.group.levelNames)
      }

      // update group name for singular group type
      if(this.isEditing && this.groupType === 'singular'){
        this.setRootProperties({ name: this.group.name })
      }
    },
    handleOnMove({ moved: item, to, index  }){
      const result = this.handleDragAndDrop({ to, item, tree: this.tree })
      this.updateTree(result)
    },
    editLevelNames(){
      this.isEditingLevelNames = true
    },
    async saveGroup(){
      this.isLoading = true
      // prepare the tree for transfer
      // first, remove all the placeholder ids 
      let result = this.removePlaceholderId(this.tree, this.placeHolderIds)
      // second, remove all the empty properties
      result = this.removeEmptyProperties(result)

      if(result){
        try{
          if(this.isEditing){
            await this.updateAndSaveGroup(result)
          }
          else {
            await this.createAndSaveGroup(result)
          }

          const response = this.httpResponse

          if(response && response.body.success){
            // notify the user
            this.$notify({
              type: 'success',
              title: 'Success',
              text: `The group was ${ this.isEditing ? 
              'updated' : 'created' } successfully.`
            })
            
            // reset the tree
            this.updateTree({})

            // go to company details page 
            this.$router.push({
              name: 'company-detail', 
              params: { id: this.activeCompany._id }
            })
          }
        }
        catch(error){
          this.$notify({
            type: "error",
            title: "Error",
            text: `Oops, unable to ${ this.isEditing ? 'update group' : 
            'create new group'}. please try again.`
          })
        }
      }

      this.isLoading = false
    },
    handleLevelNamesUpdate(names){
      this.group.levelNames = names
      this.levelOptions = names
      this.isEditingLevelNames = false
      // initialize the tree
      this.initTree()
    },
    handleGroupTypeSelection(event){
      const type = event.target.value
      // initialize singular group
      if(type === 'singular'){
        this.initializeTree({
          id: 'root',
          type: 'singular',
          name: this.group.name,
          members: [],
          companyId: this.activeCompany._id,
          memberType: 'location',
          children: [{
            id: this.generatePlaceholderId(),
            type: 'placeholder',
            name: 'Location',
            kind: 'locations',
          }]
        })
        // set the group type
        this.setGroupType(type)
      }
      else {
        // set the group type
        this.setGroupType(type)
      }
    }
  },
  async mounted(){
    await Promise.all([
      await this.getCompany(this.$route.params.id),
      await this.getCompanyLocations(this.$route.params.id),
    ])

    if(this.tree.name){
      this.group.name = this.tree.name
    }

    if (this.tree.levelNames && this.tree.levelNames.length){
      this.isEditingLevelNames = false
      this.group.levelNames = this.tree.levelNames
    }
  }
}
</script>
<style scoped lang="scss">
  .fe:hover{
    color: blue;
    cursor: pointer;
  }
  #vueFinder {
    border-radius: 10px; 
    box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.2);
  }
</style>