import UserService from '@/services/UserService'
import OrganizationService from "@/services/OrganizationService"

export default {
  namespaced: true,
  state: {
    httpResponse: null,
    organizations: [],
    userToAssignOrg: null,
  },
  getters: {
    selectOrganizations: (state) => {
      return state.organizations
    },
    selectHttpResponse: (state) => {
      return state.httpResponse
    },
    selectUserToAssignOrg: (state) => {
      return state.userToAssignOrg
    },
  },
  mutations: {
    SET_ORGANIZATIONS: (state, organizations) => {
      state.organizations = [...organizations]
    },
    SET_HTTP_RESPONSE: (state, response) => {
      state.httpResponse = response
    },
    SET_USER_TO_ASSIGN_ORG: (state, user) => {
      state.userToAssignOrg = user
    }
  },
  actions: {
    setUserToAssignOrg({ commit }, userId) {
      commit('SET_USER_TO_ASSIGN_ORG', userId)
    },
    async fetchOrganizations({ commit }) {
      const response = await OrganizationService.list()

      if(response.data){
        commit('SET_ORGANIZATIONS', response.data)
      }
    },
    async assignUserToOrganization({ commit}, payload){
      const response = await UserService.assignOrganization(payload)
      commit('SET_HTTP_RESPONSE', response)
    }
  }
}