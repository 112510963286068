<template>
  <div class="ivr-forward">
    <div class="row no-gutters">
      <div class="col-auto ml-4 mr-3 align-self-center">
        <label for="forwardTo" style="color: #95aac9">Forward To</label>
      </div>
      <div class="col align-self-center mb-3">
        <IvrDynamicFieldPickerVue @update="forwardTo = $event" :value="forwardTo">
          <template v-slot:constant>
            <input
              id="forwardTo"
              v-model="forwardTo"
              type="text"
              ref="phone"
              class="form-control"
              placeholder="+1 (000) 000-0000"
              v-mask="'+# (###) ###-####'"
            />
          </template>
        </IvrDynamicFieldPickerVue>
      </div>
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import IvrDynamicFieldPickerVue from './IvrDynamicFieldPicker.vue'

const IvrModule = createNamespacedHelpers('ivr')

export default {
  name: 'IvrForward',
  props: ['step'],
  components: { IvrDynamicFieldPickerVue },
  data: () => ({
    forwardTo: null,
  }),
  watch: {
    forwardTo(val) {
      this.emitUpdate()
    },
  },
  computed: {
    ...IvrModule.mapGetters(['selectIvrDynamicFields'])
  },
  methods: {
    emitUpdate() {
      let val = this.forwardTo
      if (val && !this.selectIvrDynamicFields.includes(val)) {
        val = `+${val.replace(/\D+/g, '')}`
      }
      this.$emit('forwardUpdate', { forwardTo: val })
    },
  },
  mounted() {
    if (this.step) {
      this.forwardTo = this.step.forwardTo
    }
    this.emitUpdate()
  },
}
</script>
<style scoped>
input.mediumCheckbox {
  height: 18px;
  width: 18px;
}
</style>
