<template>
  <b-modal
    id="SubscriptionModal"
    hide-footer
    hide-header
    centered
    body-class="p-0"
    size="lg"
    @shown="showing = !showing"
    @hidden="clearState"
  >
    <div class="px-4 pt-4">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h1>{{ typeVerb }} Subscription</h1>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- body -->
      <div class="my-4" v-if="showing">
        <div
          class="btn-group btn-group-sm mt--3 mb-4 d-flex w-100"
          role="group"
          v-if="typeVerb === 'Create'"
        >
          <button
            class="btn btn-light w-100"
            :class="{ active: filter === 'new_subscription' }"
            @click="filter = 'new_subscription'"
          >
            New Subscription
          </button>
          <button
            class="btn btn-light w-100"
            :class="{ active: filter === 'existing_subscription' }"
            @click="filter = 'existing_subscription'"
          >
            Existing Subscription
          </button>
        </div>
        <div class="row">
          <!-- stripesubscriptionid -->
          <div class="col-4" v-if="filter === 'existing_subscription'">
            <h4>Stripe Subscription Id</h4>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                placeholder="Enter stripe subscription id"
                v-model="subscription.stripe.subscriptionId"
              />
            </div>
          </div>

          <!-- nickname -->
          <div class="col-4">
            <h4>Nickname</h4>
            <div class="input-group">
              <input
                class="form-control"
                type="text"
                placeholder="Enter nickname"
                v-model="subscription.nickName"
              />
            </div>
          </div>

          <!-- type -->
          <div class="col-4 text-center">
            <span class="text-left" style="width: 110px">
              <h4>Subscription Type</h4>
              <div class="dropdown" id="type-dropdown">
                <button class="btn btn-white dropdown-toggle" type="button" data-toggle="dropdown">
                  {{ subscription.type || 'Select Type' }}
                </button>
                <div class="dropdown-menu">
                  <span class="dropdown-item" @click="subscription.type = 'company'">Company</span>
                  <span class="dropdown-item" @click="subscription.type = 'location'">
                    Location
                  </span>
                </div>
              </div>
            </span>
          </div>

          <!-- locations -->
          <div
            :class="{ 'mt-3': filter === 'existing_subscription' }"
            class="col-4"
            v-show="subscription.type === 'location'"
          >
            <h4>Locations</h4>
            <MultiSelect
              v-model="subscription.locations"
              :options="selectCompanyLocations"
              placeholder="Select Locations"
              :searchable="true"
              :closeOnSelect="false"
              :multiple="true"
              :show-labels="false"
              :limitText="(count) => `+${count} more`"
              :limit="1"
              :custom-label="locationName"
              track-by="_id"
            >
              <template slot="option" slot-scope="{ option }">
                <span class="custom__tag">
                  <span>{{ option.friendlyName || option.city || option.name }}</span>
                </span>
              </template>
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.friendlyName || option.city || option.name }}
              </template>
            </MultiSelect>
          </div>
        </div>

        <!-- point of contact -->
        <div class="row mt-4">
          <div class="col">
            <h3 class="mb-1">Point of Contact</h3>
            <span>
              This person will receive emails about the subscription such as reminders about
              payments, failed payments, etc.
            </span>
            <div class="d-flex justify-content-between mt-3">
              <!-- names -->
              <div id="contact-names" style="min-width: 30%">
                <h4>Full Name</h4>
                <div
                  class="mt-2"
                  v-for="(contact, index) of subscription.contacts"
                  :key="'name' + index"
                >
                  <div class="input-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter nickname"
                      v-model="contact['name']"
                    />
                  </div>
                </div>
              </div>
              <!-- emails -->
              <div id="contact-emails" style="min-width: 30%">
                <h4>Email</h4>
                <div
                  class="mt-2"
                  v-for="(contact, index) of subscription.contacts"
                  :key="'email' + index"
                >
                  <div class="input-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Email"
                      v-model="contact['email']"
                    />
                  </div>
                </div>
              </div>
              <!-- phones -->
              <div id="contact-phones" style="min-width: 33%">
                <h4>Phone Number</h4>
                <div
                  class="mt-2 d-flex"
                  v-for="(contact, index) of subscription.contacts"
                  :key="'phone' + index"
                >
                  <div class="input-group">
                    <input
                      class="form-control"
                      type="text"
                      placeholder="Enter Phone Number"
                      v-model="contact['phone']"
                    />
                  </div>
                  <i
                    class="far fa-trash-alt text-danger fa-lg ml-3 mt-3"
                    v-if="subscription.contacts && subscription.contacts.length > 1"
                    @click="removeFromArray(subscription.contacts, index)"
                  ></i>
                </div>
              </div>
            </div>
            <button
              class="btn btn-link btn-link-primary"
              @click="addAdditional(subscription.contacts)"
            >
              + Add additional emails
            </button>
          </div>
        </div>

        <!-- products -->
        <div class="row mt-4" v-if="filter !== 'existing_subscription'">
          <div class="col">
            <h3 class="mb-1">Products</h3>
            <span>
              If company wide, these will apply to all locations, otherwise it will only apply to
              selected Locations.
            </span>
            <div class="d-flex justify-content-between mt-3">
              <!-- product -->
              <div id="product" style="min-width: 30%">
                <h4>Product</h4>
                <div
                  class="mt-2"
                  v-for="(product, index) of subscription.stripe.products"
                  :key="'product' + index"
                >
                  <div class="dropdown" id="type-dropdown">
                    <button
                      class="btn btn-white dropdown-toggle text-left"
                      style="min-width: 225px"
                      type="button"
                      data-toggle="dropdown"
                    >
                      {{ product.name || 'Select Product' }}
                    </button>
                    <div class="dropdown-menu" style="max-height: 500px; overflow: scroll">
                      <span
                        class="dropdown-item"
                        v-for="rawProduct of selectProducts"
                        :key="rawProduct.id"
                        @click="setProduct(rawProduct, index)"
                      >
                        {{ rawProduct.name }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- quantity -->
              <div id="product-quantity" style="min-width: 30%">
                <h4>Quantity</h4>
                <div
                  class="mt-2"
                  v-for="(product, index) of subscription.stripe.products"
                  :key="'quantity' + index"
                >
                  <div class="input-group">
                    <input
                      class="form-control"
                      type="number"
                      placeholder="Enter Quanity"
                      v-model="product['quantity']"
                    />
                  </div>
                </div>
              </div>
              <!-- cost -->
              <div id="product-price" style="min-width: 33%">
                <h4>Cost</h4>
                <div
                  class="mt-2 d-flex"
                  v-for="(product, index) of subscription.stripe.products"
                  :key="'price' + index"
                >
                  <div
                    class="w-100"
                    v-if="product.id && !productPricesLoading.includes(product.id)"
                  >
                    <MultiSelect
                      v-model="product['price']"
                      :options="selectProductPrices(product.id)"
                      style="width: 100%"
                      placeholder="Select Price"
                      :searchable="true"
                      :closeOnSelect="true"
                      :show-labels="false"
                      label="name"
                      track-by="id"
                    >
                      <template slot="option" slot-scope="{ option }">
                        <span class="custom__tag">
                          <span>${{ option.unit_amount / 100 }}</span>
                        </span>
                      </template>
                      <template slot="singleLabel" slot-scope="{ option }">
                        ${{ option.unit_amount / 100 }}
                      </template>
                    </MultiSelect>
                  </div>
                  <div class="text-muted d-flex align-items-center" style="height: 40.5px" v-else>
                    <span v-if="!productPricesLoading.includes(product.id)">
                      Select a product first
                    </span>
                    <span class="loader loader-primary" v-else></span>
                  </div>
                  <i
                    class="far fa-trash-alt text-danger fa-lg ml-3 mt-3"
                    v-if="
                      subscription.stripe.products &&
                      subscription.stripe.products.length > 1 &&
                      product.id &&
                      !productPricesLoading.includes(product.id)
                    "
                    @click="removeFromArray(subscription.stripe.products, index)"
                  ></i>
                </div>
              </div>
            </div>
            <button
              class="btn btn-link btn-link-primary"
              @click="addAdditional(subscription.stripe.products)"
            >
              + Add a Service
            </button>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="border-top py-3 text-right">
        <button class="btn btn-light mr-2" @click="close">Cancel</button>
        <button
          class="btn btn-primary"
          style="width: 120px"
          @click="create"
          :disabled="!validInputs"
        >
          <div class="d-flex justify-content-center" v-if="saveLoading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else> {{ typeVerb }} </span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import MultiSelect from '@/components/MainContent/MultiSelect'
import { createNamespacedHelpers } from 'vuex'

export const SubscriptionModule = createNamespacedHelpers('subscription')
export const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'SubscriptionModal',
  props: {
    selectedSubscription: {
      type: Object,
    },
    company: {
      type: Object,
    },
  },
  components: {
    MultiSelect,
  },
  data: () => ({
    showing: false,
    saveLoading: false,
    locations: [],
    subscription: {},
    filter: 'new_subscription',
    productPricesLoading: [],
  }),
  mounted() {
    this.initialize()
  },
  computed: {
    ...LocationModule.mapGetters(['selectCompanyLocations']),
    ...SubscriptionModule.mapGetters([
      'selectProducts',
      'selectProductPrices',
      'selectSubscriptionById',
    ]),
    typeVerb() {
      return this.selectedSubscription ? 'Edit' : 'Create'
    },
    validInputs() {
      return this.subscription.type === 'location' && !this.subscription?.locations?.length
        ? false
        : true
    },
  },
  methods: {
    ...SubscriptionModule.mapActions([
      'createSubscription',
      'updateSubscription',
      'fetchSubscriptions',
      'fetchProductPrices',
    ]),
    close() {
      this.$root.$emit('bv::hide::modal', 'SubscriptionModal')
      this.showing = false
      this.fetchAllSubscriptions()
    },
    setType() {},
    initialize() {
      this.subscription = {
        nickName: '',
        type: '',
        locations: [],
        contacts: [{}],
        stripe: {
          products: [{}],
        },
        prorated: false,
        company: this.company._id,
      }
    },
    async create() {
      try {
        this.saveLoading = true
        // update/create subscription
        if (this.typeVerb === 'Create') {
          this.$notify({ text: 'Adding Subscription...' })
          await this.createSubscription(this.subscription)
          this.$notify({ text: 'Successfully added subscription' })
          this.clearState()
        } else {
          await this.handleUpdateSubscription({
            subscriptionId: this.selectedSubscription._id,
            subscriptionData: this.subscription,
          })
        }
      } catch (error) {
        console.log(error)
        this.$notify({
          title: 'Error adding subscription',
          text: `${error.body?.message || 'See console for details'}`,
          type: 'error',
          duration: 5000,
        })
        this.saveLoading = false
      }
    },
    clearState() {
      this.saveLoading = false
      this.$emit('clearSelectedSubscription')
      this.initialize()
      this.close()
    },
    locationName(location) {
      return location?.friendlyName || location?.city || location?.name
    },
    addAdditional(arr) {
      arr?.push({})
    },
    removeFromArray(arr, index) {
      arr?.splice(index, 1)
    },
    setProduct(product, index) {
      this.subscription.stripe.products[index] = product
      this.subscription = { ...this.subscription }
      this.fetchPrices(product.id)
    },
    async fetchPrices(productId) {
      this.productPricesLoading.push(productId)
      await this.fetchProductPrices({ productId })
      this.productPricesLoading = this.productPricesLoading.filter((id) => id !== productId)
    },
    wasProductsModified(prod1, prod2) {
      return !_.isEqual(prod1, prod2)
    },
    async handleUpdateSubscription({ subscriptionId, subscriptionData }) {
      const { type, locations } = subscriptionData || {}
      if (type === 'company') {
        subscriptionData.company = this.company?._id
        subscriptionData.locations = []
      }
      if (type === 'location' && locations?.length) {
        subscriptionData.company = null
      }

      const productsModified = this.wasProductsModified(
        this.subscription?.stripe?.products?.map((p) => {
          return { ...p, quantity: parseInt(p.quantity) }
        }),
        JSON.parse(localStorage.getItem('originalProducts'))
      )

      if (productsModified) {
        this.showProrateMsgBox({ subscriptionId, subscriptionData })
      } else {
        this.$notify({ text: 'Updating Subscription...' })
        await this.updateSubscription({
          subscriptionId,
          subscriptionData,
        })
        this.$notify({ text: 'Successfully updated subscription' })
        this.clearState()
      }
    },
    showProrateMsgBox({ subscriptionId, subscriptionData } = {}) {
      const self = this
      const message = `
      This subscription has been modified. Would you like to prorate the changes?
      `
      this.$bvModal
        .msgBoxConfirm(message, {
          title: 'Prorate?',
          centered: true,
          okTitle: 'Yes',
          buttonSize: 'sm',
          cancelTitle: 'No',
          hideHeaderClose: true,
        })
        .then(async (value) => {
          if (value === true) {
            subscriptionData.prorated = true
          }
          this.$notify({ text: 'Updating Subscription...' })
          await this.updateSubscription({
            subscriptionId,
            subscriptionData,
          })
          self.$notify({ text: 'Successfully updated subscription' })
          self.clearState()
        })
        .catch((error) => {
          console.error(error)
          this.$notify({
            title: 'Error updating subscription',
            text: `${error.body?.message || 'See console for details'}`,
            type: 'error',
            duration: 5000,
          })
          this.saveLoading = false
        })
    },
    async fetchAllSubscriptions() {
      await this.fetchSubscriptions({
        types: ['company', 'location'],
        companyIds: [this.company._id],
        locationIds: this.selectCompanyLocations.map((l) => l._id),
      })
    },
  },
  watch: {
    filter: {
      immediate: true,
      handler(val) {
        if (val === 'existing_subscription') {
          this.subscription.type = 'company'
        }
      },
    },
    selectedSubscription: {
      immediate: true,
      async handler() {
        if (this.selectedSubscription) {
          this.subscription.nickName = this.selectedSubscription.nickName || ''
          this.subscription.type = this.selectedSubscription.type || ''
          const selectedSubscription = this.selectedSubscription
          const locations = this.selectCompanyLocations?.filter((l) =>
            selectedSubscription?.locations?.includes(l._id)
          )
          _.set(this.subscription, 'contacts', this.selectedSubscription?.contacts || [])
          _.set(this.subscription, 'locations', locations || [])
          _.set(
            this.subscription,
            'stripe.products',
            this.selectedSubscription.stripe?.products || []
          )
          if (this.subscription.stripe?.products?.length) {
            await Promise.all(this.subscription.stripe?.products.map((p) => this.fetchPrices(p.id)))
          }
        }
      },
    },
  },
}
</script>

<style scoped>
.dropdown-toggle:after {
  float: right;
}
</style>
