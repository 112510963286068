<template>
  <div class="location-selector">
    <multiselect
    :disabled="false"
    :custom-label="customLabel"
    v-model="selectedLocations"
    :multiple="true"
    selectLabel=""
    :options="allLocations"
    track-by="_id"
    :closeOnSelect="false"
    @open="handleOpenDropdown"
    @close="handleCloseDropdown"
    @select="assignLocation"
    @remove="unAssignLocation"
    placeholder="Select locations"/>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Multiselect from 'vue-multiselect'

const GroupModule = createNamespacedHelpers('group')
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'LocationSelector',
  components: {
    multiselect: Multiselect,
  },
  data(){
    return {
      selectedLocations: []
    }
  },
  computed: {
    ...GroupModule.mapGetters(['selectcurrentGroupMembers', 'selectAssignedLocations']),
    ...LocationModule.mapGetters(['selectCompanyLocations']),

    allLocations(){
      return this.selectCompanyLocations
      .filter(l => !this.selectAssignedLocations.includes(l._id))
    },
    locationMembers(){
      return this.selectcurrentGroupMembers
    }
  },
  methods: {
    ...GroupModule.mapActions(['setAssignedLocations', 'removeAssignedLocation']),

    customLabel(location){
      return location.friendlyName || location.city
    },
    assignLocation(location){
      this.setAssignedLocations(location._id)
      this.$emit('locationAdded', location._id)
    },
    unAssignLocation(location){
      this.removeAssignedLocation(location._id)
      this.$emit('locationRemoved', location._id)
    },
    handleOpenDropdown(){
      this.$emit('handleOpenDropdown', true)
    },
    handleCloseDropdown(){
      this.$emit('handleOpenDropdown', false)
    }
  },
  watch: {
    locationMembers: function (value) {
      if (value && value.length) {
        let mappedLocations = value
        mappedLocations = mappedLocations.map((locationId) => {
          const found = this.selectCompanyLocations.find(l => l._id === locationId)
          if (found) return found
        })
        this.selectedLocations = mappedLocations
      }
    },
  },
  mounted() {
    if (this.locationMembers && this.locationMembers.length) {
      // map the locationId to locations objects
      let mappedLocations = this.locationMembers
      mappedLocations = mappedLocations.map((locationId) => {
        const found = this.selectCompanyLocations.find(l => l._id === locationId)
        if(found) return found
      })

      this.selectedLocations = mappedLocations
    }
  }
}
</script>