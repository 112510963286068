import Config from '@/config'
import firebase from 'firebase/app'
import 'firebase/auth'
import { getUserToken } from './firebase'

export function interceptor(request) {
  const { api, apiV2, pentaApi } = Config
  const fbAuth = firebase.auth().currentUser
  const fbId = fbAuth && fbAuth.uid
  const isV2Api = request.url.startsWith('v2/')
  request.url = isV2Api
    ? `${apiV2}/${request.url.replace('v2/', '')}`
    : request.url.startsWith('pentaApi/')
    ? `${pentaApi}/${request.url.replace('pentaApi/', '')}`
    : `${api}/${request.url}`

  if (isV2Api) {
    const idToken = getUserToken()
    request.headers.set('Authorization', `Bearer ${idToken}`)
  } else if (fbId) {
    // Maintain backwards compat for nexus summoner and penta apis
    request.headers.set('Authorization', fbId)
  }
}
