import TranslationTemplateService from '../services/TranslationTemplateService'

export default {
  namespaced: true,
  state: {
    isFetchingTemplates: false,
    translationTemplates: [],
    activeTranslationTemplate: null,
  },
  getters: {
    selectTranslationTemplates(state) {
      return state.translationTemplates
    },
    selectIsFetchingTemplates(state) {
      return state.isFetchingTemplates
    },
    selectActiveTranslationTemplate(state) {
      return state.activeTranslationTemplate
    },
  },
  mutations: {
    SET_TRANSLATION_TEMPLATES(state, templates) {
      state.translationTemplates = templates
    },
    SET_IS_FETCHING_TEMPLATES(state, status) {
      state.isFetchingTemplates = status
    },
    SET_ACTIVE_TRANSLATION_TEMPLATE(state, template) {
      state.activeTranslationTemplate = template
    },
  },
  actions: {
    async fetchTranslationTemplates({ commit, rootGetters, dispatch }, payload = {}) {
      dispatch('setIsFetchingTemplates', true)
      const integrationPartnerId = rootGetters['integrationPartner/selectActivePartnerId']
      const response = await TranslationTemplateService.list({
        ...payload,
        integrationPartnerIds: [integrationPartnerId],
      })
      const { translationTemplates } = response?.data?.data
      commit('SET_TRANSLATION_TEMPLATES', translationTemplates)
      dispatch('setIsFetchingTemplates', false)
    },
    async createTranslationTemplate({ dispatch }, payload) {
      const response = await TranslationTemplateService.createTanslationTemplate(payload)
      await dispatch('fetchTranslationTemplates')
      const { translationTemplate } = response?.data?.data
      return translationTemplate
    },
    async updateTranslationTemplate({ dispatch }, payload) {
      await TranslationTemplateService.updateTanslationTemplate(payload)
      await dispatch('fetchTranslationTemplates')
    },
    async deleteTranslationTemplate({ dispatch }, payload) {
      await TranslationTemplateService.deleteTanslationTemplate(payload)
      await dispatch('fetchTranslationTemplates')
    },
    setIsFetchingTemplates({ commit }, status) {
      commit('SET_IS_FETCHING_TEMPLATES', status)
    },
    setActiveTranslationTemplate({ commit }, template) {
      commit('SET_ACTIVE_TRANSLATION_TEMPLATE', template)
    },
  },
}