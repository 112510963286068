import Vue from 'vue'

export default {
  list() {
    return Vue.http.get('tag')
  },
  create(payload) {
    return Vue.http.post('tag', payload)
  },
  update({ tagId, fields }) {
    return Vue.http.patch(`tag/${tagId}`, fields)
  },
  activateAction({ tagId, action }) {
    return Vue.http.post(`tag/${tagId}/action`, { action })
  },
  archive(tagId) {
    return Vue.http.delete(`tag/${tagId}`)
  },
  bulkImport(payload) {
    return Vue.http.post('v2/tags/import', payload)
  },
}
