<template>
  <div class="card">
    <div class="card-header" v-if="orders.length > 0">
      <div class="row align-items-center">
        <div class="col">
          <!-- Search -->
          <form class="row align-items-center">
            <div class="col-auto pr-0">
              <span class="fe fe-search text-muted"></span>
            </div>
            <div class="col">
              <vue-fuse
                class="form-control form-control-flush search"
                :keys="searchable"
                :list="orderedData"
                :defaultAll="true"
                event-name="dataChanged"
              ></vue-fuse>
            </div>
          </form>
        </div>
        <div class="col-auto">
          <!-- Button -->
          <div class="dropdown">
            <!-- <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-white dropdown-toggle" data-toggle="dropdown" id="bulkActionDropdown" type="button">Bulk action</button>
            <div aria-labelledby="bulkActionDropdo  wn" class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" href="#!">Action</a> <a class="dropdown-item" href="#!">Another action</a> <a class="dropdown-item" href="#!">Something else here</a>
            </div>-->
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <div class="table-responsive" v-if="orders.length > 0">
      <table class="table table-sm table-nowrap card-table">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.title">
              <a
                :class="{sort: column.sortable, 'text-muted': sort != column.title}"
                href="#"
                @click="sort = column.title"
              >{{ column.friendly ? column.friendly : column.title }}</a>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in searchResults" :key="row._id">
            <td>
              {{ row.created | moment("MMM Do YYYY") }}
              <span
                v-if="row.partner"
                class="badge badge-soft-primary"
              >{{row.partner.name}}</span>
            </td>
            <td>
              {{(row.location || {}).name}}
              <br>
              <span class="text-muted">{{(row.location || {}).city}}</span>
            </td>
            <td>${{row.amount ? row.amount.toLocaleString() : 0}}</td>
            <td>
              <OrderProductBadges :products="row.items"></OrderProductBadges>
            </td>
            <td>
              {{(row.user || {}).firstName}} {{(row.user || {}).lastName}}
              <br>
              <span class="text-muted">{{(row.user || {}).phone}}</span>
              <br>
              <span class="text-muted">{{(row.user || {}).email}}</span>
            </td>
            <td class="text-center">
              <span>
                <!-- <i v-if="row.shipped" class="fe fe-check-circle text-success icon-large"></i> -->
                <button
                  v-if="row.shipped"
                  type="button"
                  class="btn btn-white btn-sm"
                  style="opacity: .5;"
                >Shipped</button>
                <button
                  v-else
                  type="button"
                  class="btn btn-primary btn-sm"
                  @click="markAsShipped(row._id)"
                >Mark as Shipped</button>
              </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
const { mapMutations, mapState, mapActions } = createNamespacedHelpers('order')
import OrderService from '@/services/OrderService'
import OrderProductBadges from '@/components/Modules/Orders/OrderProductBadges'

export default {
  name: 'OrdersTable',
  components: { OrderProductBadges },
  data: () => {
    return {
      searchResults: [],
      searchable: ['customer', 'location', 'shipped'],
      sort: 'created',
      columns: [
        { title: 'created', sortable: true },
        { title: 'location' },
        { title: 'amount' },
        { title: 'items' },
        { title: 'customer', sortable: true },
        { title: 'shipped', sortable: true }
      ]
    }
  },
  computed: {
    ...mapState(['orders']),
    orderedData: function() {
      return _.orderBy(this.orders, this.sort, 'desc')
    }
  },
  methods: {
    ...mapActions(['getOrdersList']),
    markAsShipped: async function(orderId) {
      console.log('marking as shiupped', orderId)
      await OrderService.shipped(orderId)
      this.getOrdersList()
    }
  },
  mounted() {
    this.getOrdersList()
  },
  created() {
    this.$on('dataChanged', results => {
      this.searchResults = results
    })
  }
}
</script>

<style scoped lang="scss">
.icon-large {
  font-size: 20px;
}
</style>
