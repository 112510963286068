<template>
  <div class="s3-file-uploader">
    <b-button type="file" class="mb-3" variant="primary" @click.prevent="uploadAudio">
      <div class="mx-4">
        <span class="fe fe-upload"></span>
        {{ isUploading ? 'Uploading...' : title || 'Upload File' }}
      </div>
    </b-button>
    <div style="display: none">
      <input :accept="accept" ref="fileOpener" type="file" @change="handleFileUpload" />
    </div>
  </div>
</template>
<script>
import { createNamespacedHelpers, mapGetters } from 'vuex'
import { compressAudio } from '../../../lib/fileCompress'

const S3Module = createNamespacedHelpers('s3')

export default {
  name: 'S3ImageFileUploader',
  props: ['accept', 'filePath', 'title', 'bucketName'],
  data() {
    return {
      isUploading: false,
    }
  },
  computed: {
    ...mapGetters(['selectConfigByName']),
    ...S3Module.mapGetters(['selectUploadProgress']),
  },
  methods: {
    ...S3Module.mapActions(['uploadFile']),
    async uploadAudio() {
      await this.$refs.fileOpener.click()
    },
    async handleFileUpload() {
      this.isUploading = true

      try {
        let file = this.$refs.fileOpener.files[0]
        if (file.type.startsWith('audio')) {
          const compressedFile = await compressAudio(file)
          file = new File([compressedFile], file.name, { type: file.type })
        }

        const bucketParams = {
          Bucket: this.bucketName,
          Key: this.filePath,
          Body: file,
          ContentType: file.type,
        }

        const response = await this.uploadFile(bucketParams)
        this.$emit('uploaded', response.Location)
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Failed to upload file. Please try again.',
        })
      }

      this.isUploading = false
    },
  },
}
</script>
