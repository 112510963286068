<template>
  <b-modal id="AddUserModal" hide-footer hide-header centered body-class="p-0" size="lg">
    <div class="px-4 pt-4">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h1>Create User</h1>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- body -->
      <div class="card-body px-5">
        <div class="row">
          <div class="col">
            <label for="firstName" class="font-weight-bold"> First Name </label>
            <input
              type="text"
              id="firstName"
              v-model="firstName"
              class="form-control mb-5"
              placeholder="John"
            />
          </div>

          <div class="col">
            <label for="lastName" class="font-weight-bold"> Last Name</label>
            <input
              type="text"
              id="lastName"
              v-model="lastName"
              class="form-control mb-5"
              placeholder="Doe"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <label class="font-weight-bold">Timezone</label>
            <MultiSelect
              class="mb-5"
              :options="timezones"
              v-model="timezone"
              :show-labels="false"
              closeOnSelect
            />
          </div>

          <div class="col">
            <label class="font-weight-bold">Partner</label>
            <MultiSelect
              class="mb-5"
              v-model="partner"
              :searchable="true"
              :allow-empty="true"
              :show-labels="false"
              placeholder="Select partner (optional)"
              :options="selectPartners"
              label="name"
              track-by="_id"
            >
              <template slot="singleLabel" slot-scope="{ option }">
                {{ option.name }}
              </template>
            </MultiSelect>
          </div>
        </div>

        <label for="email" class="font-weight-bold">Email Address</label>
        <input
          id="email"
          v-model="email"
          type="email"
          class="form-control mb-5"
          placeholder="name@address.com"
        />

        <label>Mobile Number</label>
        <input
          v-model="phone"
          type="text"
          ref="phone"
          class="form-control mb-3"
          placeholder="(000) 000-0000"
          v-mask="'(###) ###-####'"
        />
      </div>

      <!-- footer -->
      <div class="border-top py-4 text-right">
        <button class="btn btn-outline-primary mr-2" @click="create(true)" :disabled="!validInputs">
          <div class="d-flex justify-content-center" v-if="createLoading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else> Save & Continue </span>
        </button>
        <button
          class="btn btn-primary"
          style="width: 120px"
          @click="create(false)"
          :disabled="!validInputs"
        >
          <div class="d-flex justify-content-center" v-if="createLoading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else> Save </span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import MultiSelect from '@/components/MainContent/MultiSelect'
import momentTz from 'moment-timezone'

const UserModule = createNamespacedHelpers('user')
const PartnerModule = createNamespacedHelpers('partner')

export default {
  name: 'AddCompanyModal',
  data: () => ({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    timezone: '',
    partner: null,
    createLoading: false,
  }),
  components: {
    MultiSelect,
  },
  computed: {
    ...PartnerModule.mapGetters(['selectPartners']),
    validInputs() {
      return this.firstName && this.lastName && this.email && this.phone && this.timezone
    },
    timezones() {
      return [...momentTz.tz.zonesForCountry('US'), ...momentTz.tz.zonesForCountry('CA')]
    },
  },
  mounted() {
    this.getPartnerList()
  },
  methods: {
    ...UserModule.mapActions(['createUser']),
    ...PartnerModule.mapActions(['getPartnerList']),
    close() {
      this.$root.$emit('bv::hide::modal', 'AddUserModal')
    },
    async create(redirect) {
      this.createLoading = true
      try {
        const response = await this.createUser({
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          timezone: this.timezone,
          role: 'admin',
          ...(this.partner && { partner: this.partner._id }),
        })
        this.createLoading = false
        if (redirect) {
          this.$router.push({ name: 'companies' })
        } else {
          this.$emit('addedUser')
          this.close()
        }
      } catch (error) {
        console.log(error)
        this.$notify({
          text: 'Failed to create new user',
          type: 'error',
        })
      }
    },
  },
}
</script>
