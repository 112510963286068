<template>
  <div class="col-12 col-md-6">
    <div class="form-group">
      <div class="row">
        <div class="col">
          <label for="customerId">Customer Stripe ID</label>
          <input
            v-model="mutableValue"
            class="form-control"
            id="customerId"
            placeholder="Enter Stripe Customer Id"
            type="text"
          />
          <div class="text-danger">{{error}}</div>
        </div>
      </div>
    </div>
    <div class="form-group">
      <button
        @click="setStripeCustomerId()"
        class="btn btn-primary"
        :disabled="this.value === this.mutableValue"
      >Assign</button>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BillingCustomer',
  props: {
    value: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  },
  data: () => ({
    mutableValue: ''
  }),
  methods: {
    setStripeCustomerId() {
      this.$emit('input', this.mutableValue);
      this.$emit('change', this.mutableValue);
    }
  },
  watch: {
    value() {
      this.mutableValue = this.value;
    },
    errorMessage() {
      setTimeout(() => {
        this.error = '';
      }, 5000);
    }
  },
  mounted() {
    this.mutableValue = this.value;
  }
}
</script>
