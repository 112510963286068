<template>
<div>
    <div class="col-11 col-lg-11 col-xl-11">
          <h2 class="mb-4">Platforms</h2>
        </div>
    <div class="row">
        <div class="col-12">
            <div class="row">
                <div class="col-md-6 col-sm-12 col-xm-12" v-for="reviewSubscription in selectReviewSubscriptions" :key="reviewSubscription.network">
                    <ReviewSubscriptionCard 
                        :network="reviewSubscription.network"
                        @show-enable-review-subscription="showEnableReviewSubscriptionModal(reviewSubscription)"
                    />
                </div>
            </div>
        </div>
    </div>
    <EnableReviewSubscriptionModal :network="selectedNetwork" :locationId="locationId"/>
</div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import ReviewSubscriptionCard from '@/components/Modules/ReviewSubscriptions/ReviewSubscriptionCard'
import EnableReviewSubscriptionModal from '@/components/Modules/ReviewSubscriptions/EnableReviewSubscriptionModal.vue'

const ReviewSubscriptionModule = createNamespacedHelpers('reviewSubscription')
export default {
  name: 'ReviewSubscriptionDisplay',
  props: ['locationId'],
  components: {
    ReviewSubscriptionCard,
    EnableReviewSubscriptionModal
  },
  data: () => ({
    selectedNetwork: null
  }),
  computed: {
    ...ReviewSubscriptionModule.mapGetters(['selectReviewSubscriptions'])
  },
  methods: {
    ...ReviewSubscriptionModule.mapActions(['getReviewSubscriptions']),
    showEnableReviewSubscriptionModal(reviewSubscription) {
        this.selectedNetwork = reviewSubscription.network
        this.$bvModal.show('EnableReviewSubscriptionModal')
    }
  }
}
</script>