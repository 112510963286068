<template>
  <div class="ivr-dynamic-field-picker">
    <div class="row">
      <div class="col-6">
        <div v-if="showDynamicPicker">
          <select class="form-control custom-select my-2" v-model="selectedDynamicField">
            <option>None</option>
            <option v-for="dynamicField of selectIvrDynamicFields" :key="dynamicField + '-' + id">
              {{ dynamicField }}
            </option>
          </select>
          <div class="text-warning">
            If the dynamic field is not populated in the database it will hang up the call.
          </div>
        </div>
        <slot name="constant" v-else></slot>
      </div>
      <div class="col-auto align-self-center align-end ml-auto mr-4">
        <div class="row">
          <div class="col-auto">
            <div class="custom-control custom-checkbox-toggle">
              <input
                class="custom-control-input"
                type="checkbox"
                :id="id"
                v-model="showDynamicPicker"
              />
              <label class="custom-control-label" :for="id"></label>
            </div>
          </div>
          <div class="col ml--2">
            <small class="text-muted">{{ showDynamicPicker ? 'Dynamic' : 'Constant' }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { v4 as uuidv4 } from 'uuid'

const IvrModule = createNamespacedHelpers('ivr')

export default {
  name: 'IvrDynamicFieldPicker',
  props: ['value'],
  data: () => ({
    selectedDynamicField: null,
    showDynamicPicker: false,
    id: 'dynamic-input',
  }),
  computed: {
    ...IvrModule.mapGetters(['selectIvrDynamicFields']),
  },
  watch: {
    value(val) {
      if (this.selectIvrDynamicFields.includes(val)) {
        this.selectedDynamicField = val
        this.showDynamicPicker = true
      }
    },
    selectedDynamicField(val) {
      this.$emit('update', val)
    },
  },
  mounted() {
    this.id = 'dynamic-input' + '-' + uuidv4()
    if (this.selectIvrDynamicFields.includes(this.value)) {
      this.selectedDynamicField = this.value
      this.showDynamicPicker = true
    }
  },
}
</script>
