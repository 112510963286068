import SummaryEmailService from '../services/SummaryEmailService'

export default {
  namespaced: true,

  state: {
    enums: {},
    healthScores: [],
    summaryEmails: [],
  },

  getters: {
    selectHealthScores: (state, getters, rootState, rootGetters) => {
      const locations = rootGetters['location/selectCompanyLocations']
      return state?.healthScores.map((s) => {
        const { locationId, ...rest } = s
        const { city, friendlyName, name } = locations.find((l) => l._id === locationId) || {}
        const {
          averageRating,
          averageResponseTime,
          clicksToReview,
          customersSaved,
          dateRange,
          responseRate,
          surveyCount,
          surveyFeedbackCount,
          unrespondedSurveyCount,
        } = rest
        return {
          name: friendlyName || city || name,
          dateRange,
          surveyCount,
          customersSaved,
          clicksToReview,
          surveysWithFeedback: surveyFeedbackCount,
          unrespondedSurveyCount,
        }
      })
    },
  },

  mutations: {
    SET_HEALTH_SCORES(state, summaryEmails) {
      state.summaryEmails = summaryEmails
      const byLocations = summaryEmails.reduce(
        (acc, curr) => ({ ...acc, [curr.locationId]: [...(acc[curr.locationId] || []), curr] }),
        {}
      )
      const healthScores = Object.values(byLocations).map((i) => {
        const { locationId } = i[0]
        let startDate = i[0].summaryDateRange[0],
          endDate = i[0].summaryDateRange[1]

        const aggregatedData = i.reduce((acc, curr) => {
          const { data, summaryDateRange } = curr
          if (new Date(summaryDateRange[0]) < new Date(startDate)) {
            startDate = new Date(summaryDateRange[0])
          }
          if (new Date(summaryDateRange[1]) > new Date(endDate)) {
            endDate = new Date(summaryDateRange[1])
          }
          return {
            locationId,
            summaryDateRange: [startDate, endDate],
            data: {
              surveyCount: (acc?.data?.surveyCount || 0) + data.surveyCount,
              customersSaved: (acc?.data?.customersSaved || 0) + data.customersSaved,
              clicksToReview: (acc?.data?.clicksToReview || 0) + data.clicksToReview,
              surveyFeedbackCount: (acc?.data?.surveyFeedbackCount || 0) + data.surveyFeedbackCount,
              unrespondedSurveyCount:
                (acc?.data?.unrespondedSurveyCount || 0) + data.unrespondedSurveyCount,
            },
          }
        }, {})
        return {
          dateRange: aggregatedData.summaryDateRange
            .map((d) => new Date(d).toDateString())
            .join(' - '),
          locationId,
          ...aggregatedData.data,
        }
      })
      state.healthScores = healthScores
    },
  },

  actions: {
    async getSummaryEmails({ commit }, options) {
      commit('SET_HEALTH_SCORES', [])
      const response = await SummaryEmailService.list(options.filters)
      commit('SET_HEALTH_SCORES', response?.body?.data?.summaryEmails)
    },
  },
}
