<template>
  <div class="row">
    <!-- locationId -->
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col ml--2">
              <!-- Title -->
              <h4 class="card-title mb-1 platform-source">
                <a href="#!">Location ID</a>
              </h4>

              <!-- Text -->
              <p class="card-text small text-muted mb-1">
                {{ location._id }}
              </p>
            </div>
          </div>
          <!-- / .row -->
        </div>
        <!-- / .card-body -->
      </div>
    </div>

    <!-- companyId -->
    <div class="col-6">
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">
            <div class="col ml--2">
              <!-- Title -->
              <h4 class="card-title mb-1 platform-source">
                <a href="#!">Company ID</a>
              </h4>

              <!-- Text -->
              <p class="card-text small text-muted mb-1">
                {{ location.company._id }}
              </p>
            </div>
          </div>
          <!-- / .row -->
        </div>
        <!-- / .card-body -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'LocationInfo',
  props: ['location'],
  components: {},
}
</script>

<style scoped lang="scss"></style>
