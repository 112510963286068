import Vue from 'vue'

export default {
  createIntegrationProvider({ message }) {
    return Vue.http.post('v2/integration-provider', { message })
  },
  listIntegrationProviders(q) {
    return Vue.http.get(
      `v2/integration-provider${
        q
          ? '?' +
            Object.keys(q)
              .map((key) => `${key}=${q[key]}`)
              .join('&')
          : ''
      }`
    )
  },
}
