import ReviewSubscriptionService from '@/services/ReviewSubscriptionService'

const sortReviewSubscriptions = (reviewSubscriptions) => {
  return reviewSubscriptions.sort((a, b) => {
    let { network: nameA } = a
    let { network: nameB } = b
    nameA = nameA.toLowerCase()
    nameB = nameB.toLowerCase()
    if (nameA < nameB) {
      return -1
    }
    if (nameA > nameB) {
      return 1
    }
    return 0
  })
}

export default {
  namespaced: true,
  state: {
    reviewSubscriptions: [],
    searchResults: []
  },
  getters: {
    selectSearchResults: (state) => state.searchResults,
    selectReviewSubscriptions: (state) => state.reviewSubscriptions,
    selectReviewSubscriptionByNetwork: (state) => (network => {
        return state.reviewSubscriptions.find(reviewSubscription => reviewSubscription.network === network)
    }),
    selectReviewSubscriptionById: (state) => (reviewSubscriptionId => {
      return state.reviewSubscriptions.find(reviewSubscription => reviewSubscription._id === reviewSubscriptionId)
  })
  },
  mutations: {
    RESET_SEARCH_RESULTS(state) {
      state.searchResults = []
    },
    SET_SEARCH_RESULTS(state, searchResults) {
      state.searchResults = Array.isArray(searchResults) ? searchResults : [searchResults]
    },
    SET_REVIEW_SUBSCRIPTIONS(state, reviewSubscriptions) {
      const { enabled, disabled, unableToLocate } = reviewSubscriptions.reduce((data, reviewSubscription) => {
        if (!reviewSubscription.slug) {
          data.unableToLocate.push(reviewSubscription)
        } else if (reviewSubscription.disabled === false) {
          data.enabled.push(reviewSubscription)
        } else {
          data.disabled.push(reviewSubscription)
        }
        return data
      }, { enabled: [], disabled: [], unableToLocate: []})

      state.reviewSubscriptions = [
        ...sortReviewSubscriptions(enabled),
        ...sortReviewSubscriptions(disabled),
        ...sortReviewSubscriptions(unableToLocate)
      ]
    }
  },
  actions: {
    async getReviewSubscriptions({ commit }, { locationId }) {
        const result = await ReviewSubscriptionService.list({
            filters: {
              locationIds: [locationId]
            }
        })
        commit('SET_REVIEW_SUBSCRIPTIONS', result.data.data.reviewSubscriptions)
    },
    async createReviewSubscription({ commit, getters }, { locationId, network }) {
        const reviewSubscription = getters.selectReviewSubscriptionByNetwork(network)

        if (!reviewSubscription) throw new Error('Invalid network.')

        const createResult = await ReviewSubscriptionService.create({ locationId, network, slug: reviewSubscription.slug })
        
        const newReviewSubscription = createResult.data.data.reviewSubscription
        const newReviewSubscriptions = [
          ...getters.selectReviewSubscriptions.filter(rs => rs.network !== newReviewSubscription.network),
          newReviewSubscription
        ]
        commit('SET_REVIEW_SUBSCRIPTIONS', newReviewSubscriptions)
        return newReviewSubscription
    },
    async searchReviewSubscriptions({ commit }, { network, slug, name, address }) {
      commit('RESET_SEARCH_RESULTS')
      const searchResult = await ReviewSubscriptionService.search({ network, slug, name, address })

      commit('SET_SEARCH_RESULTS', searchResult.data.data.searchResults)
    },
    async enableReviewSubscription({ commit, getters }, { reviewSubscriptionId, slug }) {
      const enableResult = await ReviewSubscriptionService.enable({ reviewSubscriptionId, slug })
      const reviewSubscription = enableResult.data.data.reviewSubscription
      console.log(reviewSubscription)
      const newReviewSubscriptions = [
        ...getters.selectReviewSubscriptions.filter(rs => rs.network !== reviewSubscription.network),
        reviewSubscription
      ]
      console.log(newReviewSubscriptions)
      commit('SET_REVIEW_SUBSCRIPTIONS', newReviewSubscriptions)
      return newReviewSubscriptions
    },
    async disableReviewSubscription({ commit, getters }, { reviewSubscriptionId, locationId }) {
      await ReviewSubscriptionService.disable({ reviewSubscriptionId, locationId })
      const reviewSubscription = getters.selectReviewSubscriptionById(reviewSubscriptionId)

      const newReviewSubscriptions = [
        ...getters.selectReviewSubscriptions.filter(rs => rs.network !== reviewSubscription.network),
        {
          ...reviewSubscription,
          disabled: true
        }
      ]
      commit('SET_REVIEW_SUBSCRIPTIONS', newReviewSubscriptions)
    },
    setReviewSubscription({ commit, getters }, reviewSubscription) {
      const existingReviewSubscription = getters.selectReviewSubscriptionByNetwork(reviewSubscription.network)

      let newReviewSubscription = reviewSubscription
      if (existingReviewSubscription._id) {
        newReviewSubscription = {
          ...existingReviewSubscription,
          ...reviewSubscription
        }
      }

      const newReviewSubscriptions = [
        ...getters.selectReviewSubscriptions.filter(rs => rs.network !== reviewSubscription.network),
        {
          ...newReviewSubscription
        }
      ]
      commit('SET_REVIEW_SUBSCRIPTIONS', newReviewSubscriptions)
    },
    clearSearchResults({ commit }) {
      commit('SET_SEARCH_RESULTS', [])
    },
    refreshReviewSubscription({}, { reviewSubscriptionId }) {
      return ReviewSubscriptionService.refresh({ reviewSubscriptionId })
    },
  },
}
