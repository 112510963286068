import FeatureFlagService from '@/services/FeatureFlagService'

export default {
  namespaced: true,
  state: {
    flags: {},
    flagToBeEdited: null,
  },
  getters: {
    flag: (state) => (id) => {
      return state.flags[id]
    },
    selectFlags(state){
      return state.flags
    },
    selectFlagToBeEdited(state){
      return state.flagToBeEdited
    }
  },
  mutations: {
    SET_FEATURE_FLAGS(state, data) {
      state.flags = { ...data }
    },
    SET_FEATURE_FLAG(state, data) {
      const { name, enabled, users, companies } = data
      state.flags = { ...state.flags, [name]: {
        ...state.flags[name], enabled, users, companies
      }}
    },
    CLEAR_FEATURE_FLAG(state, name) {
      const update = { ...state.flags }
      delete update[name]
      state.flags = update
    },
    SET_FLAG_TO_BE_EDITED(state, flag){
      state.flagToBeEdited = flag
    }
  },
  actions: {
    setFlagToBeEdited({ commit }, flag){
      commit('SET_FLAG_TO_BE_EDITED', flag)
    },
    async getFeatureFlags({ commit }) {
      const response = await FeatureFlagService.list()
      commit('SET_FEATURE_FLAGS', response.data)
    },
    async createFeatureFlag({ dispatch }, { enabled, name, users, companies }) {
      const response = await FeatureFlagService.createFeatureFlag({
        enabled,
        name,
        users,
        companies
      })
      await dispatch('getFeatureFlags')
    },
    async updateFeatureFlag({ commit, getters }, { enabled, name, users, companies }) {
      const flagToBeEdited = getters['selectFlagToBeEdited']
      const response = await FeatureFlagService.updateFeatureFlag({
        name,
        users,
        enabled,
        companies
      })
      commit('SET_FEATURE_FLAG', response.body)
      // update the flag thats being edited
      if(flagToBeEdited){
        const update = {
          ...flagToBeEdited,
          flag: { ...flagToBeEdited.flag, ...response.body }
        }
        commit('SET_FLAG_TO_BE_EDITED', update)
      }
    },
    async removeFeatureFlag({ commit }, name) {
      await FeatureFlagService.removeFeatureFlag(name)
      commit('CLEAR_FEATURE_FLAG', name)
    },
  },
}
