<template>
  <b-modal
    id="CeateSourceOverrideModal"
    hide-footer
    hide-header
    centered
    @hide="closeModal"
    body-class="p-0"
    size="lg"
  >
    <div class="px-4 pt-4">
      <!-- body -->
      <div class="card-body px-4">
        <div class="header-title mb-4" style="font-size: 20px">
          {{ isEditting ? 'Updating Override' : 'Creating Override' }}
        </div>
        <div class="row mt-5">
          <div class="col">
            <input placeholder="Target" type="text" class="form-control" v-model="form.target" />
          </div>
          <div class="col">
            <input placeholder="Value" type="text" class="form-control" v-model="form.value" />
          </div>
        </div>
        <div class="form-group text-right mt-4">
          <button
            style="font-size: 20px"
            :disabled="!this.isValidForm"
            class="btn btn-primary px-5"
            @click.prevent="onCreate"
          >
            {{
              isEditting
                ? !isLoading
                  ? 'Update'
                  : 'Updating...'
                : !isLoading
                ? 'Create'
                : 'Creating...'
            }}
          </button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  name: 'CeateSourceOverrideModal',
  props: {
    override: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
    },
    isEditting: {
      type: Boolean,
    },
  },
  data() {
    return {
      form: {
        target: '',
        value: '',
      },
    }
  },
  watch: {
    override: function (value) {
      if (value) {
        this.form.value = value.value
        this.form.target = value.target
      }
    },
  },
  computed: {
    isValidForm() {
      let isValid = true
      if (!this.form.value.length) {
        isValid = false
      }
      if (!this.form.target.length) {
        isValid = false
      }
      return isValid
    },
  },
  methods: {
    closeModal() {
      this.$emit('onModalClose')
      this.$root.$emit('bv::hide::modal', 'CeateSourceOverrideModal')
      this.resetState()
    },
    onCreate() {
      if (this.isEditting) {
        this.$emit('onCreate', {
          ...this.override,
          ...this.form,
        })
      } else {
        this.$emit('onCreate', {
          ...this.form,
        })
      }
    },
    resetState() {
      this.form.value = ''
      this.form.target = ''
    },
  },
}
</script>
