<template>
  <b-modal
    id="CompanyTestDataModal"
    hide-footer
    hide-header
    centered
    size="md"
    :class="!previewData.length ? 'wide-modal-sz' : ''"
    @hidden="clearState"
  >
    <div>
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h1>Delete test data</h1>

        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- body -->
      <div class="card-body">
        <span
          >Any surveys taken
          <b>BEFORE</b>
          the date selected below will be deleted.</span
        >
        <b-form-input
          class="mt-4"
          id="createdDate"
          v-model="createdDate"
          type="date"
          placeholder="Select the date"
        ></b-form-input>
        <b-table striped hover :items="previewData" :fields="fields" v-if="previewData.length">
          <template #cell(actions)="row">
            <b-button size="sm" @click="removePreviewDataItem(row.item)" class="mr-1">
              Keep
            </b-button>
          </template>
        </b-table>
        <div class="mt-4">
          <span v-if="error" class="text-danger">{{ error }}</span>
        </div>
      </div>
      <!-- footer -->
      <div class="border-top py-4 text-right">
        <button class="btn mr-3" style="width: 120px" @click="clearState()" :disabled="loading">
          <span>Cancel</span>
        </button>
        <button
          v-if="!previewData.length"
          class="btn btn-primary"
          style="width: 120px"
          @click="getPreviewData()"
          :disabled="!validInputs || loading"
        >
          <div class="d-flex justify-content-center" v-if="loading">
            <div class="loader loader-light"></div>
          </div>
          <span class="red" v-else>Preview</span>
        </button>
        <button
          v-else
          class="btn btn-danger"
          style="width: 120px"
          @click="deleteData()"
          :disabled="loading"
        >
          <div class="d-flex justify-content-center" v-if="loading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else>Delete</span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import moment from 'moment'

const CompanyModule = createNamespacedHelpers('company')
const DeletionBackupModule = createNamespacedHelpers('deletionBackup')

const initialState = {
  loading: false,
  createdDate: null,
  previewData: [],
  error: null,
  fields: ['collection', 'created', '_id', 'customer', 'location', 'type', 'actions'],
}

export default {
  name: 'CompanyTestDataModal',
  props: ['companyId'],
  data: () => ({
    ...initialState,
  }),
  computed: {
    validInputs() {
      return this.createdDate
    },
  },
  methods: {
    ...CompanyModule.mapActions(['getCompanyTestDataPreview']),
    ...DeletionBackupModule.mapActions(['createDeletionBackups']),
    close() {
      this.$root.$emit('bv::hide::modal', 'CompanyTestDataModal')
    },
    clearState() {
      Object.assign(this, initialState)
      this.close()
    },
    removePreviewDataItem(item) {
      this.previewData = this.previewData.filter((i) => i._id !== item._id)
    },
    async getPreviewData() {
      try {
        this.error = null
        this.previewData = []
        this.loading = true
        const result = await this.getCompanyTestDataPreview({
          companyId: this.companyId,
          createdDate: moment(this.createdDate, 'YYYY-MM-DD').toISOString(),
        })

        const formattedData = Object.keys(result.body.data).reduce((data, key) => {
          const newData = [
            ...data,
            ...result.body.data[key].map((i) => ({
              collection: key,
              created: moment(i.created).format('MM/DD/YYYY'),
              _id: i._id,
              customer: i.customer?.name,
              location: i.location?.friendlyName || i.location?.city,
              type: i.type,
            })),
          ]
          return newData
        }, [])

        if (formattedData.length) {
          this.previewData = formattedData
        } else {
          this.error = "Sorry didn't find any data prior to the date you entered."
        }
      } catch (e) {
        this.error = e?.body?.message || 'Sorry something went wrong.'
      } finally {
        this.loading = false
      }
    },
    async deleteData() {
      try {
        this.error = null
        this.loading = true
        const result = await this.createDeletionBackups({
          type: 'document',
          items: this.previewData.map((i) => ({
            collectionName: i.collection,
            data: { _id: i._id },
          })),
        })
      } catch (e) {
        this.error = e?.body?.message || 'Sorry something went wrong.'
      } finally {
        this.loading = false

        if (!this.error) {
          this.$notify({
            type: 'success',
            title: 'Success',
            text: `Successfully deleted the test data!`,
          })
          this.clearState()
        }
      }
    },
  },
  mounted() {},
}
</script>

<style lang="scss">
#CompanyTestDataModal {
  .modal-dialog {
    max-width: 75% !important;
  }
}
</style>
