import Vue from 'vue'

export default {
  list(payload) {
    return Vue.http.post('v2/locations/list', payload)
  },
  get(locationId) {
    return Vue.http.get(`v2/locations/${locationId}`)
  },
  enable(locationId) {
    return Vue.http.put(`v2/locations/${locationId}`, { disabled: false })
  },
  disable(locationId) {
    return Vue.http.put(`v2/locations/${locationId}`, { disabled: true })
  },
  transfer(locationId, companyId) {
    return Vue.http.post(`v2/locations/transfer/${locationId}`, { companyId })
  },
  remove(locationId) {
    return Vue.http.delete(`v2/locations/${locationId}`)
  },
  unlinkDevice: async (deviceId, locationId) => {
    const response = await Vue.http.post('pentaApi/device/unlink-device', {
      deviceId,
      locationId,
    })
    return response.body
  },
  update(locationId, modifications) {
    return Vue.http.put(`v2/locations/${locationId}`, modifications)
  },
  create(payload) {
    return Vue.http.post('v2/locations', payload)
  },
  getByCompany(companyId) {
    return Vue.http.post(`v2/locations/list`, { filters: { companyIds: [companyId] } })
  },
  moveLocationCustomers({ origin, destination }) {
    return Vue.http.post('v2/locations/move-customers', { origin, destination })
  },
  createCTA(payload) {
    return Vue.http.post('v2/locations/create-custom-cta', payload)
  },
  updateCTA({ locationId, ctaId, payload }) {
    return Vue.http.put(`v2/locations/update-custom-cta/${locationId}/${ctaId}`, {
      ...payload,
    })
  },
  deleteCTA({ locationId, ctaId }) {
    return Vue.http.delete(`v2/locations/delete-custom-cta/${locationId}/${ctaId}`)
  },
  getCallToActions({ locationId }) {
    return Vue.http.get(`v2/locations/get-custom-ctas/${locationId}`)
  },
  getGeocoding(payload) {
    return Vue.http.post(`v2/locations/geo`, { ...payload })
  },
}
