import Vue from 'vue'

export default {
  list(name) {
    return Vue.http.post('user/list', { name })
  },
  getUsers(query) {
    return Vue.http.post('user/list', { ...query })
  },
  fetchUserByFbId() {
    return Vue.http.get('v2/users/fbId')
  },
  getLoginLink(userId) {
    return Vue.http.get(`user/link/${userId}`)
  },
  getCompanyLoginLink(companyId) {
    return Vue.http.get(`user/link/company/${companyId}`)
  },
  assignOrganization(payload) {
    const { userId, organizationId } = payload
    return Vue.http.put(`v2/users/${userId}/organization/${organizationId}`, {
      ...payload,
    })
  },
  createUser(payload) {
    return Vue.http.post('v2/users', payload)
  },
  createOvationInvite(payload) {
    return Vue.http.post('v2/users/create-ovation-invite', payload)
  },
  updateUserCompanies({ userId, companies }) {
    return Vue.http.put(`v2/users/${userId}/companies`, { companies })
  },
}
