import IntegrationPartnerService from '../services/IntegrationPartnerService';

export default {
  namespaced: true,

  state: {
    integrationLocations: [],
    saveResponse: undefined,
  },

  mutations: {
    setIntegrationLocations(state, locations) {
      state.integrationLocations = locations;
    },
    setSaveResponse(state, response) {
      state.saveResponse = response;
    }
  },

  actions: {
    async getIntegrationLocations({ commit, rootState, dispatch }, id) {
      const response = await IntegrationPartnerService.getIntegrationLocations();
      const locations = response.data.filter(({ integrationPartner: _id }) => _id === id)

      // get list of location ids from integration locations
      let locationsIds = [];
      locations.forEach(location => {
        locationsIds.push(location.location);
      })

      // get the the list of integrations from integration module
      await dispatch('integration/getIntegrationsList', locationsIds, { root: true });
      const integrations = rootState.integration.integrations;

      // add integrationId to the integration location
      locations.map(loc => {
        integrations.forEach((integration) => {
          if(loc.location === integration.location){
            loc.integrationId = integration._id
          }
        })
      })

      // commit the location list
      commit('setIntegrationLocations', locations);
    },
    async saveIntegrationLocation({ commit }, payload) {
      return await IntegrationPartnerService.saveIntegrationLocation(payload)
    },
  },
  getters: {
    selectIntegrationLocationIds: (state) => () => {
      return state?.integrationLocations.map(({ _id }) => _id)
    },
    selectIntegrationLocation: (state) => (id) => {
      return state?.integrationLocations.find(({ _id }) => _id === id)
    },
    selectIntegrationLocationId: (state, getters) => (id) => {
      const result = getters.selectIntegrationLocation(id)
      return result?.id
    },
    selectIntegrationLocationName: (state, getters) => (id) => {
      const result = getters.selectIntegrationLocation(id)
      return result?.name
    },
  },
}
