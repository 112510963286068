<template>
  <div class="content-wrapper">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col">
            <h2 class="mb-4 platform-source">Survey Source Overrides</h2>
          </div>
          <div class="col-auto">
            <button @click.prevent="openModal" class="btn btn btn-primary px-4">
              <span class="fe fe-plus"></span>Add</button>
          </div>
        </div>
        <label>Allow company to override survey sources with new values.</label>
        <div class="row mt-4">
          <table class="table table-hover" v-if="overrides.length">
            <thead>
              <tr>
                <th scope="col">Target</th>
                <th scope="col">Value</th>
                <th scope="col">Override ID</th>
                <th scope="col"></th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="override in overrides" :key="override._id">
                <td>{{ override.target }}</td>
                <td>{{ override.value }}</td>
                <td>{{ override._id }}</td>
                <td class="text-right">
                  <div>
                    <button
                      class="btn btn-outline-dark px-5 py-2 mr-4"
                      @click.prevent="handleEditOverride(override)"
                    >
                      Edit
                    </button>
                    <a href="#!" class="trash-icon">
                      <span
                        style="font-size: 20px; color: black"
                        class="fe fe-trash"
                        @click.prevent="handleDeleteOverride(override)"
                      ></span>
                    </a>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <div class="col-lg-12 col-sm-12 mt-5 text-center" v-else>No overrides to display</div>
        </div>
      </div>
    </div>
    <CeateSourceOverrideModal
      :isLoading="isLoading"
      :isEditting="isEditting"
      :override="activeOverride"
      @onCreate="handleCreate"
      @onModalClose="handleModalClose"
    />
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import CeateSourceOverrideModal from '../../../Modals/CeateSourceOverrideModal.vue'
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'SurveySourceOverrides',
  props: {
    companyId: {
      type: String,
      required: true,
    },
  },
  components: {
    CeateSourceOverrideModal,
  },
  data: () => ({
    isLoading: false,
    isEditting: false,
    activeOverride: null,
  }),
  computed: {
    ...CompanyModule.mapGetters(['selectSurveySourceOverrides']),

    overrides() {
      return this.selectSurveySourceOverrides
    },
  },
  methods: {
    ...CompanyModule.mapActions([
      'fetchSurveySourceOverrides',
      'createSurveySourceOverride',
      'deleteSurveySourceOverride',
    ]),

    openModal() {
      this.$root.$emit('bv::show::modal', 'CeateSourceOverrideModal')
    },
    closeModal() {
      this.$root.$emit('bv::hide::modal', 'CeateSourceOverrideModal')
    },
    async handleCreate(override) {
      if (this.isLoading) return
      try {
        this.isLoading = true
        if (this.isEditting) {
          const { _id, ...payload } = override
          // TODO: handle individual update
        } else {
          await this.createSurveySourceOverride({
            payload: [{ ...override }],
            companyId: this.companyId,
          })
        }
        this.$notify({
          title: 'Created',
          text: 'Override created successfully.',
        })
        this.closeModal()
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again.',
        })
      }
      this.isLoading = false
    },
    handleModalClose() {
      this.isEditting = false
      this.activeOverride = null
    },
    handleEditOverride(override) {
      if (!override) return
      this.isEditting = true
      this.activeOverride = override
      this.openModal()
    },
    async handleDeleteOverride(override) {
      try {
        await this.deleteSurveySourceOverride({
          companyId: this.companyId,
          overrideId: override?._id,
        })
        this.$notify({
          title: 'Deleted',
          text: 'Override deleted successfully.',
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Something went wrong. Please try again.',
        })
      }
    },
    async loadOverrides() {
      try {
        await this.fetchSurveySourceOverrides({
          companyId: this.companyId,
        })
      } catch (error) {
        // console.error(error)
      }
    },
  },
  mounted() {
    if (this.companyId) {
      this.loadOverrides()
    }
  },
}
</script>