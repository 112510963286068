<template>
  <div v-if="payments.items.length">
    <h4>Payment History</h4>
    <hr />
    <div class="table-responsive">
      <table class="table mb-0">
        <thead>
          <tr>
            <th>Date</th>
            <th>Amount</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="payment in payments.items" :key="payment.id">
            <td>{{ payment.created | moment("MM/DD/YYYY") }}</td>
            <td>${{ payment.amount }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BillingPayments',
  props: {
    payments: {
      type: Object,
      default: () => ({
        items: [],
        hasNextPage: false
      })
    }
  }
}
</script>
