<template>
  <BillingDetails
    @onCustomerUpdate="setActiveCompanyCustomerId($event)"
    :customer-id="activeCompanyCustomerId"
    :company-id="activeCompany._id"
  />
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const CompanyModule = createNamespacedHelpers('company')
import BillingDetails from '@/components/Modules/Billing/BillingDetails.vue'
export default {
  name: 'CompanyBilling',
  components: {
    BillingDetails
  },
  data: () => ({
    stripeCustomerId: '',
  }),
  computed: {
     ...CompanyModule.mapState([
      'activeCompany',
      'activeCompanyCustomerId'
    ])
  },
  methods: {
    ...CompanyModule.mapMutations(['setActiveCompanyCustomerId'])
  }
}
</script>
