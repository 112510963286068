<template>
  <div id="app">
    <div v-if="selectIsPartner" class="partner-alert alert alert-danger" role="alert">
      Sorry, partner access is no longer supported.
    </div>
    <div v-else>
      <FadeTransition>
        <SideNav v-if="!$route.meta.hideNav" />
      </FadeTransition>
      <FadeTransition>
        <FullLoader v-if="!initialized" />
        <router-view v-if="initialized" />
      </FadeTransition>
      <notifications />
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

import SideNav from '@/components/SideNav/SideNav'
import FadeTransition from '@/components/Transitions/FadeTransition'
import FullLoader from '@/components/MainContent/FullLoader'
import { initRouterView } from '@/router'

const { mapActions, mapGetters } = createNamespacedHelpers('auth')
const FeatureFlagModule = createNamespacedHelpers('featureFlag')

export default {
  name: 'App',
  components: {
    SideNav,
    FadeTransition,
    FullLoader,
  },
  data: () => ({
    initialized: false,
  }),
  computed: {
    ...mapGetters(['selectIsClassifier', 'selectIsPartner']),
  },
  methods: {
    ...mapActions(['getAuth']),
    ...FeatureFlagModule.mapActions(['getFeatureFlags']),
  },
  async mounted() {
    await initRouterView(this)
    this.getFeatureFlags()

    this.initialized = true
  },
}
</script>

<style lang="scss">
.partner-alert {
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
.notifications {
  /deep/ .notification-wrapper {
    overflow: initial;
  }

  /deep/ .notification {
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
    font-size: 14px;
    background: var(--blue) !important;
    border-radius: 4px;

    &.success {
      background: var(--green) !important;
    }

    &.error {
      background: var(--red) !important;
    }
  }
}

@media screen and (max-width: 767px) {
  .notifications {
    width: 100% !important;
    left: 0;
    right: 0;

    /deep/ .notification {
      margin: 0px !important;
      width: 100%;
      border-radius: 0px;
      border-left: none;
    }
  }
}

@media screen and (min-width: 768px) {
  .notifications {
    top: 10px !important;
    right: 5px !important;
  }
}
</style>
