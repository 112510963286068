import PartnerService from '@/services/PartnerService'

export default {
  namespaced: true,
  state: {
    partners: [],
  },
  getters: {
    selectPartners(state) {
      return state.partners
    },
  },
  mutations: {
    setPartners(state, partners) {
      state.partners = [...partners]
    },
  },
  actions: {
    async getPartnerList({ commit }) {
      const response = await PartnerService.list({ filters: {} })
      commit('setPartners', response.body.data.partners)
    },
  },
}
