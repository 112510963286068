<template>
  <b-modal
    id="manageCompaniesModal"
    hide-footer
    hide-header
    centered
    body-class="p-0"
    size="lg"
    @hide="reset"
    @show="initialize"
  >
    <div class="px-4 pt-4" v-if="initialized">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h1>{{ user.firstName }} {{ user.lastName }}</h1>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- body -->
      <div class="card-body px-5">
        <div class="font-weight-bold">Companies</div>
        <MultiSelect
          :options="selectCompanies"
          v-model="userCompanies"
          :show-labels="false"
          closeOnSelect
          :multiple="true"
          :loading="isLoading"
          :internal-search="false"
          @search-change="updateList"
          track-by="_id"
          placeholder="Select a company"
          :custom-label="companyLabel"
        >
          <template slot="option" slot-scope="{ option }">
            <span>
              <span>{{ option.friendlyName || option.name }} - {{ option._id }}</span>
            </span>
          </template>
        </MultiSelect>
      </div>

      <!-- footer -->
      <div class="border-top py-4 text-right">
        <button
          class="btn btn-primary"
          style="width: 120px"
          @click="apply"
          :disabled="applyLoading"
        >
          <div class="d-flex justify-content-center" v-if="applyLoading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else> Apply </span>
        </button>
      </div>
    </div>
    <div v-else>Loading...</div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'
import MultiSelect from 'vue-multiselect'

const UserModule = createNamespacedHelpers('user')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'ManageCompaniesModal',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    applyLoading: false,
    isLoading: false,
    userCompanies: [],
    initialized: false,
  }),
  components: {
    MultiSelect,
  },
  computed: {
    ...CompanyModule.mapGetters(['selectCompanies']),
  },
  methods: {
    ...CompanyModule.mapActions(['getCompanyList']),
    ...UserModule.mapActions(['updateUserCompanies']),
    close() {
      this.$root.$emit('bv::hide::modal', 'manageCompaniesModal')
    },
    reset() {
      this.initialized = false
      this.userCompanies = []
    },
    updateList: debounce(async function (event) {
      this.isLoading = true
      await this.getCompanyList({ search: event })
      this.isLoading = false
    }, 500),
    async apply() {
      this.applyLoading = true
      try {
        await this.updateUserCompanies({
          userId: this.user._id,
          companies: this.userCompanies.map((c) => c._id),
        })
        this.$emit('refresh')
        this.close()
        this.$notify({ text: 'Sucessfully updated user companies' })
      } catch (error) {
        this.$notify({ type: 'error', text: `${error.body.message}` })
      }
      this.applyLoading = false
    },
    companyLabel(company) {
      return `${company.friendlyName || company.name}`
    },
    async initialize() {
      this.userCompanies = this.user.companies
      this.initialized = true
    },
    mounted() {
      this.getCompanyList()
    },
  },
}
</script>
