import Vue from 'vue'

/**
 * @module group.service
 */

export default {
  list({ companyId }){
    return Vue.http.get(`v2/group/company/${companyId}`)
  },
  getGroup({ groupId }){
    return Vue.http.get(`v2/group/${groupId}`)
  },
  createGroup(payload){
    return Vue.http.post(`v2/group`, { ...payload })
  },
  deleteGroup(groupId){
    return Vue.http.delete(`v2/group/${groupId}`)
  },
  updateGroup({ groupId, payload }){
    return Vue.http.put(`v2/group/${groupId}`, { ...payload })
  },
}