<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
            <!-- Header -->
            <div class="header mt-md-5">
              <div class="header-body">

                
                <!-- Pretitle -->
                <h6 class="header-pretitle">
                  Overview
                </h6>
                
                <!-- Title -->
                <h1 class="header-title display-4">
                  Orders
                </h1>

              </div>
            </div>
            <orders-table></orders-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrdersTable from '@/components/Modules/Orders/OrdersTable'
export default {
  name: 'OrdersView',
  components: {
    OrdersTable
  }
}
</script>

<style scoped lang="scss">
</style>
