<template>
  <multiselect
    placeholder="Select companies"
    :close-on-select="false"
    :multiple="multiple"
    v-model="companies"
    :searchable="true"
    :showLabels="false"
    :options="options"
    :custom-label="customCompanyName"
    @input="updateCompanies"
    @close="dropdownClosed"
    @search-change="fetchCompanies"
    track-by="_id"
  />
</template>
<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'CompanySelector',
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    displayIds: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      companies: [],
    }
  },
  components: {
    Multiselect,
  },
  computed: {
    ...CompanyModule.mapGetters(['selectCompanies']),

    options() {
      return this.selectCompanies
    },
  },
  methods: {
    ...CompanyModule.mapActions(['getCompanyList']),

    customCompanyName(company) {
      return `${company.name || company.friendlyName} ${this.displayIds ? ' - ' + company._id : ''}`
    },
    updateCompanies() {
      this.$emit('updateCompanies', this.companies)
    },
    dropdownClosed() {
      this.$emit('dropdownClosed')
    },
    fetchCompanies: debounce(async function (query) {
      await this.getCompanyList({ search: query })
    }, 500),
  },
  mounted() {
    this.getCompanyList()
  },
}
</script>
