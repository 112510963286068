<template>
  <div>
    <div class="row mb-4">
      <div class="col col-12">
        <!-- Search -->
        <form @submit.prevent>
          <div class="row align-items-center">
            <div class="col">
              <div class="input-group input-group-lg input-group-merge">
                <input
                  type="search"
                  class="form-control form-control-prepended search"
                  placeholder="Search"
                  v-model="search"
                  @input="fetchLocations"
                />
                <div class="input-group-prepend">
                  <div class="input-group-text">
                    <span class="fe fe-search" v-show="!isLoading"></span>
                    <span v-show="isLoading">Searching...</span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-auto">
              <div
                class="nav btn-group"
                role="group"
                aria-label="Button group with nested dropdown"
              >
                <button
                  type="button"
                  class="btn btn-white"
                  @click="descending = false"
                  :class="{ active: !descending }"
                >
                  <span class="fe fe-arrow-up"></span>
                </button>
                <button
                  type="button"
                  class="btn btn-white"
                  @click="descending = true"
                  :class="{ active: descending }"
                >
                  <span class="fe fe-arrow-down"></span>
                </button>

                <div class="btn-group" role="group">
                  <button
                    id="btnGroupDrop1"
                    type="button"
                    class="btn btn-white dropdown-toggle"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    {{ sortTitle }}
                  </button>
                  <div
                    class="dropdown-menu"
                    aria-labelledby="btnGroupDrop1"
                    x-placement="bottom-start"
                    style="
                      position: absolute;
                      transform: translate3d(0px, 42px, 0px);
                      top: 0px;
                      left: 0px;
                      will-change: transform;
                    "
                  >
                    <a
                      class="dropdown-item"
                      v-for="sortOption in sortOptions"
                      :key="sortOption.key"
                      href="#"
                      @click="setSort(sortOption)"
                      >{{ sortOption.name }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row mx-1 mt-3" v-if="isOvationAdmin">
            <multiselect
              class="w-100"
              v-model="partner"
              deselect-label="None"
              track-by="_id"
              label="name"
              placeholder="Select partner (optional)"
              @input="fetchLocations"
              :options="partners"
              :searchable="true"
              :allow-empty="true"
            >
              <template slot="singleLabel" slot-scope="{ option }"
                ><strong>{{ option.name }}</strong></template
              >
            </multiselect>
          </div>
        </form>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-xl-4" v-for="row in orderedData" :key="row._id">
        <div class="card">
          <div class="card-header">
            <div class="col col-12 nosidepadding">
              <h6 class="header-pretitle text-primary truncate">{{ row.company.name }}</h6>
              <h4 class="header-title truncate">
                {{ row.city }} {{ row.friendlyName ? `(${row.friendlyName})` : '' }}
              </h4>
            </div>
            <div class="col col-auto text-right nosidepadding">
              <span class="grade" :class="letterGradeClass(grade(row))">{{ grade(row) }}</span>
            </div>
          </div>
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-6">
                <!-- Title -->
                <h6 class="card-title text-uppercase text-muted mb-2">Recent Surveys</h6>

                <!-- Heading -->
                <span class="h2 mb-0">{{ row.health.recentSurveys }}</span>
              </div>
              <div class="col-6">
                <!-- Title -->
                <h6 class="card-title text-uppercase text-muted mb-2">Total Surveys</h6>

                <!-- Heading -->
                <span class="h2 mb-0">{{ row.health.totalSurveys }}</span>
              </div>
              <div class="col-12 mt-3 mb-3" />
              <div class="col-6">
                <!-- Title -->
                <h6 class="card-title text-uppercase text-muted mb-2">Open Conversations</h6>

                <!-- Heading -->
                <span class="h2 mb-0">{{ row.health.openConversations }}</span>
              </div>
              <div class="col-6">
                <!-- Title -->
                <h6 class="card-title text-uppercase text-muted mb-2">Reponse Rate</h6>

                <!-- Heading -->
                <span class="h2 mb-0">{{ row.health.responseRate }}%</span>
              </div>
              <div class="col-12 mt-3 mb-3" />
              <div class="col-6" v-if="row.health && row.health.reviewVelocity">
                <!-- Title -->
                <h6 class="card-title text-uppercase text-muted mb-2">Review Velocity</h6>

                <!-- Heading -->
                <span class="h2 mb-0">
                  {{ row.health.reviewVelocity.before }}
                  <span class="h3 fe fe-arrow-right text-muted mb-0"></span>
                  {{ row.health.reviewVelocity.after }} </span
                >&nbsp;
                <span
                  v-if="row.health.reviewVelocity.after > row.health.reviewVelocity.before"
                  class="badge badge-soft-success mt--1"
                  >+{{
                    increase(row.health.reviewVelocity.before, row.health.reviewVelocity.after)
                  }}%</span
                >
              </div>
              <div class="col-6">
                <!-- Title -->
                <h6 class="card-title text-uppercase text-muted mb-2">Text Usage</h6>

                <!-- Heading -->
                <span class="h2 mb-0" v-if="row.company.campaignsEnabled">{{
                  row.health.textUsage
                }}</span>
                <span class="h2 mb-0 text-muted" v-else>0</span>
              </div>
            </div>
          </div>
          <div class="card-footer text-muted text-small">
            <div class="row">
              <div class="col-auto nosidepadding">
                <small>
                  Joined
                  <strong>{{ daysAgo(row.created).toFixed(0) }}</strong> days ago.
                </small>
              </div>
              <div class="col text-right nosidepadding">
                <button @click="companyLogin(row.company._id)" class="btn btn-white btn-sm">
                  <span class="fe fe-arrow-right"></span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6 pb-6">
        <button @click="showAllResults()" v-if="showButton" type="button" class="btn btn-white">
          Show all results
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect'
import UserService from '@/services/UserService'
import { debounce } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'

const { mapState, mapActions } = createNamespacedHelpers('location')
const AuthModule = createNamespacedHelpers('auth')
const PartnerModule = createNamespacedHelpers('partner')

export default {
  name: 'LocationsScorecards',
  components: {
    multiselect: Multiselect,
  },
  data: () => {
    return {
      additionalResults: [],
      excludePartnerLocations: undefined,
      searchResults: [],
      search: '',
      showAll: false,
      searchable: ['company.name', 'city', 'friendly'],
      sort: 'health.recentSurveys',
      sortTitle: 'Recent surveys',
      descending: false,
      partner: null,
      sortOptions: [
        { name: 'Total surveys', key: 'health.totalSurveys' },
        { name: 'Recent surveys', key: 'health.recentSurveys' },
        { name: 'Response rate', key: 'health.responseRate' },
        { name: 'Unresolved convos', key: 'health.openConversations' },
        { name: 'Text usage', key: 'health.textUsage' },
        { name: 'Review velocity', key: 'health.reviewVelocity.after' },
      ],
      isLoading: false,
    }
  },
  computed: {
    ...mapState(['locations']),
    ...AuthModule.mapGetters(['selectEmail', 'selectIsPartner']),
    ...PartnerModule.mapGetters(['selectPartners']),
    orderedData: function () {
      let locations = this.locations
      if (this.sort === 'health.textUsage') {
        locations = locations.filter((location) => location.company.campaignsEnabled)
      }
      const filteredList = locations.filter((location) => !location.disabled && location.health)
      return _.orderBy(filteredList, this.sort, this.descending ? 'desc' : 'asc')
    },
    showButton: function () {
      return !this.showAll && this.additionalResults && this.additionalResults.length
    },
    isOvationAdmin() {
      return new RegExp('@ovationup.com$', 'i').test(this.selectEmail)
    },
    isPartner() {
      return this.selectIsPartner
    },
    partners() {
      return this.selectPartners
    },
  },
  methods: {
    ...mapActions(['getLocationList']),
    ...PartnerModule.mapActions(['getPartnerList']),
    async companyLogin(companyId) {
      const response = await UserService.getCompanyLoginLink(companyId)
      const { link } = response.body
      window.open(link, '_blank')
    },
    async showAllResults() {
      this.showAll = true
      this.$emit('dataChanged', [...this.searchResults, ...this.additionalResults], {
        showAll: true,
      })
    },
    daysAgo(timestamp) {
      return this.$moment.duration(this.$moment().diff(timestamp)).asDays()
    },
    setSort(sortOption) {
      this.sortTitle = sortOption.name
      this.sort = sortOption.key
    },
    letterGradeClass(letter) {
      if (letter === 'A') return 'text-success'
      if (letter === 'B') return 'text-primary'
      if (letter === 'C') return 'text-info'
      if (letter === 'D') return 'text-warning'
      if (letter === 'F') return 'text-danger'
      if (letter === 'I') return 'text-muted'
      return 'text-muted'
    },
    letterGrade(score) {
      let letterGrade = 'F'

      if (score >= 90) {
        letterGrade = 'A'
      } else if (score >= 80) {
        letterGrade = 'B'
      } else if (score >= 70) {
        letterGrade = 'C'
      } else if (54 >= score && score >= 50) {
        letterGrade = 'D'
      }

      return letterGrade
    },
    grade(location) {
      let score = 0
      const age = this.daysAgo(location.created)
      if (age < 14) return 'I'
      let averageSurveys = location.health.totalSurveys / age
      if (location.health.recentSurveys > 0)
        score += (location.health.recentSurveys / averageSurveys) * 25
      if (
        averageSurveys &&
        location.health.recentSurveys &&
        (location.health.recentSurveys > averageSurveys || location.health.recentSurveys > 10)
      ) {
        score += 50
      }

      if (location.health.responseRate > 50) score += 10

      if (
        !location.company.campaignsEnabled ||
        (location.company.campaignsEnabled && location.health.textUsage > 20)
      )
        score += 15

      return this.letterGrade(score)
    },
    increase(before, after) {
      if (!before && after) return after * 100
      return Math.floor(((after - before) / before) * 100)
    },
    fetchLocations: debounce(async function () {
      this.isLoading = true
      const filters = { disabled: false }
      if (this.partner && this.partner._id) {
        filters.partnerIds = [this.partner._id]
      }
      await this.getLocationList({
        filters,
        search: this.search,
      })
      this.isLoading = false
    }, 500),
  },
  mounted() {
    this.excludePartnerLocations = this.isOvationAdmin
    this.fetchLocations()
    if (this.isOvationAdmin) {
      this.getPartnerList()
    }
  },
  created() {
    this.$on('dataChanged', (results, { showAll = false } = {}) => {
      if (this.showAll) {
        this.searchResults = results
      } else {
        this.searchResults = results.slice(0, 20)
        this.additionalResults = results.slice(20)
      }
      this.showAll = showAll
    })
  },
}
</script>

<style scoped lang="scss">
.grade {
  font-size: 18px;
  font-weight: bold;
}
.nosidepadding {
  padding-left: 0px;
  padding-right: 0px;
}
.truncate {
  width: 180px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
