import Vue from 'vue'

export default {
  list(payload) {
    return Vue.http.post('v2/translation-templates/list', payload)
  },
  getById({ translationTemplateId }) {
    return Vue.http.get(`v2/translation-templates/${translationTemplateId}`)
  },
  createTanslationTemplate(payload) {
    return Vue.http.post('v2/translation-templates', payload)
  },
  updateTanslationTemplate({ translationTemplateId, update }) {
    return Vue.http.put(`v2/translation-templates/${translationTemplateId}`, update)
  },
  deleteTanslationTemplate({ translationTemplateId }) {
    return Vue.http.delete(`v2/translation-templates/${translationTemplateId}`)
  },
}
