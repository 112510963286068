<template>
  <multiselect
    placeholder="Select users"
    :close-on-select="false"
    :multiple="multiple"
    v-model="users"
    :searchable="true"
    :showLabels="false"
    :options="options"
    :custom-label="customUserName"
    @input="updateUsers"
    @close="dropdownClosed"
    @search-change="fetchUsers"
    track-by="_id"
  />
</template>
<script>
import Multiselect from 'vue-multiselect'
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'UsersSelector',
  props: {
    multiple: {
      type: Boolean,
      default: true
    },
    displayIds: {
      type: Boolean,
      default: false
    },
  },
  data(){
    return {
      users: []
    }
  },
  components: {
    Multiselect,
  },
  computed: {
    ...UserModule.mapGetters(['selectUsers']),

    options(){
      return this.selectUsers
    }
  },
  methods: {
    ...UserModule.mapActions(['getUsersList']),

    customUserName(user){
      return `${user.firstName} ${user.lastName} ${ this.displayIds ? ' - ' + user._id : ''}`
    },
    updateUsers(){
      this.$emit('updateUsers', this.users)
    },
    dropdownClosed(){
      this.$emit('dropdownClosed')
    },
    fetchUsers: debounce(async function(query) {
      await this.getUsersList(query)
    }, 500),
  },
  mounted() {
    this.getUsersList()
  }
}
</script>
