<template>
  <table class="table table-hover table-responsive">
    <thead>
      <tr>
        <th v-for="header in headers" :key="header">
          <strong>{{ header }}</strong>
        </th>
      </tr>
    </thead>
    <tbody class="table-group-divider">
      <tr v-for="location in locations" :key="location.name">
        <td>
          {{ location['Location Name'] }}
        </td>
        <td>
          {{ location['Friendly Name'] }}
        </td>
        <td>
          {{ location.Address }}
        </td>
        <td
          :class="['col col-2', !location.isValidPhone ? 'text-danger border border-danger' : '']"
        >
          {{ formatPhoneNumber(location['Phone Number']) }}
        </td>
        <td>
          {{ location['Google Place ID'] }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'LocationUploadPreview',
  components: {},
  props: {
    headers: {
      type: Array,
    },
    locations: {
      type: Array,
    },
  },
  methods: {
    formatPhoneNumber(phone) {
      const cleaned = ('' + phone).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return phone
    },
  },
}
</script>

<style></style>
