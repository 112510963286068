import BandwidthService from '../services/BandwidthService'

export default {
  namespaced: true,

  state: {
    phoneNumbers: [],
  },

  getters: {
    selectPhoneNumbers(state) {
      return state.phoneNumbers
    },
  },
  mutations: {
    SET_PENDING_STATUSES(state, pendingNumbers) {
      state.phoneNumbers = state.phoneNumbers.map((phone) => ({
        ...phone,
        ...(pendingNumbers.includes(phone.phone)
          ? { status: 'PENDING', denialReasonDescription: null }
          : {}),
      }))
    },
    SET_PHONE_NUMBERS(state, phoneNumbers) {
      state.phoneNumbers = phoneNumbers
    },
  },

  actions: {
    async listPhoneNumbers({ commit }, { filters, limit, search }) {
      const response = await BandwidthService.listPhoneNumbers({ filters, limit, search })
      commit('SET_PHONE_NUMBERS', response.body.data.phoneNumbers)
    },
    async submitVerifications({ commit }, { phoneNumbers }) {
      await BandwidthService.submitPhoneVerifications({ phoneNumbers })
      commit('SET_PENDING_STATUSES', phoneNumbers)
    },
  },
}
