import CompanyService from '@/services/CompanyService'
import { get } from 'lodash'

export default {
  namespaced: true,
  state: {
    companies: [],
    activeCompany: undefined,
    activeCompanyTransactions: undefined,
    activeCompanyBusinessCredits: [],
    activeCompanyCustomerId: '',
    editBusinessCreditId: undefined,
    rawCompanies: {},
    industries: [],
    SurveySourceOverrides: [],
  },
  getters: {
    selectActiveCompanyBusinessCredits(state) {
      return state.activeCompanyBusinessCredits
    },
    selectCompanies(state) {
      return state.companies
    },
    selectActiveCompany(state) {
      return state.activeCompany
    },
    selectEditBusinessCreditId(state) {
      return state.editBusinessCreditId
    },
    selectRawCompany: (state) => (id) => {
      return state.rawCompanies[id]
    },
    selectIndustries: (state) => state.industries,
    selectSurveySourceOverrides(state) {
      return state.SurveySourceOverrides
    },
  },
  mutations: {
    setCompanies(state, companies) {
      state.companies = [
        ...companies.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase())),
      ]
    },
    setActiveCompany(state, company) {
      state.activeCompany = company
    },
    SET_RAW_COMPANIES(state, companies) {
      state.rawCompanies = {
        ...state.rawCompanies,
        ...companies.reduce((acc, cur) => ({ ...acc, [cur._id]: cur }), {}),
      }
    },
    setActiveCompanyTransactions(state, transactions) {
      state.activeCompanyTransactions = transactions
    },
    SET_ACTIVE_COMPANY_BUSINESS_CREDITS(state, businessCredits) {
      businessCredits.forEach((businessCredit) => {
        if (businessCredit.transactionType === 'DEBIT') {
          businessCredit.amount = -businessCredit.amount
        }
      })
      state.activeCompanyBusinessCredits = [
        ...businessCredits.filter((credit) => credit.voided !== true),
        ...businessCredits.filter((credit) => credit.voided === true),
      ]
    },
    setActiveCompanyPartner(state, partner) {
      if (partner === '') {
        delete state.activeCompany.partner
      } else {
        state.activeCompany.partner = partner
      }
    },
    setActiveCompanyCustomerId(state, customerId) {
      state.activeCompanyCustomerId = customerId
    },
    SET_ACTIVE_COMPANY_ORGANIZATION(state, organization) {
      state.activeCompany.organization = organization
    },
    SET_ACTIVE_COMPANY_FRIENDLY_NAME(state, friendlyName) {
      state.activeCompany.friendlyName = friendlyName
    },
    SET_EDIT_BUSINESS_CREDIT_ID(state, businessCreditId) {
      state.editBusinessCreditId = businessCreditId
    },
    UPDATE_COMPANY_CREDIT_BALANCE(state, amount) {
      state.activeCompany.textCreditBalance += amount
    },
    SET_INDUSTRIES(state, industries) {
      state.industries = industries
    },
    SET_SURVEY_SOURCE_OVERRIDES(state, overrides) {
      state.SurveySourceOverrides = overrides
    },
    SET_ACTIVE_COMPANY_CORPORATE_WEBSITE(state, url) {
      state.activeCompany.corporateWebsite = url
    },
  },
  actions: {
    async createBusinessCredit({ commit }, businessCredit) {
      const response = await CompanyService.createBusinessCredit(businessCredit)
      commit('UPDATE_COMPANY_CREDIT_BALANCE', businessCredit.amount)
      return response
    },
    async updateBusinessCredit({ commit, getters }, { businessCreditId, amount, notes, reason }) {
      const oldBusinessCredit = getters.selectActiveCompanyBusinessCredits.find(
        (credit) => credit._id === businessCreditId
      )
      const response = await CompanyService.updateBusinessCredit({
        businessCreditId,
        amount,
        notes,
        reason,
      })
      commit('UPDATE_COMPANY_CREDIT_BALANCE', amount - oldBusinessCredit.amount)
      return response
    },
    async voidBusinessCredit({ commit, getters }, businessCreditId) {
      const oldBusinessCredit = getters.selectActiveCompanyBusinessCredits.find(
        (credit) => credit._id === businessCreditId
      )
      const response = await CompanyService.voidBusinessCredit(businessCreditId)
      commit('UPDATE_COMPANY_CREDIT_BALANCE', -oldBusinessCredit.amount)
      return response
    },
    setBusinessCreditEditId({ commit }, businessCreditId) {
      commit('SET_EDIT_BUSINESS_CREDIT_ID', businessCreditId)
    },
    setActiveCompanyOrganization({ commit }, organization) {
      commit('SET_ACTIVE_COMPANY_ORGANIZATION', organization)
    },
    setActiveCompanyFriendlyName({ commit }, friendlyName) {
      commit('SET_ACTIVE_COMPANY_FRIENDLY_NAME', friendlyName)
    },
    setActiveCompanyCorporateWebsite({ commit }, url) {
      commit('SET_ACTIVE_COMPANY_CORPORATE_WEBSITE', url)
    },
    async getCompanyList({ commit }, options) {
      const { filters = {}, search } = options ?? {}
      const response = await CompanyService.list({
        search,
        filters,
      })
      commit('setCompanies', response.body.data.companies)
      commit('SET_RAW_COMPANIES', response.body.data.companies)
      return response.body.data.companies
    },
    async getCompany({ commit }, companyId) {
      const response = await CompanyService.get(companyId)

      commit('setActiveCompany', response.body.data.company)
      commit('setActiveCompanyCustomerId', get(response, 'body.payment.customer.id', ''))
      return response
    },
    async getCompanyTransactions({ commit }, companyId) {
      const response = await CompanyService.transactions({
        filters: { companyIds: [companyId] },
      })

      commit('setActiveCompanyTransactions', response.body)
    },
    async getCompanyBusinessCredits({ commit }, companyId) {
      const response = await CompanyService.getBusinessCredits({ companyId })
      commit('SET_ACTIVE_COMPANY_BUSINESS_CREDITS', response.body.data.businessCredits)
    },
    async assignOrganization({}, payload) {
      const response = await CompanyService.assignOrganization(payload)
      return response
    },
    async updateCompanySettings({}, payload) {
      const response = await CompanyService.update(payload.companyId, payload.fields)
      return response.body
    },
    async createCompany(_, payload) {
      const response = await CompanyService.create(payload)
      return response
    },
    async getCompanyTestDataPreview(_, payload) {
      const response = await CompanyService.getCompanyTestDataPreview(payload)
      return response
    },
    async fetchIndustries({ commit }) {
      const response = await CompanyService.fetchIndustries()
      commit('SET_INDUSTRIES', response.body.data.industries)
    },
    async uploadUsers(_, payload) {
      const response = await CompanyService.uploadUsers(payload)
      return response
    },
    async uploadLocations({ dispatch }, payload) {
      const response = await CompanyService.uploadLocations(payload)
      if (response && response.success) {
        await dispatch('location/getCompanyLocations', payload.companyId, { root: true })
      }
      return response
    },
    async createSurveySourceOverride({ dispatch }, payload) {
      const response = await CompanyService.createSurveySourceOverride(payload)
      if (response && response.data) {
        await dispatch('company/fetchSurveySourceOverrides', payload, { root: true })
      }
    },
    async deleteSurveySourceOverride({ dispatch }, payload) {
      const response = await CompanyService.deleteSurveySourceOverride(payload)
      if (response?.data || response?.success) {
        await dispatch('company/fetchSurveySourceOverrides', payload, { root: true })
      }
    },
    async fetchSurveySourceOverrides({ commit }, payload) {
      const response = await CompanyService.getSurveySourceOverrides(payload)
      if (response && response.data) {
        commit('SET_SURVEY_SOURCE_OVERRIDES', response.body.data)
      }
    },
  },
}
