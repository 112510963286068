<template>
  <div class="card-text small text-center text-muted mb-4">
    <div v-for="product in products" :key="product._id">
        <span v-if="product.type == 'tent'" class="badge badge-soft-primary">
            <i class="fas fa-qrcode"></i> {{ product.amount }} Table Tents
        </span>
        &nbsp;
        <span v-if="product.type == 'acrylic'" class="badge badge-soft-warning">
            <i class="fe fe-shield"></i> {{ product.amount }} Acrylic
        </span>
        &nbsp;
        <span v-if="product.type == 'kiosk'" class="badge badge-soft-success">
            <i class="fas fa-tablet-alt"></i> {{ product.amount }} Kiosk
        </span>                
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderProductBadges',
  props: ['products']
}
</script>

<style scoped lang="scss">
</style>
