<template>
  <div class="main-content">
    <div class="container-fluid">
      <!-- header -->
      <div class="header mt-md-5">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <div class="d-flex">
                <h1 class="header-title display-4">Classifier</h1>
                <span class="d-flex align-items-end ml-3 text-muted">
                  {{ selectRemainingCount }} remaining
                </span>
              </div>
            </div>
            <div class="col-auto">
              <div class="d-flex align-items-center">
                <span class="text-muted mr-3">Filter Feedback:</span>
                <Multiselect
                  selectLabel
                  :show-labels="false"
                  v-model="selectedStatus"
                  @input="changeStatus"
                  :options="statusOptions"
                  track-by="value"
                  label="title"
                >
                </Multiselect>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- body -->
      <FullLoader v-if="loading" />
      <div v-else>
        <div v-if="!Object.keys(selectFeedbackData).length">
          <h2>- Nothing more to classify at the moment</h2>
        </div>
        <div v-else>
          <div class="body">
            <div class="row">
              <div class="col col-md-6 py-4 px-6 border-right">
                <div class="d-flex justify-content-between px-1">
                  <h3>Feedback</h3>
                  <span class="text-muted">
                    {{ selectFeedbackData.companyName }}
                  </span>
                </div>
                <div class="card p-4">"{{ selectFeedbackText }}"</div>
                <!-- badges -->
                <div class="mt-5">
                  <h3>Classifications</h3>
                  <div v-if="badges.length">
                    <h1 v-for="(badge, index) in badges" :key="index">
                      <span
                        class="badge"
                        :class="{
                          'bg-light-green': badge.sentiment === 'positive',
                          'bg-light-red': badge.sentiment === 'negative',
                        }"
                        :style="{ color: badge.sentiment === 'positive' ? 'green' : 'red' }"
                      >
                        {{ badge.category.name }}
                        <span v-if="badge.subcategory">: {{ badge.subcategory.name }}</span>
                        <span class="ml-3 clickable-danger" @click="removeBadge(index)">x</span>
                      </span>
                    </h1>
                  </div>
                  <div v-else>No classifications yet...</div>
                </div>
              </div>

              <div class="col col-md-6 py-4 px-6">
                <div class="d-flex justify-content-between">
                  <!-- sentiment -->
                  <div class="text-center">
                    <h3>Sentiment</h3>
                    <button
                      class="btn tile bg-light-green d-block mb-3"
                      @click="selectSentiment('positive')"
                      v-hotkey="{ p: keybindWrapper('positive', 'selectSentiment') }"
                      :class="{ 'active-tile': selectedSentiment === 'positive' }"
                    >
                      <div class="d-flex align-items-center">
                        <span class="border px-2 pb-1 bg-white ml--2 mr-3 text-muted">p</span>
                        <i class="fas fa-smile fa-3x" style="color: green"></i>
                      </div>
                    </button>
                    <button
                      class="btn tile bg-light-red"
                      @click="selectSentiment('negative')"
                      v-hotkey="{ n: keybindWrapper('negative', 'selectSentiment') }"
                      :class="{ 'active-tile': selectedSentiment === 'negative' }"
                    >
                      <div class="d-flex align-items-center">
                        <span class="border px-2 bg-white ml--2 mr-3 text-muted">n</span>
                        <i class="fas fa-frown fa-3x" style="color: red"></i>
                      </div>
                    </button>
                  </div>

                  <!-- category -->
                  <div class="text-center px-4">
                    <h3>Category</h3>
                    <!-- main -->
                    <div v-for="(category, id, index) in selectCategories" :key="id">
                      <button
                        class="btn cat-btn text-center text-dark"
                        :class="{
                          'btn-primary': category === selectedCategory,
                          'btn-light': category !== selectedCategory,
                        }"
                        @click="selectCategory(category)"
                        v-hotkey="{ [keys[index]]: keybindWrapper(category, 'selectCategory') }"
                      >
                        <div class="row">
                          <div class="col-2 pr-0 pl-2">
                            <span class="border px-2 bg-white mr-1 text-muted">
                              {{ keys[index] }}
                            </span>
                          </div>
                          <div class="col-8">{{ category.name }}</div>
                        </div>
                      </button>
                    </div>
                    <!-- defaults -->
                    <div v-for="(category, id, index) in selectDefaultCategories" :key="id">
                      <button
                        class="btn btn-secondary cat-btn text-dark"
                        @click="addPartialBadge(category)"
                        v-hotkey="{
                          [defaultKeys[index]]: keybindWrapper(category, 'addPartialBadge'),
                        }"
                        :disabled="!selectedSentiment"
                      >
                        <div class="row">
                          <div class="col-2 pr-0 pl-2">
                            <span class="border px-2 bg-white mr-1 text-muted">
                              {{ defaultKeys[index] }}
                            </span>
                          </div>
                          <div class="col-8">{{ category.name }}</div>
                        </div>
                      </button>
                    </div>
                  </div>

                  <!-- subcategory -->
                  <div class="text-center">
                    <h3>Subcategory</h3>
                    <div v-if="selectedCategory && selectedSentiment">
                      <!-- main -->
                      <div
                        v-for="(value, id, index) in selectedCategory.subcategories"
                        :key="'A' + id"
                      >
                        <div v-if="value.name !== 'Other'">
                          <button
                            class="btn btn-light cat-btn"
                            @click="addSubcategory(value)"
                            v-hotkey="{ [`${index + 1}`]: keybindWrapper(value, 'addSubcategory') }"
                          >
                            <div class="row">
                              <div class="col-2 pr-0 pl-2">
                                <span class="border px-2 bg-white mr-1 text-muted">
                                  {{ index + 1 }}
                                </span>
                              </div>
                              <div class="col-8">{{ value.name }}</div>
                            </div>
                          </button>
                        </div>
                      </div>
                      <!-- defaults -->
                      <div
                        v-for="(value, id, index) in defaultSubcategories(selectedCategory)"
                        :key="id"
                      >
                        <button
                          class="btn btn-secondary cat-btn text-dark"
                          @click="addSubcategory(value)"
                          v-hotkey="{
                            [subDefaultKeys[index]]: keybindWrapper(value, 'addSubcategory'),
                          }"
                        >
                          <div class="row">
                            <div class="col-2 pr-0 pl-2">
                              <span class="border px-2 bg-white mr-1 text-muted">
                                {{ subDefaultKeys[index] }}
                              </span>
                            </div>
                            <div class="col-8">{{ value.name }}</div>
                          </div>
                        </button>
                      </div>
                      <!-- additional -->
                      <div>
                        <button
                          class="btn btn-secondary cat-btn"
                          @click="addPartialBadge(selectedCategory)"
                          v-hotkey="{
                            [subDefaultKeys[1]]: keybindWrapper(
                              selectedCategory,
                              'addPartialBadge'
                            ),
                          }"
                        >
                          <div class="row">
                            <div class="col-2 pr-0 pl-2">
                              <span class="border px-2 bg-white mr-1 text-muted">
                                {{ subDefaultKeys[1] }}
                              </span>
                            </div>
                            <div class="col-8">None</div>
                          </div>
                        </button>
                      </div>
                    </div>
                    <div v-else>No category/sentiment</div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- footer -->
          <div class="footer border-top pt-4">
            <div class="d-flex justify-content-between">
              <div>
                <button
                  class="btn btn-outline-secondary"
                  @click="mark('needs_review')"
                  v-hotkey="{ q: keybindWrapper('needs_review', 'mark') }"
                >
                  <span class="border px-1 bg-white mr-1 text-dark">q</span> Not Sure?
                </button>
                <button
                  class="btn btn-outline-secondary ml-2"
                  @click="mark('skipped')"
                  v-hotkey="{ esc: keybindWrapper('skipped', 'mark') }"
                >
                  <span class="border px-1 bg-white mr-1 text-dark">esc</span>
                  <span>Skip</span>
                </button>
                <button
                  class="btn btn-outline-secondary ml-2"
                  @click="undoBadge()"
                  v-hotkey="{ u: undoBadge }"
                >
                  <span class="border px-1 bg-white mr-1 text-dark">u</span>
                  <span>Undo last badge</span>
                </button>
              </div>
              <button
                class="btn btn-primary btn-lg"
                style="width: 150px"
                @click="next()"
                v-hotkey="{ enter: keybindWrapper(null, 'next') }"
                :disabled="!badges.length"
              >
                Next <span class="border border-secondary px-1 ml-2 text-muted">enter</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import FullLoader from '@/components/MainContent/FullLoader'
import Multiselect from 'vue-multiselect'
import _ from 'lodash-es'

const ClassifierModule = createNamespacedHelpers('classifier')

export default {
  name: 'ClassifierPage',
  components: {
    FullLoader,
    Multiselect,
  },
  data: () => ({
    loading: true,
    badges: [],
    selectedSentiment: null,
    selectedCategory: null,
    selectedSubcategory: null,
    keys: ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k'],
    defaultKeys: ['o'],
    subDefaultKeys: ['0', '-'],
    selectedStatus: { title: 'All Unclassified', value: 'unclassified' },
    statusOptions: [
      { title: 'All Unclassified', value: 'unclassified' },
      { title: 'All Skipped', value: 'skipped' },
      { title: 'All Needing Review', value: 'needs_review' },
    ],
  }),
  async mounted() {
    await Promise.all([this.fetchFeedbackToClassify(), this.fetchClassifierCategories()])
    this.loading = false
  },
  computed: {
    ...ClassifierModule.mapGetters([
      'selectCategories',
      'selectDefaultCategories',
      'selectFeedbackText',
      'selectFeedbackData',
      'selectRemainingCount',
    ]),
  },
  methods: {
    ...ClassifierModule.mapActions([
      'fetchFeedbackToClassify',
      'fetchClassifierCategories',
      'markStatus',
      'classify',
      'setClassificationStatus',
    ]),
    selectSentiment(sentiment) {
      this.selectedSentiment = sentiment
    },
    selectCategory(category) {
      this.selectedCategory = category
    },
    keybindWrapper(value, funcName) {
      const self = this
      return funcName === 'selectSentiment'
        ? () => self.selectSentiment(value)
        : funcName === 'selectCategory'
        ? () => self.selectCategory(value)
        : funcName === 'addPartialBadge'
        ? () => self.addPartialBadge(value)
        : funcName === 'addSubcategory'
        ? () => self.addSubcategory(value)
        : funcName === 'undoBadge'
        ? () => self.undoBadge()
        : funcName === 'mark'
        ? () => self.mark(value)
        : funcName === 'next'
        ? () => self.next()
        : () => {}
    },
    undoBadge() {
      this.badges.pop()
    },
    defaultSubcategories(category) {
      return _.flow([
        Object.entries,
        (arr) => arr.filter(([k, v]) => v.name === 'Other'),
        Object.fromEntries,
      ])(category.subcategories)
    },
    addSubcategory(subcategory) {
      if (!this.selectedSentiment || !this.selectedCategory) return
      this.selectedSubcategory = subcategory
      this.addFullBadge()
    },
    addPartialBadge(category) {
      if (!this.selectedSentiment) return
      this.addBadge({ sentiment: this.selectedSentiment, category })
    },
    addFullBadge(subcategory) {
      if (subcategory) {
        this.addBadge({
          sentiment: this.selectedSentiment,
          category: this.selectedCategory,
          subcategory,
        })
      } else {
        this.addBadge({
          sentiment: this.selectedSentiment,
          category: this.selectedCategory,
          subcategory: this.selectedSubcategory,
        })
      }
    },
    addBadge(newBadge) {
      if (!this.isDuplicateBadge(newBadge)) {
        this.badges.push(newBadge)
      }
      this.resetSelectors()
    },
    removeBadge(index) {
      this.badges.splice(index, 1)
    },
    isDuplicateBadge(newBadge) {
      let isDuplicate = false
      this.badges.forEach((badge) => {
        if (_.isEqual(badge, newBadge)) {
          isDuplicate = true
        }
      })
      return isDuplicate
    },
    resetSelectors() {
      this.selectedSentiment = null
      this.selectedCategory = null
      this.selectedSubcategory = null
    },
    resetAll() {
      this.badges = []
      this.resetSelectors()
    },
    prepClassifications() {
      return this.badges.map((badge) => {
        return {
          sentiment: badge.sentiment,
          category: badge.subcategory ? badge.subcategory._id : badge.category._id,
        }
      })
    },
    async changeStatus(selection) {
      this.setClassificationStatus(selection.value)
      this.resetAll()
      this.loading = true
      await this.fetchFeedbackToClassify()
      this.loading = false
    },
    async next() {
      if (!this.badges.length) return
      try {
        this.loading = true
        const classifications = this.prepClassifications()
        await this.classify(classifications)
        this.resetAll()
        await this.fetchFeedbackToClassify()
        this.loading = false
      } catch (error) {
        console.log(error)
        this.$notify({
          title: 'Failed to Save Classifications',
          text: 'Screenshot Error In Console, Then Refresh To Continue',
          type: 'error',
          duration: 5000,
        })
      }
    },
    async mark(status) {
      this.loading = true
      await this.markStatus(status)
      await this.fetchFeedbackToClassify()
      this.loading = false
    },
    back() {}, // not implementing on first pass
  },
}
</script>

<style scoped>
.clickable-danger:hover {
  color: red;
  cursor: pointer;
}
.body {
  display: flex;
  flex-direction: column;
  min-height: 70vh;
}
.tile {
  width: 130px;
  height: 100px;
}
.bg-light-green {
  background: #ccf7e5;
}
.bg-light-red {
  background: #ffcccb;
}
.cat-btn {
  width: 150px;
  margin-bottom: 12px;
}
.active-tile {
  border: 4px solid blue;
}
</style>
