import Vue from 'vue'

export default {
  get(customerId) {
    return Vue.http.get(`billing/stripe/${customerId}`)
  },
  connectStripeAccount({ customerId, companyId, locationId}) {
    return Vue.http.post(`billing/stripe/connect`, { customerId, companyId, locationId })
  },
  getPayments(customerId) {
    return Vue.http.get(`billing/stripe/${customerId}/payments`)
  }
}
