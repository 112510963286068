<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <!-- Search -->
          <form @submit.prevent class="row align-items-center">
            <div class="col-auto pr-0">
              <span class="fe fe-search text-muted"></span>
            </div>
            <div class="col">
              <input
                type="search"
                class="form-control form-control-flush search"
                placeholder="Search"
                v-model="search"
              />
            </div>
          </form>
        </div>
        <div class="col-auto">
          <!-- Button -->
          <div class="dropdown">
            <!-- <button aria-expanded="false" aria-haspopup="true" class="btn btn-sm btn-white dropdown-toggle" data-toggle="dropdown" id="bulkActionDropdown" type="button">Bulk action</button>
            <div aria-labelledby="bulkActionDropdo  wn" class="dropdown-menu dropdown-menu-right">
              <a class="dropdown-item" href="#!">Action</a> <a class="dropdown-item" href="#!">Another action</a> <a class="dropdown-item" href="#!">Something else here</a>
            </div>-->
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-hover table-nowrap card-table">
        <thead>
          <tr>
            <th v-for="column in columns" :key="column.title">
              <a
                :class="{ sort: column.sortable, 'text-muted': sort != column.title }"
                href="#"
                @click="
                  sort = column.title
                  descending = !descending
                "
                >{{ column.friendly ? column.friendly : column.title }}</a
              >
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in locations" :key="row._id" @click="viewLocation(row)">
            <td>
              {{ row.created | moment('MMM Do YYYY') }}
              <span v-if="row.partner" class="badge badge-soft-primary">{{
                row.partner.name
              }}</span>
            </td>
            <td>
              <div class="truncate">{{ row.name }}</div>
            </td>
            <td>
              {{ row.city }} {{ row.friendlyName ? `(${row.friendlyName})` : '' }}
              <span v-if="row.disabled" class="small text-muted">(disabled)</span>
            </td>
            <td>{{ row.phone }}</td>
            <td>{{ row.activationCode }}</td>
            <td>
              <div class="avatar-group">
                <div
                  class="avatar avatar-xs"
                  v-for="platform in getPlatforms(row)"
                  :key="platform._id"
                >
                  <img
                    :src="`/img/platforms/${platform.network}.png`"
                    alt="..."
                    class="avatar-img rounded-circle"
                  />
                </div>
              </div>
            </td>
            <td>
              <div v-for="device in row.devices" :key="device._id">
                <div
                  class="badge"
                  :class="[
                    {
                      'badge-success': minutesAgo(device.lastPing) < 1,
                      'badge-danger': minutesAgo(device.lastPing) > 1 || !device.lastPing,
                    },
                  ]"
                >
                  {{ device.device || 'iPad' }}
                </div>
              </div>
            </td>
            <!-- <td>      
              <div class="dropdown d-inline-block">
                <a class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" href="#!" role="button"><i class="fe fe-more-vertical"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" @click="deleteLocation(row._id)" href="javascript:;">
                    <span class="text-danger">Delete</span>
                  </a>
                </div>
              </div>
            </td>-->
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import { debounce } from 'lodash-es'

const { mapMutations, mapState, mapActions } = createNamespacedHelpers('location')
const AuthModule = createNamespacedHelpers('auth')

export default {
  name: 'LocationsTable',
  data: () => {
    return {
      searchResults: [],
      searchable: ['name', 'city', 'friendly'],
      search: '',
      sort: 'created',
      descending: true,
      columns: [
        { title: 'created', sortable: true },
        { title: 'name', sortable: true },
        { title: 'city', sortable: true },
        { title: 'phone', sortable: false },
        {
          title: 'activationCode',
          sortable: true,
          friendly: 'Activation',
        },
        { title: 'platforms', sortable: true },
        { title: 'devices', sortable: true },
      ],
    }
  },
  computed: {
    ...mapState(['locations']),
    ...AuthModule.mapGetters(['selectEmail']),
    orderedData: function () {
      return _.orderBy(this.locations, this.sort, this.descending ? 'desc' : 'asc')
    },
  },
  methods: {
    ...mapActions(['getLocationList']),
    getPlatforms(location) {
      return location.subscriptions
    },
    deleteLocation(locatonId) {
      console.log('delete location', locatonId)
    },
    viewLocation(location) {
      this.$router.push({
        name: 'location-detail',
        params: { id: location._id },
      })
    },
    minutesAgo(timestamp) {
      return this.$moment.duration(this.$moment().diff(timestamp)).asMinutes()
    },
  },
  mounted() {
    this.getLocationList({ filters: {} })
  },
  created() {
    this.$on('dataChanged', (results) => {
      this.searchResults = results
    })
  },
  watch: {
    search: debounce(function () {
      this.getLocationList({ filters: {}, search: this.search })
    }, 500),
  },
}
</script>

<style scoped lang="scss">
.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
