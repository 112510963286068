import OrderService from '@/services/OrderService'

export default {
  namespaced: true,
  state: {
    orders: []
  },
  mutations: {
    setOrders(state, orders) {
      state.orders = [...orders]
    }
  },
  actions: {
    async getOrdersList({ commit }) {
      const response = await OrderService.list()
      commit('setOrders', response.body)
    }
  }
}
