<template>
  <v-dialog />
</template>

<script>
export default {
  name: 'RevokeApiTokenModal',
  mounted() {
    this.$root.$on('v::show::revoke-api-token-modal', () => {
      this.$modal.show('dialog', {
        title: 'Revoke Token',
        text: 'This will revoke the api key and the integration will no longer be able to access our system. A new token will need to be issued and distributed to our partner for them to regain access',
        buttons: [
          {
            title: 'Revoke',
            handler: () => {
              this.$emit('confirm')
              this.$modal.hide('dialog')
            },
          },
          {
            title: 'Cancel',
          },
        ],
      })
    })
  },
}
</script>

<style scoped>
</style>
