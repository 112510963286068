<template>
  <b-modal
    id="UploadUsersModal"
    hide-footer
    hide-header
    centered
    scrollable
    body-class="p-0"
    size="larger"
    @hidden="clearState"
  >
    <div class="px-4 pt-4 modal-dialog-scrollable">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <div>
          <h2>
            {{ modalTitle }}
            <br />
            <small class="text-muted" v-if="step === 'preview' && users.length > 1"
              >Count: {{ users.length }} users</small
            >
          </h2>
        </div>
        <pre class="text-danger" v-if="!isValidUser">Invalid fields are marked in red</pre>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>
      <!-- body-->
      <div class="d-flex justify-content-between flex-column">
        <div class="py-3 form-group d-flex align-items-center" v-if="step === 'init'">
          <div class="mb-1">
            <input
              v-model="cognitoAuthentication"
              class="mr-2 mb-1"
              type="checkbox"
              name=""
              id="email-login"
            />
          </div>
          <div class="mt-1"><label for="email-login">Email Login</label></div>
        </div>
        <div class="row scrollable-view justify-content-center">
          <div class="py-5" v-if="step === 'init'">
            <CSVFileUploader
              @uploaded="handleUploaded"
              buttonClass="px-4"
              button-text="Select File"
            />
          </div>
          <div class="pt-3" v-if="step === 'preview'">
            <UsersUploadPreview
              :headers="headers"
              :users="users"
              :companyId="companyId"
              @locationChange="handleLocationChange"
            />
          </div>
          <div class="pt-3" v-if="step === 'errors'">
            <UsersUploadErrors :userErrors="errors" />
          </div>
        </div>
        <div class="row py-4 justify-content-between">
          <div class="d-flex">
            <button v-if="step === 'preview'" class="btn btn-default" @click.prevent="clearState">
              Cancel
            </button>
          </div>
          <div>
            <button
              v-if="step === 'preview'"
              :disabled="isDisabled"
              class="btn btn-primary"
              @click.prevent="inviteUsers"
            >
              {{ isLoading ? 'Creating...' : 'Create' }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { sortBy } from 'lodash-es'
import CSVFileUploader from '../Modules/files/CSVFileUploader.vue'
import UsersUploadErrors from '../Modules/Users/UsersUploadErrors.vue'
import UsersUploadPreview from '../Modules/Users/UsersUploadPreview.vue'
import { createNamespacedHelpers } from 'vuex'
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'UploadUsersModal',
  components: {
    CSVFileUploader,
    UsersUploadErrors,
    UsersUploadPreview,
  },
  props: {
    companyId: {
      type: String,
    },
  },

  data() {
    return {
      step: 'init',
      headers: [],
      users: [],
      errors: [],
      isLoading: false,
      modalTitle: 'Inivite Users',
      isValidUser: true,
      roles: ['admin', 'location-manager', 'employee'],
      cognitoAuthentication: false,
    }
  },
  computed: {
    isDisabled() {
      return !this.users.length || !this.isValidUser
    },
  },
  methods: {
    ...CompanyModule.mapActions(['uploadUsers']),

    close() {
      this.clearState()
      this.$root.$emit('bv::hide::modal', 'UploadUsersModal')
    },
    clearState() {
      this.step = 'init'
      this.headers = []
      this.users = []
      this.errors = []
      this.isLoading = false
      this.isValidUser = true
      this.modalTitle = 'Invite Users'
      this.cognitoAuthentication = false
    },
    handleUploaded({ headers, data }) {
      if (data && data.length) {
        this.headers = [...headers, 'Location(s)']
        this.users = this.validateUsers(data)
        this.modalTitle = 'Preview'
        this.step = 'preview'
      }
    },
    formatPhone(phone) {
      if (!phone) return
      phone = phone.replace(/[^\w]/g, '')
      if (phone.startsWith('1') && phone.length > 10) {
        phone = phone.substring(1)
      }
      return phone
    },
    isValidPhone(phone) {
      if (!phone) return false
      const regex = new RegExp(/^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/)
      return regex.test(phone)
    },
    isValidEmail(email) {
      if (!email) return false
      const regex = new RegExp(/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
      return regex.test(email)
    },
    validateUsers(users) {
      if (!users || !users.length) return
      users = users.map((item) => {
        const user = { ...item, Phone: this.formatPhone(item.Phone) }
        const isValidRole = this.roles.includes(user.Role)
        const isValidPhone = this.isValidPhone(user.Phone)
        const isValidEmail = this.isValidEmail(user.Email)
        if (!isValidPhone && !this.cognitoAuthentication) {
          this.isValidUser = false
          user.isValidPhone = false
        }
        if (!isValidRole) {
          this.isValidUser = false
          user.isValidRole = false
        }
        if (!isValidEmail) {
          this.isValidUser = false
          user.isValidEmail = false
        }
        return user
      })
      return sortBy(users, (user) => user.isValidPhone || user.isValidEmail || user.isValidRole)
    },
    async inviteUsers() {
      this.isLoading = true
      if (!this.users.length) {
        this.isLoading = false
        return
      }
      const payload = {
        users: this.users.map((user) => {
          return {
            role: user.Role,
            phone: user.Phone,
            email: user.Email,
            firstName: user['First Name'],
            lastName: user['Last Name'],
            companies: [this.companyId],
            locations: user.locations ? user.locations.map((loc) => loc._id) : [],
          }
        }),
        companyId: this.companyId,
        cognitoAuthentication: this.cognitoAuthentication,
      }

      try {
        const {
          data: { errors, users: createdUserIds },
        } = (await this.uploadUsers(payload))?.data || {}

        const total = this.users.length

        if (errors?.length) {
          this.errors = [...errors]
          const totalCreated = total - errors.length
          this.$notify({
            type: 'warn',
            text: `${totalCreated} out of ${total} users created. Please check the table for the reason`,
            duration: 5000,
          })
          this.step = 'errors'
          this.modalTitle = 'User Creation Errors'
        } else {
          this.$notify({
            type: 'success',
            text: `${total} users created successfully`,
            duration: 4000,
          })
          this.close()
        }
      } catch (error) {
        this.$notify({
          type: 'error',
          text: 'Error uploading users. Please try again.',
        })
      }
      this.isLoading = false
    },
    handleLocationChange({ index, values }) {
      this.users[index] = {
        ...this.users[index],
        locations: values,
      }
    },
  },
}
</script>

<style lang="scss">
.scrollable-view {
  overflow-y: auto;
  max-height: 750px;
}
.modal .modal-larger {
  width: 950px !important;
  max-width: 950px !important;
}
</style>
