<template>
  <div class="card">
    <div class="card-body">
      <h2 class="mb-4">Company Location Data</h2>
      <div class="form-group">
        <div class="row">
          <!-- address 1 -->
          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="address1">Address</label>
            <input
              v-model="formData.businessAddress1"
              type="text"
              placeholder="123 E Main St"
              id="address1"
              class="form-control"
            />
          </div>

          <!-- address 2 -->
          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="address2">Address 2</label>
            <input
              v-model="formData.businessAddress2"
              type="text"
              placeholder="Unit L"
              id="address2"
              class="form-control"
            />
          </div>

          <!-- city -->
          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="businessCity">City</label>
            <input
              v-model="formData.city"
              type="text"
              placeholder="Spokane"
              id="businessCity"
              class="form-control"
            />
          </div>

          <!-- state -->
          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="businessState">State/Province</label>
            <input
              v-model="formData.state"
              type="text"
              placeholder="WA"
              id="businessState"
              class="form-control"
            />
          </div>

          <!-- zip -->
          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="businessZip">Zip</label>
            <input
              v-model="formData.zip"
              type="text"
              placeholder="84058"
              id="businessZip"
              class="form-control"
            />
          </div>

          <!-- country -->
          <div class="col-lg-6 col-sm-12 mb-3">
            <label for="businessCountry">Country</label>
            <input
              v-model="formData.country"
              type="text"
              placeholder="US"
              id="businessCountry"
              class="form-control"
            />
          </div>
        </div>
      </div>

      <div class="form-group mb-0">
        <button @click.prevent="updateCompanyAddressData" class="btn btn-primary">
          Save settings
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CompanyAddressForm',
  props: {
    company: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    formData: {
      businessAddress1: '',
      businessAddress2: '',
      city: '',
      state: '',
      zip: '',
      country: '',
    },
  }),
  mounted() {
    this.formData.businessAddress1 = this.company.businessAddress1 || ''
    this.formData.businessAddress2 = this.company.businessAddress2 || ''
    this.formData.city = this.company.city || ''
    this.formData.state = this.company.state || ''
    this.formData.zip = this.company.zip || ''
    this.formData.country = this.company.country || ''
  },
  methods: {
    updateCompanyAddressData() {
      this.$emit('updateCompanyAddressData', this.formData)
    },
  },
}
</script>
