import AuthService from '@/services/AuthService'

export default {
  namespaced: true,
  state: {
    auth: {},
    details: {},
  },
  getters: {
    selectEmail: (state) => state?.auth?.email,
    selectIsPartner: (state) => state?.auth?.isPartner,
    selectIsClassifier(state) {
      return state?.auth?.isClassifier
    },
  },
  mutations: {
    setAuth(state, auth) {
      state.auth = auth
    },
  },
  actions: {
    async getAuth({ commit }) {
      const response = await AuthService.get()

      commit('setAuth', response.body.auth)
    },
  },
}
