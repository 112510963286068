<template>
  <div class="landing">
    <div class="loader loader-primary"></div>
  </div>
</template>

<script>
export default {
  name: 'LandingView'
}
</script>

<style scoped lang="scss">
.landing {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
