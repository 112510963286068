<template>
  <b-modal id="AddRedirectURLModal" 
    hide-footer 
    hide-header
    centered
    @hide="closeModal"
    body-class="p-0" 
    size="md">
    <div class="px-4 pt-4">
      <!-- body -->
      <div class="card-body px-4">
        <div class="header-title mb-4" style="font-size: 20px">
          Add URL
        </div>
        <div class="form-group">
          <input
          placeholder="https://ovationup.com/insights"
          class="form-control" 
          type="text" v-model="form.redirectURL">
          <small v-if="!form.isValid" class="text-danger">
            Please enter a properly formatted URL and then try again.
          </small>
        </div>
        <div class="form-group text-right mt-4">
          <button
          style="font-size: 20px"
          class="btn btn-primary px-5" 
          @click.prevent="saveURL">Save</button>
        </div>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
const BannerModule = createNamespacedHelpers('banner')

export default {
  name: 'AddRedirectURLModal',
  data(){
    return {
      form: {
        redirectURL: null,
        isValid: true,
      }
    }
  },
  computed: {
    ...BannerModule.mapGetters(['selectBannerToBeModified']),

    banner() {
      return this.selectBannerToBeModified
    },
  },
  watch: {
    banner(value) {
      this.form.redirectURL = 
      (value && value.redirectURL) ? value.redirectURL : ''
    }
  },
  methods: {
    saveURL(){
      if(this.isValidateURL(this.form.redirectURL)){
        this.form.isValid = true
        const payload = {
          bannerId: this.banner._id,
          updateFields: {
            redirectURL: this.form.redirectURL
          }
        }
        this.$emit('updateRedirectURL', payload)
        this.closeModal()
      }
      else {
        this.form.isValid = false
      }
    },
    closeModal(){
      this.form.isValid = true
      this.form.redirectURL = ''
      this.$emit('modalClosed')
      this.$root.$emit('bv::hide::modal', 'AddRedirectURLModal')
    },
    isValidateURL(url){
      if(!url.length){
        // url can be empty
        return true
      }
      return new RegExp(/^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g)
      .test(url)
    }
  },
}
</script>