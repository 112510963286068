<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <!-- Header -->
          <div class="header mt-md-5">
            <div class="header-body">
              <!-- Pretitle -->
              <h6 class="header-pretitle">Danger Zone</h6>

              <!-- Title -->
              <h1 class="header-title display-4">Location Actions</h1>
            </div>
          </div>
          <h2 class="header-subtitle display-5">Transfer Location</h2>
          <p>This will transfer a location from it's current company to a new company</p>
          <div class="row">
            <div class="col">
              <form @submit.prevent="transfer">
                <div class="form-group">
                  <label for="locationId">Location Id</label>
                  <multiselect
                    id="locationId"
                    aria-describedby="locationIdHelp"
                    placeholder="Search location..."
                    selectLabel
                    v-model="form.location"
                    :multiple="false"
                    @input="updateLocations"
                    :searchable="true"
                    :showLabels="false"
                    :limitText="(count) => `+${count} more`"
                    :limit="1"
                    :options="locations"
                    :custom-label="locationName"
                    track-by="_id"
                  >
                  </multiselect>
                  <small v-if="currentCompany" id="locationIdHelp" class="form-text text-muted">
                    Location's current company: {{ currentCompany.name }} — ({{ currentCompany._id }})
                  </small>
                </div>
                <div class="form-group">
                  <label for="companyId">Company Id</label>
                  <multiselect
                    id="companyId"
                    aria-describedby="companyIdHelp"
                    placeholder="Search company..."
                    selectLabel
                    v-model="form.company"
                    :multiple="false"
                    @input="updateCompanies"
                    :searchable="true"
                    :showLabels="false"
                    :limitText="(count) => `+${count} more`"
                    :limit="1"
                    :options="companies"
                    :custom-label="companyName"
                    track-by="_id"
                  >
                  </multiselect>
                  <small id="companyIdHelp" class="form-text text-muted"
                    >Be sure this is the company id you intend</small
                  >
                </div>
                <button
                  :disabled="isLoading || !form.company || !form.location"
                  type="submit"
                  class="btn btn-primary"
                >
                  Transfer
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import Multiselect from 'vue-multiselect'
const { mapActions, mapState } = createNamespacedHelpers('location')
const { mapActions: mapCompanyActions, mapState: mapCompanyState } =
  createNamespacedHelpers('company')

export default {
  name: 'DangerZone',
  components: {
    multiselect: Multiselect,
  },
  data() {
    return {
      isLoading: false,
      errMessage: false,
      form: {
        location: undefined,
        company: undefined,
      },
      currentCompany: undefined,
    }
  },
  computed: {
    ...mapState(['locations']),
    ...mapCompanyState(['companies']),
  },
  methods: {
    ...mapActions(['transferLocation', 'getLocationList']),
    ...mapCompanyActions(['getCompanyList']),
    locationName({ _id, city, friendlyName, name }) {
      return `${name} — ${friendlyName || city} — (${_id})`
    },
    companyName({ _id, name }) {
      return `${name} — (${_id})`
    },
    updateLocations(location) {
      this.$emit('updateLocations', location)
      console.log(location.company)
      this.currentCompany = location.company
    },
    updateCompanies(company) {
      this.$emit('updateCompanies', company)
    },
    async transfer() {
      this.isLoading = true
      try {
        console.log(this.form.location._id)
        console.log(this.form.company._id)

        // await this.transferLocation({
        //   location: this.form.location,
        //   company: this.form.company,
        // })
      } catch (error) {
        this.errMessage = error
      }
      this.isLoading = false
    },
  },
  mounted() {
    this.getLocationList({ filters: {} })
    this.getCompanyList()
  },
}
</script>

<style scoped lang="scss">
</style>
