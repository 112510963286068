import UserService from '@/services/UserService'

export default {
  namespaced: true,
  state: {
    users: [],
    rawUsers: {},
  },
  getters: {
    selectUsers(state) {
      return state.users
    },
    selectRawUser: (state) => (id) => {
      return state.rawUsers[id]
    },
  },
  mutations: {
    setLoggedInUser(state, users) {
      state.users = [...users]
    },
    setUsers(state, users) {
      state.users = [...users]
    },
    SET_RAW_USERS(state, users) {
      state.rawUsers = {
        ...state.rawUsers,
        ...users.reduce((acc, cur) => ({ ...acc, [cur._id]: cur }), {}),
      }
    },
  },
  actions: {
    async getUsersList({ commit }, name) {
      const response = await UserService.list(name)
      commit('setUsers', response.body.users)
    },

    async createUser(_, payload) {
      return UserService.createUser(payload)
    },
    async getUsers({ commit }, query) {
      const response = await UserService.getUsers(query)
      commit('SET_RAW_USERS', response.body.users)
    },
    async getUserByFbId({ commit, dispatch }) {
      const response = await UserService.fetchUserByFbId()
      return response.body.data.user
    },
    async createOvationInvite(_, payload) {
      const response = await UserService.createOvationInvite(payload)
      return response
    },
    async updateUserCompanies(_, { userId, companies }) {
      return UserService.updateUserCompanies({ userId, companies })
    },
    async getCompanyLoginLink(_, payload) {
      const response = await UserService.getCompanyLoginLink(payload)
      return response
    },
  },
}
