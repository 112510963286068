<template>
  <b-modal
    id="CreateAndEditCTAModal"
    hide-footer
    hide-header
    centered
    @hide="closeModal"
    body-class="p-0"
    size="lg">
    <div class="px-4 pt-2">
      <!-- body -->
      <div class="card-body px-3">
        <div class="header-title mb-5 h3" style="font-size: 20px">
          {{ isEditing ? 'Update' : 'Create' }} CTA
        </div>
        <form>
          <div class="form-group">
            <label for="title"><strong>Title</strong></label>
            <input type="text" name="title" id="title" v-model="form.action" class="form-control" />
          </div>

          <div class="form-group">
            <label for="title"><strong>URL</strong></label>
            <input
              type="text"
              name="url"
              id="url"
              v-model="form.url"
              class="form-control"
              @input="handleURLValidation"
            />
            <small v-show="!isValidURL" class="text-danger">
              Please provide a properly formatted URL
            </small>
          </div>

          <div class="form-group">
            <label for="title"><strong>Image</strong></label>
            <div class="row">
              <div class="col-8 d-flex flex-wrap justify-content-between align-items-center">
                <S3ImageFileSelector
                  :selectedImage="selectedImage"
                  @imageFileSelected="handleImageSelected"
                />
                <div class="h3">OR</div>
                <S3ImageFileUploader @uploaded="handleImageUploaded"/>
              </div>
              <div class="col-12 mt-4" v-if="selectedImage">
                <b-img
                  v-if="selectedImage.logoURL"
                  style="width: 60px; border-radius: 5px"
                  :src="selectedImage.logoURL"
                >
                </b-img>
                <div v-else class="no-logo text-muted">
                  <span style="border-radius: 5px; font-size: 40px" class="fa fa-image"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="form-group text-right mt-4">
            <button
              :disabled="isLoading || !isValidURL || !form.action || !form.url"
              @click.prevent="submitForm"
              class="btn px-4 btn-primary text-capitalize"
            >
              {{ isEditing ? 'Update' : 'Create' }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { debounce } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'
import S3ImageFileSelector from '../Modules/s3/S3ImageFileSelector.vue'
import S3ImageFileUploader from '../Modules/s3/S3ImageFileUploader.vue'
const LocationModule = createNamespacedHelpers('location')

export default {
  name: 'CreateAndEditCTAModal',
  components: {
    S3ImageFileSelector,
    S3ImageFileUploader,
  },
  data() {
    return {
      form: {
        action: null,
        url: null,
        logo: null,
      },
      isLoading: false,
      isValidURL: true,
      selectedImage: null,
      selectedImageText: 'Select an existing image',
    }
  },
  computed: {
    ...LocationModule.mapGetters([
      'selectCurrentCTA',
      'selectIsEditingCTA',
      'selectActiveLocation',
    ]),

    cta() {
      return this.selectCurrentCTA
    },
    isEditing() {
      return this.selectIsEditingCTA
    },
    locationId() {
      return this.selectActiveLocation?._id
    },
  },
  watch: {
    cta: debounce(function (value) {
      if (this.isEditing) {
        const URL = value.url || null
        const logoURL = value.logo || null
        const action = value.action || null
        this.form.url = URL
        this.form.logo = logoURL
        this.form.action = action
        const nameIndex = logoURL ? logoURL.lastIndexOf('/') + 1 : -1
        let fileName = nameIndex !== -1 ? logoURL.substring(nameIndex) : null
        this.selectedImage = { logoURL, fileName }
      }
    }),
  },
  methods: {
    ...LocationModule.mapActions([
      'createCTA',
      'updateCTA',
      'deleteCTA',
      'setCurrentCTA',
      'setIsEditingCTA',
    ]),

    resetForm() {
      this.form.url = null
      this.form.logo = null
      this.form.action = null
      ;(this.selectedImage = null), this.setCurrentCTA(null)
      this.setIsEditingCTA(false)
    },
    closeModal() {
      this.resetForm()
      this.$root.$emit('bv::hide::modal', 'CreateAndEditCTAModal')
    },
    async submitForm() {
      this.isLoading = true
      try {
        if (this.cta) {
          // update cta
          await this.updateCTA({
            ctaId: this.cta._id,
            payload: { ...this.form },
            locationId: this.locationId,
          })
        } else {
          // create new cta
          await this.createCTA({
            locationId: this.locationId,
            cta: { ...this.form },
          })
        }
        this.$notify({
          title: `Success!`,
          text: `
          The CTA was ${this.isEditing ? 'updated' : 'created'} successfully.`,
        })
      } catch (error) {
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'Oops, something went wrong. Please again a bit.',
        })
      }
      this.isLoading = false
      this.closeModal()
    },
    handleImageSelect(event) {
      const text = event.target.innerText
      this.selectedImageText = text
    },
    handleURLValidation() {
      this.isValidURL = this.validateURL(this.form.url)
    },
    handleImageSelected(file){
      this.form.logo = file?.logoURL
      this.selectedImage = file
    },
    handleImageUploaded(url){
      this.form.logo = url
      if(this.selectedImage){
        this.selectedImage.logoURL = url
      }
      else {
        this.selectedImage = { logoURL: url }
      }
    },
    validateURL(url) {
      const results = url.match(
        /^(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
      )
      return results !== null
    },
  },
}
</script>
