<template>
  <table class="table table-hover table-responsive">
    <thead>
      <tr>
        <th v-for="header in headers" :key="header">
          <strong>{{ header }}</strong>
        </th>
      </tr>
    </thead>
    <tbody class="table-group-divider">
      <tr v-for="location in locationErrors" :key="location.name">
        <td>
          {{ location.name }}
        </td>
        <td :class="['col col-3']">
          {{ formatPhoneNumber(location.phone) }}
        </td>
        <td>
          {{ location.success }}
        </td>
        <td>
          {{ location.reason }}
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
export default {
  name: 'LocationUploadErrors',
  components: {},
  props: {
    locationErrors: {
      type: Array,
    },
  },
  computed: {
    headers() {
      return (
        Object.entries(this.locationErrors?.[0]).map((e) => {
          return e[0]
        }) || []
      )
    },
  },
  methods: {
    formatPhoneNumber(phone) {
      const cleaned = ('' + phone).replace(/\D/g, '')
      const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
      if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
      }
      return phone
    },
  },
}
</script>

<style></style>
