<template>
 <div @click.prevent="onSelect">
  <div 
    class="row group-level-item" style="width: 370px" 
    :id="item.type === 'placeholder' ? 'popover-level-creation': ''">
    <div :class="item.type === 'placeholder' ? ['col', 'text-center', 'align-content-center']: 'col'">

      <span @click.prevent="OpenInputBox(item.id)" v-if="item.type === 'placeholder' && !item.kind" 
      class="fe fe-plus-circle text-capitalize" style="font-size: 20px"></span>
      
      <span @click.prevent="OpenInputBox(item.id)"
      class="add-level-btn" v-if="item.type === 'placeholder' && !item.kind" 
      style="font-size: 18px"> Add {{ item.name | textFormatter }}</span> 
      <span v-if="item.type !== 'placeholder' && !item.kind">{{ item.name }}</span>
      
      <div v-if="showInputId && showInputId === item.id">
        <label for="group-input"></label>
        <div class="input-group mb-3">
          <input v-model="group.levelName" type="text" class="form-control" :placeholder="`${ item.name } name`">
          <div class="input-group-append">
            <button class="btn btn-secondary" type="button" @click.prevent="handleCreateGroupNode">Create</button>
          </div>
        </div>
      </div>
    </div>

    <div class="col-auto d-flex flex-wrap align-content-center" v-if="item.type !== 'placeholder'">
      <div style="height: 20px; margin-top: 4px" class="badge badge-md badge-secondary mr-2" v-if="shouldDisplayCount">
        <div style="font-size:14px">
          {{ item.children.length }}
        </div>
      </div>
      <i style="font-size:18px" class="fe fe-edit mr-2" @click.prevent="toggleInputField(item.id)"></i>
      <i style="font-size:18px;" class="fe fe-trash-2" @click.prevent="confirmGroupDelete(item)"></i>
    </div>
    
  </div>
  <div class="row mt-3" v-if="currentGroupId === item.id">
    <div class="col">
      <div class="input-group mb-3">
        <input v-model="group.name" type="text" class="form-control">
        <div class="input-group-append">
          <button class="btn btn-secondary" type="button" @click.prevent="handleUpdateGroupName(item.id)">Save</button>
        </div>
      </div>
    </div>
  </div>

  <div class="row" 
  :style="[{maxWidth: '390px'}, isDropdownOpen ? { height: '500px', backgroundColor: 'white'} : '']" 
  v-if="item.kind && item.kind === 'locations'">
    <div class="col">
      <LocationSelector
      :locations="memebersLocations"
      @locationAdded="handleAssignLocation"
      @locationRemoved="handleRemoveLocation"
      @handleOpenDropdown="handleOpenDropdown"></LocationSelector>
    </div>
  </div>

 </div>
</template>
<script>
import { createNamespacedHelpers } from 'vuex'
import { v4 as uuidv4 } from 'uuid'
import GroupBase from '../groups/GroupBase.vue'
import LocationSelector from '../groups/LocationSelector.vue'
const GroupModule = createNamespacedHelpers('group')

export default {
  name: 'GroupLevelItem',
  props: ["expanded", "item", "theme"],
  extends: GroupBase,
  components: {
    LocationSelector
  },
  data(){
    return {
      group: {
        name: '',
        levelName: '',
        nodeName: '',
      },
      locations: [],
      popoverShow: false,
      isDropdownOpen: false,
      currentGroupId: null,
      showLevelNameInput: false,
      currentLevelInputId: null,
    }
  },
  computed: {
    ...GroupModule.mapGetters([
    'selectCurrentGroupItem', 
    'selectGroupCreationSteps'
    ]),

    showInputId(){
      return this.currentLevelInputId
    },
    childrenCount(){
      return this.item.children.length
    },
    selectedGroup(){
      return this.selectCurrentGroupItem
    },
    creationSteps(){
      return this.selectGroupCreationSteps
    },
    memebersLocations(){
      return this.locations
    },
    shouldDisplayCount(){
      return this.item.children && this.item.children.length ? true : false
    }, 
  },
  methods: {
    ...GroupModule.mapActions([
      'setSelectedGroupItem',
      'setGroupCreationSteps',
      'setCurrentGroupMembers',
    ]),

    handleOpenDropdown(isDropdownOpen){
      this.isDropdownOpen = isDropdownOpen
    },

    async confirmGroupDelete(item) {
      const userResponse = await this.$bvModal.msgBoxConfirm(
        `Remove: ${ item.name } ?`, {
        size: 'sm',
        okTitle: 'Remove',
        okVariant: 'danger',
        centered: true
      })
      
      if(userResponse){
        this.handleRemoveGroupItem({ tree: this.tree, groupId: item.id })
      }
    },
    onPopoverShow(){
      this.popoverShow = true
    },
    OpenInputBox(id){
      this.currentLevelInputId = id
    },
    handleAssignLocation(locationId){
      if(this.groupType === 'hierarchy'){
        // find the parent path
        const path = this.getParentPathForMembers(this.item.id)
        // assign the location
        const result = this.assignLocation({ tree: this.tree, path, locationId })
        // update the tree
        if(result) this.updateTree(result)
      }
      else if(this.groupType === 'singular'){
        // handle singular location assignment
        const result = this.assignLocationToSingularGroup({
          tree: this.tree,
          locationId,
        })

        if(result) this.updateTree(result)
      }
    },
    handleRemoveLocation(locationId){
      if(this.groupType === 'hierarchy'){
        // find the parent path
        const path = this.getParentPathForMembers(this.item.id)
        // remove the location from members
        const result = this.removeLocation({ tree: this.tree, path, locationId })
        // update the tree
        if(result) this.updateTree(result)
      }
      else if(this.groupType === 'singular'){
        // handle singular group location removal 
        const result = this.removeLocationFromSingularGroup({
          tree: this.tree,
          locationId,
        })

        if(result) this.updateTree(result)
      }
    },
    onSelect(){
      if(this.item.type && this.item.type === 'placeholder'){
        this.showLevelNameInput = true
      }
      else {
        this.showLevelNameInput = false
      }

      if(this.item.members && this.item.members.length){
        // populate the the locations selector with members
        this.setCurrentGroupMembers(this.item.members)
        this.locations = this.item.members
      }
      else {
        this.setCurrentGroupMembers(null)
        this.locations = []
      }
      // set the current selected group
      this.setSelectedGroupItem(this.item)
    },
    
    toggleInputField(id){
      if(this.currentGroupId === id){
        this.currentGroupId = null
      }
      else {
        this.currentGroupId = null
        this.currentGroupId = id
      }
    },
    handleUpdateGroupName(id){
      const payload = {
        groupId: id, 
        tree: this.tree,
        fields: {
          name: this.group.name
        }
      }
      const result = this.findOneAndUpdate(payload)
      if(result) this.updateTree(result)
      // reset selected groupId 
      this.currentGroupId = ''
    },
    handleRemoveGroupItem({ tree, groupId }){
      const newTree = this.findOneAndDelete({ tree, groupId })
      this.updateTree(newTree)
    },
    handleCreateGroupNode(){
      // reset the input textbox
      this.currentLevelInputId = ''

      if(this.group.levelName.length){
        const itemPath = this.findGroupPathById(this.tree, this.item.id)
        const index = itemPath.lastIndexOf('[')
        const parentPath = itemPath.substring(0, index)

        const result = this.insertGroupNode({
          tree: this.tree,
          path: parentPath,
          group: this.generateNewGroup(itemPath)
        })

        if(result){
          this.updateTree(result)
          this.group.levelName = null
        }
      }
    },
    generateNewGroup(itemPath){
      const maxLevelDepth = this.creationSteps.length + 1
      const depthLevel = this.findCurrentLevelDepth(itemPath)
      const nextLevel = this.creationSteps[depthLevel + 1]

      const group = {
        id: uuidv4(),
        name: this.group.levelName,
      }

      if(nextLevel){
        group.children = [
          {
            id: this.generatePlaceholderId(),
            name: this.creationSteps[depthLevel + 1],
            type: 'placeholder',
            groupLevel: this.creationSteps[depthLevel + 1],
          }
        ]
      }
      else if (depthLevel + 1 < maxLevelDepth){
        group.children = [
          {
            id: this.generatePlaceholderId(),
            name: 'Locations',
            type: 'placeholder',
            kind: 'locations'
          }
        ]
      }
      return group
    },
    findCurrentLevelDepth(path){
      if(!path) return
      let depthLevel = 0

      path.split('').forEach(char => {
        if(char.includes('.')){
          depthLevel += 1
        }
      })

      return depthLevel
    },
  },
  filters: {
    textFormatter(value) {
      if(!value) return
      return value.toLowerCase()
    },
  },
  mounted() {
    this.group.name = this.item.name
  }
}
</script>
<style scoped lang="scss">
  .fe:hover, .add-level-btn:hover{
    color: blue;
    cursor: pointer;
  }
</style>