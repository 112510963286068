<template>
  <form @submit.prevent="login">
    <div class="form-group">
      <label>Email Address</label>
      <input
        v-model="form.email"
        class="form-control"
        placeholder="name@address.com"
        type="email"
        autocomplete="email"
      >
    </div>
    <div class="form-group">
      <label>Password</label>
      <div class="input-group input-group-merge">
        <input
          v-model="form.password"
          class="form-control form-control-appended"
          placeholder="Enter your password"
          :type="hidePassword ? 'password' : 'text'"
        >
        <div
          @click="hidePassword = !hidePassword"
          class="password-mask input-group-append"
        >
          <span class="input-group-text">
            <i
              class="fe"
              :class="[hidePassword ? 'fe-eye': 'fe-eye-off']"
            ></i>
          </span>
        </div>
      </div>
    </div>
    <div
      v-if="errMessage"
      class="alert alert-danger"
      role="alert"
    >
      {{ errMessage }}
    </div>
    <button
      type="submit"
      :disabled="incompleteForm || isLoading"
      :class="{ 'is-loading': isLoading }"
      class="btn btn-lg btn-block btn-primary mb-3"
    >
      Login
    </button>
  </form>
</template>

<script>
import firebase from 'firebase/app'
import 'firebase/auth'

export default {
  name: 'LoginForm',
  data() {
    return {
      isLoading: false,
      errMessage: false,
      form: {
        email: undefined,
        password: undefined
      },
      hidePassword: true
    }
  },
  computed: {
    incompleteForm() {
      return !this.form.email || !this.form.password
    }
  },
  methods: {
    async login() {
      this.isLoading = true
      this.errMessage = false

      try {
        await firebase.auth().signInWithEmailAndPassword(this.form.email, this.form.password)

        this.$emit('authenticated')
      } catch (err) {
        this.isLoading = false
        this.errMessage = err.message
      }
    }
  }
}
</script>

<style scoped lang="scss">
.password-mask:hover {
  cursor: pointer;
}
</style>
