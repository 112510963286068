import BannerService from '../services/BannerService'

export default {
  namespaced: true,

  state: {
    banners: [],
    bannerToBeModified: null,
  },

  getters: {
    selectBanners(state) {
      return state.banners.reverse()
    },
    selectBannerToBeModified(state) {
      return state.bannerToBeModified
    },
  },

  mutations: {
    SET_BANNERS(state, banners) {
      state.banners = banners
    },
    SET_BANNER_TO_BE_MODIFIED(state, banner) {
      state.bannerToBeModified = banner
    },
  },

  actions: {
    setBannerToBeModified({ commit }, banner) {
      commit('SET_BANNER_TO_BE_MODIFIED', banner)
    },
    async fetchAllBanners({ commit }) {
      const response = await BannerService.listBanners({ filters: {} })
      commit('SET_BANNERS', response.body.data.banners)
    },

    async createBanner(_, { message }) {
      return BannerService.createBanner({ message })
    },

    async updateBanner(_, { bannerId, updateFields }) {
      return BannerService.updateBanner(bannerId, updateFields)
    },
  },
}
