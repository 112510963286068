import IntegrationService from '../services/integrationService'

export default {
  namespaced: true,

  state: {
    integrations: [],
    saveResponse: undefined,
    providers: [],
  },

  mutations: {
    setIntegrations(state, integration) {
      state.integrations = integration
    },
    setSaveResponse(state, response) {
      state.saveResponse = response
    },
    setProviders(state, payload) {
      state.providers = payload
    },
  },

  actions: {
    async createIntegrationDocument({ commit }, integration) {
      try {
        const response = await IntegrationService.create(integration)
        commit('setSaveResponse', response)
      } catch (error) {
        // commit the error response for handling user error
        commit('setSaveResponse', error)
      }
    },
    async getIntegrationsList({ commit }, locationIds) {
      const integrations = await IntegrationService.list(locationIds)
      commit('setIntegrations', integrations.data)
    },
    async fetchProviders({ commit }) {
      const response = await IntegrationService.getProviders()
      commit('setProviders', response?.body?.providers)
    },
  },
  getters: {
    selectIntegrationIds: (state) => () => {
      return state?.integrations?.map(({ _id }) => _id)
    },
    selectIntegration: (state) => (id) => {
      return state.integrations.find(({ _id }) => id === _id)
    },
    selectIntegrationId: (state, getters) => (id) => {
      const integration = getters.selectIntegration(id)
      return integration._id
    },
    selectIntegrationByIntegrationLocation: (state) => (integrationLocationId) => {
      return state.integrations.find(
        ({ integrationLocation }) => integrationLocation === integrationLocationId
      )
    },
    selectProviders: (state) => () => {
      return state?.providers || []
    },
  },
}
