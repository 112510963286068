<template>
  <div class="form-row">
    <div class="col-lg-1">
      <input type="text" class="form-control" v-model="value.order" placeholder="Order" />
    </div>
    <div class="col-lg-3">
      <input type="text" class="form-control" v-model="value.name" placeholder="Name" />
    </div>
    <div class="col-lg-6">
      <input type="text" class="form-control" v-model="value.orderUrl" placeholder="Order URL" />
    </div>
    <div class="col-lg-2">
      <div class="btn-toolbar" role="toolbar">
        <div class="btn-group mr-3 mt-3">
          <div class="form-check">
            <input class="form-check-input" type="checkbox" v-model="value.orderUsesPopup" />
            <label class="form-check-label"> Popup </label>
          </div>
        </div>
        <div class="btn-group mr-3">
          <button @click="save()" type="button" class="btn btn-primary" :disabled="loading">
            Save
          </button>
        </div>
        <div class="btn-group mr-3">
          <button
            @click="remove()"
            type="button"
            class="btn btn-danger float-right"
            :disabled="loading"
          >
            Remove
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OrderPlatformService from '@/services/OrderPlatformService'
import _ from 'lodash'

export default {
  name: 'LocationOrderPlatformRow',
  props: {
    value: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    loading: false,
  }),
  methods: {
    async save() {
      this.loading = true
      try {
        const fields = _.pick(this.value, ['name', 'orderUrl', 'orderUsesPopup', 'order'])
        if (!this.isValidateOrder(this.value?.order)) {
          throw new Error('Invalid order type. Order field must be a number')
        }

        if (this.value._id) {
          await OrderPlatformService.update({
            orderPlatformId: this.value._id,
            fields,
          })
        } else {
          const {
            body: { orderPlatform },
          } = await OrderPlatformService.add({
            ...fields,
            locationId: this.value.location,
            companyId: this.value.company,
          })
          this.$emit('input', orderPlatform)
        }
        const status = `${this.value?._id ? 'Updated' : 'Added'}`
        this.$notify({
          type: 'success',
          text: `Order Platform ${status?.toLocaleLowerCase()} successfully`,
          title: status,
        })
      } catch (error) {
        console.error(error)
        window.alert(`Failed to save order platform with Error: ${error.message}`)
        throw error
      } finally {
        this.loading = false
      }
    },
    async remove() {
      if (this.value._id) {
        this.loading = true
        try {
          await OrderPlatformService.delete(this.value._id)
          this.$notify({
            type: 'success',
            title: 'Removed',
            text: `Order platform removed successfully`,
          })
        } catch (error) {
          console.error(error)
          window.alert(`Failed to delete order platform with Error: ${error.message}`)
          throw error
        } finally {
          this.loading = false
        }
      }

      this.$emit('remove', this.value)
    },
    isValidateOrder(order) {
      // numbers only
      const regex = /^\d+$/
      return regex.test(order)
    },
  },
}
</script>

<style scoped lang="scss"></style>
