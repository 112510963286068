<template>
  <div class="card mb-5" v-if="subscription">
    <div class="card-body">
      <p class="mb-2">{{subscription.plan.name}}</p>
      <p class="small text-muted mb-0">
        {{subscription.plan.amount/100 | currency}}/mo
        <span
          class="badge badge-primary mb-1"
          v-if="subscription.quantity > 1"
        >x{{subscription.quantity}}</span>
      </p>
    </div>
    <div class="card-footer">
      <strong>{{ subscription.quantity * (subscription.plan.amount/100) | currency }}/mo</strong>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BillingSubscription',
  props: {
    subscription: {
      type: Object,
      default: () => null
    }
  }
}
</script>
