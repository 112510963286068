<template>
  <div class="ivr-gather-content">
    <div class="row ml-4">
      <div class="col-auto">
        <small class="text-muted">{{
          useDefaultOptions === true ? 'Using Default Options' : 'Using Custom Options'
        }}</small>
        <div class="form-group">
          <div class="custom-control custom-checkbox-toggle">
            <input
              class="custom-control-input"
              type="checkbox"
              :id="`default-options-toggle-${step.uuid}`"
              v-model="useDefaultOptions"
            />
            <label
              class="custom-control-label mt-3"
              :for="`default-options-toggle-${step.uuid}`"
            ></label>
          </div>
        </div>
      </div>
      <div class="col-auto">
        <small class="text-muted">Repeat Count</small>
        <input type="number" v-model="repeatCount" class="form-control" style="width: 80px" />
      </div>
      <div class="col-auto">
        <small class="text-muted">Auto Loop After Action</small>
        <div class="form-group">
          <div class="custom-control custom-checkbox-toggle">
            <input
              class="custom-control-input"
              type="checkbox"
              id="repeat-gather-after-action"
              v-model="repeatGatherAfterAction"
            />
            <label class="custom-control-label mt-3" for="repeat-gather-after-action"></label>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <draggable
      v-model="options"
      :id="`option-draggable-${step.uuid}`"
      draggable=".draggable-option"
    >
      <transition-group class="row" name="options">
        <div
          v-for="(option, index) of options"
          :key="option.uuid"
          class="col col-12 draggable-option"
        >
          <IvrGatherOption
            :errors="errors"
            :option="option"
            :index="index"
            :uploadPath="uploadPath"
            :useDefaultOptions="useDefaultOptions"
            class="mx-3"
            @deleteOption="deleteOption(option.uuid)"
            @gatherOptionUpdate="updateOption(option.uuid, $event)"
          />
        </div>
      </transition-group>
    </draggable>
    <div class="row no-gutters">
      <div class="col d-flex justify-content-center">
        <button class="btn btn-sm btn-outline-primary mb-3" @click="addOption">
          <span>Add Option</span>
        </button>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col col-12">
        <IvrInvalidGatherOption
          :errors="errors"
          :uploadPath="uploadPath"
          :invalidOption="invalidOption"
          @invalidGatherOptionUpdate="invalidOptionUpdate"
        />
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col col-12">
        <IvrLandlineSteps
          :errors="errors"
          :uploadPath="uploadPath"
          :landlineSteps="landlineSteps"
          @deleteLandlineStep="deleteLandlineStep"
          @landlineStepUpdate="landlineStepUpdate"
          @addLandlineStep="addLandlineStep"
        />
      </div>
    </div>
  </div>
</template>

<script>
import IvrGatherOption from './IvrGatherOption.vue'
import IvrLandlineSteps from './IvrLandlineSteps.vue'
import IvrInvalidGatherOption from './IvrInvalidGatherOption.vue'
import draggable from 'vuedraggable'
import { v4 as uuidv4 } from 'uuid'

export default {
  name: 'IvrGather',
  props: ['step', 'uploadPath', 'invalidOption', 'landlineSteps', 'errors'],
  components: { IvrGatherOption, IvrLandlineSteps, IvrInvalidGatherOption, draggable },
  data: () => ({
    options: [],
    useDefaultOptions: true,
    repeatCount: 2,
    repeatGatherAfterAction: false,
  }),
  computed: {},
  watch: {
    options: {
      handler: function (val) {
        this.emitUpdate()
      },
      deep: true,
    },
    repeatCount(val) {
      this.emitUpdate()
    },
    repeatGatherAfterAction(val) {
      this.emitUpdate()
    },
  },
  methods: {
    addOption() {
      this.options = [...this.options, { uuid: uuidv4(), action: {} }]
    },
    deleteOption(uuid) {
      this.options = this.options.filter((o) => o.uuid !== uuid)
    },
    updateOption(uuid, modifications) {
      const newOptions = [...this.options]
      const index = newOptions.findIndex((x) => x.uuid === uuid)
      newOptions.splice(index, 1, {
        ...modifications,
        uuid,
      })
      this.options = newOptions
    },
    addLandlineStep(i) {
      this.$emit('addLandlineStep', i)
    },
    deleteLandlineStep(uuid) {
      this.$emit('deleteLandlineStep', uuid)
    },
    landlineStepUpdate(event) {
      this.$emit('landlineStepUpdate', event)
    },
    invalidOptionUpdate(modifications) {
      this.$emit('invalidOptionUpdate', modifications)
    },
    emitUpdate() {
      this.$emit('gatherUpdate', {
        options: this.options,
        repeatCount: this.repeatCount,
        repeatGatherAfterAction: this.repeatGatherAfterAction,
      })
    },
  },
  mounted() {
    if (this.step.options && this.step.options.length) {
      this.options = this.step.options.map((o) => ({ uuid: uuidv4(), ...o }))
      const optionDigits = this.options.map((o) => o.digit)
      const sequence = Array.from({ length: this.options.length }, (_, i) => i + 1)
      if (JSON.stringify(sequence) !== JSON.stringify(optionDigits)) {
        this.useDefaultOptions = false
      }
    } else {
      this.addOption()
    }
    this.repeatGatherAfterAction = this.step?.repeatGatherAfterAction
    this.emitUpdate()
  },
}
</script>
