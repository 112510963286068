import Vue from 'vue'

export default {
  create(payload) {
    return Vue.http.post('v2/ivrs', payload)
  },
  delete(ivrId) {
    return Vue.http.delete(`v2/ivrs/${ivrId}`)
  },
  enums(payload) {
    return Vue.http.get('v2/ivrs/enums')
  },
  list(payload) {
    return Vue.http.post('v2/ivrs/list', payload)
  },
  update(ivrId, modifications) {
    return Vue.http.put(`v2/ivrs/${ivrId}`, modifications)
  },
}
