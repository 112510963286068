<template>
<div class="row">
  <div class="col-12">
    <div class="card">
        <div class="card-body">
            <div class="row align-items-center">
                <h3>QR Code Designer</h3>
                
            </div> <!-- / .row -->
            <div class="row mb-3">
                <!-- size -->
                <div class="dropdown mr-3">
                    <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Size: {{size}}px
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="top-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -2px, 0px);">
                        <a @click="changeSize(256)" class="dropdown-item" href="#!">256px</a>
                        <a @click="changeSize(512)" class="dropdown-item" href="#!">512px</a>
                        <a @click="changeSize(1024)" class="dropdown-item" href="#!">1024px</a>
                    </div>
                </div>
                <!-- color -->
                <div class="dropdown mr-3">
                    <button class="btn btn-white dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    Source: {{sourceTitle}}
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton" x-placement="top-start" style="position: absolute; will-change: transform; top: 0px; left: 0px; transform: translate3d(0px, -2px, 0px);">
                        <a @click="changeSource({title: 'QR Tent', parameter: `qr-tent`})" class="dropdown-item" href="#!">QR Tent</a>
                        <a @click="changeSource({title: 'QR Card', parameter: `qr-card`})" class="dropdown-item" href="#!">QR Card</a>
                    </div>
                </div>                
            </div>
            <div class="row">
                <qrcode :value="sourceUrl" :options="{ width: size,  margin: 0 }"></qrcode>
            </div>
            <div class="row mt-2">
                <input :value="sourceUrl" class="form-control" />
            </div>            
        </div> <!-- / .card-body -->
    </div>
    
  </div>
</div>
</template>

<script>
import VueQrcode from '@chenfengyuan/vue-qrcode'
export default {
  name: 'LocationSources',
  props: ['location'],
  data: () => {
    return {
      sourceTitle: 'QR Tent',
      sourceParameter: 'qr-tent',
      color: '#e74c3c',
      size: '256'
    }
  },
  components: {
    qrcode: VueQrcode
  },
  computed: {
    sourceUrl() {
      return `https://survey.ovationup.com/leave-survey/${
        this.location._id
      }?source=${this.sourceParameter}`
    }
  },
  methods: {
    changeSize(size) {
      this.size = size
    },
    changeSource(source) {
      this.sourceTitle = source.title
      this.sourceParameter = source.parameter
    }
  }
}
</script>

<style scoped lang="scss">
</style>
