<template>
  <div class="login-page">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-4 offset-md-4">
          <h1 class="display-4 text-center mb-3">Login</h1>
          <LoginForm @authenticated="authenticated" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LoginForm from '@/components/Auth/LoginForm'
import { initRouterView } from '@/router'
import { createNamespacedHelpers } from 'vuex'
const { mapActions, mapGetters } = createNamespacedHelpers('auth')

export default {
  name: 'LoginPage',
  components: {
    LoginForm,
  },
  computed: {
    ...mapGetters(['selectIsClassifier']),
  },
  methods: {
    ...mapActions(['getAuth']),
    authenticated() {
      initRouterView(this)
    },
  },
}
</script>

<style scoped lang="scss">
.login-page {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
