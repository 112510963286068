<template>
  <b-modal id="InviteUserModal" hide-footer hide-header centered body-class="p-0" size="lg">
    <div class="px-4 pt-4">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h1>Invite User</h1>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- body -->
      <div class="card-body px-5">
        <div class="row">
          <div class="col-12">
            <div class="form-check">
              <input
                class="form-check-input"
                type="checkbox"
                id="cognito-authentication"
                v-model="cognitoAuthentication"
              />
              <label class="font-weight-bold" for="flagEnabled"> New email login? </label>
            </div>
          </div>
          <div class="col-6 mt-3">
            <label for="firstName" class="font-weight-bold"> First Name </label>
            <input
              type="text"
              id="firstName"
              v-model="firstName"
              class="form-control"
              placeholder="John"
            />
          </div>
          <div class="col-6 mt-3">
            <label for="lastName" class="font-weight-bold"> Last Name</label>
            <input
              type="text"
              id="lastName"
              v-model="lastName"
              class="form-control"
              placeholder="Doe"
            />
          </div>
          <div class="col-6 mt-3">
            <label for="email" class="font-weight-bold">Email Address</label>
            <input
              id="email"
              v-model="email"
              type="email"
              class="form-control"
              placeholder="name@address.com"
            />
          </div>
          <div class="col-6 mt-3">
            <label for="phone" class="font-weight-bold">{{
              `Phone (${cognitoAuthentication ? 'Optional' : 'Login'})`
            }}</label>
            <input
              id="phone"
              v-model="phone"
              type="text"
              ref="phone"
              class="form-control"
              placeholder="(000) 000-0000"
              v-mask="'(###) ###-####'"
            />
          </div>
          <div class="col-12 mt-3">
            <label for="role-select" class="font-weight-bold">Role</label>
            <RoleSelector
              id="role-select"
              :multiple="false"
              :close-on-select="true"
              v-model="role"
            />
          </div>
          <div class="col-12 mt-3">
            <label for="company-select" class="font-weight-bold">Company</label>
            <CompanySelector
              id="company-select"
              :display-ids="true"
              :multiple="true"
              :close-on-select="false"
              v-model="company"
              :closeOnSelect="false"
            />
          </div>
          <div class="col-12 mt-3" v-if="!role || role.value !== 'admin'">
            <label for="company-select" class="font-weight-bold">Locations</label>
            <LocationSelector
              id="location-select"
              :display-ids="true"
              :company-id="companyId"
              v-model="locations"
            />
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="border-top py-4 text-right">
        <button
          class="btn btn-primary"
          style="width: 120px"
          @click="inviteUser()"
          :disabled="!validInputs"
        >
          <div class="d-flex justify-content-center" v-if="loading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else> Save </span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import momentTz from 'moment-timezone'
import CompanySelector from '../Selects/CompanySelector.vue'
import LocationSelector from '../Selects/LocationSelector.vue'
import RoleSelector from '../Selects/RoleSelector.vue'

const UserModule = createNamespacedHelpers('user')
const PartnerModule = createNamespacedHelpers('partner')
const CompanyModule = createNamespacedHelpers('company')

export default {
  name: 'InviteUserModal',
  data: () => ({
    cognitoAuthentication: false,
    role: null,
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    company: {},
    locations: [],
    loading: false,
  }),
  components: {
    CompanySelector,
    LocationSelector,
    RoleSelector,
  },
  computed: {
    ...CompanyModule.mapGetters(['selectCompanies']),
    sortedCompanies() {
      return this.selectCompanies
    },
    sortedLocations() {
      return []
    },
    validInputs() {
      return (
        (this.firstName && this.lastName && this.role && this.role.value && this.company?._id) ||
        (this.company?.length &&
          (this.role.value === 'admin' || this.locations.length) &&
          ((this.cognitoAuthentication && this.email) || this.phone))
      )
    },
    roles() {
      return [
        { name: 'Admin', value: 'admin' },
        { name: 'Location Manager', value: 'location-manager' },
        { name: 'Employee', value: 'employee' },
      ]
    },
    companyId() {
      return Array.isArray(this.company) ? this.company.map((c) => c._id) : this.company._id
    },
  },
  mounted() {
    this.getCompanyList()
  },
  methods: {
    ...UserModule.mapActions(['createOvationInvite']),
    ...CompanyModule.mapActions(['getCompanyList']),
    setRole(role) {
      this.role = role
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'InviteUserModal')
    },
    async inviteUser() {
      this.loading = true
      try {
        const companyIds = Array.isArray(this.company)
          ? this.company.map((company) => company._id)
          : [this.company._id]

        await this.createOvationInvite({
          cognitoAuthentication: this.cognitoAuthentication,
          role: this.role.value,
          firstName: this.firstName,
          lastName: this.lastName,
          email: this.email,
          phone: this.phone,
          companyIds: companyIds,
          locationIds: this.locations.map((location) => location._id),
        })
        this.$notify({
          type: 'success',
          title: 'Success',
          text: `Successfully sent an invitation to ${this.email}`,
        })
        this.close()
      } catch (error) {
        console.log(error)
        this.$notify({
          text: 'Failed to send user invite',
          type: 'error',
        })
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
