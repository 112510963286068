<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="row justify-content-center">
        <div class="col-12 col-lg-12 col-xl-12">
          <div class="header mt-md-5">
            <div class="header-body">
              <h6 class="header-pretitle">
                Overview
              </h6>
              <h1 class="header-title display-4">
                Reports
              </h1>
            </div>
          </div>
          <ReportsTable />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ReportsTable from '@/components/Modules/Reports/Table'

export default {
  name: 'ReportsPage',
  components: {
    ReportsTable
  }
}
</script>

<style scoped lang="scss">
</style>
