import Vue from 'vue'

export default {
  async add(payload) {
    return await Vue.http.post('order-platform', payload)
  },
  async update(payload) {
    return await Vue.http.post('order-platform/update', payload)
  },
  async delete(orderPlatformId) {
    return await Vue.http.post('order-platform/delete', {
      orderPlatformId
    })
  }
}
