<template>
  <div class="row">
    <div
      class="col-6"
      v-for="device in location.devices"
      :key="device._id"
    >
      <div class="card">
        <div class="card-body">
          <div class="row align-items-center">

            <div class="col ml--2">

              <!-- Title -->
              <h4 class="card-title mb-1 platform-source">
                <a href="#!">{{ device.device || iPad }}</a>
              </h4>

              <!-- Text -->
              <p class="card-text small text-muted mb-1">
                <span>{{device.installationId}}</span>
              </p>
            </div>
            <div class="col-auto">

              <!-- Dropdown -->
              <div class="dropdown">
                <a
                  href="#!"
                  class="dropdown-ellipses dropdown-toggle"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  data-expanded="false"
                >
                  <i class="fe fe-more-vertical"></i>
                </a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a
                    href="#!"
                    class="dropdown-item"
                    v-on:click="$emit('unlink-device', device._id)"
                  >
                    Unlink
                  </a>
                </div>
              </div>

            </div>
          </div> <!-- / .row -->
        </div> <!-- / .card-body -->
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'LocationDevices',
  props: ['location'],
  components: {}
}
</script>

<style scoped lang="scss">
</style>
