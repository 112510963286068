<template>
  <div class="s3-file-selector">
    <b-dropdown 
      id="dropdown-1" 
      variant="white" 
      :text="selectedImageText"
      class="mb-3 custom-dropdown-class">
      <b-dropdown-item
        v-for="file in imageFiles" :key="file.id"
        @click.prevent="handleImageSelected(file)">
        <div class="clearfix d-flex align-content-center">
            <b-img
              alt="Left image logo"
              style="width:25px; border-radius: 5px" 
              left :src="file.logoURL">
            </b-img>
            <div class="ml-4">
              <strong>{{ file.fileName }}</strong>
            </div>
          </div>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>
<script>
import { v4 as uuidv4 } from 'uuid'
import { createNamespacedHelpers, mapGetters } from 'vuex'
const S3Module = createNamespacedHelpers('s3')

export default {
  name: 'S3ImageFileSelector',
  data(){
    return {
      imageFiles: [],
      selectedImageText: 'Select an existing image'
    }
  },
  computed: {
    ...mapGetters(['selectConfigByName']),

    bucketName(){
      return this.selectConfigByName('BUCKET_NAME')
    },
  },
  methods: {
    ...S3Module.mapActions(['listObjects']),

    async loadFiles(){
      const prefix = 'assets/images/platforms/'
      const response = await this.listObjects({ 
        bucket: this.bucketName,
        prefix: prefix
      })

      if(response && response.Contents && response.Contents.length){
        response.Contents.forEach(file => {
          const results = this.parseImageFile(file)
          if(results){
            this.imageFiles.push(results)
          }
        })
      }
    },
    parseImageFile(file){
      const { Key } = file
      if(Key && Key.includes('.png') || Key.includes('.jpg') || Key.includes('.jpeg')){
        const nameIndex = Key.lastIndexOf('/') +1
        const logoURL = `https://s3.amazonaws.com/${this.bucketName}/${Key}`
        let fileName = Key.substring(nameIndex)

        if(fileName && fileName.length){
          fileName = fileName.replace(/\s/g, '')
        }
        return {
          logoURL,
          fileName,
          id: uuidv4()
        }
      }
      return
    },
    handleImageSelected(file){
      this.selectedImageText = file.fileName
      this.$emit('imageFileSelected', file)
    }
  },
  async mounted(){
    await this.loadFiles()
  }
}
</script>
<style scoped>
  .custom-dropdown-class /deep/ .dropdown-menu {
    max-height: 300px;
    overflow-y: auto;
  }
</style>
