<template>
  <b-modal
    id="AddBusinessCreditModal"
    hide-footer
    hide-header
    centered
    body-class="p-0"
    size="lg"
    @shown="showing = !showing; setState()"
    @hidden="clearState"
  >
    <div class="px-4 pt-4">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h1>{{ selectEditBusinessCreditId ? 'Edit Credit' : 'Create Credit' }}</h1>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- body -->
      <div class="card-body px-5">
				<label for="amount" class="font-weight-bold"> Amount </label>
				<input type="number" id="amount" v-model="amount" class="form-control mb-3" />
				<label for="business-credit-reason">Select a reason</label>
				<select
						class="form-control custom-select mb-3"
						id="business-credit-reason"
						:items="reasonOptions"
						v-model="reason"
				>
					<option value>None</option>
					<option v-for="reason of reasonOptions" :key="reason" :value="reason">
						{{reason}}
					</option>
				</select>
				<label for="notes"> Notes (optional) </label>
				<textarea-autosize
					id="notes"
					v-model="notes"
					class="form-control"
					:min-height="42"
					placeholder="Notes..."
					rows="1"
				></textarea-autosize>
      </div>
      <!-- footer -->
      <div class="border-top py-4 text-right">
				<button
          class="btn btn-danger mr-3"
          style="width: 120px"
					v-if="selectEditBusinessCreditId"
					@click="_void()"
        >
          <div class="d-flex justify-content-center" v-if="createLoading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else> Void </span>
        </button>
        <button
          class="btn btn-primary"
          style="width: 120px"
          @click="selectEditBusinessCreditId ? edit() : create()"
          :disabled="!validInputs"
        >
          <div class="d-flex justify-content-center" v-if="createLoading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else>{{ selectEditBusinessCreditId ? 'Save' : 'Create' }}</span>
        </button>
				
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const CompanyModule = createNamespacedHelpers('company')

const initialState = {
	amount: null,
	reason: null,
	reasons: {
		"Trial": 'TRIAL',
		"Signup Incentive": 'SIGNUP_INCENTIVE',
		"Tradeshow Incentive": 'TRADESHOW_INCENTIVE',
		"Retention": 'RETENTION'
	},
	notes: null,
	showing: false,
	createLoading: false
}

export default {
  name: 'AddBusinessCreditModal',
  props: ['companyId'],
  data: () => ({
		...initialState
  }),
  computed: {
		...CompanyModule.mapGetters(['selectEditBusinessCreditId', 'selectActiveCompanyBusinessCredits']),
		businessCredit() {
			return this.selectEditBusinessCreditId ? this.selectActiveCompanyBusinessCredits.find(credit => credit._id === this.selectEditBusinessCreditId) : null
		},
    validInputs() {
      return this.amount && this.reason
    },
		reasonOptions() {
			return Object.keys(this.reasons)
		}
  },
  methods: {
    ...CompanyModule.mapActions(['createBusinessCredit', 'updateBusinessCredit', 'voidBusinessCredit']),
    close() {
      this.$root.$emit('bv::hide::modal', 'AddBusinessCreditModal')
    },
    async create() {
      try {
        this.createLoading = true
        this.$notify({ text: 'Adding Business Credit...' })
				this.close()
        await this.createBusinessCredit({
					creditType: 'SMS',
          companyId: this.companyId,
					amount: parseInt(this.amount),
					reason: this.reasons[this.reason],
					notes: this.notes
        })

        this.$emit('refreshBusinessCredits')
        this.$notify({ text: 'Successfully added business credit' })
        this.clearState()
      } catch (error) {
        this.$notify({
          title: 'Error adding business credit',
          text: `${error.body.message}`,
          type: 'error',
          duration: 5000,
        })
      }
    },
		async edit() {
      try {
        this.createLoading = true
        this.$notify({ text: 'Saving Business Credit...' })
				this.close()
        await this.updateBusinessCredit({
					businessCreditId: this.selectEditBusinessCreditId,
					amount: parseInt(this.amount),
					reason: this.reasons[this.reason],
					notes: this.notes
        })

        this.$emit('refreshBusinessCredits')
        this.$notify({ text: 'Successfully saved the business credit' })
        this.clearState()
      } catch (error) {
				console.log(error)
        this.$notify({
          title: 'Error saving business credit',
          text: `${error.body.message}`,
          type: 'error',
          duration: 5000,
        })
      }
    },
		async _void() {
      try {
        this.createLoading = true
        this.$notify({ text: 'Voiding Business Credit...' })
        this.close()
        await this.voidBusinessCredit(this.selectEditBusinessCreditId)
				
        this.$emit('refreshBusinessCredits')
        this.$notify({ text: 'Successfully voided the business credit' })
        this.clearState()
      } catch (error) {
				console.log(error)
        this.$notify({
          title: 'Error voiding business credit',
          text: `${error.body.message}`,
          type: 'error',
          duration: 5000,
        })
      }
    },
    clearState() {
      Object.assign(this, initialState)
    },
		setState() {
			if (this.businessCredit) {
				this.amount = this.businessCredit.amount
				this.reason = Object.keys(this.reasons).find(reason => this.reasons[reason] === this.businessCredit.reason)
				this.notes = this.businessCredit.notes
			}
		}
  },
	watch: {
		businessCredit(val) {
			if (val) {
				this.setState()
			}
		}
	},
	mounted() {
		this.setState()
	}
}
</script>
