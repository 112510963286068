<template>
  <div>
    <label for="">Level Names</label>
    <div class="input-group mb-1">
      <input v-model="form.levelNames" type="text" class="form-control" placeholder="Enter names separated by commas">
      <div class="input-group-append">
        <button class="btn btn-outline-primary" type="button" @click.prevent="saveLevelNames">Save</button>
      </div>
    </div>
    <small v-if="hasErrors" class="text-danger">Please provide a properly formatted level names</small>
  </div>
</template>
<script>
export default {
  name: 'GroupLevelNamesInput',
  props: ['levelNames'],
  data(){
    return {
      form: {
        levelNames: '',
      },
      hasErrors: false,
      isFinished: false
    }
  },
  methods: {
    saveLevelNames(){
      this.form.hasErrors = false

      if(!this.form.levelNames.length){
        this.hasErrors = true
      }
      else {
        const levelNames = this.form.levelNames.split(',')
        .map(name => name.trim())

        if(!levelNames || !levelNames.length){
          this.hasErrors = true
        }
        else {
          this.$emit('handleLevelNamesUpdate', levelNames)
          this.isFinished = true
        }
      }
    }
  },
  mounted() {
    if(this.levelNames){
      this.form.levelNames = this.levelNames
    }
  }
}
</script>