<template>
  <div class="card">
    <div class="card-header">
      <div class="row align-items-center">
        <div class="col">
          <!-- Search -->
          <form @submit.prevent class="row align-items-center">
            <div class="col-auto pr-0">
              <span class="fe fe-search text-muted"></span>
            </div>
            <div class="col">
              <input
                type="search"
                class="form-control form-control-flush search"
                placeholder="Search"
                v-model="search"
              />
            </div>
          </form>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-nowrap card-table">
        <thead>
          <tr>
            <th v-for="(column, index) in columns" :key="column.title + index">
              <a
                :class="{ sort: column.sortable, 'text-muted': sort != column.title }"
                href="#"
                @click="
                  sort = column.title
                  descending = !descending
                "
                >{{ column.friendly ? column.friendly : column.title }}</a
              >
            </th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in users" :key="row._id">
            <td>
              {{ row.created | moment('MMM Do YYYY') }}
              <span v-if="row.partner" class="badge badge-soft-primary">{{
                row.partner.name
              }}</span>
            </td>
            <td>{{ row.firstName }} {{ row.lastName }}</td>
            <td>
              <div
                v-for="(company, index) in row.companies"
                :key="company.name + index"
                class="truncate"
              >
                {{ company.name }}
              </div>
            </td>
            <td>{{ row.phone }}</td>
            <td style="text-transform: capitalize">{{ row.role }}</td>
            <td>{{ row.locations ? row.locations.length : 0 }}</td>
            <td>
              <div class="action-wrapper">
                <div class="action-item">
                  <button
                    :disabled="sudoLoginLoading"
                    @click="sudoLoginWithAssumeUser(row._id)"
                    class="btn btn-sm btn-primary mr-4"
                  >
                    Login
                    <i class="fe fe-arrow-right" />
                  </button>
                  <!-- TODO: remove this button after v3 general release if not needed for iframe -->
                  <!-- <button
                    :disabled="sudoLoginLoading"
                    @click="sudoLogin(row._id)"
                    class="btn btn-sm btn-primary"
                  >
                    Legacy
                    <i class="fe fe-arrow-right" />
                  </button> -->
                </div>
                <div class="col action-item">
                  <div class="dropdown" style="margin-top: -3px; margin-left: -10px">
                    <a
                      style="font-size: 24px"
                      href="#!"
                      class="dropdown-ellipses dropdown-toggle"
                      role="button"
                      data-toggle="dropdown"
                    >
                      <i class="fe fe-more-vertical"></i>
                    </a>
                    <div class="dropdown-menu dropdown-menu-right">
                      <a
                        @click.prevent="openModal(row._id)"
                        href="javascript:;"
                        class="dropdown-item"
                      >
                        Assign organization
                      </a>
                      <a
                        @click.prevent="openCompanyModal(row)"
                        href="javascript:;"
                        class="dropdown-item"
                      >
                        Edit Companies
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </td>
            <!-- <td>{{row.city}} <span v-if="row.disabled" class="small text-muted">(disabled)</span></td>
            <td>{{row.activationCode}}</td>
            <td>{{row.platforms.length}}</td>
            <td>{{row.devices.length}}</td>-->
            <!-- <td>      
              <div class="dropdown d-inline-block">
                <a class="dropdown-ellipses dropdown-toggle" data-toggle="dropdown" href="#!" role="button"><i class="fe fe-more-vertical"></i></a>
                <div class="dropdown-menu dropdown-menu-right">
                  <a class="dropdown-item" @click="deleteLocation(row._id)" href="javascript:;">
                    <span class="text-danger">Delete</span>
                  </a>
                </div>
              </div>
            </td>-->
          </tr>
        </tbody>
      </table>
    </div>
    <AssignUserOrganizationModal @assignOrganization="assignOrganization" />
    <ManageCompaniesModal :user="userToEdit" @refresh="refreshUserList" />
  </div>
</template>

<script>
import UserService from '@/services/UserService'
import { debounce } from 'lodash-es'
import { createNamespacedHelpers } from 'vuex'
import AssignUserOrganizationModal from '../../Modals/AssignUserOrganizationModal.vue'
import ManageCompaniesModal from '../../Modals/ManageCompaniesModal'
import AssumeUserService from '@/services/AssumeUserService'
import Config from '@/config'

const OrganizationModule = createNamespacedHelpers('organization')
const { mapMutations, mapState, mapActions } = createNamespacedHelpers('user')

export default {
  name: 'UsersTable',
  components: {
    AssignUserOrganizationModal,
    ManageCompaniesModal,
  },
  data: () => {
    return {
      searchResults: [],
      searchable: ['firstName', 'lastName', 'company', 'locations', 'role'],
      search: '',
      sort: 'created',
      descending: true,
      columns: [
        { title: 'created', sortable: true },
        { title: 'name', sortable: true },
        { title: 'company', sortable: true },
        { title: 'phone', sortable: false },
        { title: 'role', sortable: false },
        { title: 'locations', sortable: true },
      ],
      userToEdit: {},
      sudoLoginLoading: false,
    }
  },
  computed: {
    ...mapState(['users']),
    ...OrganizationModule.mapGetters(['selectHttpResponse']),

    orderedData: function () {
      return _.orderBy(this.users, this.sort, this.descending ? 'desc' : 'asc')
    },
    httpResponse() {
      return this.selectHttpResponse
    },
  },
  methods: {
    ...mapActions(['getUsersList', 'getUserByFbId']),
    ...OrganizationModule.mapActions([
      'fetchOrganizations',
      'setUserToAssignOrg',
      'assignUserToOrganization',
    ]),
    refreshUserList() {
      this.getUsersList(this.search)
    },
    async sudoLogin(userId) {
      try {
        this.sudoLoginLoading = true
        const response = await UserService.getLoginLink(userId)
        const { link } = response.body
        window.open(link, '_blank')
      } catch (ex) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: 'Something went wrong, unable to login as user currently.',
        })
      } finally {
        this.sudoLoginLoading = false
      }
    },
    async sudoLoginWithAssumeUser(assumedUserId) {
      try {
        this.sudoLoginLoading = true
        const user = await this.getUserByFbId()
        const response = await AssumeUserService.createAssumeUser({
          userId: user._id,
          assumedUserId,
        })
        if (response && response.body.success) {
          window.open(Config.appUrl, '_self')
        }
      } catch (ex) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: 'Something went wrong, unable to login as user currently.',
        })
      } finally {
        this.sudoLoginLoading = false
      }
    },
    openModal(userId) {
      this.$bvModal.show('AssignUserOrganizationModal')
      this.setUserToAssignOrg(this.users.find((u) => u._id === userId))
    },
    openCompanyModal(user) {
      this.userToEdit = user
      this.$nextTick(() => {
        this.$bvModal.show('manageCompaniesModal')
      })
    },
    async assignOrganization(data) {
      try {
        // assign the organization to the user
        await this.assignUserToOrganization(data)

        if (this.httpResponse && this.httpResponse.body.success) {
          this.$notify({
            title: 'Success',
            type: 'success',
            text: 'User was successfully assigned to organization.',
          })
        } else if (this.httpResponse && !this.httpResponse.body.success) {
          this.$notify({
            title: 'Error',
            type: 'error',
            text: 'Something went wrong, unable to assign user to organization.',
          })
        }
      } catch (err) {
        this.$notify({
          title: 'Error',
          type: 'error',
          text: 'Something went wrong, unable to assign user to organization.',
        })
      }
    },
  },
  async mounted() {
    this.getUsersList(this.search)
    await this.fetchOrganizations()
  },
  created() {
    this.$on('dataChanged', (results) => {
      this.searchResults = results
    })
  },
  watch: {
    search: debounce(function () {
      this.getUsersList(this.search)
    }, 500),
  },
}
</script>

<style scoped lang="scss">
.truncate {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.action-wrapper {
  display: flex;
  flex-direction: row;
}
.action-item {
  flex: 1;
}
</style>
