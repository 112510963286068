import IntegrationPartnerService from '@/services/IntegrationPartnerService'

export default {
  namespaced: true,
  state: {
    integrationPartners: [],
    apiKeys: {},
    activePartner: undefined,
    tokens: {},
    activePartnerId: undefined,
  },
  getters: {
    integrationPartner: (state) => (id) => {
      return state.integrationPartners.find(({ _id }) => _id === id)
    },
    getActivePartner: (state) => {
      return state.activePartner
    },
    selectApiKey: (state) => (integrationPartnerId) => {
      return state?.apiKeys?.[integrationPartnerId]
    },
    selectToken: (state) => (integrationPartnerId) => {
      return state?.tokens?.[integrationPartnerId]
    },
    selectActivePartnerId: (state) => {
      return state?.activePartnerId
    },
  },
  mutations: {
    setIntegrationPartners(state, data) {
      state.integrationPartners = [...data].sort((a, b) => {
        let { name: nameA } = a
        let { name: nameB } = b
        nameA = nameA.toLowerCase()
        nameB = nameB.toLowerCase()
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }
        return 0
      })
    },
    setActiveApiKey(state, payload) {
      const { integrationPartnerId, data } = payload
      state.apiKeys = {
        ...state?.apiKeys,
        [integrationPartnerId]: data === undefined ? undefined : data?.apiKeys?.[0] ?? null,
      }
    },
    setCreatedInteractionPartner(state, data) {
      state.activePartner = data
    },
    setToken(state, payload) {
      const { integrationPartnerId, data } = payload
      state.tokens = {
        ...state?.tokens,
        [integrationPartnerId]: data?.token,
      }
    },
    SET_ACTIVE_PARTNER_ID(state, partnerId) {
      state.activePartnerId = partnerId
    },
  },
  actions: {
    setActivePartnerId({ commit }, id) {
      commit('SET_ACTIVE_PARTNER_ID', id)
    },
    async getIntegrationPartners({ commit, getters }, payload = {}) {
      const response = await IntegrationPartnerService.list(payload)
      commit('setIntegrationPartners', response.body.data.integrationPartners)
      if (getters.selectActivePartnerId) {
        commit(
          'setCreatedInteractionPartner',
          getters.integrationPartner(getters.selectActivePartnerId)
        )
      }
    },
    async createApiKey({ commit, dispatch }, { integrationPartnerId, scopes }) {
      const response = await IntegrationPartnerService.createApiKey({
        integrationPartnerId,
        scopes,
      })
      commit('setToken', { integrationPartnerId, data: { token: response.body.data.token } })
      dispatch('getApiKey', integrationPartnerId)
    },
    async revokeApiKey({ commit }, { integrationPartnerId, apiKeyId }) {
      try {
        const response = await IntegrationPartnerService.revokeApiKey(apiKeyId)
        if (response?.status > 200) {
          throw new Error('Something went wrong')
        }
        commit('setActiveApiKey', { integrationPartnerId, data: null })
      } catch (error) {
        throw error
      }
    },
    async getApiKey({ commit }, integrationPartnerId) {
      const response = await IntegrationPartnerService.getApiKey({
        integrationPartnerId,
      })
      commit('setActiveApiKey', { integrationPartnerId, data: response.body.data })
    },
    async createIntegrationPartner({ commit }, partner) {
      const response = await IntegrationPartnerService.createPartner(partner)
      commit('setCreatedInteractionPartner', response)
      return response
    },
    async updateIntegrationPartner({ commit }, payload) {
      const response = await IntegrationPartnerService.updateIntegrationPartner(payload)
      return response
    },
  },
}
