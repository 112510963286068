<template>
  <div class="main-content">
    <div class="container-fluid">
      <div class="header mt-md-5">
        <div class="header-body">
          <div class="row align-items-center">
            <div class="col">
              <h6 class="header-pretitle">Overview</h6>
              <h1 class="header-title display-4">Companies</h1>
            </div>
            <div class="col-auto">
              <button class="btn btn-outline-primary" @click="showCreationModal" v-if="!isPartner">
                <span class="fe fe-plus"></span>
                Add Company
              </button>
            </div>
          </div>
        </div>
      </div>
      <CompaniesTable />
    </div>
    <AddCompanyModal id="AddCompanyModal"/>
  </div>
</template>

<script>
import CompaniesTable from '@/components/Modules/Companies/CompaniesTable'
import AddCompanyModal from '@/components/Modals/AddCompanyModal'
import { createNamespacedHelpers } from 'vuex'

const AuthModule = createNamespacedHelpers('auth')

export default {
  name: 'CompaniesPage',
  components: {
    CompaniesTable,
    AddCompanyModal,
  },
  computed: {
    ...AuthModule.mapGetters(['selectIsPartner']),
    isPartner() {
      return this.selectIsPartner
    },
  },
  methods: {
    showCreationModal() {
      this.$root.$emit('bv::show::modal', 'AddCompanyModal')
    },
  },
}
</script>

<style scoped lang="scss"></style>
