import IntegrationProviderService from '../services/IntegrationProviderService'

export default {
  namespaced: true,

  state: {
    integrationProviders: [],
  },

  getters: {
    selectIntegrationProviders: (state) => () => {
      return state.integrationProviders
    },
    selectIntegrationProviderByName: (state) => (name) => {
      return state.integrationProviders.find((p) => p.name === name || p.displayName === name)
    },
  },

  mutations: {
    SET_INTEGRATION_PROVIDERS(state, integrationProviders) {
      state.integrationProviders = integrationProviders
    },
  },

  actions: {
    async fetchIntegrationProviders({ commit }) {
      const [active, disabled] = await Promise.all([
        IntegrationProviderService.listIntegrationProviders(),
        IntegrationProviderService.listIntegrationProviders({ disabled: true }),
      ])
      const all = [
        ...active.data.data.integrationProviders,
        ...disabled.data.data.integrationProviders,
      ]
      commit('SET_INTEGRATION_PROVIDERS', all)
    },

    async createIntegrationProvider(_, { message }) {
      return IntegrationProviderService.createIntegrationProvider({ message })
    },

    async updateIntegrationProvider(_, { id, payload }) {
      return IntegrationProviderService.updateIntegrationProvider({ id, payload })
    },
  },
}
