<template>
  <div class="locationItem" @mouseenter="handleHover">
    <div class="row">
      <h3>{{ locationName }}</h3>
    </div>
    <div class="row address">
      <p style="color: slategray">{{ locationAddress }}</p>
    </div>
    <div class="row created">
      <p style="color: slategray">Created {{ craetedDate }}</p>
    </div>
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { debounce } from 'lodash-es'

export default {
  name: 'LocationItem',
  props: {
    location: {
      type: Object,
      required: true,
    },
  },
  computed: {
    locationName() {
      const { friendlyName, name, city, company } = this.location
      const locationName = friendlyName || city || name || ''
      return `${company?.name} - ${locationName}`
    },
    craetedDate() {
      return moment(this.location.created).format('L') || ''
    },
    locationAddress() {
      return this.location.address || ''
    },
  },
  methods: {
    handleHover: debounce(function () {
      this.$emit('onHover', this.location)
    }, 500),
  },
}
</script>

<style lang="scss" scoped>
.created {
  margin-top: -18px;
}
.address {
  margin-top: -15px;
}
.locationItem:hover {
  cursor: pointer;
  transform: translateY(-2%);
}
</style>
