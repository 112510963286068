<template>
  <div v-if="locations.length > 0">
    <div class="table-responsive">
      <table class="table table-sm table-nowrap card-table shadow-sm p-3 mb-5 bg-white rounded">
        <thead>
          <tr>
            <th>INTEGRATION LOCATION ID</th>
            <th>ID (3rd-party)</th>
            <th>NAME (3rd-party)</th>
            <th>CREATED</th>
            <th>INTEGRATION ID</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="location in locations" :key="location._id">
            <td>{{ location._id }}</td>
            <td>{{ location.id }}</td>
            <td>{{ location.name || '—' }}</td>
            <td>{{ location.created || location.createdAt | moment('MMM Do YYYY') }}</td>
            <td><IntegrationId :integrationLocationId="location._id" /></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import IntegrationId from './IntegrationId.vue'
export default {
  name: 'IntegrationLocationTable',
  props: ['locations'],
  components: {
    IntegrationId,
  },
}
</script>