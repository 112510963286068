<template>
  <div class="ivr-speak-sentence">
    <div class="row">
      <div class="col col-2 align-self-center ml-4 mt-2">
        <label style="color: #95aac9">Voice</label>
      </div>
      <div class="col col-8 mr-3 my-2">
        <select
          class="form-control custom-select my-2"
          id="bandwidth-voice"
          v-model="selectedBandwidthVoice"
        >
          <option value>None</option>
          <option v-for="voice of bandwidthVoices" :key="voice">
            {{ voice }}
          </option>
        </select>
      </div>
    </div>
    <div class="row">
      <div class="col col-2 align-self-center ml-4 mt-2">
        <label style="color: #95aac9">Sentence</label>
      </div>
      <div class="col col-8 mr-3 my-2">
        <textarea-autosize
          id="sentence"
          v-model="modifiedSentence"
          class="form-control"
          :min-height="42"
          placeholder="Sentence..."
          rows="1"
        ></textarea-autosize>
      </div>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'

const IvrModule = createNamespacedHelpers('ivr')

export default {
  name: 'IvrSpeakSentence',
  props: ['sentence', 'voice', 'gender', 'locale'],
  components: {},
  data: () => ({
    selectedBandwidthVoice: null,
    modifiedSentence: null,
  }),
  computed: {
    ...IvrModule.mapGetters(['selectBandwidthVoices']),
    bandwidthVoices() {
      return [
        'Generic US Male',
        'Generic US Female',
        'Generic UK Male',
        'Generic UK Female',
        ...this.selectBandwidthVoices,
      ]
    },
  },
  methods: {
    emitUpdate() {
      const modification = {
        sentence: this.modifiedSentence,
      }

      if (this.selectedBandwidthVoice) {
        if (this.selectedBandwidthVoice.startsWith('Generic')) {
          modification.gender = this.selectedBandwidthVoice.endsWith('Female') ? 'female' : 'male'
          modification.locale =
            this.selectedBandwidthVoice.split(' ')[1] === 'UK' ? 'en_UK' : 'en_US'
        } else {
          modification.voice = this.selectedBandwidthVoice
        }
      }

      this.$emit('speakSentenceUpdate', modification)
    },
  },
  watch: {
    modifiedSentence(val) {
      if (val !== this.sentence) {
        this.emitUpdate()
      }
    },
    selectedBandwidthVoice(val) {
      if (val) {
        if (val.startsWith('Generic')) {
          const valGender = val.endsWith('Female') ? 'female' : 'male'
          const valLocale = val.split(' ')[1] === 'UK' ? 'en_UK' : 'en_US'
          if (valGender !== this.gender || valLocale !== this.locale) {
            this.emitUpdate()
          }
        } else if (val !== this.voice) {
          this.emitUpdate()
        }
      }
    },
  },
  mounted() {
    this.modifiedSentence = this.sentence

    if (['male', 'female'].includes(this.gender) && ['en_US', 'en_UK'].includes(this.locale)) {
      let genericVoice = `Generic ${this.locale.replace('en_', '')} `

      if (this.gender === 'male') {
        genericVoice += 'Male'
      } else {
        genericVoice += 'Female'
      }
      this.selectedBandwidthVoice = genericVoice
    } else if (this.voice) {
      this.selectedBandwidthVoice = this.voice
    }
    this.emitUpdate()
  },
}
</script>
