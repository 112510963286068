<template>
  <b-modal
    id="AddLocationModal"
    hide-footer
    hide-header
    centered
    body-class="p-0"
    size="lg"
    @shown="showing = !showing"
    @hidden="clearState"
  >
    <div class="px-4 pt-4">
      <!-- header -->
      <div class="d-flex justify-content-between border-bottom">
        <h1>Create Location</h1>
        <i class="fas fa-times fa-lg mt-2" @click="close"></i>
      </div>

      <!-- body -->
      <div class="card-body px-5">
        <!-- auto location info -->
        <div v-if="!manualEntry">
          <div class="text-center">
            <div class="card shadow">
              <div class="card-header">
                <div class="row">
                  <div class="col-12">
                    <LocationLookupInput @searched="searchLocations" :showing="showing" />
                  </div>
                </div>
              </div>
              <div v-if="locationResults.length" class="card-body">
                <LocationLookupResults
                  :locationResults="locationResults"
                  @selectedLocation="selectedLocation"
                />
              </div>
            </div>
            <button class="btn btn-outline-light text-muted btn-sm" @click="manualEntry = true">
              Enter Manually
            </button>
          </div>
        </div>

        <!-- manual entry -->
        <div v-else>
          <div>
            <label for="name" class="font-weight-bold"> Location Name </label>
            <input type="text" id="name" v-model="name" class="form-control mb-5" />

            <label for="friendlyName" class="font-weight-bold"> Friendly Name (optional) </label>
            <input type="text" id="friendlyName" v-model="friendlyName" class="form-control mb-5" />

            <label for="address" class="font-weight-bold"> Address </label>
            <input type="text" id="address" v-model="address" class="form-control mb-5" />

            <label for="phone" class="font-weight-bold"> Phone Number </label>
            <input type="text" id="phone" v-model="phone" class="form-control mb-5" />

            <label for="placeId" class="font-weight-bold"> Google Place Id (optional) </label>
            <input type="text" id="placeId" v-model="placeId" class="form-control mb-5" />
          </div>
          <div class="text-center">
            <button class="btn btn-outline-light text-muted btn-sm" @click="manualEntry = false">
              Use Google
            </button>
          </div>
        </div>
      </div>

      <!-- footer -->
      <div class="border-top py-4 text-right" v-if="manualEntry">
        <button
          class="btn btn-primary"
          style="width: 120px"
          @click="create"
          :disabled="!validInputs"
        >
          <div class="d-flex justify-content-center" v-if="createLoading">
            <div class="loader loader-light"></div>
          </div>
          <span v-else> Create </span>
        </button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { createNamespacedHelpers } from 'vuex'
import LocationLookupInput from '@/components/Modules/Locations/AddLocation/LocationLookupInput'
import LocationLookupResults from '@/components/Modules/Locations/AddLocation/LocationLookupResults'
import GoogleService from '@/services/GoogleService'

const LocationModule = createNamespacedHelpers('location')
const UserModule = createNamespacedHelpers('user')

export default {
  name: 'AddLocationModal',
  props: ['companyId'],
  data: () => ({
    showing: false,
    createLoading: false,
    manualEntry: false,
    locationResults: [],
    location: null,
    name: '',
    friendlyName: '',
    address: '',
    phone: '',
    placeId: '',
  }),
  components: {
    LocationLookupInput,
    LocationLookupResults,
  },
  computed: {
    ...LocationModule.mapGetters(['selectSearchQuery']),
    ...UserModule.mapGetters(['selectUsers']),
    validInputs() {
      return this.name && this.address && this.phone
    },
  },
  methods: {
    ...LocationModule.mapActions(['createLocation', 'setIsSearching']),
    ...UserModule.mapActions(['getUsersList']),
    async searchLocations() {
      if (!this.selectSearchQuery) {
        this.locationResults = []
        this.setIsSearching(false)
        return
      }
      this.setIsSearching(true)
      this.locationResults = await GoogleService.locationSearch({
        input: this.selectSearchQuery,
        types: ['establishment'],
        regions: {
          country: 'us',
        },
      })
      this.setIsSearching(false)
    },
    selectedLocation(location) {
      this.location = location
      this.create()
    },
    close() {
      this.$root.$emit('bv::hide::modal', 'AddLocationModal')
    },
    async create() {
      try {
        if (this.manualEntry) this.createLoading = true
        this.$notify({ text: 'Adding Location...' })
        this.close()
        await this.createLocation({
          companyId: this.companyId,
          ...(!this.manualEntry
            ? this.location
            : {
                name: this.name,
                friendlyName: this.friendlyName || undefined,
                address: this.address,
                phone: this.phone,
                placeId: this.placeId || undefined,
              }),
        })
        this.$emit('refreshLocations')
        this.$notify({ text: 'Successfully added location to this company' })
        this.clearState()
      } catch (error) {
        this.$notify({
          title: 'Error adding location',
          text: `${error.body.message}`,
          type: 'error',
          duration: 5000,
        })
      }
    },
    clearState() {
      this.location = null
      this.name = ''
      this.friendlyName = ''
      this.address = ''
      this.phone = ''
      this.placeId = ''
      this.locationResults = []
      this.createLoading = false
    },
  },
}
</script>
