import IvrService from '../services/IvrService'

export default {
  namespaced: true,

  state: {
    enums: {},
    ivrs: [],
  },

  getters: {
    selectBandwidthVerbs: (state) =>
      state.enums.BANDWIDTH_VERBS ? Object.values(state.enums.BANDWIDTH_VERBS) : [],
    selectIvrActions: (state) =>
      state.enums.IVR_ACTIONS ? Object.values(state.enums.IVR_ACTIONS) : [],
    selectBandwidthVoices: (state) =>
      state.enums.BANDWIDTH_VOICES ? Object.values(state.enums.BANDWIDTH_VOICES) : [],
    selectBandwidthLocales: (state) =>
      state.enums.BANDWIDTH_LOCALES ? Object.values(state.enums.BANDWIDTH_LOCALES) : [],
    selectIvrDynamicFields: (state) =>
      state.enums.IVR_DYNAMIC_FIELDS ? Object.values(state.enums.IVR_DYNAMIC_FIELDS) : [],
    selectIvrs: (state) => state.ivrs,
  },

  mutations: {
    SET_IVRS(state, ivrs) {
      state.ivrs = ivrs
    },
    SET_IVR_ENUMS(state, ivrEnums) {
      state.enums = ivrEnums
    },
    REMOVE_IVR_ID(state) {
      delete state.ivrs[0]?._id
    },
  },

  actions: {
    async createIvr({ commit }, options) {
      const response = await IvrService.create(options)
      commit('SET_IVRS', [response.body.ivr])
    },
    async deleteIvr({ commit }, { ivrId }) {
      const response = await IvrService.delete(ivrId)
      commit('SET_IVRS', [])
    },
    async getIvrs({ commit }, options) {
      commit('SET_IVRS', [])
      const response = await IvrService.list(options)
      commit('SET_IVRS', response.body.ivrs)
    },
    async getIvrEnums({ commit }) {
      const response = await IvrService.enums()
      commit('SET_IVR_ENUMS', response.body)
    },
    async updateIvr({ commit }, { ivrId, modifications }) {
      const response = await IvrService.update(ivrId, modifications)
      commit('SET_IVRS', [response.body.ivr])
    },
    // removeIvrId is used when a location specific ivr is toggled on and it copies the company level
    async removeIvrId({ commit }) {
      commit('REMOVE_IVR_ID')
    }
  },
}
